import React from 'react'

import { RegularMediumText } from 'common/texts'

export default function EmptyCompanies () {
  return (
    <RegularMediumText>
      Companies not found
    </RegularMediumText>
  )
}
