import { createUseGlobalState, createUseSetGlobalState } from 'hooks/useGlobalState'

export const useCompanies = createUseGlobalState('companies')
export const useSetCompanies = createUseSetGlobalState('companies')

export const useCompany = createUseGlobalState('company')
export const useSetCompany = createUseSetGlobalState('company')

export const useCompanyFilter = createUseGlobalState('companyFilter')
export const useSetCompanyFilter = createUseSetGlobalState('companyFilter')

export const useCompanyPatients = createUseGlobalState('companyPatients')
export const useSetCompanyPatients = createUseSetGlobalState('companyPatients')

export const useCompanyForm = createUseGlobalState('companyForm')
export const useSetCompanyForm = createUseSetGlobalState('companyForm')

export const useCompanyPlans = createUseGlobalState('companyPlans')
export const useSetCompanyPlans = createUseSetGlobalState('companyPlans')

export const useCompanyPlansMembers = createUseGlobalState('companyPlansMembers')
export const useSetCompanyPlansMembers = createUseSetGlobalState('companyPlansMembers')
