import request from 'utils/request';
import { PARTICIPANT_API_GRAPHQL_URL } from 'env';
import { Thunder, GraphQLTypes, InputType, OperationOptions, ValueTypes } from 'zeus-graphql/participant/zeus';
import { UseQueryOptions } from '@tanstack/react-query';
import _ from 'lodash';
import createNotification from 'utils/createNotification';
import { useTypedQuery } from 'zeus-graphql/participant/reactQuery';
import { getResponseError } from 'utils/getErrors';
import moment from 'moment';
export const GQL = Thunder(async (query, variables) => {
  return request({
    method: 'post',
    baseURL: PARTICIPANT_API_GRAPHQL_URL,
    data: { query, variables },
  } as any);
});

export const GQLQuery = GQL('query');
export const GQLMutation = GQL('mutation');

const callToast = (error: any) => {
  const message = getResponseError(error);
  if (error) {
    createNotification({ message, type: 'error' });
  }
};

export function useQuery<O extends 'Query', TData extends ValueTypes[O], TResult = InputType<GraphQLTypes[O], TData>>(
  queryKey: unknown[],
  query: TData | ValueTypes[O],
  zeusOptions?: OperationOptions & {
    headers?: Record<string, any>;
    errorTypes?: {
      __typename: string;
      message?: string;
      entityName: keyof Partial<InputType<GraphQLTypes[O], TData>>;
    }[];
  },
  options?: Omit<UseQueryOptions<TResult>, 'queryKey' | 'queryFn'>
) {
  return useTypedQuery(
    queryKey,
    query,
    {
      ...options,
      onSuccess: (data: TResult) => {
        if (data) {
          const errorTag = zeusOptions?.errorTypes?.find((type) => _.get(data, `${type.entityName as string}.__typename`) === type.__typename);

          if (errorTag && _.get(data, `${errorTag.entityName as string}`)) {
            const msg = errorTag.message || (_.get(data, `${errorTag.entityName as string}.message`, 'Oops, something went wrong') as string);
            const apolloError = new Error(msg);
            callToast(apolloError);
            options?.onError?.(apolloError);
            return;
          }
        }
        options?.onSuccess?.(data);
      },
      onError: (error: any) => {
        options?.onError?.(error);

        if (error?.message === 'Failed to fetch') {
          createNotification({ message: 'No connection to the server. Try again later', type: 'error' });
        } else {
          callToast(error);
        }
      },
    },
    zeusOptions,
    PARTICIPANT_API_GRAPHQL_URL,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('idToken')}`,
        TimeZone: moment().format('ZZ'),
        'X-Auth-Source': localStorage.getItem('role') || 'provider',
        ...zeusOptions?.headers,
      },
    }
  );
}
