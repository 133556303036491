import React, { FC } from 'react';

import { ReactComponent as ActivityIcon } from 'assets/activity.svg';
import { ReactComponent as DateIcon } from 'assets/date.svg';

import { SectionHeader } from '../../SectionHeader';
import { LibraryArticle } from '../../LibraryArticle/LibraryArticle';
import { AttachedFile } from '../../AttachedFile';
import { TaskType } from 'requests/graphql/my-health/queries/getTreatmentPlan';

type ActivityViewProps = {
  tasks?: TaskType<'activity'>[];
  isPreview?: boolean;
  onEdit: () => void;
  onDelete: () => void;
};

export const ActivityView: FC<ActivityViewProps> = ({ tasks, isPreview, onEdit, onDelete }) => {
  return (
    <div className="shadow-row p-6 border rounded-main mb-6">
      <div className="pb-6">
        <SectionHeader title="Activity" icon={<ActivityIcon />} onEdit={onEdit} isPreview={isPreview} onDelete={onDelete} />
      </div>
      {tasks?.map((task, taskIndex) => (
        <React.Fragment key={task.id || 'activity' + taskIndex}>
          {taskIndex > 0 && <div className="border-t w-full h-px my-6"></div>}
          <div className="">
            <div className="">
              <div className="flex justify-between mb-2">
                <div className="text-1822 font-ambit text-main font-semibold">{task.details.summary}</div>
                {task.details.duration && (
                  <div className="flex ">
                    <DateIcon className="w-[2rem] h-[2rem]" />
                    <div className="ml-2 text-1420 font-ambit text-main font-semibold">
                      {task.details.duration} {task.details.duration > 1 ? 'Days' : 'Day'}
                    </div>
                  </div>
                )}
              </div>
              {task.details && <div className="ck-editor-view" dangerouslySetInnerHTML={{ __html: task.details.description }}></div>}
            </div>
            {!!task.materials.length && <div className="border-t w-full h-px my-6"></div>}
            <div className="">
              {task.materials?.map((m, mi) => (
                <LibraryArticle
                  key={m.id || 'material' + mi}
                  materialId={m.materialVersion.materialId}
                  isPreview={isPreview}
                  title={m.materialVersion.title}
                  category="Diet"
                />
              ))}
            </div>
            {!!task.files.length && <div className="border-t w-full h-px my-6"></div>}
            <div className="flex">
              {task.files?.map((file, fi) => (
                <AttachedFile id={file.id} key={file.id || 'file' + fi} className="mr-4" type={file.fileType as any} filename={file.fileName} />
              ))}
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};
