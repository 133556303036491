import React from 'react'
import styled from 'styled-components'

import { WIDGETS } from 'constants/routes'; 

import { useFetchWidgetsList } from 'requests/patients/widgets/widgets'

import DetailsTemplate from 'components/patients/DetailsTemplate'
import WidgetsList from 'components/patients/widgets/WidgetsList'

export default function PatientWidgets () {
  const fetchWidgetsList = useFetchWidgetsList()

  React.useEffect(() => {
    fetchWidgetsList()
    // eslint-disable-next-line
  }, [])

  return (
    <DetailsTemplate currentTab={WIDGETS}>
      <StyledWrapper>
        <WidgetsList />
      </StyledWrapper>
    </DetailsTemplate>
  )
}

const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  margin: 0 auto;
  @media only screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
  .tab {
    min-height: 42rem;
    min-width: 42rem;
    padding: 3rem 0;
    @media only screen and (max-width: 1024px) {
      width: 100%;
    }
  }
`
