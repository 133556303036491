import React from 'react'

import { useCompanyFilter, useCompanyPatients, useSetCompanyPatients } from 'hooks/useCompanies'
import { useFetchCompanyPatients } from 'requests/companies'

import PatientsPlaceholder from './PatientsPlaceholder'
import EmptyPatients from './EmptyPatients'
import PatientsList from './PatientsList'

export default function PatientsLoader () {
  const companyPatients = useCompanyPatients()
  const companyFilter = useCompanyFilter()
  const fetchCompanyPatients = useFetchCompanyPatients()
  const clearPatinets = useSetCompanyPatients(() => null, [])

  React.useEffect(() => {
    fetchCompanyPatients(companyFilter)
    return clearPatinets
    // eslint-disable-next-line
  }, [companyFilter])

  if (!companyPatients) return <PatientsPlaceholder />

  if (companyPatients.length === 0) return <EmptyPatients />

  return <PatientsList />
}
