import React from 'react'
import styled, { css } from 'styled-components'

import { ReactComponent as Shape } from 'assets/shape.svg'

const rates = [5, 4, 3, 2, 1]

const ShapeComponent = function ShapeComponent ({ className = '', value, rate, setRate }) {
  const clickHandler = React.useCallback(() => {
    setRate(value)
  }, [setRate, value])
  return (
    <Shape
      className={`shape ${value <= rate ? 'active' : ''}`}
      onClick={clickHandler}
    />
  )
}

export default React.memo(function RateUs ({ className = '', rate, setRate }) {

  return (
    <StyledWrapper
      className={`${className} rate-container`}
      setRate={setRate}
    >
      {rates.map(item => {
        return (
          <ShapeComponent
            key={item}
            value={item}
            rate={rate}
            setRate={setRate}
          />
        )
      })}
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  .shape {
    display: block;
    width: 2.2rem;
    height: 1.8rem;
    padding: 0 0.2rem;
    cursor: pointer;
    ${({ setRate }) => setRate && css`
      :hover path {
        fill: #fab005;
      }
    `}
  }
  .shape.active {
    path {
      fill: #fab005;
    }
  }
  ${({ setRate }) => setRate && css`
    .shape:hover ~ .shape path {
      fill: #fab005;
    }
  `}
`
