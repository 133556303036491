import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { PATIENTS, DETAILS, WIDGETS, WITHINGS, WEIGHT } from 'constants/routes'; 

import DayLabel from 'common/widgets/DayLabel'
import ViewData from 'common/widgets/ViewData'
import HalfCircleIndicator from 'common/widgets/HalfCircleIndicator'

export default function WithingsWeightWidget ({ widgetId, lastResult, data }) {
  const { patientId } = useParams()
  const history = useHistory()
  const {
    summaryDate,
    weight
  } = lastResult

  const valueInDeg = 135

  const navigateToWithingsWeight = React.useCallback(() => {
    history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${WIDGETS}/${WITHINGS}/${widgetId}/${WEIGHT}`)
  }, [history, patientId, widgetId])

  return (
    <>
      <DayLabel date={summaryDate} />

      <div className='g-d-flex g-align-center g-w-100 g-mt-30'>
        <HalfCircleIndicator
          label='Now'
          value={weight || 0}
          valueInDeg={valueInDeg}
          units='lb'
        />
      </div>

      <ViewData
        className='g-mt-45'
        clickHandler={navigateToWithingsWeight}
      />
    </>
  )
}
