import React, { useState } from 'react';
import { LOGIN } from 'constants/routes';
import AuthTemplate from 'components/auth/AuthTemplate';
import AuthLink from 'components/auth/AuthLink';
import AuthInput from 'components/auth/AuthInput';
import { RegularText } from 'common/texts';
import SubmitButton from 'components/auth/forgot-password/SubmitButton';
import useTranslations from 'translations';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const { title, hint, emailPlaceholder, signInLink } = useTranslations().forgotPassword;

  return (
    <AuthTemplate title={title}>
      <RegularText className="g-mt-40 g-mb-10">{hint}</RegularText>
      <AuthInput className="g-mb-15" value={email} changeHandler={setEmail} placeholder={emailPlaceholder} type="email" />
      <SubmitButton email={email} />
      <AuthLink value={signInLink} href={`/${LOGIN}`} />
    </AuthTemplate>
  );
}
