import React from 'react'
import styled from 'styled-components'

import CalendarHeader from '../CalendarHeader'

export default React.memo(function WorkWeekHeader ({ workWeekStart, setWorkWeekStart }) {
  const content = React.useMemo(() => {
    return (new Array(5))
      .fill(null)
      .map((item, i) => {
        const currentDay = workWeekStart.clone().add(i, 'days')
        return (
          <div
            key={i}
            className='day-container'
          >
            <div className='day'>{currentDay.format('DD')}</div>
            <div className='week-day'>{currentDay.format('ddd')}</div>
          </div>
        )
      })
  }, [workWeekStart])
  return (
    <CalendarHeader
      onPrev={React.useCallback(() => setWorkWeekStart(workWeekStart.clone().add(-7, 'days')), [workWeekStart, setWorkWeekStart])}
      onNext={React.useCallback(() => setWorkWeekStart(workWeekStart.clone().add(7, 'days')), [workWeekStart, setWorkWeekStart])}
    >
      <StyledWrapper>
        {content}
      </StyledWrapper>
    </CalendarHeader>
  )
})

const StyledWrapper = styled.div`
  display: flex;
  margin: 0 7rem;
  width: 100%;
  .day-container {
    width: ${100 / 5}%;
    text-align: center;
    .day {
      font-size: 1.8rem;
      line-height: 2rem;
      font-weight: 600;
    }
    .week-day {
      font-size: 1.3rem;
      line-height: 1.5rem;
    }
  }
`
