import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import { DASHBOARD, PROVIDERS, CREATE } from 'constants/routes'; 


import { PlateHeader } from 'common/plate/styles'
import BackButton from 'common/plate/BackButton'
import CustomButton from 'common/CustomButton'

export default React.memo(function ProvidersHeader () {
  const history = useHistory()

  const navigateToCreate = React.useCallback(() => {
    history.push(`/${PROVIDERS}/${CREATE}`)
  }, [history])

  return (
    <PlateHeader>
      <BackButton href={`/${DASHBOARD}`} />
      Providers

      <StyledCustomButton clickHandler={navigateToCreate}>
        Add new
      </StyledCustomButton>
    </PlateHeader>
  )
})

const StyledCustomButton = styled(CustomButton)`
  padding: 1rem 2.5rem;
  margin-left: auto;
`
