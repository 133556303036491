import React from 'react'
import styled from 'styled-components'

import { ReactComponent as DownloadIcon } from 'assets/download.svg'

import { useDownloadFile } from 'requests/appointments'

import eventPath from 'utils/eventPath'

export default React.memo(function DownloadButton () {
  const downloadFile = useDownloadFile()
  const [isOpen, setIsOpen] = React.useState(false)
  const togleOptions = React.useCallback(() => setIsOpen(current => !current), [setIsOpen])
  const closeOptions = React.useCallback(() => setIsOpen(false), [setIsOpen])
  const ref = React.useRef()

  React.useEffect(() => {
    function handleCloseOption (e) {
      if (eventPath(e).includes(ref.current)) return
      setIsOpen(false)
    }

    window.addEventListener('click', handleCloseOption)
    return () => window.removeEventListener('click', handleCloseOption)
  }, [])

  const fetchFile = React.useCallback((type) => {
    downloadFile(type)
    closeOptions()
  }, [downloadFile, closeOptions])

  return (
    <StyledWrapper
      ref={ref}
      className='download'
    >
      <div
        className='button'
        onClick={togleOptions}
      >
        <DownloadIcon
          className='icon'
        />
        <div className='label'>
          Download
        </div>
        <div className='select-wrapper'>
          <div className='select-icon' />
        </div>
      </div>

      {isOpen && (
        <div className='options-list'>
          <div
            className='option'
            onClick={() => fetchFile('pdf')}
          >
            Download in PDF
          </div>
          <div
            className='option'
            onClick={() => fetchFile('excel')}
          >
            Download in Excel
          </div>
        </div>
      )}
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  position: relative;
  align-self: flex-end;
  .button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #16123f;
    cursor: pointer;
    border: solid 1px #16123f;
    border-radius: 2px;
    transition: all 0.2s;
    :hover {
      background: rgba(22, 18, 63, 0.8);
    }
    :active {
      box-shadow: 0 0 0 0.3rem rgba(22, 18, 63, 0.25);
    }
    .icon {
      display: block;
      width: 1.4rem;
      height: 1.4rem;
      margin-left: 1.6rem;
    }
    .label {
      font-size: 1.3rem;
      line-height: 2.4rem;
      color: #fff;
      padding: 1.1rem 1.5rem;
      transition: color 0.2s;
    }
    .select-wrapper {
      padding: 2rem 1.2rem;
      border-left: 1px solid #2D2A52;
      .select-icon {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 6px solid #fff;
        transition: all 0.2s;
      }
    }
  }
  .options-list {
    position: absolute;
    top: 100%;
    width: 100%;
    background: #fff;
    transform: translate(0, 1.1rem);
    border: 1px solid #dadada;
    z-index: 999;
    .option {
      font-size: 1.3rem;
      padding: 1rem 1.5rem;
      cursor: pointer;
      :hover {
        background: #fbfbfb;
      }
    }
  }
`
