import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { DASHBOARD, APPOINTMENTS, DAY, WEEK, WORK_WEEK, MONTH, PROFILE, AVAILABILITY, CREATE } from 'constants/routes';
import { useGoogleLogin } from '@react-oauth/google';

import { RowPlate } from 'common/plate/plates';
import BackButton from 'common/plate/BackButton';
import { PlateHeader, PlateContent } from 'common/plate/styles';
import TabsSwitcher from 'common/TabsSwitcher';
import LinkButton from 'common/LinkButton';
import request from '../../utils/request';

import { globalContext, useSetUser, useUser } from '../../hooks/useGlobalState';
import { useFetchUser } from '../../requests/user';
import checkmark from 'assets/checkmark.svg';
import close from 'assets/close.svg';
import { GOOGLE_CALENDAR_CLIENT_ID } from '../../env';

const tabsConfig = [
  { id: MONTH, label: 'Month', href: `/${APPOINTMENTS}/${MONTH}` },
  { id: WORK_WEEK, label: 'Work week', href: `/${APPOINTMENTS}/${WORK_WEEK}` },
  { id: WEEK, label: 'Week', href: `/${APPOINTMENTS}/${WEEK}` },
  { id: DAY, label: 'Day', href: `/${APPOINTMENTS}/${DAY}` },
];

export default React.memo(function AppointmentsTemplate({ children, currentTab }) {
  const fetchUser = useFetchUser();
  const user = useUser();

  const clientId = GOOGLE_CALENDAR_CLIENT_ID;

  const calendarSyncedCode = Number(user.google_calendar_synced);

  const checkmarkIcon = calendarSyncedCode === 2 ? checkmark : close;

  let syncText = (
    <>
      Your calendar is not synchronized with Google Calendar. <br /> Click the button below to sync appointments with Google Calendar.
    </>
  );

  if (calendarSyncedCode === 2) {
    syncText = 'Your calendar is synchronized with Google Calendar.';
  }

  if (calendarSyncedCode === 1) {
    syncText = 'Retry please and grant permissions for google calendar.';
  }

  const responseGoogle = React.useCallback(
    async (response) => {
      console.log(response);
      await request({
        url: 'integrations/google/create-tokens',
        method: 'post',
        data: {
          code: response.code,
        },
      });

      await fetchUser();
    },
    [fetchUser]
  );

  const responseError = (response) => {
    console.log(response);
  };

  const history = useHistory();
  const navigateToCreateAppointment = React.useCallback(() => {
    history.push(`/${APPOINTMENTS}/${CREATE}`);
  }, [history]);

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => responseGoogle(tokenResponse),
    onError: (error) => responseError(error),
    scope: 'openid email profile https://www.googleapis.com/auth/calendar',
    flow: 'auth-code',
  });

  return (
    <RowPlate>
      <PlateHeader>
        <BackButton href={`/${DASHBOARD}`} />
        Appointments
      </PlateHeader>
      <script src="https://accounts.google.com/gsi/client" async defer></script>
      <PlateContent>
        <TabsSwitcher className="g-mt-20 g-mb-30" tabsConfig={tabsConfig} currentTab={currentTab} />

        {children}
        <StyledCalendarSyncMessage success={calendarSyncedCode === 2}>
          <div className="checkmark">
            <img alt="checkmark" src={checkmarkIcon} />
          </div>
          <div className="checkmark-text">{syncText}</div>
        </StyledCalendarSyncMessage>

        <StyledWrapper>
          <LinkButton onClick={() => login()} style={{ marginRight: '10px' }}>
            Sync with Google Calendar
          </LinkButton>
          <LinkButton
            onClick={React.useCallback(() => {
              history.push({
                pathname: `/${PROFILE}/${AVAILABILITY}`,
                state: { referrer: `/${APPOINTMENTS}/${currentTab}` },
              });
            }, [history, currentTab])}
          >
            Change your availablility
          </LinkButton>
          <LinkButton className="g-ml-auto" onClick={navigateToCreateAppointment}>
            Create appointment
          </LinkButton>
        </StyledWrapper>
      </PlateContent>
    </RowPlate>
  );
});

const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: auto;
  align-self: flex-start;
`;
const StyledCalendarSyncMessage = styled.div`
  display: flex;
  width: 465px;
  margin-bottom: 10px;
  .checkmark {
    background-color: ${(props) => (props.success ? '#84C972' : '#FE6968')};
    border-radius: 50%;
    margin-right: 10px;
    height: 22px;
    padding: ${(props) => (props.success ? '5px 5px' : '4px 7px')};
    padding-left: ${(props) => (props.success ? '5px' : '8px')};
  }
  .checkmark-text {
    font-size: 13px;
    line-height: 19px;
  }
`;
