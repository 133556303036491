import React from "react";

import { AVAILABILITY } from "constants/routes";  

import ProfileTemplate from "components/profile/ProfileTemplate";
import AvailableTime from "components/profile/availability/available-time/index";

export default function Availability() {
  return (
    <ProfileTemplate currentTab={AVAILABILITY}>
      <AvailableTime />
    </ProfileTemplate>
  );
}
