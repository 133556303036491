import React from 'react'

import { useCompanies } from 'hooks/useCompanies'
import { useFetchCompanies } from 'requests/companies'

import CompaniesPlaceholder from './CompaniesPlaceholder'
import EmptyCompanies from './EmptyCompanies'
import CompaniesList from './CompaniesList'

export default function CompaniesLoader () {
  const fetchCompanies = useFetchCompanies()
  const companies = useCompanies()

  React.useEffect(() => {
    fetchCompanies()
    // eslint-disable-next-line
  }, [])

  if (!companies) return <CompaniesPlaceholder />

  if (companies.length === 0) return <EmptyCompanies />

  return <CompaniesList />
}
