import React from 'react';
import { get } from 'lodash';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { PROVIDERS, PATIENTS, DETAILS, OVERVIEW, SUMMARY } from 'constants/routes';

import { UppercaseText, RegularText } from 'common/texts';
import UserAvatar from 'common/UserAvatar';
import EditButton from './edit-button';
import ViewButton from './ViewButton';
import { useUser } from '../../../../hooks/useGlobalState';

export default React.memo(function AppointmentsPreview({ id, startedAt, provider, patient, status }) {
  const history = useHistory();
  const user = useUser();

  const navigateToProvider = React.useCallback(() => {
    history.push(`/${PROVIDERS}/${provider.id}/${SUMMARY}`);
  }, [history, provider]);

  const navigateToPatient = React.useCallback(() => {
    history.push(`/${PATIENTS}/${DETAILS}/${patient.id}/${OVERVIEW}`);
  }, [history, patient]);

  return (
    <StyledWrapper>
      <RegularText className="time">{moment.utc(startedAt).local().format('h:mm a')}</RegularText>

      <div className="information">
        <UserAvatar
          className="avatar"
          avatar={provider.avatar}
          firstName={provider.first_name}
          lastName={provider.last_name}
          onClick={navigateToProvider}
        />
        <div>
          <RegularText className="name">{provider.full_name}</RegularText>
          <UppercaseText>Provider</UppercaseText>
        </div>
      </div>

      <div className="information">
        <UserAvatar className="avatar" firstName={patient.first_name} lastName={patient.last_name} onClick={navigateToPatient} />
        <div>
          <RegularText className="name">{patient.full_name}</RegularText>
          <UppercaseText>Patient</UppercaseText>
        </div>
      </div>

      <UppercaseText className="status">{status}</UppercaseText>
      <div className={`flex flex-col w-[12rem] h-[8rem] ${provider.id === user.id ? 'justify-between' : 'justify-center'}`}>
        {provider.id === user.id && <ViewButton id={id} status={status} />}
        <EditButton id={id} status={status} />
      </div>
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  padding: 2rem;
  background: #fafbfd;
  border: solid 1px #e9ebef;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  .time {
    width: 10%;
  }
  .name {
    line-height: 2.5rem;
  }
  .information {
    display: flex;
    width: 30%;
    .avatar {
      margin-right: 1.5rem;
    }
  }
  .status {
    width: 15%;
  }
  .edit-button {
    width: 15%;
  }
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: space-between;
    .time {
      width: 15%;
    }
    .information {
      width: initial;
      text-align: center;
    }
    .status {
      width: 20%;
      text-align: right;
    }
    .edit-button {
      width: 100%;
      margin-top: 1rem;
    }
  }
  @media screen and (max-width: 480px) {
    .time {
      width: 15%;
    }
    .information {
      display: block;
      .avatar {
        margin: 0 auto;
      }
    }
  }
`;
