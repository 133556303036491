import React from 'react'
import styled from 'styled-components'

import StatsItem from '../../common/StatsItem'
import { MediumText, HintText } from 'common/texts'

import onlyPositive from 'utils/onlyPositive'
import toRelative from 'utils/toRelative'
import normalizePercentageValue from 'utils/normalizePercentageValue'
import valueToLabel from 'utils/widgets/valueToLabel'

export default function ActivityData ({ data }) {
  const normalizedActivityScore = onlyPositive(data.score)
  const normalizedCalActive = onlyPositive(data.cal_active)
  const normalizedTargetCalories = onlyPositive(data.target_calories)

  const normalizedGoalProgress = React.useMemo(() => {
    const percentageValue = toRelative(normalizedCalActive, normalizedTargetCalories)
    return normalizePercentageValue(percentageValue)
  }, [normalizedCalActive, normalizedTargetCalories])

  const normalizedTotalCalories = onlyPositive(data.cal_total)

  const normalizedDailyMovement = React.useMemo(() => {
    const dailyMovement = onlyPositive(data.daily_movement / 1000)
    return dailyMovement ? dailyMovement.toFixed(1) : 0
  }, [data])

  const normalizedSteps = onlyPositive(data.steps)

  return (
    <StyledWrapper>
      <div className='value-wrapper'>
        <MediumText className='g-mb-15'>Activity</MediumText>
        <span className='value g-mr-5'>{normalizedActivityScore}</span>
        <HintText className='hint g-mb-15'>
          {valueToLabel(normalizedActivityScore)}
        </HintText>
      </div>
      <StatsItem
        className='g-d-flex g-fw-w g-align-center'
        hintClassName='g-mr-10'
        statsTitle='Goal progress:'
        statsData={`${normalizedGoalProgress}%`}
      />
      <StatsItem
        className='g-d-flex g-fw-w g-align-center g-mt-15'
        hintClassName='g-mr-10'
        statsTitle='Total burn:'
        statsData={`${normalizedTotalCalories} Cal`}
      />
      <StatsItem
        className='g-d-flex g-fw-w g-align-center g-mt-15'
        hintClassName='g-mr-10'
        statsTitle='Walking equivalence:'
        statsData={`${normalizedDailyMovement} km`}
      />
      <StatsItem
        className='g-d-flex g-fw-w g-align-center g-mt-15'
        hintClassName='g-mr-10'
        statsTitle='Steps:'
        statsData={normalizedSteps}
      />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  .value-wrapper {
    @media screen and (max-width: 480px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .value {
    font-size: 3rem;
    @media screen and (max-width: 480px) {
      margin: 0 0.5rem 1.5rem;
    }
  }
`
