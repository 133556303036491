import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import { MESSAGES } from 'constants/routes'; 

import { ReactComponent as Bell } from 'assets/bell.svg'

import NotificationsCounter from 'common/NotificationsCounter'

export default React.memo(function Notifications () {
  const history = useHistory()

  const navigateToMessages = React.useCallback(() => {
    history.push(`/${MESSAGES}`)
  }, [history])

  return (
    <StyledWrapper onClick={navigateToMessages}>
      <Bell className='bell' />

      <NotificationsCounter />
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  position: relative;
  cursor: pointer;
  .bell {
    display: block;
    width: 2rem;
    height: 2rem;
  }
`
