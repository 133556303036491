import React from 'react'

export default React.memo(function PatientFeelingSick () {
  return (
    <div>
      Please describe your state.<br />
      You can write directly to your provider or to this group.<br />
      Book appointment with your provider as soon as possible.
    </div>
  )
})
