export const permissions = {
  patient: {
    canChangePassword: 'patient.canChangePassword',
    canChangeDoctorVisits: 'patient.canChangeDoctorVisits',
    canSetTestPatients: 'patient.canSetTestPatients',
    canExportAllData: 'patient.canExportAllData',
    canDeletePatient: 'patient.canDeletePatient',
    canVerifyEmail: 'patient.canVerifyEmail',
    canEditProfile: 'patient.canEditProfile',
    canAddAdminNotes: 'patient.canAddAdminNotes',
    canEditDoctorNotes: 'patient.canEditDoctorNotes',
    canDeleteDoctorNotes: 'patient.canDeleteDoctorNotes',
  },
  providers: {
    canViewPage: 'providers.canViewPage',
  },
  appointmentsAdmin: {
    canViewPage: 'appointmentsAdmin.canViewPage',
  },
  activityLog: {
    canViewPage: 'activityLog.canViewPage',
  },
  companies: {
    canAddNewCompany: 'companies.canAddNewCompany',
    canSeeCoupon: 'companies.canSeeCoupon',
    canDeletePatient: 'companies.canDeletePatient',
  },
  messages: {
    canChangeSettings: 'messages.canChangeSettings',
  },
  library: {
    canSeeStatus: 'library.canSeeStatus',
  },
} as const;
