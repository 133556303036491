import React, { FC } from 'react';
import { TailwindCustomColors, useTailwindColors } from '../../hooks/useTailwindTheme';

type Props = {
  size?: number;
  mainColor?: keyof TailwindCustomColors;
  secondaryColor?: keyof TailwindCustomColors;
};

export const CreateOutlineIcon: FC<Props> = ({ size = 16, mainColor = 'main', secondaryColor }) => {
  const colors = useTailwindColors();
  const mColor = colors[mainColor] || colors.main;
  const secColor = secondaryColor ? colors[secondaryColor] || mColor : mColor;
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 7V12.75C12 12.9142 11.9677 13.0767 11.9048 13.2284C11.842 13.38 11.75 13.5178 11.6339 13.6339C11.5178 13.75 11.38 13.842 11.2284 13.9048C11.0767 13.9677 10.9142 14 10.75 14H3.25C2.91848 14 2.60054 13.8683 2.36612 13.6339C2.1317 13.3995 2 13.0815 2 12.75V5.25C2 4.91848 2.1317 4.60054 2.36612 4.36612C2.60054 4.1317 2.91848 4 3.25 4H8.48375"
        stroke={mColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3732 1.66407C14.3275 1.6139 14.2721 1.5735 14.2104 1.54533C14.1487 1.51715 14.0819 1.50178 14.0141 1.50015C13.9463 1.49851 13.8788 1.51064 13.8158 1.53581C13.7528 1.56098 13.6956 1.59866 13.6475 1.64657L13.261 2.03126C13.2141 2.07814 13.1878 2.1417 13.1878 2.20798C13.1878 2.27425 13.2141 2.33782 13.261 2.3847L13.6154 2.73845C13.6386 2.76178 13.6662 2.7803 13.6966 2.79294C13.727 2.80558 13.7596 2.81208 13.7925 2.81208C13.8255 2.81208 13.8581 2.80558 13.8885 2.79294C13.9189 2.7803 13.9465 2.76178 13.9697 2.73845L14.3466 2.36345C14.5372 2.17313 14.555 1.86313 14.3732 1.66407ZM12.4794 2.81251L6.83816 8.44376C6.80396 8.47782 6.7791 8.52012 6.76598 8.56657L6.50504 9.34376C6.49879 9.36485 6.49834 9.38723 6.50376 9.40855C6.50917 9.42987 6.52023 9.44933 6.53579 9.46488C6.55134 9.48044 6.5708 9.4915 6.59212 9.49692C6.61344 9.50233 6.63583 9.50188 6.65691 9.49563L7.43348 9.2347C7.47993 9.22157 7.52222 9.19671 7.55629 9.16251L13.1875 3.52063C13.2396 3.46798 13.2689 3.3969 13.2689 3.32282C13.2689 3.24875 13.2396 3.17767 13.1875 3.12501L12.8766 2.81251C12.8239 2.75994 12.7525 2.73041 12.678 2.73041C12.6036 2.73041 12.5321 2.75994 12.4794 2.81251Z"
        fill={secColor}
      />
    </svg>
  );
};
