import React from 'react'
import { Markup } from 'interweave'
import styled from 'styled-components'
import get from 'lodash/get'

import { useQuiz } from 'hooks/patient/useQuestionnaires'

export default React.memo(function Html ({ className = '', metaPath }) {
  const html = useQuiz(current => get(current, `${metaPath}.html`), [metaPath])
  return (
    <StyledWrapper className={className}>
      <Markup content={html} />
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  .paragraph-title {
    font-size: 1.6rem;
    font-weight: 500;
  }
  .paragraph {
    font-size: 1.3rem;
    line-height: 3rem;
    font-weight: 300;
  }
  .list {
    font-size: 1.3rem;
    line-height: 3rem;
    margin-left: 2.5rem;
    font-weight: 300;
    li {
      padding-left: 1rem;
    }
  }
`
