import React, { useState } from "react";
import styled from "styled-components";

import { useFetchUser } from "requests/user";

import { RegularMediumText, RegularText } from "common/texts";
import InputWithLabel from "common/InputWithLabel";
import CustomButton from "common/CustomButton";

import createNotification from "utils/createNotification";
import QRCode from "react-qr-code";
import { Cognito } from "../../../libs/cognito";

const cognito = Cognito.getInstance();

export default React.memo(function ConfirmAdding() {
  const fetchUser = useFetchUser();
  const [qrCode, setQrCode] = useState("");
  const [code, setCode] = useState("");


  React.useEffect(() => {
    cognito
      .associateSoftwareToken()
      .then((qr) => {
        setQrCode(qr);
      })
      .catch((error) => console.log(error));
    // exlint-disable-next-line
  }, []);

  const handleSubmit = React.useCallback(() => {
    cognito
      .verifyOTPCode(code)
      .then(() => {
        createNotification({
          message: "Two-step authentication enabled. Please Login again.",
          type: "success",
        });
      })
      .catch((error) => {
        console.log(error);

        createNotification({
          message: "Verification code is invalid. Please Try again.",
          type: "error",
        });
      });
  }, [qrCode, code, fetchUser]);

  return (
    <>
      <RegularMediumText className="g-mt-25">
        Add authentication
      </RegularMediumText>

      <RegularText>
        1. Install two-factor authentication application on your phone.
      </RegularText>
      <StyledLink
        className="g-mt-5"
        rel="noopener noreferrer"
        target="_blank"
        href="https://apps.apple.com/app/google-authenticator/id388497605"
      >
        Google Authenticator for iOS
      </StyledLink>
      <StyledLink
        rel="noopener noreferrer"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
      >
        Google Authenticator for Android
      </StyledLink>
      {!qrCode && (
        <>
          <RegularText className="g-mt-15">
            Generation QR Code for you ...
          </RegularText>
          <FakeQR>
            <div className="loading">Loading ...</div>
            <div className="line">
              <div className="left">
                <div className="point"></div>
              </div>
              <div className="right">
                <div className="point"></div>
              </div>
            </div>
            <div className="line">
              <div className="left">
                <div className="point"></div>
              </div>
            </div>
          </FakeQR>
        </>
      )}
      {qrCode && (
        <>
          <RegularText className="g-mt-15">
            2. Take a picture of QR code in the installed application
          </RegularText>
          <StyledQrCode value={qrCode} />

          <RegularText className="g-mt-5">
            3. Enter the verification code from your device
          </RegularText>

          <InputWithLabel
            className="g-mt-30 g-mb-20"
            placeholder="Verification Code (6 digits)"
            value={code}
            changeHandler={setCode}
          />

          <StyledCustomButton
            disabled={code.length !== 6}
            clickHandler={handleSubmit}
          >
            Add
          </StyledCustomButton>
        </>
      )}
    </>
  );
});

const StyledLink = styled.a`
  font-size: 1.3rem;
  line-height: 2.4rem;
`;

const StyledQrCode = styled(QRCode)`
  display: block;
  align-self: flex-start;
  width: 15rem;
  height: 15rem;
  background: #e9ebef;
`;

const StyledCustomButton = styled(CustomButton)`
  margin: auto auto 0 0;
`;
const FakeQR = styled.div`
  width: 15rem;
  height: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1rem;
  position: relative;

  .loading {
    position: absolute;
    top: 50%;
    height: 20px;
    width: 15rem;
    text-align: center;
    margin-top: -10px;
    font-size: 2rem;
  }

  .point {
    width: 10px;
    height: 10px;
    background: black;
  }
  .left,
  .right {
    width: 30px;
    height: 30px;
    background: white;
    border: 5px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .line {
    display: flex;
    justify-content: space-between;
  }
`;
