import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { COMPANIES } from 'constants/routes';

import { ColumnWidePlate } from 'common/plate/plates'
import { PlateHeader, PlateContent } from 'common/plate/styles'
import BackButton from 'common/plate/BackButton'
import InputWithLabel from 'common/InputWithLabel'
import CustomButton from 'common/CustomButton'

import request from 'utils/request'
import createNotification from 'utils/createNotification'

function existValidation (value) {
  return !!value
}

export default React.memo(function PatientInvite () {
  const history = useHistory()
  const [isSended, setIsSended] = React.useState(false)
  const [validate, setValidate] = React.useState(false)
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const { companyId } = useParams();

  const submitHandler = React.useCallback(() => {
    setValidate(true)
    if (!existValidation(firstName)) return
    if (!existValidation(lastName)) return
    if (!existValidation(email)) return
    setIsSended(true)
    request({
      method: 'post',
      url: `companies/${companyId}/invite-patient`,
      data: {
        first_name: firstName,
        last_name: lastName,
        email: email
      }
    })
      .then(() => {
        createNotification({ message: 'Patient successfully invited', type: 'success' })
        history.push(`/${COMPANIES}/${companyId}`)
      })
      .catch((error) => console.log(error))
  }, [setValidate, firstName, lastName, email, setIsSended, history])

  return (
    <ColumnWidePlate>
      <PlateHeader>
        <BackButton href={`/${COMPANIES}/${companyId}`} />
        Invite new patient
      </PlateHeader>

      <PlateContent>
        <InputWithLabel
          className='g-mt-20'
          label='First name'
          placeholder='Enter first name'
          isRequred
          isValid={!validate || existValidation(firstName)}
          value={firstName}
          changeHandler={setFirstName}
        />

        <InputWithLabel
          className='g-mt-20'
          label='Last name'
          placeholder='Enter last name'
          isRequred
          isValid={!validate || existValidation(lastName)}
          value={lastName}
          changeHandler={setLastName}
        />

        <InputWithLabel
          className='g-mt-20'
          label='Email'
          placeholder='Enter email address'
          isRequred
          isValid={!validate || existValidation(email)}
          value={email}
          changeHandler={setEmail}
        />

        <CustomButton
          className='g-mt-auto'
          disabled={isSended}
          clickHandler={submitHandler}
        >
          Submit
        </CustomButton>
      </PlateContent>
    </ColumnWidePlate>
  )
})

