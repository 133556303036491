import React from 'react'
import moment from 'moment'
import qs from 'qs'

import { useSetLogs } from 'hooks/useLogs'

import normalizeParams from 'utils/normalizeParams'

import request from 'utils/request'

export function useFetchLogs () {
  const setLogs = useSetLogs((prev, next) => next, [])
  return React.useCallback((options) => {
    const params = normalizeParams(options)
    if (params.to) params.to = moment(params.to).format('YYYY-MM-DD')
    if (params.from) params.from = moment(params.from).format('YYYY-MM-DD')
    request({
      method: 'get',
      url: `/admins/activity-log?${qs.stringify(params, { encode: false })}`
    })
      .then(({ data: { data: { activities } } }) => setLogs(activities))
      .catch((error) => console.log(error))
  }, [setLogs])
}
