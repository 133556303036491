import React from "react";
import styled from "styled-components";
import Select from "react-dropdown-select";
import get from "lodash/get";

import { UppercaseText } from "common/texts";

function DropdownHandleRenderer({ state: { dropdown } }) {
  return <StyledHandler dropdown={dropdown} />;
}

export default React.memo(function CustomSelect({
  label,
  options = [],
  className = "",
  placeholder = "",
  value,
  changeHandler,
  isRequred,
  isValid,
  isLoading,
  disabled,
}) {
  const handleChange = React.useCallback(
    (values) => {
      changeHandler(get(values, "[0].value"));
    },
    [changeHandler]
  );

  if (isLoading) {
    return (
      <StyledLoader className={className}>
        {label && <div className="loader-label" />}
        <div className="loader-select" />
      </StyledLoader>
    );
  }

  const selectedOption = options.find((option) => option.value === value);
  return (
    <StyledWrapper className={className}>
      {label && (
        <UppercaseText className="g-mb-10">
          {label}
          {isRequred && <span className="required">*</span>}
        </UppercaseText>
      )}
      <StyledSelect
        disabled={disabled}
        isRequred={isRequred}
        isValid={isValid}
        values={selectedOption && [selectedOption]}
        onChange={handleChange}
        options={options}
        placeholder={placeholder}
        searchable={false}
        dropdownHandleRenderer={DropdownHandleRenderer}
      />
    </StyledWrapper>
  );
});

const StyledLoader = styled.div`
  max-width: 27rem;
  width: 100%;
  margin-left: 1px;
  @media only screen and (max-width: 480px) {
    max-width: initial;
  }
  .loader-label {
    width: 50%;
    height: 1.8rem;
    margin-bottom: 1rem;
    background: #e9ebef;
  }
  .loader-select {
    height: 4.8rem;
    background: #e9ebef;
  }
`;

const StyledWrapper = styled.div`
  max-width: 27rem;
  width: 100%;
  .required {
    display: inline-block;
    line-height: 1.8rem;
    color: #f86060;
    font-size: 1.3rem;
    margin-left: 1px;
  }
  @media only screen and (max-width: 480px) {
    max-width: initial;
  }
`;

const StyledHandler = styled.div`
  width: 0;
  height: 0;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-top: 0.6rem solid #223232;
  ${({ dropdown }) => dropdown && "transform: rotate(180deg)"};
`;

const StyledSelect = styled(Select)`
  &.react-dropdown-select {
    background: #fff;
    border: solid 1px #e9ebef;
    padding: 0.8rem 1.3rem;
    min-height: auto;
    &:hover {
      border: solid 1px #ccc;
    }
    &:focus {
      box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.25);
    }
    &:focus-within {
      border: solid 1px #e9ebef;
      box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.25);
    }
    ${({ values, isValid, isRequred }) =>
      isRequred &&
      !isValid &&
      `
      border-color: #f86060;
      &:hover {
        border: solid 1px #f86060;
      }
      &:focus {
        box-shadow: 0 0 0 0.1rem #f86060;
      }
      &:focus-within {
        border: solid 1px #f86060;
        box-shadow: 0 0 0 0.1rem #f86060;
      }
    `};
  }
  .react-dropdown-select-content {
    font-size: 1.3rem;
    line-height: 3rem;
    max-width: 100%;
    span {
      font-size: 1.3rem;
    }
    input {
      ${({ values }) => values && "display: none"};
      width: 100%;
      font-size: 1.3rem;
      &::placeholder {
        font-size: 1.3rem;
        font-weight: 300;
        ${({ values, isValid, isRequred }) =>
          isRequred &&
          !isValid &&
          !values &&
          `
          color: #f86060;
        `};
      }
    }
  }
  .react-dropdown-select-dropdown {
    border: solid 1px #e9ebef;
    border-bottom: none;
    .react-dropdown-select-item {
      font-size: 1.3rem;
      line-height: 3rem;
      border-bottom: solid 1px #e9ebef;
    }
    .react-dropdown-select-item-selected {
      color: #223232;
      background: #e9ebef;
    }
  }
  .react-dropdown-select-no-data {
    font-size: 1.3rem;
    color: #223232;
  }
`;
