import React from 'react'

import { useProviderAppointments } from 'hooks/useProviders'

import AppointmentsPlaceholder from './AppointmentsPlaceholder'
import AppointmentsList from './AppointmentsList'

export default React.memo(function AppointmentsLoader () {
  const providerAppointments = useProviderAppointments()

  if (!providerAppointments) return <AppointmentsPlaceholder />

  if (providerAppointments && providerAppointments.length === 0) return null

  return (
    <AppointmentsList />
  )
})
