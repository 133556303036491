import React from 'react'
import styled from 'styled-components'
import get from 'lodash/get'

import { usePatientMedications } from 'hooks/usePatients'

import AddRowButton from './AddRowButton'

export default function Medications ({ MedicationPreview, validate }) {
  const medications = usePatientMedications(current => get(current, 'medications')) || []
  return (
    <>
      <StyledWrapper>
        {medications.map((medication, i) => {
          return (
            <MedicationPreview
              key={i}
              index={i}
              isLast={medications.length === 1}
              medication={medication}
              validate={validate}
            />
          )
        })}
      </StyledWrapper>
      <AddRowButton />
    </>
  )
}

const StyledWrapper = styled.div`
  .preview:nth-child(odd) {
    background: #f8f8f8;
  }
  .preview:last-child {
    border-bottom: 1px solid #e9ebef;
  }
`
