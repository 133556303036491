import React from 'react'
import moment from 'moment'
import styled from 'styled-components'

import { ReactComponent as LogoIcon } from 'assets/logo-white-icon.svg';

export default React.memo(function SystemMessageTemplate ({ timestamp, children }) {
  return (
    <StyledWrapper>
      <div className='label-wrapper g-mb-5'>
        <LogoIcon className='user-icon' />
        <div className='label g-ml-10'>
          Ciba Health team
        </div>
        <div className='label g-ml-10'>
          {moment.utc(timestamp).local().format('h:mm A')}
        </div>
      </div>
      <div className='body'>
        {children}
      </div>
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  margin-bottom: 1.5rem;
  max-width: 90%;
  .label-wrapper {
    display: flex;
    align-items: center;
    .user-icon {
      width: 2rem;
      height: 2rem;
      font-size: 0.8rem;
      flex-shrink: 0;
    }
    .label {
      font-size: 1.3rem;
      color: #bcc1c1;
    }
  }
  .body {
    display: inline-block;
    padding: 0.8rem;
    font-size: 1.3rem;
    word-break: break-word;
    border-radius: 0.8rem;
    background: #f1f5f8;
  }
`
