import React from 'react';
import { HeadingH3, SubHeading } from './UI/Typography';
import { Button } from 'components/ui2.0/Button';

const EmptyState = ({ icon: Icon, heading, subheading, buttonText, onClick }) => {
  return (
    <div className="text-center py-10">
      {Icon && <Icon className="w-20 h-20 mx-auto" />}
      <HeadingH3>{heading}</HeadingH3>
      <SubHeading>{subheading}</SubHeading>
      {buttonText && (
        <div className="mt-3">
          <Button className="mx-auto text-center" onClick={onClick}>
            {buttonText}
          </Button>
        </div>
      )}
    </div>
  );
};

export default EmptyState;
