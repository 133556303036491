import React from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'


import Modal from 'common/Modal'
import InputWithLabel from 'common/InputWithLabel'
import CustomButton from 'common/CustomButton'

import validatePassword from 'utils/validatePassword'

import request from 'utils/request'
import createNotification from 'utils/createNotification'
import CustomSelect from '../../../../common/CustomSelect'
import { useFetchPlans } from '../../../../requests/options'
import { usePlans } from '../../../../hooks/useOptions'
import { useFetchCompanyPlans } from '../../../../requests/companies'

export default function AddPlanModal ({ closeModal }) {
  const fetchPlans = useFetchPlans()
  const plans = usePlans()
  const fetchCompanyPlans = useFetchCompanyPlans()

  React.useEffect(() => {
    fetchPlans()

    // eslint-disable-next-line
  }, [])

  const [planId, setPlanId] = React.useState(0)
  const [isRequesting, setIsRequesting] = React.useState(false)
  const { companyId } = useParams()

  const addProgram = React.useCallback(() => {
    setIsRequesting(true)
    request({
      method: 'post',
      url: `/company/subscriptions`,
      data: {
        company_id: companyId,
        plan_id: planId,
        coupon: companyId + '-' + planId,
        capacity: '47'
      }
    })
      .then(() => {
        closeModal()
        fetchCompanyPlans()
        createNotification({ message: 'Plan successfully added', type: 'success' })
      })
      .catch((error) => {
        closeModal()
        console.log(error)
      })
  }, [companyId, planId, closeModal])

  return (
    <Modal
      title='Select programs'
      closeModal={closeModal}
      minWidth={48}
      maxWidth={48}
      withSeparator
    >
      <ModalContent>
        <div className='g-d-flex g-justify-between g-mt-15'>
          <CustomSelect
            label={'Select Plan'}
            options={plans || []}
            isRequred
            changeHandler={setPlanId}
            isValid={true}
          />
        </div>
        <CustomButton
          className='g-mt-20'
          disabled={(planId === 0) || isRequesting}
          clickHandler={addProgram}
        >
          Add new program
        </CustomButton>
      </ModalContent>
    </Modal>
  )
}

const ModalContent = styled.div`
  .input {
    max-width: 100%;
  }
  .icon-wrapper {
    flex-shrink: 0;
    width: 4.8rem;
    height: 4.8rem;
    margin-top: auto;
    cursor: pointer;
    svg {
      transition: all 0.2s;
      :hover {
        opacity: 0.5;
      }
      :active {
        opacity: 1;
      }
    }
  }
  .error {
    color: #f86060;
    font-size: 1.2rem;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
  }
`
