import React from 'react'
import get from 'lodash/get'

import { MEDICATIONS_NAME, SUPPLEMENTS_NAME } from 'constants/patient-overview'
import getMedicines from 'utils/patients/getMedicines'

import CardTemplate from '../CardTemplate'
import List from './list'

export default function Medicines ({ title, answers }) {
  let answersList = []
  const medications = get(answers, `${MEDICATIONS_NAME}`, null)
  const supplements = get(answers, `${SUPPLEMENTS_NAME}`, null)
  if (medications) answersList.push(getMedicines({ 'title': 'Medications', 'medicines': medications }))
  if (supplements) answersList.push(getMedicines({ 'title': 'Supplements', 'medicines': supplements }))

  if (answersList.length === 0) return null

  return (
    <CardTemplate title={title}>
      <List answers={answersList} />
    </CardTemplate>
  )
}
