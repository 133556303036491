import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import get from 'lodash/get'

import { PROGRAMS } from 'constants/routes'

import { MediumText } from 'common/texts'
import LinkButton from 'common/LinkButton'

export default function ProgramPreview ({ program }) {
  const history = useHistory()
  const id = get(program, 'id')
  const name = get(program, 'name')

  const navigateToPreview = React.useCallback(() => {
    history.push(`/${PROGRAMS}/${id}`)
  }, [history, id])

  return (
    <StyledWrapper>

      <MediumText className='name'>
        {name}
      </MediumText>

      <LinkButton
        className='view'
        onClick={navigateToPreview}
      >
        Edit
      </LinkButton>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafbfd;
  border: 1px solid #e9ebef;
  padding: 2rem;
  margin-top: 1.5rem;
  .name {
    max-width: 100%;
    margin-left: 2rem;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .count {
    min-width: 8rem;
    margin-left: auto;
  }
  .view {
    margin-left: 2rem;
    padding: 0.6rem 3.5rem;
  }
`
