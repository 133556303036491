import React from 'react'
import styled from 'styled-components'

import { PlateHeader } from 'common/plate/styles'
import InputWithLabel from 'common/InputWithLabel'
import CloseIcon from 'common/CloseIcon'
import CreateChat from './CreateChat'

export default React.memo(function ChatListHeader ({
  isLoading, search, setSearch
}) {
  const clearSearch = React.useCallback(() => setSearch(''), [setSearch])
  return (
    <StyledPlateHeader>
      <CreateChat />
      <div className='search-wrapper'>
        <InputWithLabel
          className='search-input'
          placeholder='Search by patient name'
          isLoading={isLoading}
          value={search}
          changeHandler={setSearch}
        />
        {search && (
          <CloseIcon
            className='icon-clear'
            closeHandler={clearSearch}
          />
        )}
      </div>
    </StyledPlateHeader>
  )
})

const StyledPlateHeader = styled(PlateHeader)`
  display: block;
  .search-wrapper {
    position: relative;
    margin-top: 2rem;
    .search-input {
      input {
        line-height: 2rem;
      }
      .loader-input {
        height: 3.8rem;
      }
    }
    .icon-clear {
      position: absolute;
      right: 0.5rem;
      top: 50%;
      transform: translate(0px, -50%);
    }
  }
`
