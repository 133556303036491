import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import get from 'lodash/get'

import { PATIENTS, DETAILS, QUESTIONNAIRES } from 'constants/routes'; 

import { useQuiz } from 'hooks/patient/useQuestionnaires'

import BackButton from 'common/plate/BackButton'

export default React.memo(function BackHandler () {
  const { patientId } = useParams()
  const history = useHistory()
  const group = useQuiz(current => get(current, 'group'))

  const navigationHandler = React.useCallback(() => {
    history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${QUESTIONNAIRES}/${group}`)
  }, [history, patientId, group])

  return <BackButton navigationHandler={navigationHandler} />
})
