import React from 'react'
import styled from 'styled-components'

import { UppercaseText } from 'common/texts'

import CalendarInput from './CalendarInput'
import CalendarWindow from './CalendarWindow'

export default React.memo(function CustomCalendar ({
  className = '', isLoading, label, minDate, selectedDate, setSelectedDate, isRequred, isValid
}) {
  const [isOpen, setIsOpen] = React.useState(false)
  const ref = React.useRef()

  const closeHandler = React.useCallback((e) => {
    if (ref.current && ref.current.contains(e.target)) return
    setIsOpen(false)
  }, [ref, setIsOpen])

  React.useEffect(() => {
    window.addEventListener('click', closeHandler)
    return () => window.removeEventListener('click', closeHandler)
  }, [closeHandler])

  if (isLoading) {
    return (
      <StyledLoader className={className}>
        {label && <div className='loader-label' />}
        <div className='loader-input' />
      </StyledLoader>
    )
  }

  return (
    <StyledWrapper className={className} ref={ref}>
      {label && (
        <UppercaseText className='g-mb-10'>
          {label}
        </UppercaseText>
      )}
      <CalendarInput
        isRequred={isRequred}
        isValid={isValid}
        value={selectedDate}
        setIsOpen={setIsOpen}
      />
      {isOpen && (
        <CalendarWindow
          minDate={minDate}
          setIsOpen={setIsOpen}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      )}
    </StyledWrapper>
  )
})

const StyledLoader = styled.div`
  max-width: 27rem;
  width: 100%;
  @media only screen and (max-width: 480px) {
    max-width: initial;
  }
  .loader-label {
    height: 1.8rem;
    width: 50%;
    margin-bottom: 1rem;
    background: #e9ebef;
  }
  .loader-input {
    height: 4.8rem;
    background: #e9ebef;
  }
`

const StyledWrapper = styled.div`
  position: relative;
  max-width: 27rem;
  width: 100%;
  @media only screen and (max-width: 480px) {
    max-width: initial;
  }
`
