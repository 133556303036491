import React from 'react'
import styled from 'styled-components'

export default function PatientsPlaceholder () {
  return (
    <StyledWrapper>
      <div className='placeholder' />
      <div className='placeholder' />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  .placeholder {
    height: 9rem;
    margin-top: 1.5rem;
    border: 1px solid #e9ebef;
    background: #fafbfd;
    @media only screen and (max-width: 480px) {
      height: 20rem;
    }
  }
`
