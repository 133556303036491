import React from 'react'
import get from 'lodash/get'

import { useMedicationsCategories } from 'hooks/useOptions'
import { usePatientMedications, useSetPatientMedications } from 'hooks/usePatients'
import { useFetchMedicationsCategories } from 'requests/options'

import CustomSelect from 'common/CustomSelect'

export default function CategorySelect ({ validate }) {
  const medicationsCategories = useMedicationsCategories()
  const fetchMedicationsCategories = useFetchMedicationsCategories()
  const isLoadedPatientMedications = usePatientMedications(current => !!current)
  const categoryId = usePatientMedications(current => get(current, 'category_id'))
  const setCategoryId = useSetPatientMedications((prev, next) => ({ ...prev, category_id: next }))

  React.useEffect(() => {
    fetchMedicationsCategories()
    // eslint-disable-next-line
  }, [])
  return (
    <CustomSelect
      className='g-mb-20'
      label='Category'
      placeholder='Select category'
      isLoading={!medicationsCategories || !isLoadedPatientMedications}
      options={medicationsCategories}
      isRequred
      isValid={!validate || categoryId}
      value={categoryId}
      changeHandler={setCategoryId}
    />
  )
}
