export const TYPE_WEIGHT = 1
export const TYPE_HEIGHT = 4
export const TYPE_FAT_RATIO = 6
export const TYPE_FAT_MASS = 8
export const TYPE_MUSCLE_MASS = 76
export const TYPE_BONE_MASS = 88
export const TYPE_DIASTOLIC = 9
export const TYPE_SYSTOLIC = 10
export const TYPE_PULSE = 11
export const TYPE_WATER = 77
