import _ from 'lodash';
import { $, GraphQLTypes, InputType, Selector } from 'zeus-graphql/patient/zeus';
import { useQuery } from '../client';
import { GET_LIBRARY_MATERIAL } from '../constants';

const category = Selector('LibraryMaterialCategoryType')({
  id: true,
  title: true,
  slug: true,
  __typename: true,
});
const provider = Selector('ProviderType')({
  profile: {
    fName: true,
    lName: true,
  },
});

const material = Selector('LibraryMaterialType')({
  id: true,
  category,
  type: true,
  title: true,
  materialId: true,
  description: true,
  content: true,
  createdAt: true,
  createdBy: provider,
  status: true,
});

export type LibraryMaterialCategoryType = InputType<GraphQLTypes['LibraryMaterialCategoryType'], typeof category>;
export type ProviderType = InputType<GraphQLTypes['ProviderType'], typeof provider>;
export type LibraryMaterialType = InputType<GraphQLTypes['LibraryMaterialType'], typeof material>;

export const useLibraryMaterial = (materialId: string) => {
  const { data, isLoading, error } = useQuery(
    [GET_LIBRARY_MATERIAL, materialId],
    {
      getLibraryMaterial: [
        {
          materialId: $`materialId`,
        },
        material,
      ],
    },
    {
      operationName: GET_LIBRARY_MATERIAL,
      variables: {
        materialId,
      },
    }
  );

  return {
    libraryMaterial: data?.getLibraryMaterial,
    isLoading,
    error,
  };
};
