import React from 'react'
import get from 'lodash/get'

import { useGenders } from 'hooks/useProfile'
import { useLocalPatientProfile } from 'hooks/usePatients'

export function useIsPatientProfileLoading() {
  const patientLocalProfile = useLocalPatientProfile(current => !!current)
  const genders = useGenders(current => !!current)
  return React.useMemo(() => {
    return !patientLocalProfile || !genders
  }, [patientLocalProfile, genders])
}

function withoutValidation() {
  return true
}

function existValidation(value) {
  return !!value
}

function zipValidation(value) {
  return value !== null && value?.trim() !== ''
}

function birthDateValidation(value) {
  return !value?.split('-').includes('')
}

function phoneValidation(value) {
  return /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(value) || /^\d{7}$/.test(value)
}

function emergencyPhoneValidation(value) {
  if (!value) return true
  return phoneValidation(value)
}

export const VALIDATION_RULES = {
  'email': existValidation,
  'first_name': existValidation,
  'last_name': existValidation,
  'phone': phoneValidation,
  'zip': zipValidation,
  'gender': existValidation,
  'state': existValidation,
  'city': existValidation,
  'birth_date': birthDateValidation,
  'address_1': existValidation,
  'address_2': withoutValidation,
  'emergency.phone': emergencyPhoneValidation
}

export function useMissedFields() {
  const localPatientProfile = useLocalPatientProfile()
  return React.useMemo(() => {
    return Object.keys(VALIDATION_RULES).filter(field => {
      const validationCb = get(VALIDATION_RULES, field)
      if (validationCb && localPatientProfile && !validationCb(get(localPatientProfile, field))) return true
      return false
    })
  }, [localPatientProfile])
}

function useIsPrimaryProviderValid() {
  return useLocalPatientProfile(current => {
    const withoutProvider = get(current, 'no_primary_provider', '')
    if (withoutProvider) return true
    const name = get(current, 'primary_provider.name', '')
    const address = get(current, 'primary_provider.address', '')
    const officeNumber = get(current, 'primary_provider.office_number', '')
    if (!name || !address || !officeNumber) return false
    return true
  })
}

export function useIsFormValid() {
  const missedFields = useMissedFields()
  const isPrimaryProviderValid = useIsPrimaryProviderValid()
  return React.useMemo(() => {
    if (missedFields.length !== 0) return false
    if (!isPrimaryProviderValid) return false
    return true
  }, [missedFields, isPrimaryProviderValid])
}
