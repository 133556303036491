import React from 'react'
import styled from 'styled-components'

import { HintText, RegularText } from 'common/texts'

export default function Note ({ isOpen, note }) {
  if (!isOpen) return null
  return (
    <StyledWrapper>
      <HintText>Provider note</HintText>
      <RegularText>{note}</RegularText>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  padding: 1rem 2.5rem 1.5rem;
  border-top: 0.1rem solid #e9ebef;
  @media screen and (max-width: 768px) {
    border-top: none;
  }
`
