import React from 'react'

import { FlexLine } from 'common/plate/styles'
import ChatsList from 'components/messages/ChatsList'
import CreateDirectContent from 'components/messages/create-direct-content'

export default function CreateDirect () {
  return (
    <FlexLine>
      <ChatsList withHidden />

      <CreateDirectContent />
    </FlexLine>
  )
}
