import React from 'react'
import styled from 'styled-components'

import { useMessageCounter } from 'hooks/useMessages'

export default React.memo(function NotificationsCounter ({ className }) {
  const messageCounter = useMessageCounter()

  if (!messageCounter) return null

  return (
    <StyledWrapper className={className}>
      {messageCounter}
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  position: absolute;
  bottom: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background: #f86060;
  width: 2rem;
  height: 2rem;
  border: solid 1px #fff;
  border-radius: 50%;
`
