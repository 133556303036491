import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import get from 'lodash/get'

import { ReactComponent as PipIcon } from 'assets/video/pip.svg'

import { APPOINTMENTS, CONSULTATION } from 'constants/routes'; 

import { useConsultation } from 'hooks/useAppointments'

import { IconWrapper } from './style'

export default React.memo(function PipHandler () {
  const history = useHistory()
  const appointmentId = useConsultation(current => get(current, 'appointmentId'))

  const navigateToConsultation = React.useCallback(() => {
    history.push(`/${APPOINTMENTS}/${CONSULTATION}/${appointmentId}`)
  }, [history, appointmentId])

  return (
    <StyledWrapper>
      <IconWrapper onClick={navigateToConsultation}>
        <PipIcon />
      </IconWrapper>
      <div className='tool-tip'>
        Exit minimized video
      </div>
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  position: relative;
  &:hover {
    .tool-tip {
      display: block;
    }
  }
  .tool-tip {
    position: absolute;
    top: 100%;
    right: 0;
    background: #eef5fd;
    padding: 1rem 1.4rem;
    border: 1px solid #e7e7e7;
    border-radius: 0.7rem;
    white-space: nowrap;
    font-size: 1.3rem;
    transform: translate(-2rem, 1rem);
    display: none;
  }
`
