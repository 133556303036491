import React from 'react'
import get from 'lodash/get'

import { usePatinetProfile } from 'hooks/usePatients'

import { MediumText } from 'common/texts'
import PropertyValue from 'common/PropertyValue'

export default function EmergencyInformation () {
  const patinetProfile = usePatinetProfile()

  const isLoading = React.useMemo(() => !patinetProfile, [patinetProfile])

  const emergencyName = get(patinetProfile, 'emergency.name', '')
  const emergencyRelationship = get(patinetProfile, 'emergency.relationship', '')
  const emergencyPhone = get(patinetProfile, 'emergency.phone', '')

  return (
    <>
      <MediumText className='g-mt-30'>
        Emergency information
      </MediumText>

      <PropertyValue
        className='g-mt-5'
        isLoading={isLoading}
        property='Name'
        value={emergencyName}
      />
      <PropertyValue
        className='g-mt-5'
        isLoading={isLoading}
        property='Relationship'
        value={emergencyRelationship}
      />
      <PropertyValue
        className='g-mt-5'
        isLoading={isLoading}
        property='Phone'
        value={emergencyPhone}
      />
    </>
  )
}
