import React from 'react'
import styled from 'styled-components'

function normalizeTime (value = '') {
  if (value.toString().length < 2) return '0' + value
  return value
}

export default React.memo(function Timer ({ isStarted }) {
  const [timer, setTimer] = React.useState(0)

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTimer((current) => current + 1)
    }, 1000)
    return () => clearInterval(interval)
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    setTimer(0)
  }, [isStarted])

  const parsedTimer = React.useMemo(() => {
    const seconds = normalizeTime(Math.floor(timer % 60))
    const minutes = normalizeTime(Math.floor((timer / 60) % 60))
    const hours = normalizeTime(Math.floor((timer / (60 * 60)) % 24))
    const result = [minutes, seconds]
    if (hours !== '00') result.unshift(hours)
    return result.join(':')
  }, [timer])

  if (!isStarted) return null

  return (
    <StyledWrapper>
      {parsedTimer}
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  align-self: center;
  margin-left: auto;
  font-size: 1.2rem;
  font-weight: 400;
`
