import { GraphQLTypes, $, ValueTypes } from 'zeus-graphql/my-health/zeus';
import { GQLArgs, useMutation } from '../client';
import { UPLOAD_TASK_FILES } from '../constants';

type MutationResponse = Record<
  'uploadTaskFiles',
  {
    files: (GraphQLTypes['UploadFileInfoType'] | GraphQLTypes['FileTypeIsNotSupported'] | GraphQLTypes['TooLargeFile'])[];
  }
>;

export type FailedFile = GraphQLTypes['FileTypeIsNotSupported'] | GraphQLTypes['TooLargeFile'];
export type UploadFileInfoType = GraphQLTypes['UploadFileInfoType'];

type Vars = Required<ValueTypes['MyHealthMutation']>['uploadTaskFiles'][0];

type Args = GQLArgs<MutationResponse>;

export const useUploadTaskFiles = (args?: Args) => {
  const instance = useMutation(
    [UPLOAD_TASK_FILES],
    {
      uploadTaskFiles: [
        {
          files: $`files`,
        },
        {
          __typename: true,
          files: {
            '...on FileTypeIsNotSupported': {
              __typename: true,
              message: true,
              originalFileName: true,
            },
            '...on TooLargeFile': {
              __typename: true,
              message: true,
              originalFileName: true,
            },
            '...on UploadFileInfoType': {
              __typename: true,
              fileName: true,
              fileType: true,
              id: true,
              originalFileName: true,
            },
          },
        },
      ],
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      operationName: UPLOAD_TASK_FILES,
    },
    args
  );

  const uploadTaskFiles = instance.mutateAsync as (args: Vars) => Promise<MutationResponse>;

  return { ...instance, uploadTaskFiles };
};
