import React from 'react'
import { useParams } from 'react-router-dom'
import get from 'lodash/get'
import styled from 'styled-components'

import { useChatChannels, useGetChannelName } from 'hooks/useMessages'
import { useScreenWidth } from 'hooks/useUtils'

import { ColumnTightPlate } from 'common/plate/plates'
import ChatListHeader from 'common/chat/chat-list-header'
import ChatListItem from 'common/chat/chat-list-item'
import ChatListPlaceholder from 'common/loaders/ChatListPlaceholder'

const ChatsList = React.memo(function ChatsList ({ search }) {
  const { channelSID } = useParams()
  const chatChannels = useChatChannels()
  const getChannelName = useGetChannelName()

  const parsedChatChannels = React.useMemo(() => {
    return chatChannels
      .sort((a, b) => {
        const dateA = Math.max(+get(a, 'lastMessage.timestamp', 0), +a.dateCreated || 0, +a.dateUpdated || 0)
        const dateB = Math.max(+get(b, 'lastMessage.timestamp', 0), +b.dateCreated || 0, +b.dateUpdated || 0)
        return dateB - dateA
      })
      .filter(channel => {
        const channelName = getChannelName(channel) || ''
        if (!search) return true
        return ~channelName.toLowerCase().indexOf(search.toLowerCase())
      })
      .map((channel) => {
        const sid = get(channel, 'sid')
        return (
          <ChatListItem
            key={sid}
            isActive={sid === channelSID}
            channel={channel}
          />
        )
      })
  }, [chatChannels, getChannelName, search, channelSID])

  return (
    <div className='list'>
      {parsedChatChannels}
    </div>
  )
})

export default React.memo(function ChatsListLoader ({ withHidden }) {
  const { channelSID } = useParams()
  const isMobile = useScreenWidth((value) => value <= 480, [])
  const chatChannels = useChatChannels()
  const [search, setSearch] = React.useState('')

  const placeholder = (
    <>
      <ChatListPlaceholder />
      <ChatListPlaceholder />
    </>
  )

  return (
    <StyledColumnTightPlate isHidden={isMobile && (channelSID || withHidden)}>
      <ChatListHeader
        isLoading={!chatChannels}
        search={search}
        setSearch={setSearch}
      />

      {!chatChannels
        ? placeholder
        : <ChatsList search={search} />
      }
    </StyledColumnTightPlate>
  )
})

const StyledColumnTightPlate = styled(ColumnTightPlate)`
  ${({ isHidden }) => isHidden && 'display: none'};
  margin-right: 3rem;
  .list {
    overflow-y: auto;
    max-height: 53rem;
  }
`
