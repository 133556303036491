import React from 'react'
import styled from 'styled-components'
import { useParams, useHistory } from 'react-router-dom'
import get from 'lodash/get'

import { MESSAGES } from 'constants/routes'; 

import { ReactComponent as Arrow } from 'assets/arrow-down.svg'

import { useChatChannels } from 'hooks/useMessages'
import { useAddMember } from 'requests/messages'

import UserSearch from 'common/chat/user-search'
import { StyledLinePlaceholder } from '../styles'

export default React.memo(function InviteHeader () {
  const { channelSID } = useParams()
  const history = useHistory()
  const { isLoading, channel } = useChatChannels(channels => {
    if (!channels) return { isLoading: true }
    const currentChannel = channels.find(channel => channel.sid === channelSID)
    return { channel: currentChannel }
  }, [channelSID])

  const addMember = useAddMember(get(channel, 'state.attributes.type'))

  const backHandler = React.useCallback(() => {
    history.push(`/${MESSAGES}/${channelSID}`)
  }, [history, channelSID])

  if (isLoading) {
    return (
      <StyledWrapper>
        <StyledArrow onClick={backHandler} />
        <StyledLinePlaceholder />
      </StyledWrapper>
    )
  }

  if (!channel) {
    return (
      <StyledWrapper>
        <StyledArrow onClick={backHandler} />
        <span className='input-label'>
          Chat doesn't exist or you don't have a permission
        </span>
      </StyledWrapper>
    )
  }

  return (
    <StyledWrapper>
      <StyledArrow onClick={backHandler} />
      <span className='input-label'>Invite users:</span>
      <UserSearch
        placeholder='Enter the name of the provider or patient'
        selectHandler={addMember}
      />
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  display: flex;
  height: 7rem;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 600;
  padding: 1.4rem 2.5rem;
  border-bottom: solid 2px #f1f5f8;
  .input-label {
    white-space: nowrap;
  }
`

const StyledArrow = styled(Arrow)`
  transform: rotate(90deg);
  flex-shrink: 0;
  width: 3rem;
  height: 2.6rem;
  margin-bottom: 1px;
  padding: 1rem;
  margin-left: -1rem;
  cursor: pointer;
`
