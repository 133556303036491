import React from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { useModalHandler } from 'hooks/useUtils'
import { useRemovePatient } from 'requests/patients/summary'

import ConfirmationModal from 'common/ConfirmationModal'

export default function DeletePatientButton () {
  const { patientId } = useParams()
  const [isOpen, openModal, closeModal] = useModalHandler()
  const removePatient = useRemovePatient(patientId)

  const handleRemovePatient = React.useCallback(async () => {
    await removePatient()
    closeModal()
  }, [removePatient, closeModal])

  return (
    <>
      <StyledWrapper onClick={openModal}>
        delete patient
      </StyledWrapper>
      {isOpen && (
        <ConfirmationModal
          closeModal={closeModal}
          confirmHandler={handleRemovePatient}
        />
      )}
    </>
  )
}

const StyledWrapper = styled.div`
  display: inline-block;
  align-self: flex-start;
  font-size: 16px;
  color: rgba(0,0,0,0.5);
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
  :hover {
    text-decoration: none;
  }
`
