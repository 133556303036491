import React from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import IconLink from 'common/IconLink';

import useTranslations from 'translations';

import { useUserContext } from '../../../context/userContext';
import { sidebarConfig } from './../sidebarConfig';
import { useFeatureFlags } from '../../../hooks/useFeatureFlag';
import { ADMINS_EMAILS } from 'env';

export default React.memo(function Sidebar() {
  const { pathname } = useLocation();
  const translations = useTranslations();
  const { role } = useUserContext();
  const ff = useFeatureFlags();
  const allowedAdmins = ADMINS_EMAILS;
  const currentAdmib = localStorage.getItem('email') || '';

  const sidebarLinks = React.useMemo(() => {
    return sidebarConfig.map(({ path, href, roles, isActiveHref, BeforeIcon, AfterIcon, className, withCounter, featureFlag }) => {
      if (featureFlag && !ff[featureFlag]) {
        return null;
      }
      if (roles.includes('customAdmin') && !allowedAdmins.includes(currentAdmib)) {
        return null;
      }
      if (!roles.includes(role)) {
        return null;
      }
      const value = get(translations, path);
      return (
        <IconLink
          className={className}
          key={path}
          value={value}
          href={href}
          BeforeIcon={BeforeIcon}
          AfterIcon={AfterIcon}
          isActive={pathname.includes(isActiveHref)}
          withCounter={withCounter}
        />
      );
    });
  }, [translations, pathname, role, ff]);

  return <StyledWrapper>{sidebarLinks}</StyledWrapper>;
});

const StyledWrapper = styled.div`
  display: none;
  @media only screen and (min-width: 1080px) {
    display: block;
    width: 160px;
  }
`;
