import axios from 'axios';
import Cookies from 'js-cookie';
import { loadProgressBar } from 'axios-progress-bar';
import moment from 'moment';
import 'axios-progress-bar/dist/nprogress.css';
import { API_URL } from '../env';

loadProgressBar({ showSpinner: false }, axios);

/**
 * @param {object} p
 * @param {string=} p.url
 * @param {string=} p.baseURL
 * @param {object=} p.headers
 * @param {string=} p.responseType
 * @param {string=} p.method
 * @param {object=} p.params
 * @param {object=} p.data
 */
export default ({ url = '', baseURL = API_URL, headers, responseType = 'json', method = 'get', params = {}, data = {}, signal = {} }) => {
  return axios({
    baseURL,
    url,
    method,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('idToken')}`,
      TimeZone: moment().format('ZZ'),
      'X-Auth-Source': localStorage.getItem('role') || 'provider',
      ...headers,
    },
    responseType,
    params,
    data,
  });
};
