import React from 'react'

import { useSetMedicationProperty } from 'hooks/usePatients'

import CustomTextarea from 'common/CustomTextarea'

export default React.memo(function Note ({ className = '', isOpen, index, note }) {
  const setMedicationNote = useSetMedicationProperty({ index, field: 'note' })
  if (!isOpen) return null
  return (
    <div className={className}>
      <CustomTextarea
        label='Note'
        value={note}
        changeHandler={setMedicationNote}
      />
    </div>
  )
})
