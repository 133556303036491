import React from 'react'
import { Switch } from 'react-router-dom'

import { useCreatePatinetDoctorNoteDraft } from 'requests/patients'

import onbeforeunloadHandler from 'utils/onbeforeunloadHandler'

export default function PatientCreateNoteWrapper ({ children }) {
  const createPatinetDoctorNoteDraft = useCreatePatinetDoctorNoteDraft()
  React.useEffect(() => {
    onbeforeunloadHandler({ key: 'createPatinetDoctorNoteDraft', func: createPatinetDoctorNoteDraft })
    return () => {
      onbeforeunloadHandler({ key: 'createPatinetDoctorNoteDraft', func: null })
      createPatinetDoctorNoteDraft()
    }
    // eslint-disable-next-line
  }, [])
  React.useEffect(() => {
    window.addEventListener('blur', createPatinetDoctorNoteDraft)
    return () => window.removeEventListener('blur', createPatinetDoctorNoteDraft)
    // eslint-disable-next-line
  }, [])
  return (
    <Switch>
      {children}
    </Switch>
  )
}
