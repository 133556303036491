import React from 'react'
import styled, { css } from 'styled-components'

import { useSetWidgetFilters } from 'hooks/patient/widgets/useWidgets'

import LinkButton from 'common/LinkButton'

export default React.memo(function Tab ({
  tab: { id, label, start, end }, period, setPeriod
}) {
  const isactive = id === period
  const setWidgetFilters = useSetWidgetFilters(prev => ({ ...prev, start, end }), [start, end])
  const selectHandler = React.useCallback(() => {
    setPeriod(id)
    setWidgetFilters()
  }, [setPeriod, id, setWidgetFilters])
  return (
    <StyledLinkButton
      className='button g-mb-5'
      isactive={isactive.toString()}
      onClick={selectHandler}
    >
      {label}
    </StyledLinkButton>
  )
})

const StyledLinkButton = styled(LinkButton)`
  min-width: 14rem;
  @media screen and (max-width: 768px) {
    min-width: auto;
  }
  ${({ isactive }) => isactive === 'true' && css`
    background: #fff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
  `}
`
