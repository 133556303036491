import React from 'react'

import { WEEK, MONTH, QUARTER, YEAR } from 'constants/withings-tabs'

import WeekView from './WeekView'
import MonthView from './MonthView'
import QuarterView from './QuarterView'
import YearView from './YearView'

const config = {
  [WEEK]: WeekView,
  [MONTH]: MonthView,
  [QUARTER]: QuarterView,
  [YEAR]: YearView
}

export default React.memo(function DateSelector ({ currentTab, period }) {
  const parsedConfig = React.useMemo(() => {
    const Component = config[period]
    if (!Component) return null
    return (
      <Component
        currentTab={currentTab}
        period={period}
      />
    )
  }, [period, currentTab])

  return (
    <div className='g-mt-30'>
      {parsedConfig}
    </div>
  )
})
