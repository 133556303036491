import React from 'react'
import styled from 'styled-components'

import PrescriptionLeftCol from './PrescriptionLeftCol'
import PrescriptionRightCol from './PrescriptionRightCol'

export default function PrescriptionContent () {
  return (
    <StyledWrapper className='g-d-flex g-justify-between g-mt-10'>
      <PrescriptionLeftCol />
      <PrescriptionRightCol />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
  .field {
    max-width: 100%;
    p {
      text-transform: capitalize;
      color: inherit;
      font-size: 1.6rem;
      font-weight: 500;
    }
  }
  .fields-group {
    align-items: flex-end;
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }
    .field {
      @media screen and (max-width: 768px) {
        width: 49%;
      }
      &:nth-child(n+2) {
        margin-left: 5%;
        @media screen and (max-width: 1024px) {
          margin-left: 2%;
        }
        @media screen and (max-width: 768px) {
          margin-left: 0;
        }
      }
      &:nth-child(n+3) {
        @media screen and (max-width: 768px) {
          margin-top: 2rem;
        }
      }
    }
  }
`
