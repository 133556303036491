import React from "react";
import { useHistory, useParams } from "react-router-dom";
import {Client} from "twilio-chat";
import get from "lodash/get";
import filter from "lodash/filter";
import map from "lodash/map";

import { MESSAGES, INVITE } from "constants/routes";

import { globalContext } from "hooks/useGlobalState";
import {
  useSetChatTokenData,
  useSetChatClient,
  useSetChatChannels,
  useSetChatUsers,
} from "hooks/useMessages";
import { useSetOverlayLoading } from "hooks/useUtils";

import request from "utils/request";
import { parseChannels } from "utils/messages";
import { getParticipants } from './graphql/participant/getParticipants';

export function useFetchChatUsersData() {
  const { chatUsers } = React.useContext(globalContext);
  const setChatUsers = useSetChatUsers((prev, next) => [...prev, ...next]);
  return React.useCallback(
    (channels) => {
      const users = [];
      const participants = [];
      channels.forEach((channel) => {
        const members = get(channel, "state.attributes.members", []);
        members.forEach((member) => {
          if (!member.type || !member.chat_identity) return;
          if (
            chatUsers.value.find(
              (item) => item.chat_identity === member.chat_identity
            )
          )
            return;
          if (users.find((item) => item.id === member.chat_identity)) return;
          if (member.type !== "participant") {
            users.push({ type: member.type, id: member.chat_identity });
          } else {
            participants.push(member);
          }
        });
      });
      let usersPromise = Promise.resolve();
      let participantsPromise = Promise.resolve();

      if (users.length > 0) {
        usersPromise = request({
          method: "post",
          url: "/members-data",
          data: users,
        })
          .then(
            ({
              data: {
                data: { members },
              },
            }) => members
          )
          .catch((error) => console.log(error));
      }

      if (participants.length > 0) {
        participantsPromise = getParticipants(participants.map((p) => p.id));
      }
      return Promise.allSettled([usersPromise, participantsPromise]).then(
        ([users, participants]) => {
          setChatUsers([
            ...(users.value || []),
            ...(participants.value || []).map((p) => ({
              ...p,
              type: "participant",
              first_name: p.firstName,
              last_name: p.lastName,
              chat_identity: p.chatIdentity,
              full_name: `${p.firstName} ${p.lastName}`,
            })),
          ]);
        }
      );
    },
    [chatUsers, setChatUsers]
  );
}

async function recursivelyGetSubscribedChannels(channels, getNextPage) {
  const { items, hasNextPage, nextPage } = await getNextPage();
  const newChannels = [...channels, ...items];
  if (hasNextPage) {
    return await recursivelyGetSubscribedChannels(newChannels, nextPage);
  }
  return newChannels;
}

export function useInitialChatData() {
  const { chatTokenData } = React.useContext(globalContext);
  const setChatTokenData = useSetChatTokenData((prev, next) => next, []);
  const setChatClient = useSetChatClient((prev, next) => next, []);
  const setChatChannels = useSetChatChannels((prev, next) => next, []);
  const fetchChatUsersData = useFetchChatUsersData();
  return React.useCallback(async () => {
    const chatTokenDataSnap = chatTokenData.value;
    const channels = [];
    if (!chatTokenDataSnap) {
      try {
        const {
          data: { data },
        } = await request({
          method: "get",
          url: `/messages/token`,
        });
        setChatTokenData(data);
        const chatClient = await Client.create(data.token);

        setChatClient(chatClient);
        const { items, hasNextPage, nextPage } =
          await chatClient.getSubscribedChannels({ limit: 100 });
        channels.push(...items);
        if (hasNextPage) {
          const paginateChannels = await recursivelyGetSubscribedChannels(
            [],
            nextPage
          );
          channels.push(...paginateChannels);
        }
        const parsedChannels = parseChannels(channels);
        await fetchChatUsersData(parsedChannels);
        setChatChannels(parsedChannels);
      } catch (error) {
        console.log(error);
      }
    }
  }, [
    chatTokenData,
    setChatTokenData,
    setChatClient,
    fetchChatUsersData,
    setChatChannels,
  ]);
}

export function useSendMessage(channel) {
  const { user } = React.useContext(globalContext);
  return React.useCallback(
    (message = "", attributes = { }) => {
      const userId = get(user, "value.id");
      const role = get(user, "value.role");
      const members = get(channel, "state.attributes.members", []);
      const recipients = filter(members, (item) => item.id !== userId);
      const normalizedRecipients = map(recipients, (item) => ({
        recipient_type: item.type,
        recipient_id: item.id,
      }));

      request({
        method: "post",
        url: "/messages",
        data: {
          message,
          sender_type: role,
          sender_id: userId,
          recipients: normalizedRecipients,
          channel_id: channel.sid,
        },
      }).catch((error) => console.log(error));
    },
    [channel, user]
  );
}

export function useCreateDirectChat() {
  const history = useHistory();
  const setOverlayLoading = useSetOverlayLoading((prev, next) => next);
  return React.useCallback(
    ({ id, type }) => {
      setOverlayLoading(true);
      return request({
        method: "get",
        url: "/providers/chats",
        params: {
          member_id: id,
          member_type: type,
        },
      })
        .then(({ data: { data } }) => {
          history.push(`/${MESSAGES}/${data.channel_sid}`);
        })
        .catch((error) => console.log(error))
        .finally(() => setOverlayLoading(false));
    },
    [history, setOverlayLoading]
  );
}

export function useCreateGroupChat() {
  const { user } = React.useContext(globalContext);
  const history = useHistory();
  const setOverlayLoading = useSetOverlayLoading((prev, next) => next);
  return React.useCallback(
    (name) => {
      const userId = get(user, "value.id");
      setOverlayLoading(true);
      return request({
        method: "post",
        url: `providers/${userId}/chat`,
        data: {
          name,
        },
      })
        .then(({ data: { data } }) => {
          history.push(`/${MESSAGES}/${data.channel_sid}/${INVITE}`);
        })
        .catch((error) => console.log(error))
        .finally(() => setOverlayLoading(false));
    },
    [user, history, setOverlayLoading]
  );
}

export function useChangeChatOwner(channelType) {
  const { user } = React.useContext(globalContext);
  const { channelSID } = useParams();
  const setOverlayLoading = useSetOverlayLoading((prev, next) => next);
  return React.useCallback(
    (id) => {
      const userId = get(user, "value.id");
      setOverlayLoading(true);
      return request({
        method: "put",
        url: `providers/${userId}/channel/${channelType}/${channelSID}/update`,
        data: {
          owner: id,
        },
      })
        .catch((error) => console.log(error))
        .finally(() => setOverlayLoading(false));
    },
    [user, channelType, channelSID, setOverlayLoading]
  );
}

export function useChangeChatName(channelType) {
  const { user } = React.useContext(globalContext);
  const { channelSID } = useParams();
  const setOverlayLoading = useSetOverlayLoading((prev, next) => next);
  return React.useCallback(
    (name) => {
      const userId = get(user, "value.id");
      setOverlayLoading(true);
      return request({
        method: "put",
        url: `providers/${userId}/channel/${channelType}/${channelSID}/update`,
        data: {
          name,
        },
      })
        .then(() => true)
        .catch((error) => console.log(error))
        .finally(() => setOverlayLoading(false));
    },
    [user, channelType, channelSID, setOverlayLoading]
  );
}

export function useAddMember(channelType) {
  const { user } = React.useContext(globalContext);
  const { channelSID } = useParams();
  const history = useHistory();
  const setOverlayLoading = useSetOverlayLoading((prev, next) => next);
  return React.useCallback(
    (member) => {
      const userId = get(user, "value.id");
      setOverlayLoading(true);
      return request({
        method: "put",
        url: `providers/${userId}/channel/${channelType}/${channelSID}/members`,
        data: {
          members: [member],
        },
      })
        .then(({ data: { data } }) => {
          history.push(`/${MESSAGES}/${channelSID}`);
        })
        .catch((error) => console.log(error))
        .finally(() => setOverlayLoading(false));
    },
    [user, channelType, channelSID, history, setOverlayLoading]
  );
}

export function useRemoveMember(channelType) {
  const { user } = React.useContext(globalContext);
  const { channelSID } = useParams();
  const setOverlayLoading = useSetOverlayLoading((prev, next) => next);
  return React.useCallback(
    (members) => {
      const userId = get(user, "value.id");
      setOverlayLoading(true);
      return request({
        method: "delete",
        url: `providers/${userId}/channel/${channelType}/${channelSID}/members`,
        data: {
          members,
        },
      })
        .catch((error) => console.log(error))
        .finally(() => setOverlayLoading(false));
    },
    [user, channelType, channelSID, setOverlayLoading]
  );
}

export function useDeleteChannel() {
  const { user } = React.useContext(globalContext);
  const { channelSID } = useParams();
  const history = useHistory();
  const setOverlayLoading = useSetOverlayLoading((prev, next) => next);
  return React.useCallback(() => {
    const userId = get(user, "value.id");
    setOverlayLoading(true);
    return request({
      method: "delete",
      url: `providers/${userId}/channel/${channelSID}/delete`,
    })
      .then(() => {
        history.push(`/${MESSAGES}`);
        return true;
      })
      .catch((error) => console.log(error))
      .finally(() => setOverlayLoading(false));
  }, [user, channelSID, setOverlayLoading, history]);
}
