import React from 'react'
import { useParams } from 'react-router-dom'

import { useCompanyFilter } from 'hooks/useCompanies'
import { useFetchCompany, useFetchCompanyPatients } from 'requests/companies'

import LinkButton from 'common/LinkButton'
import Modal from 'common/Modal'
import CustomButton from 'common/CustomButton'

import request from 'utils/request'
import createNotification from 'utils/createNotification'

export default function DeletePatientButton ({ patinetId }) {
  const fetchCompany = useFetchCompany()
  const fetchCompanyPatients = useFetchCompanyPatients()
  const companyFilter = useCompanyFilter()
  const { companyId } = useParams()

  const [isSended, setIsSended] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState(false)
  const openModal = React.useCallback(() => setIsOpen(true), [setIsOpen])
  const closeModal = React.useCallback(() => setIsOpen(false), [setIsOpen])

  const handleDeletePatient = React.useCallback(() => {
    setIsSended(true)
    request({
      method: 'delete',
      url: `/companies/${companyId}/patients/${patinetId}`
    })
      .then(() => {
        createNotification({ message: 'Patient successfully removed', type: 'success' })
        fetchCompany()
        fetchCompanyPatients(companyFilter)
        closeModal()
      })
      .catch((error) => console.log(error))
      .finally(() => setIsSended(false))
  }, [setIsSended, companyId, patinetId, fetchCompany, fetchCompanyPatients, companyFilter, closeModal])

  return (
    <>
      <LinkButton
        className='delete'
        disabled={isSended}
        onClick={openModal}
      >
        Delete
      </LinkButton>

      {isOpen && (
        <Modal
          title='Are you sure in that?'
          minWidth={44}
          maxWidth={44}
          closeModal={closeModal}
        >
          <CustomButton
            className='g-mt-20'
            clickHandler={handleDeletePatient}
          >
            Remove patient from company
          </CustomButton>
        </Modal>
      )}
    </>
  )
}
