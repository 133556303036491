import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { PATIENTS, DETAILS, OVERVIEW, APPOINTMENTS, CONSULTATION } from 'constants/routes'; 

import UserAvatar from 'common/UserAvatar'
import Timer from './Timer'

export default React.memo(function VideoHeader ({
  patientId, firstName, lastName, avatarUrl, fullName, isStarted
}) {
  const { appointmentId } = useParams()
  const history = useHistory()

  const navigateToPatient = React.useCallback(() => {
    return history.push({
      pathname: `/${PATIENTS}/${DETAILS}/${patientId}/${OVERVIEW}`,
      state: { referrer: `/${APPOINTMENTS}/${CONSULTATION}/${appointmentId}` }
    })
  }, [history, patientId, appointmentId])

  return (
    <StyledWrapper>
      <UserAvatar
        className='avatar'
        firstName={firstName}
        lastName={lastName}
        avatar={avatarUrl}
        onClick={navigateToPatient}
      />
      {fullName}
      <Timer isStarted={isStarted} />
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 600;
  padding: 1.4rem 2.5rem;
  border-bottom: solid 2px #f1f5f8;
  .avatar {
    min-width: 4rem;
    width: 4rem;
    height: 4rem;
    margin-right: 1rem;
  }
`
