import React from 'react';
import { BehaviorSubject } from 'rxjs';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import { createUseGlobalState, createUseSetGlobalState } from 'utils/globalStateHandlers';

import jsonQuizConfig from 'configs/jsonQuizConfig';
import jsonQuizResultsConfig from 'configs/jsonQuizResultsConfig';
import jsonQuizMetricsConfig from 'configs/jsonQuizMetricsConfig';

import { RAW_SCORE, T_SCORE, METRIC_RULES } from 'constants/quiz-types';

export const questionnairesContext = React.createContext({
  quizzesData: new BehaviorSubject(null),
  quiz: new BehaviorSubject(null),
  quizStep: new BehaviorSubject(0),
});

const globalState = createUseGlobalState(questionnairesContext);
const setGlobalState = createUseSetGlobalState(questionnairesContext);

export const useQuizzesData = globalState('quizzesData');
export const useSetQuizzesData = setGlobalState('quizzesData');

export const useQuiz = globalState('quiz');
export const useSetQuiz = setGlobalState('quiz');

export const useQuizStep = globalState('quizStep');
export const useSetQuizStep = setGlobalState('quizStep');

export function useIsQuizChanged() {
  return useQuiz((current) => {
    if (!current) return false;
    const { prevContent, content } = current;
    return !isEqual(prevContent, content);
  });
}

export function useElementsMapper() {
  const { quiz } = React.useContext(questionnairesContext);
  const step = useQuizStep();
  const pages = useQuiz((current) => get(current, 'content.pages', []), []);
  return React.useMemo(() => {
    const elements = get(pages, `[${step}].elements`);
    if (!elements) return null;
    return elements
      .map((element, i) => {
        const type = get(element, 'type');
        const Component = jsonQuizConfig[type];
        if (!Component) {
          console.warn(`Element type: ${type} not found`);
          return null;
        }
        const visibleIf = get(element, 'visibleIf');
        if (visibleIf) {
          const [key, value] = visibleIf.split('=');
          if (get(quiz, `value.content.answers.${key}.value`) !== value) return null;
        }
        return (
          <Component
            key={i}
            metaPath={`content.pages[${step}].elements[${i}]`}
            answerField={get(quiz, `value.content.pages[${step}].elements[${i}].name`)}
          />
        );
      })
      .filter((item) => item);
  }, [pages, step, quiz]);
}

export function useComplexElementsMapper({ metaPath, rowIndex }) {
  const { quiz } = React.useContext(questionnairesContext);
  const elements = useQuiz((current) => get(current, `${metaPath}.columns`, []), [metaPath]);
  return React.useMemo(() => {
    return elements.map((element, i) => {
      const type = get(element, 'cellType');
      const Component = jsonQuizConfig[type];
      if (!Component) {
        console.warn(`Element type: ${type} not found`);
        return null;
      }
      const answerField = get(quiz, `value.${metaPath}.name`);
      return <Component key={i} className="g-mt-20" metaPath={`${metaPath}.columns[${i}]`} answerField={`${answerField}[${rowIndex}][${i}]`} />;
    });
  }, [elements, metaPath, rowIndex, quiz]);
}

export function useElementsResultMapper() {
  const { quiz } = React.useContext(questionnairesContext);
  const pages = useQuiz((current) => get(current, 'content.pages', []), []);
  return React.useMemo(() => {
    return pages.map(({ elements }, i) => {
      if (!elements) return null;
      return elements
        .map((element, j) => {
          const type = get(element, 'type');
          const Component = jsonQuizResultsConfig[type];
          if (!Component) {
            console.warn(`Element type: ${type} not found`);
            return null;
          }
          const visibleIf = get(element, 'visibleIf');
          if (visibleIf) {
            const [key, value] = visibleIf.split('=');
            if (get(quiz, `value.content.answers.${key}.value`) !== value) return null;
          }
          return (
            <Component
              key={i + j}
              metaPath={`content.pages[${i}].elements[${j}]`}
              answerField={get(quiz, `value.content.pages[${i}].elements[${j}].name`)}
            />
          );
        })
        .filter((item) => item);
    });
  }, [pages, quiz]);
}
export function useElementsResultMetricsMapper(type) {
  const MetricComponent = jsonQuizMetricsConfig[type];
  const MetricRules = jsonQuizMetricsConfig[METRIC_RULES][type];
  const { quiz } = React.useContext(questionnairesContext);
  const pages = useQuiz((current) => get(current, 'content.pages', []), []);

  return React.useMemo(() => {
    const filteredAnswers = pages
      .map(({ elements }) => {
        if (!elements) return false;
        return elements
          .map((element) => {
            if (!MetricComponent || !MetricRules) {
              console.warn(`Element type: ${type} not found`);
              return null;
            }
            const name = element.name;
            const value = get(quiz, `value.content.answers.${name}.value`);
            const questionRule = MetricRules[RAW_SCORE][name];
            if (!questionRule) return false;

            return questionRule[value];
          })
          .filter((item) => item);
      })
      .flat();
    const RawScore = filteredAnswers.reduce((acc, val) => acc + (typeof val === 'number' ? val : 0), 0);
    const TScore = MetricRules[T_SCORE][RawScore];
    if (!TScore) console.warn(`Element RawScore: ${RawScore} not found`);
    return <MetricComponent rawScore={RawScore} tScore={TScore} />;
  }, [pages, quiz]);
}

export function useComplexElementsResultMapper({ metaPath, rowIndex }) {
  const { quiz } = React.useContext(questionnairesContext);
  const elements = useQuiz((current) => get(current, `${metaPath}.columns`, []), [metaPath]);
  return React.useMemo(() => {
    return elements.map((element, i) => {
      const type = get(element, 'cellType');
      const Component = jsonQuizResultsConfig[type];
      if (!Component) {
        console.warn(`Element type: ${type} not found`);
        return null;
      }
      const answerField = get(quiz, `value.${metaPath}.name`);
      return <Component key={i} className="g-mt-20" metaPath={`${metaPath}.columns[${i}]`} answerField={`${answerField}[${rowIndex}][${i}]`} />;
    });
  }, [elements, metaPath, rowIndex, quiz]);
}
