import React from 'react'

import Bar from './Bar'

export default function BarsList ({ config, chartOffsets }) {
  const parsedBars = React.useMemo(() => {
    return config.map((time, i) => {
      return (
        <Bar
          key={i}
          time={time}
          chartOffsets={chartOffsets}
        />
      )
    })
  }, [config, chartOffsets])
  return parsedBars
}
