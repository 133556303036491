import React from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { useFetchLogs } from 'requests/logs'
import { ACTIVITY_LOG, DASHBOARD } from 'constants/routes'

import CustomButton from 'common/CustomButton'
import ReloadButton from 'common/ReloadButton'

export default React.memo(function Footer () {
  const history = useHistory()
  const fetchLogs = useFetchLogs()

  const navigateToActivityLog = React.useCallback(() => {
    history.push(`/${DASHBOARD}/${ACTIVITY_LOG}`)
  }, [history])

  const reloadHandler = React.useCallback(() => {
    fetchLogs({
      from: moment().add(-7, 'd').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD')
    })
  }, [fetchLogs])

  return (
    <div className='g-d-flex g-justify-between g-mt-auto'>
      <CustomButton
        clickHandler={navigateToActivityLog}
      >
        Activity log
      </CustomButton>

      <ReloadButton
        clickHandler={reloadHandler}
      />
    </div>
  )
})
