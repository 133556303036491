import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'clsx';

import { ReactComponent as ArrowBackIcon } from 'assets/arrow-back.svg';

import { useSwitch } from 'common/Switch';
import { viewItems, ViewSwitch } from './ViewSwitch';
import { Sidebar } from './Sidebar/Sidebar';
import { SummarySection } from './sections/SummarySection/SummarySection';
import { DietSection } from './sections/DietSection/DietSection';
import { TreatmentPlanHeader } from './TreatmentPlanHeader';
import { TaskType, TaskTypeName, TreatmentPlanType } from '../../../../requests/graphql/my-health/queries/getTreatmentPlan';
import moment from 'moment';
import { PatientDetails, ProviderDetails } from './sections/SummarySection/SummarySectionView';
import { ConfirmationModal } from '../../../ui2.0/ConfirmationModal';
import { TreatmentPlanStatus } from '../../../../zeus-graphql/my-health/zeus';
import { MedicationsSection } from './sections/MedicationsSection/MedicationsSection';
import { LabsSection } from './sections/LabsSection/LabsSection';
import { ActivitySection } from './sections/ActivitySection/ActivitySection';

const lsStorage = {
  prefix: 'treatment-plan-',
  extract(patientId: string) {
    const data = localStorage.getItem(`${this.prefix}${patientId}`);
    return data ? JSON.parse(data) : {};
  },
  insert(patientId: string, data: Record<string, any>) {
    localStorage.setItem(`${this.prefix}${patientId}`, JSON.stringify(data));
  },
  get(patientId: string, id: string) {
    const data = this.extract(patientId);
    return data[id];
  },
  set(patientId: string, id: string, value: Record<string, any>) {
    const data = this.extract(patientId);
    data[id] = value;
    this.insert(patientId, data);
  },
  remove(patientId: string, id: string) {
    const data = this.extract(patientId);
    delete data[id];
    this.insert(patientId, data);
  },
};

type TreatmentPlanEditProps = {
  plan?: Partial<TreatmentPlanType>;
  patientId: string;
  onSaveDraft: (plan: Partial<TreatmentPlanType>) => Promise<void>;
  onSaveAndPush: (plan: Partial<TreatmentPlanType>) => Promise<void>;
  provider?: ProviderDetails;
  patient: PatientDetails;
  loading?: boolean;
};

const common = {
  __typename: 'TaskGqlType',
  id: '',
  title: '',
  type: 'other',
  status: 'draft',
  isNew: true,
  details: {},
  files: [],
  materials: [],
};

const placeholders: Partial<Record<TaskTypeName, TaskType<TaskTypeName>>> = {
  diet: {
    ...common,
    type: 'diet',
    details: {
      summary: '',
      duration: 1,
      description: '',
      type: 'diet',
    },
  } as TaskType<'diet'>,
  activity: {
    ...common,
    type: 'activity',
    details: {
      summary: '',
      duration: 1,
      description: '',
      type: 'activity',
    },
  } as TaskType<'activity'>,
  labs: {
    ...common,
    type: 'labs',
    details: {
      summary: '',
    },
  } as TaskType<'labs'>,
  medications: {
    ...common,
    type: 'medications',
    details: {
      categoryId: 0,
      startDate: '',
      endDate: '',
      name: '',
      measureId: 0,
      frequencyId: 0,
      quantity: 0,
      description: '',
      type: 'medications',
    },
  } as TaskType<'medications'>,
};

const makeTaskPlaceholder = (type: TaskTypeName): TaskType<TaskTypeName> => {
  return placeholders[type] || (common as TaskType<TaskTypeName>);
};

export const TreatmentPlanEdit: FC<TreatmentPlanEditProps> = ({ plan, loading, onSaveDraft, onSaveAndPush, patient, patientId, provider }) => {
  const [activeView, setActiveView] = useSwitch({ items: viewItems });
  const [planToPublish, setPlanToPublish] = useState<Partial<TreatmentPlanType> | undefined>();

  const [isReadyToBackup, setIsReadyToBackup] = useState(false);

  const [title, setTitle] = useState(plan?.title || `Treatment Plan ${moment().format('MM.DD.yyyy')} `);
  const [summary, setSummary] = useState(plan?.summary || '');
  const [currentTasks, setCurrentTasks] = useState<TaskType[]>((plan?.tasks as TaskType[]) || []);

  const addTaskItem = useCallback(<T extends TaskTypeName>(val: T) => {
    const newTask = makeTaskPlaceholder(val);
    setCurrentTasks((prev) => [...prev, newTask]);
  }, []);

  const onSectionChange = useCallback(
    <T extends TaskTypeName>(name: T) =>
      (items: TaskType<T>[]) => {
        setCurrentTasks((p) => [...p.filter((t) => t.type !== name), ...items]);
      },
    []
  );

  const handleChangeTitle = useCallback((title: string) => {
    setTitle(title);
  }, []);

  const resetDraft = useCallback(() => {
    lsStorage.remove(patientId, plan?.id || 'new');
    setTitle((plan?.title as string) || `Treatment Plan ${moment().format('MM.DD.yyyy')} `);
    setSummary((plan?.summary as string) || '');
    setCurrentTasks((plan?.tasks as TaskType[]) || []);
  }, [patientId, plan]);

  const handleSaveDraft = useCallback(async () => {
    await onSaveDraft({
      ...(plan?.id ? { id: plan.id } : {}),
      title,
      summary,
      tasks: currentTasks,
    });

    lsStorage.remove(patientId, plan?.id || 'new');
  }, [title, summary, currentTasks]);

  const handleSaveAndPush = useCallback(() => {
    setPlanToPublish({
      ...(plan?.id ? { id: plan.id } : {}),
      title,
      summary,
      tasks: currentTasks,
      status: TreatmentPlanStatus.sent,
    });
  }, [title, summary, currentTasks]);

  const saveAndPush = useCallback(async () => {
    if (!planToPublish) return;

    await onSaveAndPush(planToPublish);
    lsStorage.remove(patientId, planToPublish.id || 'new');
    setPlanToPublish(undefined);
  }, [planToPublish, patientId]);

  const filterTasks = useCallback(
    <N extends TaskTypeName>(name: N) =>
      () =>
        currentTasks.filter((t) => t.type === name) as TaskType<N>[],
    [currentTasks]
  );

  const dietTasks = useMemo(filterTasks('diet'), [currentTasks]);
  const labsTasks = useMemo(filterTasks('labs'), [currentTasks]);
  const medicationsTasks = useMemo(filterTasks('medications'), [currentTasks]);
  const activityTasks = useMemo(filterTasks('activity'), [currentTasks]);

  const isDirty = useMemo(() => {
    const planTasks = plan?.tasks || [];
    const planTitle = plan?.title || '';
    const planSummary = plan?.summary || '';

    return JSON.stringify(planTasks) !== JSON.stringify(currentTasks) || planTitle !== title || planSummary !== summary;
  }, [currentTasks, title, summary, plan]);

  useEffect(() => {
    setTitle((plan?.title as string) || `Treatment Plan ${moment().format('MM.DD.yyyy')} `);
  }, [plan?.title]);

  useEffect(() => {
    setSummary((plan?.summary as string) || '');
  }, [plan?.summary]);

  useEffect(() => {
    setCurrentTasks((plan?.tasks as TaskType[]) || []);
  }, [plan?.tasks]);

  useEffect(() => {
    if (!patientId || loading) return;
    const id = plan?.id || 'new';
    const data = lsStorage.get(patientId, id);
    if (data) {
      setTitle(data?.title || plan?.title || `Treatment Plan ${moment().format('MM.DD.yyyy')} `);
      setSummary(data?.summary || plan?.summary || '');
      setCurrentTasks(data?.currentTasks || plan?.tasks || []);
    }
    setIsReadyToBackup(true);
  }, [patientId, plan, loading]);

  useEffect(() => {
    if (!patientId || loading || !isDirty || !isReadyToBackup) return;

    const data = {
      title,
      summary,
      currentTasks,
      timestamp: new Date(),
    };
    const id = plan?.id || 'new';

    lsStorage.set(patientId, id, data);
  }, [currentTasks, title, summary, isReadyToBackup, plan, loading, isDirty]);

  return (
    <div className="">
      <TreatmentPlanHeader
        patientId={patientId}
        isSaveActive={!!currentTasks.length && currentTasks.every((t) => !t.isNew)}
        loading={loading}
        isDirty={isDirty && !loading && plan?.id}
        onReset={resetDraft}
        onSaveAndPush={handleSaveAndPush}
        onSave={handleSaveDraft}
        onChangeTitle={handleChangeTitle}
        title={title}
      />

      <div className="bg-alabaster m-4 rounded-main shadow-row p-6">
        <div className="flex h-full">
          <div className="flex-1 pr-6">
            <div className="flex justify-between mb-6">
              <ViewSwitch loading={loading} items={viewItems} activeItem={activeView} setActiveItem={setActiveView} />
              <div className="flex">
                <div
                  className={classNames('cursor-pointer1 flex justify-center  items-center rounded-md h-8 w-8', {
                    'animate-pulse bg-catskillWhite': loading,
                    'bg-white': !loading,
                  })}
                >
                  {!loading && <ArrowBackIcon className="fill-manatee opacity-20" />}
                </div>
                <div
                  className={classNames('cursor-pointer1 flex justify-center items-center rounded-md  h-8 w-8 ml-4', {
                    'animate-pulse bg-catskillWhite': loading,
                    'bg-white': !loading,
                  })}
                >
                  {!loading && <ArrowBackIcon className="fill-manatee opacity-20 -scale-x-[1]" />}
                </div>
              </div>
            </div>
            <div className="">
              <SummarySection
                title={title}
                createdAt={plan?.createdAt}
                provider={plan?.createdBy?.profile || provider}
                patient={patient}
                isPreview={activeView?.value === 'preview'}
                loading={loading}
                onChange={setSummary}
                summary={summary}
              />
              {!loading && dietTasks.length > 0 && (
                <DietSection
                  isPreview={activeView?.value === 'preview'}
                  onChange={onSectionChange('diet')}
                  onAddNew={() => addTaskItem('diet')}
                  tasks={dietTasks}
                />
              )}
              {!loading && medicationsTasks.length > 0 && (
                <MedicationsSection
                  isPreview={activeView?.value === 'preview'}
                  onChange={onSectionChange('medications')}
                  onAddNew={() => addTaskItem('medications')}
                  tasks={medicationsTasks}
                />
              )}
              {!loading && labsTasks.length > 0 && (
                <LabsSection
                  isPreview={activeView?.value === 'preview'}
                  onChange={onSectionChange('labs')}
                  onAddNew={() => addTaskItem('labs')}
                  tasks={labsTasks}
                />
              )}
              {!loading && activityTasks.length > 0 && (
                <ActivitySection
                  isPreview={activeView?.value === 'preview'}
                  onChange={onSectionChange('activity')}
                  onAddNew={() => addTaskItem('activity')}
                  tasks={activityTasks}
                />
              )}
            </div>
          </div>
          <div className="border-l min-w-[32rem] w-[26%]">
            <Sidebar loading={loading} onSelectTaskItem={addTaskItem} />
          </div>
        </div>
      </div>
      {planToPublish && (
        <ConfirmationModal
          title="You are going to send treatment plan for the patient. After this treatment plan will be locked for editing. Proceed?"
          okButtonClassName="bg-main"
          okText="Save and Send"
          onClose={() => setPlanToPublish(undefined)}
          onDelete={saveAndPush}
        />
      )}
    </div>
  );
};
