import React from 'react'

import { LIST } from 'constants/routes'; 

import ReferralTemplate from 'components/referral/ReferralTemplate'
import ReferralLoader from 'components/referral/referral-list/ReferralLoader'

export default function Referral () {
  return (
    <ReferralTemplate
      currentTab={LIST}
    >
      <ReferralLoader />
    </ReferralTemplate>
  )
}
