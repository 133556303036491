import React from 'react'

import { useChatChannels } from 'hooks/useMessages'

import MessageAdded from './MessageAdded'
import MessageRemoved from './MessageRemoved'
import ChannelUpdated from './ChannelUpdated'
import ChannelAdded from './ChannelAdded'
import ChannelRemoved from './ChannelRemoved'
import ChannelMemberLeft from './ChannelMemberLeft'
import CountHandler from './CountHandler'

export default React.memo(function ChatListeners () {
  const chatChannels = useChatChannels()

  if (!chatChannels) return null

  return (
    <>
      <MessageAdded />
      <MessageRemoved />
      <ChannelUpdated />
      <ChannelAdded />
      <ChannelRemoved />
      <ChannelMemberLeft />
      <CountHandler />
    </>
  )
})
