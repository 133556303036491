import React from 'react'
import styled from 'styled-components'

import { useDayData } from 'hooks/patient/widgets/useWidgets'

import WeightStatsItem from './WeightStatsItem'

export default function WeightStats () {
  const weight = useDayData('weight')
  const fatMass = useDayData('fatMass')

  return (
    <StyledWrapper className='g-d-flex g-fw-w g-w-100 g-justify-center'>
      <WeightStatsItem
        label='Weight'
        value={weight || 0}
        units='lb'
      />
      <WeightStatsItem
        label='Fat mass'
        value={fatMass || 0}
        units='lb'
      />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  padding-bottom: 4rem;
  border-bottom: 0.1rem solid #e9ebef;
  @media screen and (max-width: 480px) {
    justify-content: space-between;
  }
`
