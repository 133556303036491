import React from 'react'

import { useSetAppointmentCreate } from 'hooks/useAppointments'

import SearchableSelect from 'common/searchable-select'

export default React.memo(function PatientSelect () {
  const setPatient = useSetAppointmentCreate((prev, patient) => ({ ...prev, patient }), [])
  const clearPatient = useSetAppointmentCreate((prev) => ({ ...prev, patient: '' }), [])
  return (
    <SearchableSelect
      className='patient'
      label='Patient'
      placeholder='Patient'
      searchLink='patients'
      searchField='patients'
      onSelect={setPatient}
      onClear={clearPatient}
    />
  )
})
