import styled from 'styled-components'

export const UnequalColumns = styled.div`
  border-top: 0.1rem solid #e9ebef;
  border-bottom: 0.1rem solid #e9ebef;
  @media screen and (max-width: 768px) {
    display: block;
  }
  .col {
    padding: 3rem 1.5rem;
  }
  .col-left {
    min-width: 30.5rem;
    padding-left: 4rem;
    @media screen and (max-width: 480px) {
      min-width: 100%;
    }
  }
  .col-right {
    flex-grow: 1;
    text-align: center;
    border-left: 0.1rem solid #e9ebef;
    @media screen and (max-width: 768px) {
      border-top: 0.1rem solid #e9ebef;
      border-left: none;
    }
  }
`

export const EqualColumns = styled.div`
  border-top: 0.1rem solid #e9ebef;
  @media screen and (max-width: 768px) {
    display: block;
    border-top: none;
  }
  .col {
    padding: 2rem 1.5rem;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .col-left {
    padding-right: 5.5rem;
    padding-left: 3.5rem;
    @media screen and (max-width: 768px) {
      padding-right: 3rem;
      padding-left: 3rem;
    }
  }
  .col-right {
    padding-right: 3.5rem;
    padding-left: 5.5rem;
    border-left: 0.1rem solid #e9ebef;
    @media screen and (max-width: 768px) {
      padding-right: 3rem;
      padding-left: 3rem;
      border-top: 0.1rem solid #e9ebef;
      border-left: none;
    }
  }
  .hint {
    display: inline-block;
  }
`
