import React from 'react'
import styled from 'styled-components'

export default React.memo(function ListPlaceholder ({ className = '' }) {
  return (
    <StyledListPlaceholder className={className}>
      <div className='line line-1' />
      <div className='line line-2 ' />
      <div className='line line-3' />
    </StyledListPlaceholder>
  )
})

const StyledListPlaceholder = styled.div`
  padding: 1.5rem 2.5rem 2rem;
  border-bottom: solid 1px #e9ebef;
  .line {
    background: #e9ebef;
    height: 1.7rem;
  }
  .line-1 {
    width: 80%;
  }
  .line-2 {
    width: 60%;
    margin: 1.5rem 0;
  }
  .line-3 {
    width: 40%;
  }
`
