import Comment from 'components/patients/questionnaires/quiz-results/elements/Comment'
import Matrixdynamic from 'components/patients/questionnaires/quiz-results/elements/Matrixdynamic'
import Text from 'components/patients/questionnaires/quiz-results/elements/Text'
import RadioGroup from 'components/patients/questionnaires/quiz-results/elements/RadioGroup'
import SwitchGroup from 'components/patients/questionnaires/quiz-results/elements/SwitchGroup'
import Expression from 'components/patients/questionnaires/quiz-results/elements/Expression'
import Checkbox from 'components/patients/questionnaires/quiz-results/elements/Checkbox'
import MultipleText from 'components/patients/questionnaires/quiz-results/elements/MultipleText'

const config = {
  'matrixdynamic': Matrixdynamic,
  'comment': Comment,
  'dropdown': Text,
  'text': Text,
  'radiogroup': RadioGroup,
  'switchgroup': SwitchGroup,
  'expression': Expression,
  'checkbox': Checkbox,
  'multipletext': MultipleText,
  'submitbutton': () => null,
  'nextbutton': () => null
}

export default config
