import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import get from 'lodash/get'

import { PATIENTS, DETAILS, QUESTIONNAIRES } from 'constants/routes'; 

import { useQuiz, useQuizStep, useSetQuizStep } from 'hooks/patient/useQuestionnaires'

import BackButton from 'common/plate/BackButton'

export default React.memo(function BackHandler () {
  const { patientId } = useParams()
  const history = useHistory()
  const step = useQuizStep()
  const group = useQuiz(current => get(current, 'group'))
  const navigateBack = useSetQuizStep(prev => +prev - 1)

  const navigationHandler = React.useCallback(() => {
    if (+step === 0) return history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${QUESTIONNAIRES}/${group}`)
    navigateBack()
  }, [history, patientId, step, group, navigateBack])

  return <BackButton navigationHandler={navigationHandler} />
})
