import React from 'react'
import get from 'lodash/get'
import moment from 'moment'

import { useProviderProfile } from 'hooks/useProviders'

import { MediumText } from 'common/texts'
import PropertyValue from 'common/PropertyValue'
import ChangePasswordButton from './change-password-button'

export default function PersonalInformation () {
  const providerProfile = useProviderProfile()

  const isLoading = React.useMemo(() => !providerProfile, [providerProfile])

  const firstName = get(providerProfile, 'first_name', '')
  const lastName = get(providerProfile, 'last_name', '')
  const gender = get(providerProfile, 'gender', '')
  const dateOfBirthday = React.useMemo(() => {
    const birthDate = get(providerProfile, 'birth_date', '')
    if (birthDate === 'n/a') return ''
    const parsedBirthDate = moment(birthDate).format('MMMM DD, YYYY')
    const age = moment().diff(birthDate, 'years')
    return `${parsedBirthDate} (${age} years)`
  }, [providerProfile])
  const weight = get(providerProfile, 'weight', '')

  return (
    <>
      <MediumText>
        Personal Information
      </MediumText>

      <PropertyValue
        className='g-mt-5'
        isLoading={isLoading}
        property='First name'
        value={firstName}
      />
      <PropertyValue
        className='g-mt-5'
        isLoading={isLoading}
        property='Last name'
        value={lastName}
      />
      <PropertyValue
        className='g-mt-5'
        isLoading={isLoading}
        property='Gender'
        value={gender}
      />
      <PropertyValue
        className='g-mt-5'
        isLoading={isLoading}
        property='Date of Birthday'
        value={dateOfBirthday}
      />
      <PropertyValue
        className='g-mt-5'
        isLoading={isLoading}
        property='Weight'
        value={weight}
      />
      <PropertyValue
        className='g-mt-5'
        isLoading={isLoading}
        property='Password'
        value={<ChangePasswordButton />}
      />
    </>
  )
}
