import { formatDate } from '../utils';
import Calendar from 'react-calendar';
import styled from 'styled-components';

const CustomCalendar = ({ selectedDate, handleDateChange, mealsByDate }) => {
  return (
    <div className="rounded-lg shadow-md bg-white">
      <StyledCalendar
        onChange={handleDateChange}
        selectRange={true}
        view={'month'}
        value={selectedDate}
        maxDate={new Date()}
        tileClassName={({ date, view }) => {
          if (view === 'month') {
            const dateString = formatDate(date);
            if (mealsByDate[dateString]) {
              return 'highlight';
            }
          }
        }}
      />
    </div>
  );
};

export default CustomCalendar;

export const StyledCalendar = styled(Calendar)`
  position: absolute;
  background-color: #f1f5f8;
  border-radius: 8px;
  box-shadow: 0px 3px 15px 0px #00000026;
  z-index: 2;
  padding: 20px;
  border: 0.63px solid #ebebeb;
  .react-calendar {
    /* padding: 1rem;
    display: grid;
    grid-template-columns: repeat(7, auto); */
    &__navigation button {
      &:enabled {
        &:hover {
          background-color: unset;
        }
      }
    }

    &__navigation__arrow {
      font-size: 20px;
    }
    &__navigation__label__labelText {
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
      color: #333333;
    }
    &__month-view__weekdays__weekday {
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      abbr {
        text-decoration: none;
        text-transform: capitalize;
      }
    }
    &__tile {
      border-radius: 50%;
      width: calc(38px + 10px * 2);
      height: calc(38px + 3px * 2);
      box-sizing: border-box;
      &:hover {
        background-color: #16123f !important;
        cursor: not-allowed;
        abbr {
          color: white;
        }
        &:disabled {
          background-color: #666666 !important;
        }
      }
      &:disabled {
        background-color: #f1f5f8 !important;
      }
      abbr {
        font-size: 16px;
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        color: #666666;
      }
      &--active {
        background-color: #16123f;
        abbr {
          color: white;
        }
      }
    }
  }
  .highlight {
    background-color: #cdc8ff !important;
    abbr {
      color: white !important;
    }
  }
`;
