import React from 'react'
import { useParams } from 'react-router-dom'

import { useIsQuizChanged } from 'hooks/patient/useQuestionnaires'
import { useSaveQuiz } from 'requests/patients/questionnaires'

import CustomButton from 'common/CustomButton'

export default React.memo(function SaveHandler () {
  const { quizId } = useParams()
  const saveQuiz = useSaveQuiz()
  const isChanged = useIsQuizChanged()

  const handleSave = React.useCallback(() => {
    saveQuiz({ quizId })
  }, [quizId, saveQuiz])

  return (
    <CustomButton
      className='g-ml-15'
      disabled={!isChanged}
      inversion
      clickHandler={handleSave}
    >
      Save
    </CustomButton>
  )
})
