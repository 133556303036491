import React from 'react'
import styled from 'styled-components'

import { RowPlate } from 'common/plate/plates'
import { PlateHeader, PlateContent } from 'common/plate/styles'
import BackButton from 'common/plate/BackButton'
import { DASHBOARD } from 'constants/routes'


export default function TrainingAndResources () {
  return (
    <StyledRowPlate>
      <PlateHeader>
        <BackButton href={`/${DASHBOARD}`} />
        Training and resources
      </PlateHeader>
      <PlateContent>
        <a
          className='source-link g-mt-5'
          href='https://www.youtube.com/playlist?list=PLps489ytX5DOIKTYDOmXYEWltS22OkzLQ'
          rel='noopener noreferrer'
          target='_blank'
        >
          Type 2 Diabetes Program
        </a>
        <a
          className='source-link g-mt-5'
          href='https://drive.google.com/file/d/1D4dM-3uj3Eh4LEOUKATWH3aExa7P1dLY/view'
          rel='noopener noreferrer'
          target='_blank'
        >
          Dashboard Training
        </a>
      </PlateContent>
    </StyledRowPlate>
  )
}

const StyledRowPlate = styled(RowPlate)`
  min-height: 66rem;
  .source-link {
    font-size: 1.3rem;
  }
`
