import React from 'react'
import styled from 'styled-components'

import UserSelect from './UserSelect'
import FromSelect from './FromSelect'
import ToSelect from './ToSelect'

export default function LogsFilters () {
  return (
    <StyledWrapper className='g-d-flex g-justify-between g-mb-10'>
      <UserSelect />
      <FromSelect />
      <ToSelect />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  .from {
    margin: 0 1.5rem;
  }
  @media screen and (max-width: 480px) {
    display: block;
    .from {
      margin: 1rem 0 0 0;
    }
    .to {
      margin: 1rem 0 0 0;
    }
  }
`
