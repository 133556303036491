import React from 'react'

import { RegularMediumText } from 'common/texts'

export default function EmptyReferral () {
  return (
    <RegularMediumText className='g-mt-30'>
      You do not have any referral users.
    </RegularMediumText>
  )
}
