import React from 'react'

import { useCompanyPlansMembers, useSetCompanyPlansMembers } from 'hooks/useCompanies'
import { useFetchCompanyPlansMembers } from 'requests/companies'
import PatientsList from '../company-plan/PatientsList'
import EmptyPatients from '../company/patients-loader/EmptyPatients'
import PatientsPlaceholder from '../company/patients-loader/PatientsPlaceholder'

export default function PlansLoader () {
  const companyPlansMembers = useCompanyPlansMembers()
  const fetchCompanyPlansMembers = useFetchCompanyPlansMembers()
  const clearPlansMembers = useSetCompanyPlansMembers(() => null, [])

  React.useEffect(() => {
    fetchCompanyPlansMembers()
    // eslint-disable-next-line
    return clearPlansMembers
  }, [])

  if (!companyPlansMembers) return <PatientsPlaceholder />

  if (companyPlansMembers.length === 0) return <EmptyPatients />

  return <PatientsList />
}
