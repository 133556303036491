import moment from "moment";
import cloneDeep from "lodash/cloneDeep";

/**
 *
 * @param {{ defaults: Object.<string, number[][]>; specials:{from: string, to:string, hoursCouples: number[][]}[]}} availability
 * @param {Date} selectedDate
 * @returns
 */

export function makeRange(availability, selectedDate) {
  const currentDayNum = selectedDate.getDay();
  const maybeSpecialDay = (availability.specials || []).find((specialDay) =>
    moment(specialDay.from).isSame(selectedDate, "day")
  );

  const defaultDay =
    (availability.defaults || [])[currentDayNum === 0 ? 7 : currentDayNum] ||
    [];

  const range = maybeSpecialDay?.hoursCouples || defaultDay;

  return { range, type: maybeSpecialDay ? "special" : "default" };
}

/**
 * @param {{ defaults: Object.<string, number[][]>; specials:{from: string, to:string, hoursCouples: number[][]}[]}} availability
 * @param {Date} date
 * @param {String} view
 * @returns
 */
export function workingDaysHighlight(
  availability = { defaults: {}, specials: [] },
  dirtyDates = [],
  dirtyDays = [],
  date,
  view
) {
  if (view === "month") {
    if (
      dirtyDays.includes(date.getUTCDay() + 1) ||
      dirtyDates
        .map((d) => moment(d).format("YYYY-MM-DD"))
        .includes(moment(date).format("YYYY-MM-DD"))
    ) {
      return "react-calendar__tile--dirty";
    }
    const { range, type } = makeRange(availability, date);

    if (range.length === 0 && type === "special") {
      return "react-calendar__tile--special-workday";
    }

    if (range.length > 0) {
      return type === "special"
        ? "react-calendar__tile--special-workday"
        : "react-calendar__tile--default-workday";
    }
  }
}
/**
 * @param {{ defaults: Object.<string, number[][]>; specials:{from: string, to:string, hoursCouples: number[][]}[]}} availability
 * @param {Date} selectedDate
 * @param {number[][]} ranges
 */
export function calculateAvailability(
  availability = {
    defaults: {},
    specials: [],
  },
  selectedDate,
  ranges
) {
  const currentDayNum = selectedDate.getUTCDay() + 1;

  const ava = cloneDeep(availability);
  const maybeSpecialDay = (ava.specials || []).find((specialDay) =>
    moment(specialDay.from).isSame(selectedDate, "day")
  );
  const defaultDay = (ava.defaults || {})[currentDayNum];

  if (!ranges) {
    return { ...ava, specials: ava.specials || [] };
  }

  if (JSON.stringify(ranges) === JSON.stringify(defaultDay)) {
    return ava;
  }

  if (
    maybeSpecialDay &&
    JSON.stringify(ranges) === JSON.stringify(maybeSpecialDay.hoursCouples)
  ) {
    return ava;
  }

  if (maybeSpecialDay) {
    maybeSpecialDay.hoursCouples = ranges.map((range) => range.slice());
    return ava;
  }

  const from = moment(selectedDate);
  const to = moment(selectedDate);

  from.hours(0);
  from.minutes(0);
  from.seconds(0);

  to.hours(23);
  to.minutes(59);
  to.seconds(59);

  return {
    ...ava,
    specials: [
      ...(ava.specials || []),
      {
        from: from.format("YYYY-MM-DD HH:mm:ss"),
        to: to.format("YYYY-MM-DD HH:mm:ss"),
        hoursCouples: ranges,
      },
    ],
  };
}

/**
 *
 * @param {number} hour
 * @param {boolean} toUTC
 */
function convertHoursTimezone(hour, toUTC = false) {
  const t = toUTC ? moment().local() : moment().utc();
  t.hours(hour);
  t.minutes(0);
  t.seconds(0);
  t.milliseconds(0);

  if (toUTC) {
    return t.utc().hours();
  }
  return t.local().hours();
}

/**
 *
 * @param {{ defaults: Object.<string, number[][]>; specials:{from: string, to:string, hoursCouples: number[][]}[]}} p.availability
 * @param {boolean} toUTC
 * @returns {{ defaults: Object.<string, number[][]>; specials:{from: string, to:string, hoursCouples: number[][]}[]}}
 */
export function convertAvailabilityTimezone(availability, toUTC = false) {
  const { defaults, specials } = availability || {};
  return {
    defaults: Object.keys(defaults).reduce((acc, dayNum) => {
      return {
        ...acc,
        [dayNum]: defaults[dayNum].map((range) =>
          range.map((h) => convertHoursTimezone(h, toUTC))
        ),
      };
    }, {}),
    specials: specials.map((special) => {
      return {
        ...special,
        hoursCouples: special.hoursCouples.map((range) =>
          range.map((h) => convertHoursTimezone(h, toUTC))
        ),
      };
    }),
  };
}

/**
 *
 * @param {{from: string, to:string, hoursCouples: number[][]}[]} specials
 * @param {{from: string, to:string, hoursCouples: number[][]}[]} originalSpecials
 */
export function calculateDirtyDates(specials = [], originalSpecials = []) {
  const allDates = [];
  specials.forEach((s) => {
    if (!allDates.includes(s.from)) {
      allDates.push(s.from);
    }
  });
  originalSpecials.forEach((s) => {
    if (!allDates.includes(s.from)) {
      allDates.push(s.from);
    }
  });

  return allDates.reduce((acc, date) => {
    const maybeHasDate = specials.find((s) => s.from === date);
    const maybeOriginalHasDate = originalSpecials.find((s) => s.from === date);

    if (JSON.stringify(maybeHasDate) !== JSON.stringify(maybeOriginalHasDate)) {
      return [...acc, date];
    }

    return acc;
  }, []);
}

export const calculateDirtyDays = (defaults = {}, originalDefaults = {}) => {
  const allDays = Object.keys(defaults);
  return allDays
    .reduce((acc, day) => {
      if (
        JSON.stringify(defaults[day]) !== JSON.stringify(originalDefaults[day])
      ) {
        return [...acc, day];
      }

      return acc;
    }, [])
    .map(Number);
};
