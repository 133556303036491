import React from 'react'
import styled from 'styled-components'

import { SURVEY, JSON_FORM } from 'constants/quiz-types'

import SurveyButton from './SurveyButton'
import JsonFormButton from './JsonFormButton'

const buttonsConfig = {
  [SURVEY]: SurveyButton,
  [JSON_FORM]: JsonFormButton,
}

export default React.memo(function MainButton ({ id, type, total, step }) {
  const isStarted = step !== 0
  const isCompleted = total === step
  const label = React.useMemo(() => {
    if (!isStarted) return 'Begin'
    if (isCompleted) return 'Results'
    return 'Continue'
  }, [isStarted, isCompleted])
  const ButtonComponent = React.useMemo(() => {
    const Component = buttonsConfig[type]
    if (!Component) {
      console.warn(`Quiz with type: ${type} not implemented`)
      return () => null
    }
    return Component
  }, [type])
  return (
    <Styledwrapper>
      <ButtonComponent
        id={id}
        label={label}
        isCompleted={isCompleted}
      />
    </Styledwrapper>
  )
})

const Styledwrapper = styled.div`
  .button {
    width: 100%;
    padding: 1.1rem 1.5rem;
  }
`
