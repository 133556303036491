import _ from 'lodash';
import { useMemo } from 'react';
import { GraphQLTypes, InputType, Selector } from 'zeus-graphql/my-health/zeus';
import { useQuery } from '../client';
import { GET_MEDICATION_MEASURES } from '../constants';
import { sortOptions } from '../../../../utils/sortOptions';

const medicationMeasure = Selector('MedicationMeasureType')({
  __typename: true,
  id: true,
  measure: true,
  createdAt: true,
});

export type MedicationFrequenciesType = InputType<GraphQLTypes['MedicationMeasureType'], typeof medicationMeasure>;

export const useMedicationMeasures = () => {
  const { data, isLoading } = useQuery(
    [GET_MEDICATION_MEASURES],
    {
      getMedicationMeasures: medicationMeasure,
    },
    {
      operationName: GET_MEDICATION_MEASURES,
    }
  );

  const medicationMeasures = data?.getMedicationMeasures || [];
  const medicationMeasuresOptions = useMemo(
    () =>
      medicationMeasures
        .map((category) => ({
          value: category.id + '',
          label: category.measure,
        }))
        .sort(sortOptions),
    [medicationMeasures]
  );

  const medicationMeasuresFilterOptions = useMemo(() => [{ value: 'all', label: 'All' }, ...medicationMeasuresOptions], [medicationMeasuresOptions]);

  return {
    medicationMeasures,
    medicationMeasuresOptions,
    medicationMeasuresFilterOptions,
    isLoading,
  };
};
