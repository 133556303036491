import React from 'react'
import get from 'lodash/get'

import { useReferralList } from 'hooks/useReferral'

import ReferralPlaceholder from './ReferralPlaceholder'
import EmptyReferral from './EmptyReferral'
import ReferralList from './ReferralList'

export default function ReferralLoader () {
  const referralList = useReferralList(current => get(current, 'list'))

  if (!referralList) return <ReferralPlaceholder />

  if (referralList.length === 0) return <EmptyReferral />

  return <ReferralList />
}
