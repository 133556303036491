import React from 'react'
import styled from 'styled-components'

import CustomButton from 'common/CustomButton'
import { useCreateProgram, useFetchPrograms } from '../../../requests/programs'

export default React.memo(function UpdateButton ({ isLoading }) {

  const [isSended, setIsSended] = React.useState(false)
  const createProgram = useCreateProgram()
  const fetchPrograms = useFetchPrograms()

  const handleCreateProgram = React.useCallback(() => {
    createProgram(setIsSended)
    fetchPrograms()
  }, [createProgram, setIsSended])
  return (
    <StyledCustomButton
      disabled={isSended}
      clickHandler={handleCreateProgram}
      isLoading={isLoading}
    >
      Create
    </StyledCustomButton>
  )
})

const StyledCustomButton = styled(CustomButton)`
  margin-top: 3.6rem;
`
