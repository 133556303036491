import React from 'react';

import SpecializationSelect from './SpecializationSelect';
import SearchInput from './SearchInput';

export default React.memo(function ProvidersFilters() {
  return (
    <div style={{ marginBottom: 35 }} className="g-d-flex g-mb-35">
      <SpecializationSelect />
      <SearchInput />
    </div>
  );
});
