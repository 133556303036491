import React from 'react';

import { globalContext } from 'hooks/useGlobalState';
import { useChatClient, useSetChatChannels } from 'hooks/useMessages';

export default function ChannelMemberLeft() {
  const { chatTokenData } = React.useContext(globalContext);
  const chatClient = useChatClient();
  const memberLeft = useSetChatChannels(
    (prev, member) => {
      if (member.identity === chatTokenData.value.chat_identity) {
        return prev.filter((channel) => channel.sid !== member.channel.sid);
      }
      return prev;
    },
    [chatTokenData]
  );

  React.useEffect(() => {
    chatClient.on('memberLeft', memberLeft);

    return () => {
      chatClient.removeListener('memberLeft', memberLeft);
    };
  }, [chatClient, memberLeft]);

  return null;
}
