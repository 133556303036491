import React from 'react'
import styled from 'styled-components'
import get from 'lodash/get'

import Name from './Name'
import Estimate from './Estimate'
import Progress from './Progress'
import SubButton from './SubButton'
import MainButton from './main-button'
import MetricsButton from './MetricsButton'

export default React.memo(function QuizPreview ({ quiz }) {
  const id = get(quiz, 'id')
  const type = get(quiz, 'type')
  const name = get(quiz, 'name')
  const estimate = get(quiz, 'estimate')
  const total = get(quiz, 'total')
  const step = get(quiz, 'step')
  const group = get(quiz, 'group')
  const visibleForPatient = get(quiz, 'visible_for_patient')
  const background = visibleForPatient && ['pre-assessment', 'patient-medical-history'].includes(group) ? '#f2f6f9': '#fafbfd'
  const isVisibleMetricsButton = name.includes("PROMIS-10");
  return (
    <StyledWrapper style={{background: background}}>
      <div className='name'>
        <Name
          total={total}
          step={step}
          name={name}
        />
      </div>
      <div className='estimate'>
        <Estimate estimate={estimate} />
      </div>
      <div className='progress'>
        <Progress
          total={total}
          step={step}
        />
      </div>
      <div className='sub-button'>
        {isVisibleMetricsButton && (
          <MetricsButton
            id={id}
            type={type}
            total={total}
            step={step}
          />
        )}
        <SubButton
          id={id}
          type={type}
          total={total}
          step={step}
        />
      </div>
      <div className='main-button'>
        <MainButton
          id={id}
          type={type}
          total={total}
          step={step}
        />
      </div>
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: 44fr 15fr 15fr 12fr 14fr;
  grid-gap: 1rem;
  align-items: center;
  margin-bottom: 2rem;
  padding: 3rem;
  border-radius: 8px;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 2rem;
    .name {
      grid-column-start: 1;
      grid-column-end: 5;
    }
    .estimate {
      grid-column-start: 1;
      grid-column-end: 3;
    }
    .progress {
      grid-column-start: 3;
      grid-column-end: 5;
    }
    .sub-button {
      grid-column-start: 1;
      grid-column-end: 3;
    }
    .main-button {
      grid-column-start: 3;
      grid-column-end: 5;
    }
  }
`
