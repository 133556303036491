import React from "react";
import { Redirect } from "react-router-dom";
import get from "lodash/get";

import { useAppointmentCreate } from "hooks/useAppointments";

import { ColumnWidePlate } from "common/plate/plates";
import { PlateHeader } from "common/plate/styles";
import BackButton from "common/plate/BackButton";
import ProviderPicker from "components/appointmentsAdmin/appointments-create/ProviderPicker";
import ProviderLoader from "components/appointmentsAdmin/appointments-create/ProviderLoader";
import { APPOINTMENTS_ADMIN, CONFIGURATE, CREATE } from "../../../constants/routes";

export default function CreateChangeProvider() {
  const patientId = useAppointmentCreate((current) => get(current, "patient.id"));
  const doctorId = useAppointmentCreate((current) => get(current, "provider.id"));

  if (!doctorId || !patientId) return <Redirect to={`/${APPOINTMENTS_ADMIN}/${CREATE}`} />;

  return (
    <ColumnWidePlate>
      <PlateHeader>
        <BackButton href={`/${APPOINTMENTS_ADMIN}/${CREATE}/${CONFIGURATE}`} />
        Book an appointment
      </PlateHeader>

      <ProviderPicker />

      <ProviderLoader />
    </ColumnWidePlate>
  );
}
