import React from 'react'
import styled from 'styled-components'
import CustomButton from 'common/CustomButton'

import AddPlanModal from './AddPlanModal'

export default function AddPlanButton () {
  const [isOpen, setIsOpen] = React.useState(false)

  const openModal = React.useCallback(() => setIsOpen(true), [setIsOpen])
  const closeModal = React.useCallback(() => setIsOpen(false), [setIsOpen])

  return (
    <>
      <StyledCustomButton clickHandler={openModal}>
        Add program
      </StyledCustomButton>
      {isOpen && (
        <AddPlanModal
          closeModal={closeModal}
        />
      )}
    </>
  )
}

const StyledWrapper = styled.span`
  color: rgba(34, 50, 50, 0.5);
  text-decoration: underline;
  cursor: pointer;
  :hover {
    text-decoration: none;
  }
`
const StyledCustomButton = styled(CustomButton)`
  padding: 1rem 2.5rem;
  margin-left: 2rem;
`
