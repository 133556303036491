import React from 'react'

import { usePrograms, useSetPrograms } from 'hooks/usePrograms'
import { useFetchPrograms } from 'requests/programs'

import ProgramsPlaceholder from './ProgramsPlaceholder'
import EmptyPrograms from './EmptyPrograms'
import ProgramsList from './ProgramsList'

export default function ProgramsLoader () {
  const fetchPrograms = useFetchPrograms()
  const clearPrograms = useSetPrograms(() => null, [])
  const programs = usePrograms()

  React.useEffect(() => {
    fetchPrograms()
    return clearPrograms
    // ts-ignore
  }, [fetchPrograms])

  if (!programs) return <ProgramsPlaceholder />

  if (programs.length === 0) return <EmptyPrograms />

  return <ProgramsList />
}
