import React from 'react'
import styled from 'styled-components'
import ImageUploading from 'react-images-uploading'

import { useCompanyForm, useSetCompanyForm } from 'hooks/useCompanies'

import { MediumText } from 'common/texts'
import UserAvatar from 'common/UserAvatar'
import LinkButton from 'common/LinkButton'

const maxMbFileSize = 1 * 1024 * 1024

export default React.memo(function CompanyPhoto () {
  const uploadedAvatar = useCompanyForm(current => current.logo, [])
  const setUploadedAvatar = useSetCompanyForm((prev, next) => ({ ...prev, logo: next }), [])

  const avatarSrc = uploadedAvatar

  React.useEffect(() => {
    return () => setUploadedAvatar(null)
    // eslint-disable-next-line
  }, [])

  const handleImageUpload = React.useCallback((images) => {
    setUploadedAvatar(images[0].dataURL)
  }, [setUploadedAvatar])

  return (
    <StyledWrapper>
      <MediumText>
        Company logo
      </MediumText>

      <div className='g-d-flex g-align-center g-mt-15'>
        <StyledUserAvatar
          avatar={avatarSrc}
          firstName='N'
          lastName='A'
        />
        <ImageUploading
          onChange={handleImageUpload}
          maxFileSize={maxMbFileSize}
          acceptType={['jpg', 'jpeg', 'png']}
        >
          {({ onImageUpload }) => {
            return (
              <div>
                <LinkButton onClick={onImageUpload}>
                  Upload
                </LinkButton>
                <div className='hint'>
                  Recommended size 100x100
                </div>
              </div>
            )
          }}
        </ImageUploading>
      </div>
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  .hint {
    font-size: 1.3rem;
    line-height: 2.4rem;
    color: #000;
    opacity: 0.5;
  }
`

const StyledUserAvatar = styled(UserAvatar)`
  width: 8rem;
  height: 8rem;
  margin-right: 2rem;
`
