import { createUseGlobalState, createUseSetGlobalState } from '../useGlobalState'

export const useFoodJournal = createUseGlobalState('foodJournal')
export const useSetFoodJournal = createUseSetGlobalState('foodJournal')

export const useMealNote = createUseGlobalState('mealnote')
export const useSetMealNote = createUseSetGlobalState('mealnote')

export const useUpdateMealNote = createUseGlobalState('updateMealnote')
export const useSetUpdateMealNote = createUseSetGlobalState('updateMealnote')
