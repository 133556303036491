import React from 'react'

import { progressTabsConfig } from 'constants/medications-tabs'

import Tabs from '../common/tabs'

export default React.memo(function PeriodSelector () {
  return (
    <Tabs
      tabsConfig={progressTabsConfig}
    />
  )
})
