import React from "react";

import { globalContext } from "hooks/useGlobalState";
import { useSetReferralList, useSetReferral } from "hooks/useReferral";

import request from "utils/request";
import { useParams } from 'react-router-dom';

export function useFetchPatientReferral () {
  const { patientId } = useParams()
  const setReferral = useSetReferral((prev, next) => next, [])
  return React.useCallback(() => {
    request({
      method: 'get',
      url: `/patients/${patientId}/referrals`
    })
      .then(({ data: { data } }) => setReferral(data))
      .catch((error) => console.log(error))
  }, [patientId, setReferral])
}


export function useFetchReferralList() {
  const { user } = React.useContext(globalContext);
  const setReferralList = useSetReferralList((prev, next) => next, []);
  return React.useCallback(() => {
    const userId = user.value.id;
    request({
      method: "get",
      url: `/providers/${userId}/referrals`,
    })
      .then(
        ({
          data: {
            data: { list },
          },
        }) => setReferralList(list)
      )
      .catch((error) => console.log(error));
  }, [user, setReferralList]);
}

export function useFetchProviderReferral() {
  const { providerId } = useParams();
  const setReferral = useSetReferralList((prev, next) => next, []);
  return React.useCallback(() => {
    request({
      method: "get",
      url: `/providers/${providerId}/referrals`,
    })
      .then(({ data: { data } }) => setReferral(data))
      .catch((error) => console.log(error));
  }, [providerId, setReferral]);
}
