import React from 'react'

import AddSecurity from './AddSecurity'
import ConfirmAdding from './ConfirmAdding'

export default function AddTwoFaWrapper () {
  const [isAttempted, setIsAttempted] = React.useState(false)
  return (
    isAttempted
      ? <ConfirmAdding />
      : <AddSecurity submitHandler={setIsAttempted} />
  )
}
