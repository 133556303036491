import React from 'react'
import styled from 'styled-components'

import { RegularText, HintText } from 'common/texts'

export default function Info ({ className = '', label = '', value = '' }) {
  return (
    <StyledWrapper className={className}>
      <RegularText>{label}</RegularText>
      <HintText className='value'>{value}</HintText>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  .value {
    font-size: 1.2rem;
    line-height: 1.7;
  }
`
