import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { ADMIN_NOTES, CREATE, PROVIDERS } from 'constants/routes'; 

import { useSetProviderAdminNotes } from 'hooks/useProviders'

import CustomButton from 'common/CustomButton'

export default React.memo(function NewAdminNoteButton () {
  const { providerId } = useParams()
  const history = useHistory()
  const clearProviderAdminNotes = useSetProviderAdminNotes(() => null, [])

  const createNewNote = React.useCallback(() => {
    clearProviderAdminNotes()
    history.push({
      pathname: `/${PROVIDERS}/${providerId}/${ADMIN_NOTES}/${CREATE}`,
      state: { referrer: `/${PROVIDERS}/${providerId}/${ADMIN_NOTES}` }
    })
  }, [history, providerId, clearProviderAdminNotes])

  return (
    <CustomButton
      className='g-mt-auto'
      clickHandler={createNewNote}
    >
      Add  new notes
    </CustomButton>
  )
})
