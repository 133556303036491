import React from 'react'

import GlobalMentalHealth from './global-mental-health'
import GlobalPhysicalHealth from './global-physical-health'


export default React.memo(function QuizMetricsBody () {
  return (
    <>
      <GlobalPhysicalHealth/>
      <GlobalMentalHealth/>
    </>
  )
})
