import React from "react";
import get from "lodash/get";
import styled from "styled-components";
import { useParams, useHistory } from "react-router-dom";

import { useAppointment, useSetAppointment, useAppointmentsProviders } from "hooks/useAppointments";

import CustomButton from "common/CustomButton";
import ProviderPreview from "./ProviderPreview";
import { APPOINTMENTS_ADMIN, RESCHEDULE } from 'constants/routes';

export default React.memo(function ProvidersList() {
  const { appointmentId } = useParams();
  const history = useHistory();
  const selectedProvider = useAppointment((current) => get(current, "provider", {}), []);
  const setNewProvider = useSetAppointment((prev, provider) => ({ ...prev, provider, started_at: "" }), []);
  const [selectedId, setSelectedId] = React.useState(selectedProvider.id);
  const providers = useAppointmentsProviders();

  const changeProvider = React.useCallback(() => {
    if (selectedProvider.id !== selectedId) setNewProvider(providers.find((provider) => provider.id === selectedId));
    history.push(`/${APPOINTMENTS_ADMIN}/${RESCHEDULE}/:appointmentId`);
  }, [selectedProvider, selectedId, providers, setNewProvider, history, appointmentId]);

  const parsedProviders = React.useMemo(() => {
    return providers.map((provider) => {
      const id = get(provider, "id");
      const avatar = get(provider, "avatar", "");
      const firstName = get(provider, "first_name", "");
      const lastName = get(provider, "last_name", "");
      const fullName = get(provider, "full_name", "");
      const qualificationTitle = get(provider, "qualification_title", "");
      return (
        <ProviderPreview
          key={id}
          id={id}
          isSelected={selectedId === id}
          avatar={avatar}
          firstName={firstName}
          lastName={lastName}
          fullName={fullName}
          qualificationTitle={qualificationTitle}
          setSelectedId={setSelectedId}
        />
      );
    });
  }, [providers, selectedId]);

  return (
    <StyledWrapper>
      <div className="list-wrapper">{parsedProviders}</div>

      <div className="button-wrapper">
        <CustomButton clickHandler={changeProvider}>Change</CustomButton>
      </div>
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  .list-wrapper {
    height: 40rem;
    overflow-y: auto;
  }
  .button-wrapper {
    margin-top: auto;
    padding: 3rem;
  }
`;
