import React from 'react'
import get from 'lodash/get'
import styled from 'styled-components'

import { useQuiz, useSetQuizStep } from 'hooks/patient/useQuestionnaires'

import CustomButton from 'common/CustomButton'

export default React.memo(function SubmitButton ({ className = '', metaPath }) {
  const submitHandler = useSetQuizStep(prev => +prev + 1, [])
  const text = useQuiz(current => get(current, `${metaPath}.text`, ''), [metaPath])
  const styles = useQuiz(current => get(current, `${metaPath}.styles`, ''), [metaPath])

  return (
    <StyledCustomButton
      className={className}
      clickHandler={submitHandler}
      styles={styles}
    >
      {text}
    </StyledCustomButton>
  )
})

const StyledCustomButton = styled(CustomButton)`
  ${({ styles }) => styles || ''};
`
