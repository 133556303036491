import Calendar from "react-calendar";
import styled from "styled-components";

const StyledCalendar = styled(Calendar)`
  background: #fff;
  button {
    border: none;
    outline: none;
    background: #fff;
    :hover:not([disabled]) {
      cursor: pointer;
    }
  }
  .react-calendar__month-view__weekdays {
    margin: 1rem 0;
  }
  .react-calendar__navigation__label {
    font-size: 1.4rem;
    font-weight: 600;
  }
  .react-calendar__navigation__arrow {
    font-size: 2.3rem;
    width: 2.5rem;
    :disabled {
      opacity: 0.5;
    }
  }
  .react-calendar__navigation__prev-button {
    letter-spacing: -3px;
    padding-right: 3px;
  }
  .react-calendar__navigation__next-button {
    letter-spacing: -3px;
    padding-right: 3px;
  }
  .react-calendar__navigation__prev2-button {
    display: none;
  }
  .react-calendar__navigation__next2-button {
    display: none;
  }
  .react-calendar__month-view__weekdays__weekday {
    text-align: center;
    abbr {
      font-size: 1.4rem;
      font-weight: 600;
      text-decoration: none;
    }
  }
  .react-calendar__month-view__days {
    button:disabled {
      opacity: 0.5;
    }
  }
  .react-calendar__tile {
    padding: 0.3rem 0;
    abbr {
      display: block;
      margin: auto;
      font-size: 1.4rem;
      line-height: 3rem;
      width: 3rem;
      height: 3rem;
    }
    :hover:not([disabled]) {
      abbr {
        color: #fff;
        background: #16123f;
        border-radius: 50%;
      }
    }
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    opacity: 0.5;
  }

  .react-calendar__tile--default-workday {
    abbr {
      color: #000000;
      background: #dce2e8;
      border-radius: 50%;
    }
  }

  .react-calendar__tile--special-workday {
    abbr {
      color: #fff;
      background: #1d4166;
      border-radius: 50%;
    }
  }

  .react-calendar__tile--dirty {
    abbr {
      color: #fff;
      background: #504D6F;
      border-radius: 50%;
    }
  }
  .react-calendar__tile--active {
    abbr {
      color: #fff;
      background: #16123f;
      border-radius: 50%;
    }
  }
`;

export default StyledCalendar;
