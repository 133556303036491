import React from 'react'
import ReactDOM from 'react-dom'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import get from 'lodash/get'

import { APPOINTMENTS, CONSULTATION } from 'constants/routes'; 

import { useConsultation } from 'hooks/useAppointments'

import VideoPlayer from './MainVideoComponent'
import VideoHandler from 'common/video-controls/VideoHandler'
import AudioHandler from 'common/video-controls/AudioHandler'
import ChatHandler from 'common/video-controls/ChatHandler'
import PipHandler from 'common/video-controls/PipHandler'

const blacklistRoutes = [
  `/${APPOINTMENTS}/${CONSULTATION}`
]

export default React.memo(function PictureInPicture () {
  const { pathname } = useLocation()
  const participant = useConsultation(current => get(current, 'participants[0]'))

  let blacklisted = false
  blacklistRoutes.forEach(route => {
    if (~pathname.indexOf(route)) blacklisted = true
  })

  if (blacklisted || !participant) return null

  return ReactDOM.createPortal(
    <StyledWrapper>
      <VideoPlayer participant={participant} />
      <div className='controls'>
        <VideoHandler />
        <AudioHandler />
        <ChatHandler />
        <PipHandler />
      </div>
    </StyledWrapper>,
    document.getElementById('picture-in-picture')
  )
})

const StyledWrapper = styled.div`
  position: fixed;
  right: 2rem;
  bottom: 10rem;
  background: #000;
  width: 32.5rem;
  height: 20rem;
  border-radius: 5px;
  box-shadow: 0 1px 8px 0 rgba(20, 46, 110, 0.1);
  z-index: 1000;
  :hover {
    .controls {
      opacity: 1;
    }
  }
  .video {
    width: 100%;
    height: 100%;
    transform: scaleX(-1) !important;
  }
  .controls {
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    padding: 6px;
    background: linear-gradient(355.21deg, #000000 -16.09%, rgba(0, 0, 0, 0.05) 181.88%);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    transition: opacity 0.2s;
    @media screen and (max-width: 1024px) {
      opacity: 1;
    }
  }
`
