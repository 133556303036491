import React, { FC } from 'react';
import classNames from 'clsx';
import Modal from '../../common/Modal';

type DeleteModalProps = {
  title: string;
  okText?: string;
  cancelText?: string;
  okButtonClassName?: string;
  cancelButtonClassName?: string;
  onClose: () => void;
  onDelete: () => void;
};

const ModalWindow = Modal as any;

export const ConfirmationModal: FC<DeleteModalProps> = ({
  title,
  okText = 'Ok',
  cancelText = 'Cancel',
  okButtonClassName,
  cancelButtonClassName,
  onClose,
  onDelete,
}) => {
  return (
    <ModalWindow maxWidth="8" title={title} closeModal={onClose}>
      <div className="flex justify-center h-[10rem]">
        <div className="flex mt-16">
          <button
            onClick={onClose}
            className={classNames('w-[16rem] border border-main rounded-[2.4rem] h-[4.2rem] text-1324 text-main mr-4', cancelButtonClassName)}
          >
            {cancelText}
          </button>
          <button onClick={onDelete} className={classNames('w-[16rem] rounded-[2.4rem] h-[4.2rem] text-1324 text-white', okButtonClassName)}>
            {okText}
          </button>
        </div>
      </div>
    </ModalWindow>
  );
};
