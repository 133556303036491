import React from 'react'
import styled from 'styled-components'

import { ReactComponent as Micro } from 'assets/environment-check/micro.svg'

const scale = new Array(21)
  .fill(null)
  .map((item, i) => (i + 1) * 5)

export default function VolumeVisualization ({ volume }) {
  const parsedSacel = React.useMemo(() => {
    return scale.map(value => {
      return (
        <div
          key={value}
          className={`scale-item ${value < volume ? 'active' : ''}`}
        />
      )
    })
  }, [volume])

  return (
    <StyledWrapper className='g-align-self-start g-mt-20'>
      <Micro className='icon' />
      <div className='scale-wrapper'>
        {parsedSacel}
      </div>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  .icon {
    display: block;
    width: 6rem;
    height: 6rem;
    margin: 0 auto;
  }
  .scale-wrapper {
    display: flex;
    .scale-item {
      background: #000;
      width: 0.8rem;
      height: 2rem;
      margin-right: 0.5rem;
      border-radius: 2px;
    }
    .scale-item.active {
      background: #5ed35c;
    }
  }
`
