import React from 'react'

import { RegularMediumText } from 'common/texts'

export default React.memo(function EmptyAppointments () {
  return (
    <RegularMediumText>
      Appointments not found
    </RegularMediumText>
  )
})
