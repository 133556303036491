import React, { FC } from 'react';
import { Button } from '../../../ui2.0/Button';

type SectionFooterProps = {
  onCancel: () => void;
  onSave: () => void;
};

export const SectionFooter: FC<SectionFooterProps> = ({ onCancel, onSave }) => {
  return (
    <div className="flex justify-end mt-6">
      <div className="flex">
        <Button onClick={onCancel} className="uppercase font-bold" type="secondary">
          Cancel
        </Button>
        <Button onClick={onSave} className="ml-4 uppercase font-bold">
          Done
        </Button>
      </div>
    </div>
  );
};
