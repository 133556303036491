import React from 'react'
import { useParams } from 'react-router-dom'

import { useMedicationsTab, useMedicationsChart, useSetMedicationsChart } from 'hooks/patient/useMedications'
import { useFetchPatientMedicationsChartProgress } from 'requests/patients/medication'

import MedicationsChartPlaceholder from './MedicationsChartPlaceholder'
import MedicationsChart from './medications-chart'

export default React.memo(function ChartLoader () {
  const { medicationId } = useParams()
  const { from, to } = useMedicationsTab()
  const fetchPatientMedicationsChartProgress = useFetchPatientMedicationsChartProgress()
  const medicationsChart = useMedicationsChart()
  const cleartMedicationsChart = useSetMedicationsChart(() => null)

  React.useEffect(() => {
    fetchPatientMedicationsChartProgress({ from, to, medicationId })
    return cleartMedicationsChart
    // eslint-disable-next-line
  }, [from, to, medicationId])

  if (!medicationsChart) return <MedicationsChartPlaceholder />

  return <MedicationsChart />
})
