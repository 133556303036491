import React from 'react'

import { INFORMATION } from 'constants/routes'; 

import { useProfile, useGenders } from 'hooks/useProfile'
import { useFetchGenders, useFetchProfile } from 'requests/profile'

import ProfileTemplate from 'components/profile/ProfileTemplate'
import ProfilePhoto from 'components/profile/information/profile-photo'
import Hint from 'components/profile/information/Hint'
import PersonalInformation from 'components/profile/information/personal-information'
import ContactInformation from 'components/profile/information/ContactInformation'
import Biography from 'components/profile/information/Biography'
import UpdateButton from 'components/profile/information/UpdateButton'

export default function Information () {
  const fetchProfile = useFetchProfile()
  const fetchGenders = useFetchGenders()

  const profile = useProfile()
  const genders = useGenders()

  React.useEffect(() => {
    fetchGenders()
    fetchProfile()
    // eslint-disable-next-line
  }, [])

  const isLoading = React.useMemo(() => {
    return !profile || !genders
  }, [profile, genders])

  return (
    <ProfileTemplate currentTab={INFORMATION}>
      <ProfilePhoto isLoading={isLoading} />
      {!isLoading && <Hint />}
      <PersonalInformation isLoading={isLoading} />
      <ContactInformation isLoading={isLoading} />
      <Biography isLoading={isLoading} />
      <UpdateButton />
    </ProfileTemplate>
  )
}
