import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { DASHBOARD } from 'constants/routes';

import { ColumnWidePlate } from 'common/plate/plates';
import { RegularText } from 'common/texts';

export default function RescheduleSuccess() {
  return (
    <ColumnWidePlate>
      <StyledWrapper>
        <div className="title">Thank you!</div>

        <RegularText className="g-mt-10">The date of appointment is changed</RegularText>

        <Link className="link g-mt-20" to={`/${DASHBOARD}`}>
          To the Dashboard
        </Link>
      </StyledWrapper>
    </ColumnWidePlate>
  );
}

const StyledWrapper = styled.div`
  margin: auto;
  text-align: center;
  .title {
    font-size: 3.6rem;
    font-weight: 700;
  }
  .link {
    display: block;
    font-size: 1.3rem;
    :hover {
      text-decoration: none;
    }
  }
`;
