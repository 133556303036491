import React from 'react'
import { useParams } from 'react-router-dom'

import { PATIENTS, DETAILS, WIDGETS, WITHINGS, BLOOD_PRESSURE, PULSE } from 'constants/routes'; 

import { RowPlate } from 'common/plate/plates'
import { PlateHeader, PlateContent } from 'common/plate/styles'
import BackButton from 'common/plate/BackButton'
import ExportData from 'common/widgets/ExportData'
import TabsSwitcher from 'common/TabsSwitcher'

export default React.memo(function BPMTemplate ({ currentTab, children }) {
  const { patientId, widgetId } = useParams()
  const tabsConfig = [
    { id: BLOOD_PRESSURE, label: 'Pressure', href: `/${PATIENTS}/${DETAILS}/${patientId}/${WIDGETS}/${WITHINGS}/${widgetId}/${BLOOD_PRESSURE}` },
    { id: PULSE, label: 'Pulse', href: `/${PATIENTS}/${DETAILS}/${patientId}/${WIDGETS}/${WITHINGS}/${widgetId}/${PULSE}` }
  ]
  const exportConfig = {
    [BLOOD_PRESSURE]: { title: 'Pressure systolic', field: 'systolic' },
    [PULSE]: { title: 'Pulse bpm', field: 'pulse' }
  }

  const { title, field } = exportConfig[currentTab]

  return (
    <RowPlate>
      <PlateHeader>
        <BackButton href={`/${PATIENTS}/${DETAILS}/${patientId}/${WIDGETS}`} />
        Blood pressure
        <ExportData
          title={title}
          field={field}
        />
      </PlateHeader>
      <PlateContent>
        <TabsSwitcher
          className='g-mt-10 g-mb-30'
          tabsConfig={tabsConfig}
          currentTab={currentTab}
        />
        {children}
      </PlateContent>
    </RowPlate>
  )
})
