import React, { FC, useMemo, useState } from 'react';
import classNames from 'clsx';
import { ReactComponent as PublishIcon } from 'assets/send.svg';
import { ReactComponent as UnpublishIcon } from 'assets/unpublish.svg';
import { ReactComponent as EditIcon } from 'assets/edit.svg';
import { ReactComponent as DotsIcon } from 'assets/dots.svg';
import { ReactComponent as DuplicateIcon } from 'assets/copy-paste.svg';
import { ReactComponent as EyeIcon } from 'assets/eye.svg';
import { ReactComponent as TrashIcon } from 'assets/trash.svg';

import { LibraryMaterialType } from 'requests/graphql/patient/queries/getLibraryMaterials';
import moment from 'moment';
import { StatusPill } from './StatusPill';
import { LibraryMaterialStatuses } from '../../zeus-graphql/patient/zeus';
import { DropdownMenuFunc } from '../ui2.0/DropdownMenu';

export const MaterialListItemSkeleton = () => (
  <section className=" animate-pulse flex rounded-main items-center px-5 shadow-row my-2 lg:mx-2">
    <div className="col px-1 w-[20rem] lg:w-[40%] py-2.5">
      <div className="mb-2">
        <div className="bg-catskillWhite rounded-[3.2rem] w-[10rem] h-4"></div>
      </div>
      <div className="">
        <div className="bg-catskillWhite rounded-[3.2rem] w-[20rem] h-4"></div>
      </div>
    </div>
    <div className="col px-1 w-[5rem] lg:w-[30%]">
      <div className="bg-catskillWhite rounded-[3.2rem] w-[15rem] h-4"></div>
    </div>
    <div className="col flex-1">
      <div className="flex items-center">
        <div className="">
          <div className="bg-catskillWhite rounded-[3.2rem] w-[10rem] h-4"></div>
        </div>
      </div>
    </div>
    <div className="col flex-1 mx-2">
      <div className="flex items-center">
        <div className="bg-catskillWhite rounded-[3.2rem] w-[6rem] h-5"></div>
      </div>
    </div>
    <div className="col flex-1 flex justify-end">
      <div className="h-6 w-6 rounded-full bg-catskillWhite"></div>
    </div>
  </section>
);

type MaterialListItemProps = {
  itemsPerPage: number;
  canSeeStatus?: boolean;
  length: number;
  index: number;
  material: LibraryMaterialType;
  onUnpublish: () => void;
  onView: () => void;
  onDelete: () => void;
  onDuplicate: () => void;
  onEdit: () => void;
  onPublish: () => void;
};

export const MaterialListItem: FC<MaterialListItemProps> = ({
  material,
  length,
  index,
  canSeeStatus,
  itemsPerPage,
  onUnpublish,
  onView,
  onDelete,
  onDuplicate,
  onEdit,
  onPublish,
}) => {
  const published = material.status === LibraryMaterialStatuses.published;
  const menu = useMemo(
    () => [
      {
        icon: published ? <UnpublishIcon /> : <PublishIcon />,
        text: published ? 'Unpublish' : 'Publish',
        handler: () => {
          published ? onUnpublish() : onPublish();
        },
      },
      {
        icon: <EyeIcon className="fill-manatee" />,
        text: 'View',
        handler: () => {
          onView();
        },
      },
      {
        icon: <EditIcon />,
        text: 'Edit',
        handler: () => {
          onEdit();
        },
      },
      // {
      //   icon: <DuplicateIcon />,
      //   text: 'Duplicate',
      //   handler: () => {
      //     onDuplicate();
      //   },
      // },
      {
        icon: <TrashIcon className="stroke-error" />,
        text: 'Remove',
        handler: () => {
          onDelete();
        },
        color: 'text-error',
      },
    ],
    [published]
  );

  const ddMenu = DropdownMenuFunc({
    className: 'mt-2',
    placement: 'bottom-end',
    list: menu,
    children: <DotsIcon className="cursor-pointer" />,
  });

  const handleOpenClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (ddMenu.buttonRef.current?.contains(e.target as Node) || ddMenu.elemRef.current?.contains(e.target as Node)) return;
    onView();
  };
  return (
    <section
      onClick={handleOpenClick}
      className={classNames('flex rounded-main items-center px-5 cursor-pointer shadow-row my-2 lg:mx-2', {
        'lg:mr-6': length < itemsPerPage,
        'mb-4': index === length - 1,
      })}
    >
      <div className="col px-1 w-[20rem] lg:w-[40%] py-2.5">
        <div className="title text-main text-1220 lg:text-1622 font-ambit font-semibold">{material.title}</div>
        <div className="desc text-manatee text-1020 lg:text-1420 font-ambit">{material.description}</div>
      </div>
      <div className="col px-1 w-[5rem] lg:w-[30%] text-1220 lg:text-1622 text-main font-ambit font-semibold">{`${
        material.createdBy?.profile.fName || ''
      } ${material.createdBy?.profile.lName || ''}`}</div>
      <div className="col flex-1">
        <div className="flex items-center">
          <div className="text-1220 lg:text-1622 font-semibold font-ambit text-manatee">{moment(material.createdAt).format('MM.DD.YYYY')}</div>
        </div>
      </div>
      {canSeeStatus && (
        <div className="col flex-1 mx-2">
          <div className="flex items-center">
            <StatusPill status={material.status} />
          </div>
        </div>
      )}
      <div className="col flex-1 flex justify-end">{ddMenu.element}</div>
    </section>
  );
};
