import React from 'react'

import { RegularText } from 'common/texts'
import Modal from 'common/Modal'

export default React.memo(function NotAvailableQuizModal ({ closeModal }) {
  return (
    <Modal
      title='Ciba Health Team is editing your questionnaire'
      closeModal={closeModal}
    >
      <RegularText>
        Ciba Health Team is currently editing your questionnaire.
      </RegularText>
      <RegularText>
        After the provider finishes editing, you can access the questionnaire.
      </RegularText>
    </Modal>
  )
})
