import React from 'react'
import get from 'lodash/get'

import CardTemplate from '../CardTemplate'
import List from './list'

export default function Questionnaires ({ title, answers, pages }) {
  let answersList = []
  pages.forEach(page => {
    const elements = get(page, 'elements', [])
    elements.forEach(element => {
      const name = get(element, 'name', null)
      const title = get(element, 'title', '')
      const value = get(answers, `${name}.value`, null)
      if (value) answersList.push({ title, 'values': [value] })
    })
  })

  if (answersList.length === 0) return null

  return (
    <CardTemplate title={title}>
      <List answers={answersList} />
    </CardTemplate>
  )
}
