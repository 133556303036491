import React from 'react';
import styled from 'styled-components';

import { RegularText } from 'common/texts';

const defaultLabels = ['Minimal', 'Mild', 'Moderate', 'Moderately Severe', 'Severe'];

export default React.memo(function Scale({ labels = defaultLabels, scaleCount, value }) {
  //use maximum 100% value:
  const filledLineWidth = `${Math.min((value / scaleCount) * 100, 100)}%`;

  const parsedLables = React.useMemo(() => {
    return labels.map((label, i) => <RegularText key={i}>{label}</RegularText>);
  }, [labels]);
  return (
    <StyledScale scaleCount={scaleCount} filledLineWidth={filledLineWidth}>
      <div className="dashes">{parsedLables}</div>
      <div className="slider-container">
        <div className="filled-line" />
        <div className="pointer">
          <div className="pointer-center" />
          <div className="pointer-tooltip">{value}</div>
        </div>
      </div>
    </StyledScale>
  );
});

const StyledScale = styled.div`
  height: 5rem;
  background: linear-gradient(to right, #1fbba6, #f8c236, #ef7a3d, #ae1b2e);
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  .dashes {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    background-image: repeating-linear-gradient(
      to right,
      #223232,
      #223232 1px,
      transparent 1px,
      transparent ${({ scaleCount }) => 100 / scaleCount}%
    );
    background-size: 100% 1.3rem;
    background-repeat: no-repeat;
    background-position: bottom;
  }
  .slider-container {
    position: relative;
    display: flex;
    height: 0.5rem;
    background: #e9ebef;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .filled-line {
    height: 100%;
    background: #223232;
    width: ${({ filledLineWidth }) => filledLineWidth};
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .pointer {
    position: absolute;
    left: ${({ filledLineWidth }) => filledLineWidth};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: #fff;
    border: solid 1px #223232;
    transform: translate(-1rem, -0.75rem);
    .pointer-center {
      width: 1.4rem;
      height: 1.4rem;
      border-radius: 50%;
      background: #223232;
    }
    .pointer-tooltip {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, 0.8rem);
      min-width: 4.4rem;
      width: 4.4rem;
      background: #223232;
      font-size: 1.8rem;
      line-height: 2.8rem;
      font-weight: 600;
      color: #fff;
      text-align: center;
      &:before {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translate(-50%, 0);
        display: block;
        width: 0;
        height: 0;
        border-left: 0.7rem solid transparent;
        border-right: 0.7rem solid transparent;
        border-bottom: 0.5rem solid #223232;
      }
    }
  }
`;
