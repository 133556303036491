import React from 'react'

import { useSetPatientMedications } from 'hooks/usePatients'
import { useFetchMedicationsMeasures, useFetchMedicationsFrequencies } from 'requests/options'
import { useFetchPatientMedications } from 'requests/patients/medication'

import { RowPlate } from 'common/plate/plates'
import { PlateContent } from 'common/plate/styles'
import Header from 'components/patients/medications/edit-medications/Header'
import CategorySelect from 'components/patients/medications/common/CategorySelect'
import Medications from 'components/patients/medications/common/medications'
import MedicationPreview from 'components/patients/medications/edit-medications/MedicationPreview'
import UpdateButton from 'components/patients/medications/edit-medications/UpdateButton'

export default function PatientMedicationsEdit () {
  const fetchMedicationsMeasures = useFetchMedicationsMeasures()
  const fetchMedicationsFrequencies = useFetchMedicationsFrequencies()
  const fetchPatientMedications = useFetchPatientMedications()
  const clearPatientMedications = useSetPatientMedications(() => null)

  React.useEffect(() => {
    fetchMedicationsMeasures()
    fetchMedicationsFrequencies()
    fetchPatientMedications()
    return clearPatientMedications
    // eslint-disable-next-line
  }, [])

  return (
    <RowPlate>
      <Header />
      <PlateContent>
        <CategorySelect />

        <Medications MedicationPreview={MedicationPreview} />

        <UpdateButton />
      </PlateContent>
    </RowPlate>
  )
}
