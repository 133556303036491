import React from 'react'
import get from 'lodash/get'

import { globalContext } from 'hooks/useGlobalState'
import { usePatientLabs } from 'hooks/usePatients'

import LabPreview from './LabPreview'

export default function LabsList () {
  const { user } = React.useContext(globalContext)
  const patientLabs = usePatientLabs()

  const parsedPatientLabs = React.useMemo(() => {
    return patientLabs.map(lab => {
      const id = get(lab, 'id')
      const authorId = get(lab, 'author.id', '')
      const name = get(lab, 'name', '')
      const uploaded = get(lab, 'uploaded', '')
      const uploadedBy = authorId === user.value.id ? 'You' : get(lab, 'author.full_name', '')
      return (
        <LabPreview
          key={id}
          id={id}
          name={name}
          uploaded={uploaded}
          uploadedBy={uploadedBy}
          isOwner={authorId === user.value.id}
        />
      )
    })
  }, [patientLabs, user])

  return (
    <div className='g-mt-30'>
      {parsedPatientLabs}
    </div>
  )
}
