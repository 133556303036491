import React, { FC, useEffect, useState } from 'react';
import classNames from 'clsx';

export type Option = {
  label: string;
  value: string;
};

type InputFieldProps = {
  isRequired?: boolean;
  label?: string;
  className?: string;
  error?: string;
  onEnter?: () => void;
  type?: 'text' | 'number';
  maxLength?: number;
  placeholder?: string;
  value?: string;
  onChange: (value: string) => void;
};

export const InputField: FC<InputFieldProps> = ({
  onChange,
  onEnter,
  value,
  label,
  isRequired,
  placeholder,
  maxLength,
  error,
  type = 'text',
  className,
}) => {
  const hasError = !!error;

  return (
    <div className="w-full">
      {label && (
        <div className="flex mb-1">
          <div className="text-1324 text-coil">{label}</div>
          {isRequired && <div className="text-error text-1324">*</div>}
        </div>
      )}
      <input
        type="text"
        value={value || ''}
        placeholder={placeholder}
        onKeyUp={(e) => {
          if (e.keyCode === 13 || e.which === 13) {
            onEnter?.();
          }
        }}
        onChange={(e) => {
          if (maxLength && e.target.value.length > maxLength) {
            e.target.value = e.target.value.slice(0, maxLength);
          }
          if (type === 'number' && !/^\d*$/.test(e.target.value)) {
            e.target.value = e.target.value.slice(0, e.target.value.length - 1);
          }
          onChange(e.target.value);
        }}
        className={classNames(
          'placeholder:text-lavenderGray focus:outline-main placeholder:text-1324 w-full h-10 text-main text-1522 font-ambit border bg-white rounded-[3.2rem] px-4',
          className,
          {
            'border-error': hasError,
            'border-lavenderGray': !hasError,
          }
        )}
      />

      <div
        className={classNames('text-error text-1214 px-2 py-1', {
          hidden: !hasError,
        })}
      >
        {error}
      </div>
    </div>
  );
};
