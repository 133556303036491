import React from 'react'
import styled from 'styled-components'

export default React.memo(function NotFound () {
  return (
    <StyledWrapper>
      Not found
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  text-align: center;
  padding: 2rem 0;
`
