import { GQLArgs, useMutation } from '../client';
import { DELETE_LIBRARY_MATERIAL } from '../constants';
import { $, ValueTypes } from 'zeus-graphql/patient/zeus';

type MutationResponse = Record<'deleteLibraryMaterial', boolean>;

type Vars = Required<ValueTypes['MainMutation']>['deleteLibraryMaterial'][0];

type Args = GQLArgs<MutationResponse>;

export const useDeleteLibraryMaterial = (args?: Args) => {
  const instance = useMutation(
    [],
    {
      deleteLibraryMaterial: [
        {
          materialId: $`materialId`,
        },
        true,
      ],
    },
    {
      operationName: DELETE_LIBRARY_MATERIAL,
    },
    args
  );
  const deleteMaterial = instance.mutateAsync as (vars: Vars) => Promise<MutationResponse>;

  return { ...instance, deleteMaterial };
};
