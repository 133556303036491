import React from 'react'
import styled from 'styled-components'
import get from 'lodash/get'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

import { APPOINTMENTS, APPOINTMENT_INFO, DAY } from 'constants/routes'; 

export default React.memo(function DataLine ({ appointment }) {
  const history = useHistory()

  if (!appointment) {
    return (
      <StyledPlaceholder />
    )
  }

  const id = get(appointment, 'id')
  const fullName = get(appointment, 'patient.full_name')
  const startedAt = get(appointment, 'started_at')
  const start = moment.utc(startedAt).local().format('hh:mm A')
  const end = moment.utc(startedAt).local().add(1, 'hours').format('hh:mm A')
  return (
    <StyledWrapper onClick={() => {
      history.push({
        pathname: `/${APPOINTMENTS}/${APPOINTMENT_INFO}/${id}`,
        state: { referrer: `/${APPOINTMENTS}/${DAY}` }
      })
    }}>
      <div className='name'>{fullName}</div>
      <div className='time'>{start} - {end}</div>
    </StyledWrapper>
  )
})

const StyledPlaceholder = styled.div`
  margin: 0 1.5rem 0 7rem;
  padding: 0.8rem 1.5rem;
  min-height: 6rem;
`

const StyledWrapper = styled.div`
  margin: 0 1.5rem 0 7rem;
  padding: 0.8rem 1.5rem;
  background: #16123f;
  min-height: 6rem;
  cursor: pointer;
  .name {
    font-size: 1.6rem;
    color: #fff;
  }
  .time {
    font-size: 1.3rem;
    color: #fff;
  }
`
