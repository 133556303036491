import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { PATIENTS, DETAILS, WIDGETS, OURA, ACTIVITY_CONTRIBUTORS } from 'constants/routes'; 

import DayLabel from 'common/widgets/DayLabel'
import ViewData from 'common/widgets/ViewData'
import ActivityPreview from './ActivityPreview'

import onlyPositive from 'utils/onlyPositive'
import toHM from 'utils/toHM'
import toRelative from 'utils/toRelative'
import normalizePercentageValue from 'utils/normalizePercentageValue'

export default function OuraActivityWidget ({ widgetId, lastResult }) {
  const { patientId } = useParams()
  const history = useHistory()
  const {
    summaryDate,
    cal_active: calActive,
    target_calories: targetCalories,
    inactive
  } = lastResult

  const normalizedTargetCalories = onlyPositive(targetCalories)
  const normalizedCalActive = onlyPositive(calActive)
  const normalizedGoalProgress = normalizePercentageValue(toRelative(normalizedCalActive, normalizedTargetCalories))
  const normalizedInactive = toHM(onlyPositive(inactive), 'minutes')

  const navigateToActivityContributors = React.useCallback(() => {
    history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${WIDGETS}/${OURA}/${widgetId}/${ACTIVITY_CONTRIBUTORS}`)
  }, [history, patientId, widgetId])

  return (
    <>
      <DayLabel date={summaryDate} />
      <ActivityPreview
        targetCalories={normalizedTargetCalories}
        calActive={normalizedCalActive}
        percentageValue={normalizedGoalProgress}
        inactive={normalizedInactive}
      />
      <ViewData
        className='g-mt-45'
        clickHandler={navigateToActivityContributors}
      />
    </>
  )
}
