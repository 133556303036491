import styled from 'styled-components'

import { ReactComponent as GroupChatIcon } from 'assets/group-chat.svg'

import UserAvatar from 'common/UserAvatar'

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5rem 1.5rem 1.5rem 2.2rem;
  border-bottom: solid 1px #e9ebef;
  ${({ isActive }) => isActive && 'background: #fafbfd'};
  cursor: pointer;
`

export const StyledUserAvatar = styled(UserAvatar)`
  width: 4rem;
  min-width: 4rem;
  height: 4rem;
`

export const StyledGroupChatIcon = styled(GroupChatIcon)`
  width: 4rem;
  min-width: 4rem;
  height: 4rem;
`

export const StyledDetails = styled.div`
  overflow: hidden;
  .name {
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .sub-name {
    font-size: 1rem;
    font-weight: 500;
    opacity: 0.3;
    text-transform: uppercase;
  }
`

export const StyledCounter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-left: auto;
  font-size: 1.3rem;
  font-weight: 600;
  color: #fff;
  background: #f86060;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
`
