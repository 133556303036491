import React from 'react';
import { useHistory } from 'react-router-dom';
import { DASHBOARD } from 'constants/routes';

import { useLogsFilter } from 'hooks/useLogs';
import { useFetchLogs } from 'requests/logs';

import CustomButton from 'common/CustomButton';
import ReloadButton from 'common/ReloadButton';

export default React.memo(function Footer() {
  const history = useHistory();
  const logsFilter = useLogsFilter();
  const fetchLogs = useFetchLogs();

  const navigateToDashboard = React.useCallback(() => {
    history.push(`/${DASHBOARD}`);
  }, [history]);

  const reloadHandler = React.useCallback(() => {
    fetchLogs(logsFilter);
  }, [fetchLogs, logsFilter]);

  return (
    <div className="g-d-flex g-justify-between g-mt-auto">
      <CustomButton clickHandler={navigateToDashboard}>Latest activity</CustomButton>

      <ReloadButton clickHandler={reloadHandler} />
    </div>
  );
});
