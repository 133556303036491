import React from 'react'
import get from 'lodash/get'
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'
import styled from 'styled-components'

import { useQuiz, useSetQuiz } from 'hooks/patient/useQuestionnaires'

import CustomSelect from 'common/CustomSelect'

export default React.memo(function Dropdown ({ className = '', metaPath, answerField }) {
  const element = useQuiz(current => get(current, metaPath, [metaPath]))
  const label = get(element, 'title', '')
  const options = get(element, 'choices', []).map(({ value, text }) => ({ value, label: text }))
  const value = useQuiz(current => get(current, `content.answers.${answerField}.value`, null))
  const styles = useQuiz(current => get(current, `${metaPath}.styles`, ''), [metaPath])

  const changeHandler = useSetQuiz((prev, value) => {
    return set(cloneDeep(prev), `content.answers.${answerField}.value`, value)
  }, [answerField])

  return (
    <StyledCustomSelect
      className={className}
      label={label}
      options={options}
      value={value}
      changeHandler={changeHandler}
      styles={styles}
    />
  )
})

const StyledCustomSelect = styled(CustomSelect)`
  ${({ styles }) => styles || ''};
`
