import React from "react";
import { Cognito } from "../../libs/cognito";

import Spinner from "common/loaders/Spinner";


export default function Logout() {
  const cognito = Cognito.getInstance();
  React.useEffect(() => {
    cognito.signOut();
    window.location = "/login";
  }, []);

  return <Spinner className="loader-container" />;
}
