import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

export default React.memo(function SwitcherTab ({ label, href, isActive, isSeparatorNeed, onClick }) {
  return (
    <StyledWrapper onClick={onClick}>
      <StyledLink
        to={href}
        isactive={isActive.toString()}
      >
        {label}
      </StyledLink>
      {isSeparatorNeed && <div className='separator' />}
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  position: relative;
  @media only screen and (max-width: 480px) {
    min-width: 24%;
  }
  .separator {
    position: absolute;
    right: 0;
    top: 0.8rem;
    bottom: 0.8rem;
    border-right: solid 1px #ccc;
  }
`

const StyledLink = styled(Link)`
  display: block;
  font-size: 1.3rem;
  line-height: 3.2rem;
  min-width: 9.5rem;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  border-radius: 0.7rem;
  ${({ isactive }) => isactive === 'true' && `
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  `}
`
