import React from 'react'

import { useAppointmentsFilters } from 'hooks/useAppointments'
import { useFetchAppointments } from 'requests/appointments'

import SubModal from 'common/SubModal'
import CustomTextarea from 'common/CustomTextarea'
import CustomButton from 'common/CustomButton'

import request from 'utils/request'
import createNotification from 'utils/createNotification'

export default React.memo(function CancelAppointmentModal ({ id, closeModal, closeMainModal }) {
  const { period, provider, patient } = useAppointmentsFilters()
  const fetchAppointments = useFetchAppointments()
  const [text, setText] = React.useState('')

  const cancelHandler = React.useCallback(() => {
    request({
      method: 'delete',
      url: `/admins/appointments/${id}`,
      data: {
        text
      }
    })
      .then(() => {
        closeModal()
        closeMainModal()
        createNotification({ message: 'Appointment successfully canceled', type: 'success' })
        fetchAppointments({ period, provider, patient })
      })
      .catch((error) => console.log(error))
  }, [closeModal, closeMainModal, fetchAppointments, id, text, period, provider, patient])

  return (
    <SubModal
      title='Are you sure in that?'
      closeModal={closeModal}
      minWidth={44}
      maxWidth={44}
    >
      <CustomTextarea
        className='g-mt-20'
        label='please indicate the reason'
        placeholder='Type the reason'
        value={text}
        changeHandler={setText}
      />

      <CustomButton
        className='g-mt-20'
        clickHandler={cancelHandler}
        disabled={!text}
      >
        Cancel appointment
      </CustomButton>
    </SubModal>
  )
})
