import { useEffect } from 'react';
import features from '../features.json';

type Feature = typeof features;

export const useFeatureFlags = () => {
  const feats = Object.keys(features) as (keyof Feature)[];
  const ls = localStorage.getItem('features');
  const parts = new URLSearchParams(window.location.search);
  const lsParts = new URLSearchParams(ls || '');

  useEffect(() => {
    if (ls) {
      const lsParts = new URLSearchParams(ls);

      feats.forEach((key) => {
        if (!lsParts.has(key) && parts.has(key)) {
          lsParts.set(key, parts.get(key) || '');
        }
      });

      localStorage.setItem('features', lsParts.toString());
    }

    const newUrlsParts = new URLSearchParams();

    feats.forEach((key) => {
      if (parts.has(key)) {
        newUrlsParts.set(key, parts.get(key) || '');
      } else if (lsParts.has(key)) {
        newUrlsParts.set(key, lsParts.get(key) || '');
      }
    });

    localStorage.setItem('features', newUrlsParts.toString());
  }, [parts]);

  return feats
    .map((key) => {
      const val = lsParts.get(key) || parts.get(key) || features[key];
      return {
        name: key,
        value: val === 'true' || Number(val) === 1,
      };
    })
    .reduce<Feature>((acc, nxt) => {
      acc[nxt.name] = nxt.value;

      return acc;
    }, {} as Feature);
};
