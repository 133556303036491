export default function getBoundaryValues (data, type) {
  if (!data) return {}
  const values = []
  data.forEach(item => {
    if (typeof +item[type] === 'number' && !isNaN(+item[type])) values.push(+item[type])
  })
  const min = values.length !== 0 ? Math.min(...values) : 0
  const max = values.length !== 0 ? Math.max(...values) : 0
  const avg = (max + min) / 2
  return { min, max, avg }
}
