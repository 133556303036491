import React from "react";
import styled from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import get from "lodash/get";

import { APPOINTMENTS, PROVIDERS, SUMMARY } from "constants/routes";  

import { UppercaseText, RegularText, MediumText } from "common/texts";
import UserAvatar from "common/UserAvatar";
import LinkButton from "common/LinkButton";
import { getTzAbbr } from "./../../../../../utils/tz";

export default React.memo(function AppointmentPreview({ appointment }) {
  const { providerId } = useParams();
  const id = get(appointment, "id");
  const firstName = get(appointment, "patient.first_name", "");
  const lastName = get(appointment, "patient.last_name", "");
  const fullName = get(appointment, "patient.full_name", "");
  const date = get(appointment, "started_at");
  const status = get(appointment, "status", "");

  const history = useHistory();
  const clickHandler = React.useCallback(() => {
    history.push({
      pathname: `/${APPOINTMENTS}/${id}`,
      state: { referrer: `/${PROVIDERS}/${providerId}/${SUMMARY}` },
    });
  }, [history, id, providerId]);

  return (
    <StyledWrapper>
      <div className="time-section">
        <RegularText>
          {moment.utc(date).local().format("D MMMM, YYYY, h:mmA")}{" "}
          {getTzAbbr(date)}
        </RegularText>
        <RegularText>{status}</RegularText>
      </div>
      <div className="patient-section">
        <div className="g-d-flex">
          <UserAvatar
            className="patient-avatar"
            firstName={firstName}
            lastName={lastName}
          />
          <div className="g-ml-15">
            <MediumText>{fullName}</MediumText>
            <UppercaseText>Patient</UppercaseText>
          </div>
        </div>
        {status !== "CANCELLED" && (
          <LinkButton className="view-button" onClick={clickHandler}>
            View
          </LinkButton>
        )}
      </div>
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  padding: 1.5rem 2.5rem 2rem;
  border-bottom: solid 1px #e9ebef;
  .time-section {
    display: flex;
    justify-content: space-between;
    opacity: 0.5;
  }
  .patient-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
  }
  .patient-avatar {
    min-width: 5.3rem;
    width: 5.3rem;
    height: 5.3rem;
  }
  .view-button {
    width: 10rem;
  }
`;
