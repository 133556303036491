import React from 'react'
import get from 'lodash/get'

import NotFound from './NotFound'
import SearchItem from './SearchItem'

export default React.memo(function SearchItemsList ({ users, selectHandler }) {
  return React.useMemo(() => {
    if (users.length === 0) return <NotFound />
    return users.map((user) => {
      const id = get(user, 'id', '')
      const avatar = get(user, 'avatar', '')
      const firstName = get(user, 'first_name', '')
      const lastName = get(user, 'last_name', '')
      const fullName = get(user, 'full_name', '')
      const type = get(user, 'type', '')
      const qualification = get(user, 'qualification', '')
      return (
        <SearchItem
          key={id}
          id={id}
          avatar={avatar}
          firstName={firstName}
          lastName={lastName}
          fullName={fullName}
          type={type}
          qualification={qualification}
          selectHandler={selectHandler}
        />
      )
    })
  }, [users, selectHandler])
})
