import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import 'react-calendar/dist/Calendar.css';
import { ReactComponent as CalendarIcon } from '../../../../assets/calendar-button.svg';
import MealModal from './MealModal';
import { formatDate, formatDateReadable, groupMealsByDate } from './utils';
import Meals from './components/Meals';
import CustomCalendar from './components/CustomCalendar';
import styled from 'styled-components';
import { SecondaryText } from './components/UI/Typography';

const MealCalendar = ({ data }) => {
  const location = useLocation();
  const history = useHistory();

  const [state, setState] = useState({
    isModalOpen: false,
    selectedMeal: null,
    currentMealId: null,
    selectedDate: [null, null],
    mealsByDate: {},
    isCalendarVisible: false,
    lastFoundDate: null,
  });

  const dateFrom = state.selectedDate[0];
  const dateTo = state.selectedDate[1];
  const mealTypeOrder = ['breakfast', 'lunch', 'dinner', 'snack'];
  const isSelectSameDates = formatDate(dateFrom) === formatDate(dateTo);

  const updateState = useCallback(
    (newState) => {
      setState((prevState) => ({ ...prevState, ...newState }));
    },
    [setState]
  );

  // Find the earliest and latest dates with meals
  const dates = data.map((item) => new Date(item.date));
  const firstDate = new Date(Math.min.apply(null, dates));
  const lastDate = new Date(Math.max.apply(null, dates));

  const findFullRangeDateWithMeal = useCallback(() => {
    updateState({ selectedDate: [firstDate, lastDate], lastFoundDate: lastDate });
  }, [data, updateState]);

  const getMeals = useCallback(() => {
    updateState({ mealsByDate: groupMealsByDate(data) });
  }, [data, updateState]);

  const getDefaultSelectedDate = useCallback(() => {
    const params = new URLSearchParams(location.search);
    const paramDateFrom = params.get('dateFrom');
    const paramDateTo = params.get('dateTo');
    if (paramDateFrom && paramDateTo) {
      const fromDate = new Date(formatDate(paramDateFrom));
      const toDate = new Date(formatDate(paramDateTo));
      updateState({ selectedDate: [fromDate, toDate], lastFoundDate: lastDate });
    } else {
      findFullRangeDateWithMeal();
    }
  }, [location.search, findFullRangeDateWithMeal, updateState]);

  useEffect(() => {
    getDefaultSelectedDate();
  }, [data, getDefaultSelectedDate]);

  useEffect(() => {
    getMeals();
  }, [data, getMeals]);

  const toggleCalendarVisibility = () => {
    updateState({ isCalendarVisible: !state.isCalendarVisible });
  };

  const openModal = (meal) => {
    updateState({ selectedMeal: meal, isModalOpen: true, currentMealId: meal.id });
  };

  const closeModal = () => {
    updateState({ selectedMeal: null, isModalOpen: false });
  };

  const handleDateChange1 = (value) => {
    // Check if value is an array (range selection) or a single value (single date selection)
    if (Array.isArray(value)) {
      updateState({ selectedDate: value, isCalendarVisible: false });

      // Update URL with selected date range
      const fromDate = formatDate(value[0]);
      const toDate = formatDate(value[1]);
      const params = new URLSearchParams(location.search);
      params.set('dateFrom', fromDate);
      params.set('dateTo', toDate);
      history.push({ pathname: location.pathname, search: `?${params.toString()}` });
    } else {
      // Single date selected, create a "range" of one day
      const newValue = [value, value];
      updateState({ selectedDate: newValue, isCalendarVisible: false });

      // Update URL with selected date
      const formattedDate = formatDate(value);
      const params = new URLSearchParams(location.search);
      params.set('dateFrom', formattedDate);
      params.set('dateTo', formattedDate);
      history.push({ pathname: location.pathname, search: `?${params.toString()}` });
    }
  };

  const goToLatestRecords = () => {
    findFullRangeDateWithMeal();
    //Clear search params
    const params = new URLSearchParams(location.search);
    params.forEach((_, key) => params.delete(key));
    history.push({ pathname: location.pathname });
  };

  const selectedMeal = data.find((meal) => meal.id === state.currentMealId);

  return (
    <>
      {data.length ? (
        <StyledButton onClick={toggleCalendarVisibility}>
          <CalendarIcon />
          <div className="flex flex-col text-left">
            <p>
              {formatDateReadable(dateFrom)}
              {!isSelectSameDates ? ` - ${formatDateReadable(dateTo)}` : ''}
            </p>
            {formatDate(dateTo) === formatDate(state.lastFoundDate) && <SecondaryText>Latest records</SecondaryText>}
          </div>
        </StyledButton>
      ) : null}

      {state.isCalendarVisible && (
        <CustomCalendar selectedDate={state.selectedDate} handleDateChange={handleDateChange1} mealsByDate={state.mealsByDate} />
      )}

      {state.selectedDate && (
        <Meals
          dateFrom={dateFrom}
          dateTo={dateTo}
          mealsData={state.mealsByDate}
          mealTypeOrder={mealTypeOrder}
          openModal={openModal}
          handleGoToLatestRecords={goToLatestRecords}
          recordsCount={data.length}
          isSelectSameDates={isSelectSameDates}
          selectedDate={state.selectedDate}
        />
      )}

      {state.isModalOpen && state.selectedMeal && <MealModal meal={state.selectedMeal} closeModal={closeModal} mealData={selectedMeal} />}
    </>
  );
};

export default MealCalendar;

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #16123f;
  margin-bottom: 10px;
`;
