import React from 'react'
import styled from 'styled-components'
import get from 'lodash/get'
import { useHistory, useParams } from 'react-router-dom'

import { PATIENTS, DETAILS, DOCTOR_NOTES, CREATE } from 'constants/routes'; 

import { usePatinetDoctorNote, useSetPatinetDoctorNote } from 'hooks/usePatients'
import { useFetchSymptomsList } from 'requests/patients'

import { RegularMediumText, HintText } from 'common/texts'
import DetailsTemplate from 'components/patients/DetailsTemplate'
import CustomTextarea from 'common/CustomTextarea'
import PatientSymptoms from 'components/patients/doctor-notes/PatientSymptoms'
import CustomButton from 'common/CustomButton'

export default function PatientCreateNoteStep2 () {
  const { patientId } = useParams()
  const history = useHistory()
  const fetchSymptomsList = useFetchSymptomsList()

  const general = usePatinetDoctorNote(current => get(current, 'general', ''), [])
  const setgeneral = useSetPatinetDoctorNote((prev, general) => ({ ...prev, general }), [])

  const setCurrentPage = useSetPatinetDoctorNote((prev) => ({ ...prev, draftPage: 2 }), [])

  React.useEffect(() => {
    fetchSymptomsList()
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    setCurrentPage()
    // eslint-disable-next-line
  }, [])

  const toNextStep = React.useCallback(() => {
    history.push({
      pathname: `/${PATIENTS}/${DETAILS}/${patientId}/${DOCTOR_NOTES}/${CREATE}/3`,
      state: { referrer: `/${PATIENTS}/${DETAILS}/${patientId}/${DOCTOR_NOTES}/${CREATE}/2` }
    })
  }, [history, patientId])

  const toPrevStep = React.useCallback(() => {
    history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${DOCTOR_NOTES}/${CREATE}/1`)
  }, [history, patientId])

  return (
    <DetailsTemplate currentTab={DOCTOR_NOTES}>
      <RegularMediumText>
        Review of system
      </RegularMediumText>

      <CustomTextarea
        className='g-mt-20'
        label='General'
        placeholder='Free text'
        value={general}
        changeHandler={setgeneral}
      />

      <PatientSymptoms />

      <StyledButtonGroup>
        <CustomButton
          clickHandler={toNextStep}
        >
          Next
        </CustomButton>

        <HintText
          className='prev-button g-ml-30'
          onClick={toPrevStep}
        >
          Previous
        </HintText>
      </StyledButtonGroup>
    </DetailsTemplate>
  )
}

const StyledButtonGroup = styled.div`
  margin: auto auto 0 0;
  display: flex;
  align-items: center;
  .prev-button {
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
`
