import React from 'react';
import styled from 'styled-components';

import CloseIcon from 'common/CloseIcon';

export default React.memo(function PatientSelect({ value, onChange, onClear }) {
  return (
    <StyledWrapper>
      <input className="input" placeholder="Enter patient`s name or MRN" value={value} onChange={(e) => onChange(e.target.value)} />
      {value && <CloseIcon className="close" closeHandler={onClear} />}
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  position: relative;
  width: 20.1rem;
  .input {
    display: block;
    width: 100%;
    font-size: 1.3rem;
    line-height: 3rem;
    padding: 0.8rem 1.6rem;
    border: solid 1px #e9ebef;
    border-radius: 2px;
    outline: none;
    &::placeholder {
      font-size: 1.2rem;
      font-weight: 300;
    }
    &:hover {
      border: solid 1px #ccc;
    }
    &:focus {
      box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.25);
    }
  }
  .close {
    position: absolute;
    top: 1rem;
    right: 0.5rem;
    bottom: 0;
  }
`;
