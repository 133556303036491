import React from 'react'
import styled from 'styled-components'

export default React.memo(function SwitcherTab ({
  id, label, setCurrentTab, isActive, isSeparatorNeed, isRequred
}) {
  const changeHandler = React.useCallback(() => {
    setCurrentTab(id)
  }, [id, setCurrentTab])

  return (
    <StyledWrapper
      className='tab-container'
      onClick={changeHandler}
    >
      <StyledDiv
        className='tab'
        isactive={isActive.toString()}
      >
        {label}
        {isRequred && <span className='required'>*</span>}
      </StyledDiv>
      {isSeparatorNeed && <div className='separator' />}
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  position: relative;
  cursor: pointer;
  @media only screen and (max-width: 480px) {
    min-width: 24%;
  }
  .separator {
    position: absolute;
    right: 0;
    top: 0.8rem;
    bottom: 0.8rem;
    border-right: solid 1px #ccc;
  }
`

const StyledDiv = styled.div`
  display: block;
  font-size: 1.3rem;
  line-height: 3.2rem;
  min-width: 9.5rem;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  border-radius: 0.7rem;
  ${({ isactive }) => isactive === 'true' && `
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  `}
  .required {
    display: inline-block;
    color: #f86060;
    font-size: 1.3rem;
    line-height: 1.8rem;
    margin-left: 1px;
  }
`
