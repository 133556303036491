import React from 'react'

import { useChatClient, useSetChatChannels } from 'hooks/useMessages'
import { useFetchChatUsersData } from 'requests/messages'

import { updateChannels } from 'utils/messages'

export default function ChannelUpdated () {
  const chatClient = useChatClient()
  const fetchChatUsersData = useFetchChatUsersData()

  const updateChatChannel = useSetChatChannels((prev, { channel, updateReasons }) => {
    fetchChatUsersData([channel])
    return updateChannels(prev, channel)
  }, [fetchChatUsersData])

  React.useEffect(() => {
    chatClient.on('channelUpdated', updateChatChannel)

    return () => {
      chatClient.removeListener('channelUpdated', updateChatChannel)
    };
  }, [chatClient, updateChatChannel])

  return null
}
