import React from 'react'
import styled from 'styled-components'

import { RegularMediumText, RegularText } from 'common/texts'
import LinkButton from 'common/LinkButton'

export default React.memo(function RemoveSecurity ({ submitHandler }) {
  return (
    <div>
      <RegularMediumText className='g-mt-25'>
        Two-step authentication
      </RegularMediumText>
      <RegularText>
        Keep your account extra secure with a two-factor authentication.
      </RegularText>

      <StyledLinkButton
        className='g-mt-15'
        onClick={React.useCallback(() => submitHandler(true), [submitHandler])}
      >
        Turn off
      </StyledLinkButton>
    </div>
  )
})

const StyledLinkButton = styled(LinkButton)`
  align-self: flex-start;
  padding: 0.6rem 3.5rem;
`
