import React from "react";
import styled from "styled-components";
import get from "lodash/get";

import {
  useFetchProviderTimes,
  useUpdateProviderTimes,
} from "requests/providers";

import CustomButton from "common/CustomButton";
import TimeZone from "./TimeZone";
import NotificationsSwitcher from "./NotificationsSwitcher";
import TabsSwitcher from "../../../../common/TabsSwitcher";
import { HintText } from "../../../../common/texts";
import { WeekAvailability } from "./WeekAvailability";
import { CalendarView } from "./CalendarView";
import { calculateDirtyDates, calculateDirtyDays } from "./helpers";
import { useTimes, useSetTimes } from "hooks/useProfile";

const tabsConfig = [
  { id: "time-view", label: "Available Time", href: "#" },
  { id: "calendar-view", label: "Calendar", href: "#" },
];

const init = {
  defaults: {},
  specials: [],
};

export default React.memo(function AvailableTime() {
  const updateTimes = useUpdateProviderTimes();
  const fetchTimes = useFetchProviderTimes();
  const setTimes = useSetTimes((prev, work_time) => ({ ...prev, work_time }));
  const times = useTimes();

  const { loading = false, original = init, work_time = init } = times || {};
  const [tab, setTab] = React.useState(tabsConfig[0].id);
  const [hasError, setError] = React.useState(false);
  const [dirtyDates, setDirtyDates] = React.useState([]);
  const [dirtyDays, setDirtyDays] = React.useState([]);

  const onChange = (newAva) => {
    setTimes({
      defaults: get(newAva, "defaults", {}),
      specials: get(newAva, "specials", []),
    });
    setDirtyDays(
      calculateDirtyDays(newAva.defaults, get(original, "defaults", []))
    );
    setDirtyDates(
      calculateDirtyDates(newAva.specials, get(original, "specials", []))
    );
  };

  const update = () => {
    updateTimes();
    setDirtyDates([]);
    setDirtyDays([]);
  };

  const resetTimes = () => {
    setTimes(original);
    setDirtyDates([]);
    setDirtyDays([]);
  };

  const hasDiffDefaults =
    JSON.stringify(work_time.defaults) !== JSON.stringify(original.defaults);

  const isSubmitEnabled = hasDiffDefaults || dirtyDates.length > 0;

  React.useEffect(() => {
    fetchTimes();
  }, []);

  return (
    <>
      <Root>
        <NotificationsSwitcher />
        <TabsSwitcher
          className="TabsSwitcher g-mt-20 g-mb-30"
          tabsConfig={tabsConfig}
          onChange={setTab}
          currentTab={tab}
        />

        {tabsConfig[0].id === tab && (
          <HintText style={{ width: "38rem" }} className="g-mt-20">
            Select time ranges for weekdays when you are available for
            appointments. The time is displayed in your time zone, based on your
            address.
          </HintText>
        )}
        {tabsConfig[1].id === tab && (
          <HintText style={{ width: "38rem" }} className="g-mt-20">
            Select your availability time range for specific calendar days when
            you are available for appointments. Bare in mind that the changes in
            the calendar have priority over the Available Time tab. The time is
            displayed in your time zone, based on your address.
          </HintText>
        )}
        <TimeZone iana={times && times.timezone} />
        {tabsConfig[0].id === tab && (
          <WeekAvailability
            availability={work_time}
            onHasError={setError}
            onChange={onChange}
          />
        )}
        {tabsConfig[1].id === tab && (
          <CalendarView
            availability={work_time}
            onHasError={setError}
            dirtyDates={dirtyDates}
            dirtyDays={dirtyDays}
            onChange={onChange}
          />
        )}
        <UpdateButton
          clickHandler={update}
          disabled={!(isSubmitEnabled && !hasError)}
          isLoading={loading}
        >
          Update
        </UpdateButton>
        <ResetButton
          clickHandler={resetTimes}
          disabled={!(hasDiffDefaults || dirtyDates.length > 0)}
          isLoading={loading}
        >
          Reset
        </ResetButton>
      </Root>
    </>
  );
});

const Root = styled.div`
  .TabsSwitcher {
    width: fit-content;
  }
`;

const UpdateButton = styled(CustomButton)`
  margin-top: 3.6rem;
  margin-right: 2rem;
`;

const ResetButton = styled(CustomButton)`
  margin-top: 3.6rem;
`;
