import React from 'react'
import moment from 'moment'
import styled from 'styled-components'

import { useWidgetFilters } from 'hooks/patient/widgets/useWidgets'

import { MediumText } from 'common/texts'
import DayLabel from '../DayLabel'
import Bars from './Bars'
import DaysSelectors from './DaysSelectors'

export default React.memo(function DaySelector ({ data, label, color = '#16123f' }) {
  const selectedDay = useWidgetFilters(current => current.selectedDay)

  const daysList = React.useMemo(() => {
    const start = moment(selectedDay).subtract(7, 'days').format('YYYY-MM-DD')
    return (new Array(15)).fill().map((item, i) => {
      return moment(start).add(i, 'days').format('YYYY-MM-DD')
    })
  }, [selectedDay])

  return (
    <StyledWrapper>
      {data.length === 0 && (
        <MediumText className='g-mb-10 g-text-align-center'>
          There has been no activity in the last 30 days
        </MediumText>
      )}
      <div className='header'>
        <DayLabel
          className='g-w-100'
          date={selectedDay}
        />
        <div className='g-w-100 g-text-align-center'>
          <b>{label}</b>
        </div>
        <div className='g-w-100' />
      </div>
      <Bars
        data={data}
        daysList={daysList}
        color={color}
      />
      <DaysSelectors daysList={daysList} />
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  padding: 1.5rem 2.5rem 3rem;
  .header {
    display: flex;
    justify-content: space-between;
    font-size: 1.5rem;
    label {
      width: 100%;
    }
  }
`
