import React from 'react'
import { useParams } from 'react-router-dom'

import { useQuiz, useSetQuiz } from 'hooks/patient/useQuestionnaires'
import { useFetchQuiz } from 'requests/patients/questionnaires'

import { RowPlate } from 'common/plate/plates'
import { PlateContent } from 'common/plate/styles'
import QuizMetricsHeader from 'components/patients/questionnaires/quiz-metrics/quiz-metrics-header'
import QuizMetricsBody from 'components/patients/questionnaires/quiz-metrics/quiz-metrics-body'

export default function PatientQuizMetrics () {
  const { quizId } = useParams()
  const fetchQuiz = useFetchQuiz()
  const isQuizLoaded = useQuiz(current => !!current)
  const clearQuiz = useSetQuiz(() => null, [])

  React.useEffect(() => {
    fetchQuiz(quizId)
    return clearQuiz
    // eslint-disable-next-line
  }, [quizId])

  if (!isQuizLoaded) return null

  return (
    <RowPlate>
      <QuizMetricsHeader />
      <PlateContent>
        <QuizMetricsBody />
      </PlateContent>
    </RowPlate>
  )
}
