import React from 'react'
import get from 'lodash/get'

import { useQuiz } from 'hooks/patient/useQuestionnaires'

import { RegularMediumText, RegularText } from 'common/texts'

export default React.memo(function Expression ({ className = '', metaPath }) {
  const title = useQuiz(current => get(current, `${metaPath}.title`, ''), [metaPath])
  const description = useQuiz(current => get(current, `${metaPath}.description`, ''), [metaPath])
  return (
    <>
      <RegularMediumText
        className={`${className} g-mt-20`}
      >
        {title}
      </RegularMediumText>
      <RegularText>
        {description}
      </RegularText>
    </>
  )
})
