import React from 'react'
import styled from 'styled-components'
import get from 'lodash/get'
import { useHistory, useParams } from 'react-router-dom'

import { PATIENTS, DETAILS, DOCTOR_NOTES, CREATE } from 'constants/routes'; 

import { usePatinetDoctorNote, useSetPatinetDoctorNote } from 'hooks/usePatients'
import { useFetchPatinetProfile } from 'requests/patients'

import { RegularMediumText } from 'common/texts'
import DetailsTemplate from 'components/patients/DetailsTemplate'
import CustomTextarea from 'common/CustomTextarea'
import CustomButton from 'common/CustomButton'

export default function PatientCreateNoteStep1 () {
  const { patientId } = useParams()
  const history = useHistory()

  const fetchPatinetProfile = useFetchPatinetProfile()

  const illnessHistory = usePatinetDoctorNote(current => get(current, 'illnessHistory', ''), [])
  const setIllnessHistory = useSetPatinetDoctorNote((prev, illnessHistory) => ({ ...prev, illnessHistory }), [])

  const setCurrentPage = useSetPatinetDoctorNote((prev) => ({ ...prev, draftPage: 1 }), [])

  React.useEffect(() => {
    fetchPatinetProfile()
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    setCurrentPage()
    // eslint-disable-next-line
  }, [])

  const toNextStep = React.useCallback(() => {
    history.push({
      pathname: `/${PATIENTS}/${DETAILS}/${patientId}/${DOCTOR_NOTES}/${CREATE}/2`,
      state: { referrer: `/${PATIENTS}/${DETAILS}/${patientId}/${DOCTOR_NOTES}/${CREATE}/1` }
    })
  }, [history, patientId])

  return (
    <DetailsTemplate currentTab={DOCTOR_NOTES}>
      <RegularMediumText>
        Provider notes
      </RegularMediumText>

      <RegularMediumText>
        History of Present Illness
      </RegularMediumText>

      <StyledCustomTextarea
        className='g-mt-10 g-mb-45'
        label='Enter patient history of present Illness'
        placeholder='Free text'
        value={illnessHistory}
        changeHandler={setIllnessHistory}
      />

      <StyledCustomButton
        clickHandler={toNextStep}
      >
        Next
      </StyledCustomButton>
    </DetailsTemplate>
  )
}

const StyledCustomTextarea = styled(CustomTextarea)`
  textarea {
    min-height: 25rem;
  }
  .loader-input {
    min-height: 25rem;
  }
`

const StyledCustomButton = styled(CustomButton)`
  margin: auto auto 0 0;
`
