import React from 'react'

import { RegularText } from 'common/texts'
import LinkButton from 'common/LinkButton'
import RefundAppointmentModal from './RefundAppointmentModal'

export default function RefundButton ({ id, closeMainModal }) {
  const [isOpen, setIsOpen] = React.useState(false)
  const openModal = React.useCallback(() => setIsOpen(true), [setIsOpen])
  const closeModal = React.useCallback(() => setIsOpen(false), [setIsOpen])

  return (
    <>
      <div className='g-d-flex g-justify-between g-align-center g-mt-20'>
        <RegularText>
          Refund the visit to the patient
        </RegularText>
        <LinkButton
          className='button'
          onClick={openModal}
        >
          Refund
        </LinkButton>
      </div>

      {isOpen && (
        <RefundAppointmentModal
          closeModal={closeModal}
          closeMainModal={closeMainModal}
          id={id}
        />
      )}
    </>
  )
}
