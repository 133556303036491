import styled from 'styled-components'

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  margin: 0 0.8rem;
  width: 4.8rem;
  height: 4.8rem;
  cursor: pointer;
  @media screen and (max-width: 320px) {
    margin: 0 0.4rem;
  }
  :hover {
    opacity: 0.75;
  }
  :active {
    opacity: 0.5;
  }
`
