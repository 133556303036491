import React from "react";
import styled from "styled-components";

import { WideRowPlate } from "common/plate/plates";
import { PlateHeader, PlateContent } from "common/plate/styles";
import { MediumText } from "common/texts";

import CustomSelect from "common/CustomSelect";
import RequestCameraPermission from "common/camera/RequestCameraPermission";
import DevicesListener from "common/camera/DevicesListener";
import VideoListener from "common/camera/VideoListener";
import StopStreamListener from "common/camera/StopStreamListener";
import ButtonGroup from "components/environment-check/camera/ButtonGroup";

export default function Camera() {
  const [deviceList, setDeviceList] = React.useState([]);
  const [selectedCamera, setSelectedCamera] = React.useState(null);

  const videoRef = React.useRef();
  const currentStream = React.useRef();

  const normalizedOptions = React.useMemo(() => {
    return deviceList.map(({ deviceId, label }) => ({
      label,
      value: deviceId,
    }));
  }, [deviceList]);

  return (
    <>
      <RequestCameraPermission
        currentStream={currentStream}
        setDeviceList={setDeviceList}
        setSelectedCamera={setSelectedCamera}
      />
      <VideoListener
        selectedCamera={selectedCamera}
        currentStream={currentStream}
        videoRef={videoRef}
      />
      <DevicesListener setDeviceList={setDeviceList} />
      <StopStreamListener videoRef={videoRef} currentStream={currentStream} />
      <WideRowPlate>
        <PlateHeader>Step 1 of 4 - Camera</PlateHeader>

        <PlateContent>
          <CustomSelect
            className="g-mt-15"
            label="Select camera"
            options={normalizedOptions}
            value={selectedCamera}
            changeHandler={setSelectedCamera}
          />

          <MediumText className="g-mt-30">Do you see youself?</MediumText>

          <StyledVideoWrapper>
            <div></div>
            <video ref={videoRef} autoPlay />
          </StyledVideoWrapper>

          <ButtonGroup />
        </PlateContent>
      </WideRowPlate>
    </>
  );
}

const StyledVideoWrapper = styled.div`
  height: 16rem;
  width: 22rem;
  align-self: flex-start;
  background: #6c6a6a;
  video {
    display: block;
    transform: scaleX(-1) !important;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 16rem;
    background: #6c6a6a;
  }
`;
