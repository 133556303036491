import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { PATIENTS, DETAILS, WIDGETS, WITHINGS, BLOOD_PRESSURE } from 'constants/routes'; 

import DayLabel from 'common/widgets/DayLabel'
import PressurePreview from './PressurePreview'
import ViewData from 'common/widgets/ViewData'

export default function WithingsBloodPressureWidget ({ widgetId, lastResult }) {
  const { patientId } = useParams()
  const history = useHistory()
  const {
    summaryDate,
    pulse
  } = lastResult

  const navigateToBloodPressure = React.useCallback(() => {
    history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${WIDGETS}/${WITHINGS}/${widgetId}/${BLOOD_PRESSURE}`)
  }, [history, patientId, widgetId])

  return (
    <>
      <DayLabel date={summaryDate} />
      <PressurePreview
        widgetId={widgetId}
        pulse={pulse}
      />
      <ViewData
        className='g-mt-45'
        clickHandler={navigateToBloodPressure}
      />
    </>
  )
}
