import React from 'react'
import get from 'lodash/get'

import { useCompanies } from 'hooks/useCompanies'

import CompanyPreview from './CompanyPreview'

export default function CompaniesList () {
  const companies = useCompanies()

  const parsedCompanies = React.useMemo(() => {
    return companies.map(company => {
      const id = get(company, 'id')
      return (
        <CompanyPreview
          key={id}
          company={company}
        />
      )
    })
  }, [companies])

  return parsedCompanies
}
