import React from 'react'

import { usePatientAdminNotes } from 'hooks/usePatients'

import ListPreviewPlaceholder from 'common/loaders/ListPreviewPlaceholder'
import EmptyAdminNotes from './EmptyAdminNotes'
import AdminNotesList from './AdminNotesList'

export default function AdminNotesLoader () {
  const patientAdminNotes = usePatientAdminNotes()

  if (!patientAdminNotes) return <ListPreviewPlaceholder />

  if (patientAdminNotes.length === 0) return <EmptyAdminNotes />

  return <AdminNotesList />
}
