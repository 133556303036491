import styled from 'styled-components'

export const PreFormatting = styled.pre`
  white-space: pre-wrap;
`

export const UppercaseText = styled.p`
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
`

export const HintText = styled.p`
  font-size: 1.3rem;
  line-height: 2.4rem;
  color: rgba(0, 0, 0, 0.5);
`

export const SmallText = styled.p`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 2.2rem;
`

export const RegularText = styled.p`
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 2.4rem;
`

export const BoldText = styled.p`
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 2.4rem;
`

export const RegularMediumText = styled.p`
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 3rem;
`

export const RegularBigText = styled.p`
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 3rem;
`

export const MediumText = styled.p`
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 3rem;
`

export const BoldBigText = styled.p`
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 3rem;
`
