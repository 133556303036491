import React from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import get from 'lodash/get';

import { PATIENTS, DETAILS, DOCTOR_NOTES, EDIT, PREVIEW } from 'constants/routes';

import { useUser } from 'hooks/useGlobalState';
import { useSetPatinetDoctorNote } from 'hooks/usePatients';
import { useRemoveDoctorNoteDraft } from 'requests/patients';

import { UppercaseText, RegularText } from 'common/texts';
import LinkButton from 'common/LinkButton';
import { permissions } from '../../../../permissions';
import { useUserContext } from '../../../../context/userContext';

export default React.memo(function NotePreview({ note }) {
  const { patientId } = useParams();
  const history = useHistory();
  const { can } = useUserContext();
  const clearPatinetDoctorNote = useSetPatinetDoctorNote(() => null, []);

  const id = get(note, 'id');
  const removeDoctorNoteDraft = useRemoveDoctorNoteDraft(id);

  const autorId = get(note, 'provider.id');
  const isOwner = useUser((current) => get(current, 'id') === autorId, [autorId]);

  const medicalRecord = get(note, 'medical_record');
  const editable = get(note, 'editable');
  const author = get(note, 'provider.full_name');
  const published = moment.utc(get(note, 'published', '')).local().format('D MMMM YYYY h:mm A');
  const draft = get(note, 'draft');
  const draftPage = get(note, 'draft_page');

  const handleEdit = React.useCallback(() => {
    clearPatinetDoctorNote();
    history.push({
      pathname: `/${PATIENTS}/${DETAILS}/${patientId}/${DOCTOR_NOTES}/${id}/${EDIT}/${draftPage || 1}`,
      state: { referrer: `/${PATIENTS}/${DETAILS}/${patientId}/${DOCTOR_NOTES}` },
    });
  }, [history, patientId, id, draftPage, clearPatinetDoctorNote]);

  const handlePreview = React.useCallback(() => {
    clearPatinetDoctorNote();
    history.push({
      pathname: `/${PATIENTS}/${DETAILS}/${patientId}/${DOCTOR_NOTES}/${id}/${PREVIEW}`,
      state: { referrer: `/${PATIENTS}/${DETAILS}/${patientId}/${DOCTOR_NOTES}` },
    });
  }, [history, patientId, id, clearPatinetDoctorNote]);

  return (
    <StyledPreview draft={draft}>
      <div className="mrn g-mt-5 g-mb-5">
        <UppercaseText>medical record number</UppercaseText>
        <RegularText>{medicalRecord}</RegularText>
      </div>

      <div className="autor g-mt-5 g-mb-5">
        <UppercaseText>Author</UppercaseText>
        <RegularText>{author}</RegularText>
      </div>

      <div className="published g-mt-5 g-mb-5">
        <UppercaseText className="published-label">{draft ? 'draft' : 'published'}</UppercaseText>
        <RegularText>{published}</RegularText>
      </div>

      <div className="buttons">
        {editable && (
          <LinkButton className="edit-button g-mr-10" onClick={handleEdit}>
            Edit
          </LinkButton>
        )}
        {can(permissions.patient.canDeleteDoctorNotes) ||
          (draft && isOwner && (
            <LinkButton className="view-button" onClick={removeDoctorNoteDraft}>
              Delete
            </LinkButton>
          ))}
        {!draft && (
          <LinkButton className="view-button" onClick={handlePreview}>
            View notes
          </LinkButton>
        )}
      </div>
    </StyledPreview>
  );
});

const StyledPreview = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2.4rem;
  margin-bottom: 1.5rem;
  background: ${({ draft }) => (draft ? '#f2f6f9' : '#fafbfd')};
  border-radius: 0.8rem;
  .mrn {
    width: 25%;
  }
  .autor {
    width: 20%;
  }
  .published {
    width: 25%;
  }
  .published-label {
    position: relative;
    padding-left: 1.1rem;
    &::before {
      content: '';
      position: absolute;
      top: 0.6rem;
      left: 0;
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 50%;
      background: ${({ draft }) => (draft ? '#fe6968' : '#8fc73e')};
    }
  }
  .buttons {
    display: flex;
    width: 30%;
    justify-content: flex-end;
    margin: auto 0;
  }
  .edit-button {
    padding: 0.6rem 2.5rem;
  }
  .view-button {
    min-width: 11rem;
  }
  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
    .mrn {
      width: 30%;
      text-align: center;
    }
    .autor {
      width: 30%;
      text-align: center;
    }
    .published {
      width: 30%;
      text-align: center;
    }
    .buttons {
      width: 100%;
      margin: 0.5rem 0;
    }
    .edit-button {
      width: 100%;
    }
    .view-button {
      width: 100%;
    }
  }
`;
