import React from "react";
import { useParams, useHistory } from "react-router-dom";

import { PATIENTS, ACTIVE } from "constants/routes";  

import {
  useSetPatientFullName,
  useSetPatinetSummary,
  useSetPatientTestState,
  usePatientTestState,
} from "hooks/usePatients";

import request from "utils/request";
import createNotification from "utils/createNotification";

export function useFetchPatinetSummary() {
  const { patientId } = useParams();
  const setPatinetSummary = useSetPatinetSummary((prev, next) => next, []);
  const setPatientFullName = useSetPatientFullName((prev, next) => next, []);
  return React.useCallback(() => {
    request({
      method: "get",
      url: `/patients/${patientId}/summary`,
    })
      .then(({ data: { data } }) => {
        setPatinetSummary(data);
        if (data.patient.full_name) setPatientFullName(data.patient.full_name);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [patientId, setPatinetSummary, setPatientFullName]);
}

export function useChangeTestPatientTestState() {
  const { patientId } = useParams();
  const testPatientState = usePatientTestState();
  const setTestPatientState = useSetPatientTestState((prev, next) => next, []);
  return React.useCallback(() => {
    request({
      method: "patch",
      url: `/admins/patients/${patientId}/is_test/toggle`,
    })
      .then(({ data }) => {
        setTestPatientState(Boolean(data));
        createNotification({
          message: "Test State of Patient successfully updated",
          type: "success",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [testPatientState]);
}

export function useUpdatePatinetStatus() {
  const { patientId } = useParams();
  const setPatinetSummary = useSetPatinetSummary((prev, next) => next, []);
  return React.useCallback(
    (statusData) => {
      request({
        method: "put",
        url: `/patients/${patientId}/status`,
        data: statusData,
      })
        .then(({ data: { data } }) => {
          setPatinetSummary(data);
          createNotification({
            message: "Status successfully updated",
            type: "success",
          });
        })
        .catch((error) => console.log(error));
    },
    [patientId, setPatinetSummary]
  );
}

export function useExportAllData() {
  const { patientId } = useParams();
  return React.useCallback(() => {
    request({
      method: "get",
      url: `/admins/patients/${patientId}/excel`,
      responseType: "blob",
    })
      .then((response) => {
        const { data } = response;
        const contentDisposition = response.request.getResponseHeader(
          "Content-Disposition"
        );
        const name = contentDisposition
          .split("filename")[1]
          .replace(/"/g, "")
          .replace(/=/g, "");
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  }, [patientId]);
}

export function useRemovePatient(patientId) {
  const history = useHistory();
  return React.useCallback(() => {
    request({
      method: "delete",
      url: `/admins/patients/${patientId}`,
    })
      .then(() => {
        history.push(`/${PATIENTS}/${ACTIVE}`);
        createNotification({
          message: "Patient successfully removed",
          type: "success",
        });
      })
      .catch((error) => console.log(error));
  }, [patientId, history]);
}
