import React from 'react'

import { RegularMediumText } from 'common/texts'

export default function EmptyMedicationsList () {
  return (
    <RegularMediumText>
      Patient do not have any uploaded Medications.
    </RegularMediumText>
  )
}
