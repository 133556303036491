import React from 'react'

import { useIsMedicationValid } from 'hooks/usePatients'
import { useCreatePatientMedications } from 'requests/patients/medication'

import CustomButton from 'common/CustomButton'

export default function CreateButton ({ setValidate }) {
  const isMedicationValid = useIsMedicationValid()
  const createPatientMedications = useCreatePatientMedications()
  const changeHandler = React.useCallback(() => {
    setValidate(true)
    if (isMedicationValid) createPatientMedications()
  }, [isMedicationValid, setValidate, createPatientMedications])

  return (
    <CustomButton
      className='g-mt-auto'
      clickHandler={changeHandler}
    >
      Create
    </CustomButton>
  )
}
