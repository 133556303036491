import React from 'react'
import styled from 'styled-components'

import StatusesList from './StatusesList'
import Lines from './Lines'
import BarsList from './BarsList'

export default React.memo(function Grid ({ config, chartOffsets }) {
  return (
    <>
      <StatusesList config={config} />
      <StyledWrapper>
        <Lines />
        <BarsList config={config} chartOffsets={chartOffsets} />
      </StyledWrapper>
    </>
  )
})

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  height: 20rem;
  border-left: 1px solid #e9ebef;
  border-bottom: 1px solid #e9ebef;
`
