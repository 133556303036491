import React from 'react'
import get from 'lodash/get'
import styled from 'styled-components'

import { useQuiz } from 'hooks/patient/useQuestionnaires'

import InputWithLabel from 'common/InputWithLabel'

export default function Text ({ className = '', metaPath, answerField }) {
  const label = useQuiz(current => get(current, `${metaPath}.title`, ''), [metaPath])
  const value = useQuiz(current => get(current, `content.answers.${answerField}.value`, ''), [answerField])
  const styles = useQuiz(current => get(current, `${metaPath}.styles`, ''), [metaPath])

  return (
    <StyledInputWithLabel
      className={className}
      label={label}
      value={value}
      styles={styles}
      readOnly
    />
  )
}

const StyledInputWithLabel = styled(InputWithLabel)`
  ${({ styles }) => styles || ''};
`
