import React from 'react'
import styled from 'styled-components'

import BackButton from 'common/plate/BackButton'

export default React.memo(function ChatWrongHeader ({ href }) {
  return (
    <StyledWrapper>
      {href && <BackButton href={href} />}
      <div>
        Chat doesn't exist or you don't have a permission
      </div>
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 2rem 2.5rem;
  border-bottom: solid 2px #f1f5f8;
`
