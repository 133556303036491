import { capitalize } from 'lodash';
import { useMemo } from 'react';
import { sortOptions } from '../../../../utils/sortOptions';
import { useQuery } from '../client';
import { GET_LIBRARY_MATERIAL_TYPES } from '../constants';

export const useLibraryMaterialTypes = () => {
  const { data, isLoading, error } = useQuery(
    [GET_LIBRARY_MATERIAL_TYPES],
    {
      getLibraryMaterialTypes: true,
    },
    {
      operationName: GET_LIBRARY_MATERIAL_TYPES,
    }
  );

  const libraryMaterialTypes = data?.getLibraryMaterialTypes || [];
  const libraryMaterialTypesOptions = useMemo(
    () =>
      libraryMaterialTypes
        .map((type) => ({
          value: type,
          label: capitalize(type),
        }))
        .sort(sortOptions),
    [libraryMaterialTypes]
  );

  return {
    libraryMaterialTypes,
    libraryMaterialTypesOptions,
    isLoading,
    error,
  };
};
