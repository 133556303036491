/* eslint-disable */

import { AllTypesProps, ReturnTypes } from './const';
type ZEUS_INTERFACES = never
type ZEUS_UNIONS = GraphQLTypes["UploadFileInfoTypeTooLargeFileFileTypeIsNotSupported"] | GraphQLTypes["TreatmentPlanTypeTreatmentIsSentError"] | GraphQLTypes["TreatmentPlanDeletedTypeTreatmentIsSentError"]

export type ValueTypes = {
    ["MyHealthQuery"]: AliasType<{
getTreatmentPlan?: [{	treatmentPlanId:ValueTypes["UUID"]},ValueTypes["TreatmentPlanType"]],
getTreatmentPlans?: [{	filters?:ValueTypes["TreatmentPlanFilterType"] | null,	page:number,	perPage:number},ValueTypes["TreatmentPlanTypeConnection"]],
getTaskFileUrl?: [{	fileId:ValueTypes["UUID"]},boolean],
getPatientTasks?: [{	date:ValueTypes["Date"]},ValueTypes["PatientTaskGqlType"]],
getPatientTasksDates?: [{	fromDate:ValueTypes["Date"],	toDate:ValueTypes["Date"]},boolean],
getPatientMedications?: [{	page:number,	perPage:number,	filters?:ValueTypes["MedicationsFilterType"] | null},ValueTypes["MedicationContentTypeConnection"]],
	getMedicationCategories?:ValueTypes["MedicationCategoryType"],
	getMedicationFrequencies?:ValueTypes["MedicationFrequencyType"],
	getMedicationMeasures?:ValueTypes["MedicationMeasureType"],
getPatientMedication?: [{	medicationId:number},ValueTypes["MedicationContentDetailType"]],
		__typename?: boolean
}>;
	["TreatmentPlanType"]: AliasType<{
	tasks?:ValueTypes["TaskGqlType"],
	createdBy?:ValueTypes["ProviderType"],
	updatedBy?:ValueTypes["ProviderType"],
	taskAmount?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	id?:boolean,
	title?:boolean,
	summary?:boolean,
	status?:boolean,
	deletedAt?:boolean,
	sentAt?:boolean,
		__typename?: boolean
}>;
	["TaskGqlType"]: AliasType<{
	title?:boolean,
	files?:ValueTypes["TaskFileType"],
	details?:boolean,
	materials?:ValueTypes["TaskMaterialType"],
	createdAt?:boolean,
	updatedAt?:boolean,
	id?:boolean,
	type?:boolean,
	startDate?:boolean,
	endDate?:boolean,
	status?:boolean,
		__typename?: boolean
}>;
	["TaskFileType"]: AliasType<{
	id?:boolean,
	fileName?:boolean,
	objectName?:boolean,
	fileType?:boolean,
	createdAt?:boolean,
	uploadFinishedAt?:boolean,
		__typename?: boolean
}>;
	["UUID"]:unknown;
	/** Date with time (isoformat) */
["DateTime"]:unknown;
	/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
["JSON"]:unknown;
	["TaskMaterialType"]: AliasType<{
	materialVersion?:ValueTypes["LibraryMaterialType"],
	id?:boolean,
		__typename?: boolean
}>;
	["LibraryMaterialType"]: AliasType<{
	category?:ValueTypes["LibraryMaterialCategoryType"],
	createdBy?:ValueTypes["ProviderType"],
	updatedBy?:ValueTypes["ProviderType"],
	createdAt?:boolean,
	updatedAt?:boolean,
	id?:boolean,
	title?:boolean,
	description?:boolean,
	type?:boolean,
	content?:boolean,
	currentVersion?:boolean,
	materialId?:boolean,
	status?:boolean,
	deletedAt?:boolean,
		__typename?: boolean
}>;
	["LibraryMaterialCategoryType"]: AliasType<{
	createdAt?:boolean,
	updatedAt?:boolean,
	id?:boolean,
	slug?:boolean,
	title?:boolean,
		__typename?: boolean
}>;
	["ProviderType"]: AliasType<{
	profile?:ValueTypes["ProfileType"],
	providerInfo?:ValueTypes["ProviderInformationType"],
	qualifications?:ValueTypes["ProviderQualificationType"],
	createdAt?:boolean,
	updatedAt?:boolean,
	id?:boolean,
	email?:boolean,
	isSuper?:boolean,
	isSupport?:boolean,
		__typename?: boolean
}>;
	["ProfileType"]: AliasType<{
	avatarUrl?:boolean,
	gender?:ValueTypes["GenderType"],
	createdAt?:boolean,
	updatedAt?:boolean,
	id?:boolean,
	profileableId?:boolean,
	profileableType?:boolean,
	fName?:boolean,
	lName?:boolean,
	birthDate?:boolean,
	phone?:boolean,
	city?:boolean,
	zip?:boolean,
	address1?:boolean,
	address2?:boolean,
	biography?:boolean,
	avatar?:boolean,
	deletedAt?:boolean,
		__typename?: boolean
}>;
	["GenderType"]: AliasType<{
	createdAt?:boolean,
	updatedAt?:boolean,
	id?:boolean,
	name?:boolean,
	slug?:boolean,
		__typename?: boolean
}>;
	/** Date (isoformat) */
["Date"]:unknown;
	["ProviderInformationType"]: AliasType<{
	createdAt?:boolean,
	updatedAt?:boolean,
	id?:boolean,
	education?:boolean,
	practices?:boolean,
	experienceYears?:boolean,
	shortSummary?:boolean,
	qualificationTitle?:boolean,
		__typename?: boolean
}>;
	["ProviderQualificationType"]: AliasType<{
	qualification?:ValueTypes["QualificationType"],
	id?:boolean,
		__typename?: boolean
}>;
	["QualificationType"]: AliasType<{
	createdAt?:boolean,
	updatedAt?:boolean,
	id?:boolean,
	name?:boolean,
	title?:boolean,
	slug?:boolean,
	type?:boolean,
	icon?:boolean,
	cost?:boolean,
		__typename?: boolean
}>;
	/** Decimal (fixed-point) */
["Decimal"]:unknown;
	["TreatmentPlanTypeConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	items?:ValueTypes["TreatmentPlanType"],
		__typename?: boolean
}>;
	["PageInfo"]: AliasType<{
	hasNextPage?:boolean,
	hasPreviousPage?:boolean,
	currentPage?:boolean,
	perPage?:boolean,
	lastPage?:boolean,
	total?:boolean,
		__typename?: boolean
}>;
	["TreatmentPlanFilterType"]: {
	patientId?:ValueTypes["UUID"] | null,
	status?:ValueTypes["TreatmentPlanStatus"] | null
};
	["TreatmentPlanStatus"]:TreatmentPlanStatus;
	["PatientTaskGqlType"]: AliasType<{
	title?:boolean,
	files?:ValueTypes["TaskFileType"],
	details?:boolean,
	materials?:ValueTypes["TaskMaterialType"],
	createdAt?:boolean,
	updatedAt?:boolean,
	id?:boolean,
	type?:boolean,
	startDate?:boolean,
	endDate?:boolean,
	status?:boolean,
	completedAt?:boolean,
		__typename?: boolean
}>;
	["MedicationContentTypeConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	items?:ValueTypes["MedicationContentType"],
		__typename?: boolean
}>;
	["MedicationContentType"]: AliasType<{
	measure?:ValueTypes["MedicationMeasureType"],
	frequency?:ValueTypes["MedicationFrequencyType"],
	medicationsList?:ValueTypes["MedicationType"],
	duration?:boolean,
	total?:boolean,
	totalProgress?:boolean,
	completedDays?:boolean,
	totalPerDay?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	id?:boolean,
	medication?:boolean,
	icon?:boolean,
	dosage?:boolean,
	note?:boolean,
	startedAt?:boolean,
	endedAt?:boolean,
		__typename?: boolean
}>;
	["MedicationMeasureType"]: AliasType<{
	createdAt?:boolean,
	updatedAt?:boolean,
	id?:boolean,
	measure?:boolean,
		__typename?: boolean
}>;
	["MedicationFrequencyType"]: AliasType<{
	createdAt?:boolean,
	updatedAt?:boolean,
	id?:boolean,
	frequency?:boolean,
	totalPerDay?:boolean,
		__typename?: boolean
}>;
	["MedicationType"]: AliasType<{
	category?:ValueTypes["MedicationCategoryType"],
	patient?:ValueTypes["PatientType"],
	provider?:ValueTypes["ProviderType"],
	createdAt?:boolean,
	updatedAt?:boolean,
	id?:boolean,
		__typename?: boolean
}>;
	["MedicationCategoryType"]: AliasType<{
	createdAt?:boolean,
	updatedAt?:boolean,
	id?:boolean,
	category?:boolean,
		__typename?: boolean
}>;
	["PatientType"]: AliasType<{
	profile?:ValueTypes["ProfileType"],
	createdAt?:boolean,
	updatedAt?:boolean,
	id?:boolean,
	email?:boolean,
	emailVerifiedAt?:boolean,
	medicalRecord?:boolean,
	experianceDone?:boolean,
	trialEndsAt?:boolean,
	termsAgree?:boolean,
	noInsurance?:boolean,
	firstVisitedAt?:boolean,
	lastVisitedAt?:boolean,
	deletedAt?:boolean,
	noPrimaryProvider?:boolean,
		__typename?: boolean
}>;
	["MedicationsFilterType"]: {
	status?:ValueTypes["FilterStatus"] | null
};
	["FilterStatus"]:FilterStatus;
	["MedicationContentDetailType"]: AliasType<{
	measure?:ValueTypes["MedicationMeasureType"],
	frequency?:ValueTypes["MedicationFrequencyType"],
	medicationsList?:ValueTypes["MedicationType"],
	duration?:boolean,
	total?:boolean,
	totalProgress?:boolean,
	completedDays?:boolean,
	totalPerDay?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	id?:boolean,
	medication?:boolean,
	icon?:boolean,
	dosage?:boolean,
	note?:boolean,
	startedAt?:boolean,
	endedAt?:boolean,
	takenToday?:boolean,
		__typename?: boolean
}>;
	["MyHealthMutation"]: AliasType<{
uploadTaskFiles?: [{	files:ValueTypes["Upload"][]},ValueTypes["UploadedFilesType"]],
createTreatmentPlan?: [{	data:ValueTypes["TreatmentPlanInput"]},ValueTypes["TreatmentPlanType"]],
updateTreatmentPlan?: [{	treatmentPlanId:ValueTypes["UUID"],	data:ValueTypes["TreatmentPlanUpdateInput"]},ValueTypes["TreatmentPlanTypeTreatmentIsSentError"]],
deleteTreatmentPlan?: [{	treatmentPlanId:ValueTypes["UUID"]},ValueTypes["TreatmentPlanDeletedTypeTreatmentIsSentError"]],
completeTask?: [{	taskId:ValueTypes["UUID"],	date:ValueTypes["Date"]},boolean],
unCompleteTask?: [{	taskId:ValueTypes["UUID"],	date:ValueTypes["Date"]},boolean],
takeMedication?: [{	medicationId:number,	date:ValueTypes["Date"]},boolean],
unTakeMedication?: [{	medicationId:number,	date:ValueTypes["Date"]},boolean],
		__typename?: boolean
}>;
	["UploadedFilesType"]: AliasType<{
	files?:ValueTypes["UploadFileInfoTypeTooLargeFileFileTypeIsNotSupported"],
		__typename?: boolean
}>;
	["UploadFileInfoTypeTooLargeFileFileTypeIsNotSupported"]: AliasType<{		["...on UploadFileInfoType"] : ValueTypes["UploadFileInfoType"],
		["...on TooLargeFile"] : ValueTypes["TooLargeFile"],
		["...on FileTypeIsNotSupported"] : ValueTypes["FileTypeIsNotSupported"]
		__typename?: boolean
}>;
	["UploadFileInfoType"]: AliasType<{
	fileName?:boolean,
	originalFileName?:boolean,
	fileType?:boolean,
	id?:boolean,
		__typename?: boolean
}>;
	["TooLargeFile"]: AliasType<{
	message?:boolean,
	originalFileName?:boolean,
		__typename?: boolean
}>;
	["FileTypeIsNotSupported"]: AliasType<{
	message?:boolean,
	originalFileName?:boolean,
		__typename?: boolean
}>;
	["Upload"]:unknown;
	["TreatmentPlanInput"]: {
	title:string,
	summary:string,
	patientId:ValueTypes["UUID"],
	status:ValueTypes["TreatmentPlanStatus"],
	tasks:ValueTypes["TaskInput"][]
};
	["TaskInput"]: {
	id?:ValueTypes["UUID"] | null,
	type:ValueTypes["TaskType"],
	startDate?:ValueTypes["Date"] | null,
	endDate?:ValueTypes["Date"] | null,
	diet?:ValueTypes["DietInput"] | null,
	activity?:ValueTypes["ActivityInput"] | null,
	medication?:ValueTypes["MedicationInput"] | null,
	lab?:ValueTypes["LabInput"] | null,
	materials?:ValueTypes["UUID"][],
	files?:ValueTypes["UUID"][]
};
	["TaskType"]:TaskType;
	["DietInput"]: {
	summary:string,
	duration:number,
	description:string,
	type:string
};
	["ActivityInput"]: {
	summary:string,
	duration:number,
	description:string,
	type:string
};
	["MedicationInput"]: {
	categoryId:number,
	startDate?:ValueTypes["Date"] | null,
	endDate?:ValueTypes["Date"] | null,
	name:string,
	measureId:number,
	frequencyId:number,
	quantity:number,
	description?:string | null,
	type:string
};
	["LabInput"]: {
	summary:string
};
	["TreatmentPlanTypeTreatmentIsSentError"]: AliasType<{		["...on TreatmentPlanType"] : ValueTypes["TreatmentPlanType"],
		["...on TreatmentIsSentError"] : ValueTypes["TreatmentIsSentError"]
		__typename?: boolean
}>;
	["TreatmentIsSentError"]: AliasType<{
	message?:boolean,
		__typename?: boolean
}>;
	["TreatmentPlanUpdateInput"]: {
	title?:string | null,
	summary?:string | null,
	status?:ValueTypes["TreatmentPlanStatus"] | null,
	tasks?:ValueTypes["TaskInput"][]
};
	["TreatmentPlanDeletedTypeTreatmentIsSentError"]: AliasType<{		["...on TreatmentPlanDeletedType"] : ValueTypes["TreatmentPlanDeletedType"],
		["...on TreatmentIsSentError"] : ValueTypes["TreatmentIsSentError"]
		__typename?: boolean
}>;
	["TreatmentPlanDeletedType"]: AliasType<{
	deleted?:boolean,
		__typename?: boolean
}>
  }

export type ModelTypes = {
    ["MyHealthQuery"]: {
		getTreatmentPlan:ModelTypes["TreatmentPlanType"],
	getTreatmentPlans:ModelTypes["TreatmentPlanTypeConnection"],
	getTaskFileUrl:string,
	getPatientTasks:ModelTypes["PatientTaskGqlType"][],
	getPatientTasksDates:ModelTypes["Date"][],
	getPatientMedications:ModelTypes["MedicationContentTypeConnection"],
	getMedicationCategories:ModelTypes["MedicationCategoryType"][],
	getMedicationFrequencies:ModelTypes["MedicationFrequencyType"][],
	getMedicationMeasures:ModelTypes["MedicationMeasureType"][],
	getPatientMedication:ModelTypes["MedicationContentDetailType"]
};
	["TreatmentPlanType"]: {
		tasks:ModelTypes["TaskGqlType"][],
	createdBy?:ModelTypes["ProviderType"],
	updatedBy?:ModelTypes["ProviderType"],
	taskAmount:number,
	createdAt?:ModelTypes["DateTime"],
	updatedAt?:ModelTypes["DateTime"],
	id:ModelTypes["UUID"],
	title:string,
	summary:string,
	status:string,
	deletedAt?:ModelTypes["DateTime"],
	sentAt?:ModelTypes["DateTime"]
};
	["TaskGqlType"]: {
		title:string,
	files:ModelTypes["TaskFileType"][],
	details:ModelTypes["JSON"],
	materials:ModelTypes["TaskMaterialType"][],
	createdAt?:ModelTypes["DateTime"],
	updatedAt?:ModelTypes["DateTime"],
	id:ModelTypes["UUID"],
	type:string,
	startDate?:ModelTypes["DateTime"],
	endDate?:ModelTypes["DateTime"],
	status?:string
};
	["TaskFileType"]: {
		id:ModelTypes["UUID"],
	fileName:string,
	objectName:string,
	fileType:string,
	createdAt:ModelTypes["DateTime"],
	uploadFinishedAt?:ModelTypes["DateTime"]
};
	["UUID"]:any;
	/** Date with time (isoformat) */
["DateTime"]:any;
	/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
["JSON"]:any;
	["TaskMaterialType"]: {
		materialVersion:ModelTypes["LibraryMaterialType"],
	id:ModelTypes["UUID"]
};
	["LibraryMaterialType"]: {
		category:ModelTypes["LibraryMaterialCategoryType"],
	createdBy?:ModelTypes["ProviderType"],
	updatedBy?:ModelTypes["ProviderType"],
	createdAt?:ModelTypes["DateTime"],
	updatedAt?:ModelTypes["DateTime"],
	id:ModelTypes["UUID"],
	title:string,
	description:string,
	type:string,
	content:string,
	currentVersion:boolean,
	materialId:ModelTypes["UUID"],
	status:string,
	deletedAt?:ModelTypes["DateTime"]
};
	["LibraryMaterialCategoryType"]: {
		createdAt?:ModelTypes["DateTime"],
	updatedAt?:ModelTypes["DateTime"],
	id:ModelTypes["UUID"],
	slug:string,
	title:string
};
	["ProviderType"]: {
		profile:ModelTypes["ProfileType"],
	providerInfo?:ModelTypes["ProviderInformationType"],
	qualifications?:ModelTypes["ProviderQualificationType"][],
	createdAt?:ModelTypes["DateTime"],
	updatedAt?:ModelTypes["DateTime"],
	id:string,
	email:string,
	isSuper:boolean,
	isSupport:boolean
};
	["ProfileType"]: {
		avatarUrl?:string,
	gender?:ModelTypes["GenderType"],
	createdAt?:ModelTypes["DateTime"],
	updatedAt?:ModelTypes["DateTime"],
	id:number,
	profileableId:string,
	profileableType:string,
	fName?:string,
	lName?:string,
	birthDate?:ModelTypes["Date"],
	phone?:string,
	city?:string,
	zip?:string,
	address1?:string,
	address2?:string,
	biography?:string,
	avatar?:string,
	deletedAt?:ModelTypes["DateTime"]
};
	["GenderType"]: {
		createdAt?:ModelTypes["DateTime"],
	updatedAt?:ModelTypes["DateTime"],
	id:number,
	name:string,
	slug:string
};
	/** Date (isoformat) */
["Date"]:any;
	["ProviderInformationType"]: {
		createdAt?:ModelTypes["DateTime"],
	updatedAt?:ModelTypes["DateTime"],
	id:number,
	education?:string,
	practices?:string,
	experienceYears?:number,
	shortSummary?:string,
	qualificationTitle?:string
};
	["ProviderQualificationType"]: {
		qualification:ModelTypes["QualificationType"],
	id:number
};
	["QualificationType"]: {
		createdAt?:ModelTypes["DateTime"],
	updatedAt?:ModelTypes["DateTime"],
	id:number,
	name:string,
	title:string,
	slug:string,
	type?:string,
	icon?:string,
	cost?:ModelTypes["Decimal"]
};
	/** Decimal (fixed-point) */
["Decimal"]:any;
	["TreatmentPlanTypeConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	items:ModelTypes["TreatmentPlanType"][]
};
	["PageInfo"]: {
		hasNextPage:boolean,
	hasPreviousPage:boolean,
	currentPage:number,
	perPage:number,
	lastPage:number,
	total:number
};
	["TreatmentPlanFilterType"]: GraphQLTypes["TreatmentPlanFilterType"];
	["TreatmentPlanStatus"]: GraphQLTypes["TreatmentPlanStatus"];
	["PatientTaskGqlType"]: {
		title:string,
	files:ModelTypes["TaskFileType"][],
	details:ModelTypes["JSON"],
	materials:ModelTypes["TaskMaterialType"][],
	createdAt?:ModelTypes["DateTime"],
	updatedAt?:ModelTypes["DateTime"],
	id:ModelTypes["UUID"],
	type:string,
	startDate?:ModelTypes["DateTime"],
	endDate?:ModelTypes["DateTime"],
	status?:string,
	completedAt?:ModelTypes["DateTime"]
};
	["MedicationContentTypeConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	items:ModelTypes["MedicationContentType"][]
};
	["MedicationContentType"]: {
		measure:ModelTypes["MedicationMeasureType"],
	frequency:ModelTypes["MedicationFrequencyType"],
	medicationsList?:ModelTypes["MedicationType"],
	duration:number,
	total:ModelTypes["Decimal"],
	totalProgress:ModelTypes["Decimal"],
	completedDays:number,
	totalPerDay:ModelTypes["Decimal"],
	createdAt?:ModelTypes["DateTime"],
	updatedAt?:ModelTypes["DateTime"],
	id:number,
	medication:string,
	icon?:string,
	dosage:ModelTypes["Decimal"],
	note?:string,
	startedAt:ModelTypes["DateTime"],
	endedAt:ModelTypes["DateTime"]
};
	["MedicationMeasureType"]: {
		createdAt?:ModelTypes["DateTime"],
	updatedAt?:ModelTypes["DateTime"],
	id:number,
	measure:string
};
	["MedicationFrequencyType"]: {
		createdAt?:ModelTypes["DateTime"],
	updatedAt?:ModelTypes["DateTime"],
	id:number,
	frequency:string,
	totalPerDay:number
};
	["MedicationType"]: {
		category:ModelTypes["MedicationCategoryType"],
	patient:ModelTypes["PatientType"],
	provider?:ModelTypes["ProviderType"],
	createdAt?:ModelTypes["DateTime"],
	updatedAt?:ModelTypes["DateTime"],
	id:number
};
	["MedicationCategoryType"]: {
		createdAt?:ModelTypes["DateTime"],
	updatedAt?:ModelTypes["DateTime"],
	id:number,
	category:string
};
	["PatientType"]: {
		profile:ModelTypes["ProfileType"],
	createdAt?:ModelTypes["DateTime"],
	updatedAt?:ModelTypes["DateTime"],
	id:string,
	email:string,
	emailVerifiedAt?:ModelTypes["DateTime"],
	medicalRecord?:string,
	experianceDone:boolean,
	trialEndsAt?:ModelTypes["DateTime"],
	termsAgree:boolean,
	noInsurance:boolean,
	firstVisitedAt?:ModelTypes["DateTime"],
	lastVisitedAt?:ModelTypes["DateTime"],
	deletedAt?:ModelTypes["DateTime"],
	noPrimaryProvider:boolean
};
	["MedicationsFilterType"]: GraphQLTypes["MedicationsFilterType"];
	["FilterStatus"]: GraphQLTypes["FilterStatus"];
	["MedicationContentDetailType"]: {
		measure:ModelTypes["MedicationMeasureType"],
	frequency:ModelTypes["MedicationFrequencyType"],
	medicationsList?:ModelTypes["MedicationType"],
	duration:number,
	total:ModelTypes["Decimal"],
	totalProgress:ModelTypes["Decimal"],
	completedDays:number,
	totalPerDay:ModelTypes["Decimal"],
	createdAt?:ModelTypes["DateTime"],
	updatedAt?:ModelTypes["DateTime"],
	id:number,
	medication:string,
	icon?:string,
	dosage:ModelTypes["Decimal"],
	note?:string,
	startedAt:ModelTypes["DateTime"],
	endedAt:ModelTypes["DateTime"],
	takenToday:ModelTypes["Decimal"]
};
	["MyHealthMutation"]: {
		uploadTaskFiles:ModelTypes["UploadedFilesType"],
	createTreatmentPlan:ModelTypes["TreatmentPlanType"],
	updateTreatmentPlan:ModelTypes["TreatmentPlanTypeTreatmentIsSentError"],
	deleteTreatmentPlan:ModelTypes["TreatmentPlanDeletedTypeTreatmentIsSentError"],
	completeTask:boolean,
	unCompleteTask:boolean,
	takeMedication:ModelTypes["Decimal"],
	unTakeMedication:ModelTypes["Decimal"]
};
	["UploadedFilesType"]: {
		files:ModelTypes["UploadFileInfoTypeTooLargeFileFileTypeIsNotSupported"][]
};
	["UploadFileInfoTypeTooLargeFileFileTypeIsNotSupported"]:ModelTypes["UploadFileInfoType"] | ModelTypes["TooLargeFile"] | ModelTypes["FileTypeIsNotSupported"];
	["UploadFileInfoType"]: {
		fileName?:string,
	originalFileName?:string,
	fileType?:string,
	id?:ModelTypes["UUID"]
};
	["TooLargeFile"]: {
		message:string,
	originalFileName?:string
};
	["FileTypeIsNotSupported"]: {
		message:string,
	originalFileName?:string
};
	["Upload"]:any;
	["TreatmentPlanInput"]: GraphQLTypes["TreatmentPlanInput"];
	["TaskInput"]: GraphQLTypes["TaskInput"];
	["TaskType"]: GraphQLTypes["TaskType"];
	["DietInput"]: GraphQLTypes["DietInput"];
	["ActivityInput"]: GraphQLTypes["ActivityInput"];
	["MedicationInput"]: GraphQLTypes["MedicationInput"];
	["LabInput"]: GraphQLTypes["LabInput"];
	["TreatmentPlanTypeTreatmentIsSentError"]:ModelTypes["TreatmentPlanType"] | ModelTypes["TreatmentIsSentError"];
	["TreatmentIsSentError"]: {
		message:string
};
	["TreatmentPlanUpdateInput"]: GraphQLTypes["TreatmentPlanUpdateInput"];
	["TreatmentPlanDeletedTypeTreatmentIsSentError"]:ModelTypes["TreatmentPlanDeletedType"] | ModelTypes["TreatmentIsSentError"];
	["TreatmentPlanDeletedType"]: {
		deleted:boolean
}
    }

export type GraphQLTypes = {
    ["MyHealthQuery"]: {
	__typename: "MyHealthQuery",
	getTreatmentPlan: GraphQLTypes["TreatmentPlanType"],
	getTreatmentPlans: GraphQLTypes["TreatmentPlanTypeConnection"],
	getTaskFileUrl: string,
	getPatientTasks: Array<GraphQLTypes["PatientTaskGqlType"]>,
	getPatientTasksDates: Array<GraphQLTypes["Date"]>,
	getPatientMedications: GraphQLTypes["MedicationContentTypeConnection"],
	getMedicationCategories: Array<GraphQLTypes["MedicationCategoryType"]>,
	getMedicationFrequencies: Array<GraphQLTypes["MedicationFrequencyType"]>,
	getMedicationMeasures: Array<GraphQLTypes["MedicationMeasureType"]>,
	getPatientMedication: GraphQLTypes["MedicationContentDetailType"]
};
	["TreatmentPlanType"]: {
	__typename: "TreatmentPlanType",
	tasks: Array<GraphQLTypes["TaskGqlType"]>,
	createdBy?: GraphQLTypes["ProviderType"],
	updatedBy?: GraphQLTypes["ProviderType"],
	taskAmount: number,
	createdAt?: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"],
	id: GraphQLTypes["UUID"],
	title: string,
	summary: string,
	status: string,
	deletedAt?: GraphQLTypes["DateTime"],
	sentAt?: GraphQLTypes["DateTime"]
};
	["TaskGqlType"]: {
	__typename: "TaskGqlType",
	title: string,
	files: Array<GraphQLTypes["TaskFileType"]>,
	details: GraphQLTypes["JSON"],
	materials: Array<GraphQLTypes["TaskMaterialType"]>,
	createdAt?: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"],
	id: GraphQLTypes["UUID"],
	type: string,
	startDate?: GraphQLTypes["DateTime"],
	endDate?: GraphQLTypes["DateTime"],
	status?: string
};
	["TaskFileType"]: {
	__typename: "TaskFileType",
	id: GraphQLTypes["UUID"],
	fileName: string,
	objectName: string,
	fileType: string,
	createdAt: GraphQLTypes["DateTime"],
	uploadFinishedAt?: GraphQLTypes["DateTime"]
};
	["UUID"]:any;
	/** Date with time (isoformat) */
["DateTime"]:any;
	/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
["JSON"]:any;
	["TaskMaterialType"]: {
	__typename: "TaskMaterialType",
	materialVersion: GraphQLTypes["LibraryMaterialType"],
	id: GraphQLTypes["UUID"]
};
	["LibraryMaterialType"]: {
	__typename: "LibraryMaterialType",
	category: GraphQLTypes["LibraryMaterialCategoryType"],
	createdBy?: GraphQLTypes["ProviderType"],
	updatedBy?: GraphQLTypes["ProviderType"],
	createdAt?: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"],
	id: GraphQLTypes["UUID"],
	title: string,
	description: string,
	type: string,
	content: string,
	currentVersion: boolean,
	materialId: GraphQLTypes["UUID"],
	status: string,
	deletedAt?: GraphQLTypes["DateTime"]
};
	["LibraryMaterialCategoryType"]: {
	__typename: "LibraryMaterialCategoryType",
	createdAt?: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"],
	id: GraphQLTypes["UUID"],
	slug: string,
	title: string
};
	["ProviderType"]: {
	__typename: "ProviderType",
	profile: GraphQLTypes["ProfileType"],
	providerInfo?: GraphQLTypes["ProviderInformationType"],
	qualifications?: Array<GraphQLTypes["ProviderQualificationType"]>,
	createdAt?: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"],
	id: string,
	email: string,
	isSuper: boolean,
	isSupport: boolean
};
	["ProfileType"]: {
	__typename: "ProfileType",
	avatarUrl?: string,
	gender?: GraphQLTypes["GenderType"],
	createdAt?: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"],
	id: number,
	profileableId: string,
	profileableType: string,
	fName?: string,
	lName?: string,
	birthDate?: GraphQLTypes["Date"],
	phone?: string,
	city?: string,
	zip?: string,
	address1?: string,
	address2?: string,
	biography?: string,
	avatar?: string,
	deletedAt?: GraphQLTypes["DateTime"]
};
	["GenderType"]: {
	__typename: "GenderType",
	createdAt?: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"],
	id: number,
	name: string,
	slug: string
};
	/** Date (isoformat) */
["Date"]:any;
	["ProviderInformationType"]: {
	__typename: "ProviderInformationType",
	createdAt?: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"],
	id: number,
	education?: string,
	practices?: string,
	experienceYears?: number,
	shortSummary?: string,
	qualificationTitle?: string
};
	["ProviderQualificationType"]: {
	__typename: "ProviderQualificationType",
	qualification: GraphQLTypes["QualificationType"],
	id: number
};
	["QualificationType"]: {
	__typename: "QualificationType",
	createdAt?: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"],
	id: number,
	name: string,
	title: string,
	slug: string,
	type?: string,
	icon?: string,
	cost?: GraphQLTypes["Decimal"]
};
	/** Decimal (fixed-point) */
["Decimal"]:any;
	["TreatmentPlanTypeConnection"]: {
	__typename: "TreatmentPlanTypeConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	items: Array<GraphQLTypes["TreatmentPlanType"]>
};
	["PageInfo"]: {
	__typename: "PageInfo",
	hasNextPage: boolean,
	hasPreviousPage: boolean,
	currentPage: number,
	perPage: number,
	lastPage: number,
	total: number
};
	["TreatmentPlanFilterType"]: {
		patientId?: GraphQLTypes["UUID"],
	status?: GraphQLTypes["TreatmentPlanStatus"]
};
	["TreatmentPlanStatus"]: TreatmentPlanStatus;
	["PatientTaskGqlType"]: {
	__typename: "PatientTaskGqlType",
	title: string,
	files: Array<GraphQLTypes["TaskFileType"]>,
	details: GraphQLTypes["JSON"],
	materials: Array<GraphQLTypes["TaskMaterialType"]>,
	createdAt?: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"],
	id: GraphQLTypes["UUID"],
	type: string,
	startDate?: GraphQLTypes["DateTime"],
	endDate?: GraphQLTypes["DateTime"],
	status?: string,
	completedAt?: GraphQLTypes["DateTime"]
};
	["MedicationContentTypeConnection"]: {
	__typename: "MedicationContentTypeConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	items: Array<GraphQLTypes["MedicationContentType"]>
};
	["MedicationContentType"]: {
	__typename: "MedicationContentType",
	measure: GraphQLTypes["MedicationMeasureType"],
	frequency: GraphQLTypes["MedicationFrequencyType"],
	medicationsList?: GraphQLTypes["MedicationType"],
	duration: number,
	total: GraphQLTypes["Decimal"],
	totalProgress: GraphQLTypes["Decimal"],
	completedDays: number,
	totalPerDay: GraphQLTypes["Decimal"],
	createdAt?: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"],
	id: number,
	medication: string,
	icon?: string,
	dosage: GraphQLTypes["Decimal"],
	note?: string,
	startedAt: GraphQLTypes["DateTime"],
	endedAt: GraphQLTypes["DateTime"]
};
	["MedicationMeasureType"]: {
	__typename: "MedicationMeasureType",
	createdAt?: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"],
	id: number,
	measure: string
};
	["MedicationFrequencyType"]: {
	__typename: "MedicationFrequencyType",
	createdAt?: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"],
	id: number,
	frequency: string,
	totalPerDay: number
};
	["MedicationType"]: {
	__typename: "MedicationType",
	category: GraphQLTypes["MedicationCategoryType"],
	patient: GraphQLTypes["PatientType"],
	provider?: GraphQLTypes["ProviderType"],
	createdAt?: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"],
	id: number
};
	["MedicationCategoryType"]: {
	__typename: "MedicationCategoryType",
	createdAt?: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"],
	id: number,
	category: string
};
	["PatientType"]: {
	__typename: "PatientType",
	profile: GraphQLTypes["ProfileType"],
	createdAt?: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"],
	id: string,
	email: string,
	emailVerifiedAt?: GraphQLTypes["DateTime"],
	medicalRecord?: string,
	experianceDone: boolean,
	trialEndsAt?: GraphQLTypes["DateTime"],
	termsAgree: boolean,
	noInsurance: boolean,
	firstVisitedAt?: GraphQLTypes["DateTime"],
	lastVisitedAt?: GraphQLTypes["DateTime"],
	deletedAt?: GraphQLTypes["DateTime"],
	noPrimaryProvider: boolean
};
	["MedicationsFilterType"]: {
		status?: GraphQLTypes["FilterStatus"]
};
	["FilterStatus"]: FilterStatus;
	["MedicationContentDetailType"]: {
	__typename: "MedicationContentDetailType",
	measure: GraphQLTypes["MedicationMeasureType"],
	frequency: GraphQLTypes["MedicationFrequencyType"],
	medicationsList?: GraphQLTypes["MedicationType"],
	duration: number,
	total: GraphQLTypes["Decimal"],
	totalProgress: GraphQLTypes["Decimal"],
	completedDays: number,
	totalPerDay: GraphQLTypes["Decimal"],
	createdAt?: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"],
	id: number,
	medication: string,
	icon?: string,
	dosage: GraphQLTypes["Decimal"],
	note?: string,
	startedAt: GraphQLTypes["DateTime"],
	endedAt: GraphQLTypes["DateTime"],
	takenToday: GraphQLTypes["Decimal"]
};
	["MyHealthMutation"]: {
	__typename: "MyHealthMutation",
	uploadTaskFiles: GraphQLTypes["UploadedFilesType"],
	createTreatmentPlan: GraphQLTypes["TreatmentPlanType"],
	updateTreatmentPlan: GraphQLTypes["TreatmentPlanTypeTreatmentIsSentError"],
	deleteTreatmentPlan: GraphQLTypes["TreatmentPlanDeletedTypeTreatmentIsSentError"],
	completeTask: boolean,
	unCompleteTask: boolean,
	takeMedication: GraphQLTypes["Decimal"],
	unTakeMedication: GraphQLTypes["Decimal"]
};
	["UploadedFilesType"]: {
	__typename: "UploadedFilesType",
	files: Array<GraphQLTypes["UploadFileInfoTypeTooLargeFileFileTypeIsNotSupported"]>
};
	["UploadFileInfoTypeTooLargeFileFileTypeIsNotSupported"]:{
	__typename:"UploadFileInfoType" | "TooLargeFile" | "FileTypeIsNotSupported"
	['...on UploadFileInfoType']: '__union' & GraphQLTypes["UploadFileInfoType"];
	['...on TooLargeFile']: '__union' & GraphQLTypes["TooLargeFile"];
	['...on FileTypeIsNotSupported']: '__union' & GraphQLTypes["FileTypeIsNotSupported"];
};
	["UploadFileInfoType"]: {
	__typename: "UploadFileInfoType",
	fileName?: string,
	originalFileName?: string,
	fileType?: string,
	id?: GraphQLTypes["UUID"]
};
	["TooLargeFile"]: {
	__typename: "TooLargeFile",
	message: string,
	originalFileName?: string
};
	["FileTypeIsNotSupported"]: {
	__typename: "FileTypeIsNotSupported",
	message: string,
	originalFileName?: string
};
	["Upload"]:any;
	["TreatmentPlanInput"]: {
		title: string,
	summary: string,
	patientId: GraphQLTypes["UUID"],
	status: GraphQLTypes["TreatmentPlanStatus"],
	tasks: Array<GraphQLTypes["TaskInput"]>
};
	["TaskInput"]: {
		id?: GraphQLTypes["UUID"],
	type: GraphQLTypes["TaskType"],
	startDate?: GraphQLTypes["Date"],
	endDate?: GraphQLTypes["Date"],
	diet?: GraphQLTypes["DietInput"],
	activity?: GraphQLTypes["ActivityInput"],
	medication?: GraphQLTypes["MedicationInput"],
	lab?: GraphQLTypes["LabInput"],
	materials?: Array<GraphQLTypes["UUID"]>,
	files?: Array<GraphQLTypes["UUID"]>
};
	["TaskType"]: TaskType;
	["DietInput"]: {
		summary: string,
	duration: number,
	description: string,
	type: string
};
	["ActivityInput"]: {
		summary: string,
	duration: number,
	description: string,
	type: string
};
	["MedicationInput"]: {
		categoryId: number,
	startDate?: GraphQLTypes["Date"],
	endDate?: GraphQLTypes["Date"],
	name: string,
	measureId: number,
	frequencyId: number,
	quantity: number,
	description?: string,
	type: string
};
	["LabInput"]: {
		summary: string
};
	["TreatmentPlanTypeTreatmentIsSentError"]:{
	__typename:"TreatmentPlanType" | "TreatmentIsSentError"
	['...on TreatmentPlanType']: '__union' & GraphQLTypes["TreatmentPlanType"];
	['...on TreatmentIsSentError']: '__union' & GraphQLTypes["TreatmentIsSentError"];
};
	["TreatmentIsSentError"]: {
	__typename: "TreatmentIsSentError",
	message: string
};
	["TreatmentPlanUpdateInput"]: {
		title?: string,
	summary?: string,
	status?: GraphQLTypes["TreatmentPlanStatus"],
	tasks?: Array<GraphQLTypes["TaskInput"]>
};
	["TreatmentPlanDeletedTypeTreatmentIsSentError"]:{
	__typename:"TreatmentPlanDeletedType" | "TreatmentIsSentError"
	['...on TreatmentPlanDeletedType']: '__union' & GraphQLTypes["TreatmentPlanDeletedType"];
	['...on TreatmentIsSentError']: '__union' & GraphQLTypes["TreatmentIsSentError"];
};
	["TreatmentPlanDeletedType"]: {
	__typename: "TreatmentPlanDeletedType",
	deleted: boolean
}
    }
export const enum TreatmentPlanStatus {
	draft = "draft",
	sent = "sent"
}
export const enum FilterStatus {
	ongoing = "ongoing",
	finished = "finished"
}
export const enum TaskType {
	medications = "medications",
	diet = "diet",
	activity = "activity",
	labs = "labs"
}
export class GraphQLError extends Error {
    constructor(public response: GraphQLResponse) {
      super("");
      console.error(response);
    }
    toString() {
      return "GraphQL Response Error";
    }
  }


export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<
  UnwrapPromise<ReturnType<T>>
>;
export type ZeusHook<
  T extends (
    ...args: any[]
  ) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>
> = ZeusState<ReturnType<T>[N]>;

type WithTypeNameValue<T> = T & {
  __typename?: boolean;
};
type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};
export interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}
type DeepAnify<T> = {
  [P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
type IsArray<T, U> = T extends Array<infer R> ? InputType<R, U>[] : InputType<T, U>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;

type IsInterfaced<SRC extends DeepAnify<DST>, DST> = FlattenArray<SRC> extends ZEUS_INTERFACES | ZEUS_UNIONS
  ? {
      [P in keyof SRC]: SRC[P] extends '__union' & infer R
        ? P extends keyof DST
          ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P]>
          : {}
        : never;
    }[keyof DST] &
      {
        [P in keyof Omit<
          Pick<
            SRC,
            {
              [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P;
            }[keyof DST]
          >,
          '__typename'
        >]: IsPayLoad<DST[P]> extends boolean ? SRC[P] : IsArray<SRC[P], DST[P]>;
      }
  : {
      [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends boolean ? SRC[P] : IsArray<SRC[P], DST[P]>;
    };

export type MapType<SRC, DST> = SRC extends DeepAnify<DST> ? IsInterfaced<SRC, DST> : never;
export type InputType<SRC, DST> = IsPayLoad<DST> extends { __alias: infer R }
  ? {
      [P in keyof R]: MapType<SRC, R[P]>;
    } &
      MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>>
  : MapType<SRC, IsPayLoad<DST>>;
type Func<P extends any[], R> = (...args: P) => R;
type AnyFunc = Func<any, any>;
export type ArgsType<F extends AnyFunc> = F extends Func<infer P, any> ? P : never;
export type OperationOptions = {
  variables?: Record<string, any>;
  operationName?: string;
};
export type SubscriptionToGraphQL<Z, T> = {
  ws: WebSocket;
  on: (fn: (args: InputType<T, Z>) => void) => void;
  off: (fn: (e: { data?: InputType<T, Z>; code?: number; reason?: string; message?: string }) => void) => void;
  error: (fn: (e: { data?: InputType<T, Z>; errors?: string[] }) => void) => void;
  open: () => void;
};
export type SelectionFunction<V> = <T>(t: T | V) => T;
export type fetchOptions = ArgsType<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (
  ...args: infer R
) => WebSocket
  ? R
  : never;
export type chainOptions =
  | [fetchOptions[0], fetchOptions[1] & {websocket?: websocketOptions}]
  | [fetchOptions[0]];
export type FetchFunction = (
  query: string,
  variables?: Record<string, any>,
) => Promise<any>;
export type SubscriptionFunction = (query: string) => any;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;



export const ZeusSelect = <T>() => ((t: any) => t) as SelectionFunction<T>;

export const ScalarResolver = (scalar: string, value: any) => {
  switch (scalar) {
    case 'String':
      return  `${JSON.stringify(value)}`;
    case 'Int':
      return `${value}`;
    case 'Float':
      return `${value}`;
    case 'Boolean':
      return `${value}`;
    case 'ID':
      return `"${value}"`;
    case 'enum':
      return `${value}`;
    case 'scalar':
      return `${value}`;
    default:
      return false;
  }
};


export const TypesPropsResolver = ({
    value,
    type,
    name,
    key,
    blockArrays
}: {
    value: any;
    type: string;
    name: string;
    key?: string;
    blockArrays?: boolean;
}): string => {
    if (value === null) {
        return `null`;
    }
    let resolvedValue = AllTypesProps[type][name];
    if (key) {
        resolvedValue = resolvedValue[key];
    }
    if (!resolvedValue) {
        throw new Error(`Cannot resolve ${type} ${name}${key ? ` ${key}` : ''}`)
    }
    const typeResolved = resolvedValue.type;
    const isArray = resolvedValue.array;
    const isArrayRequired = resolvedValue.arrayRequired;
    if (typeof value === 'string' && value.startsWith(`ZEUS_VAR$`)) {
        const isRequired = resolvedValue.required ? '!' : '';
        let t = `${typeResolved}`;
        if (isArray) {
          if (isRequired) {
              t = `${t}!`;
          }
          t = `[${t}]`;
          if(isArrayRequired){
            t = `${t}!`;
          }
        }else{
          if (isRequired) {
                t = `${t}!`;
          }
        }
        return `\$${value.split(`ZEUS_VAR$`)[1]}__ZEUS_VAR__${t}`;
    }
    if (isArray && !blockArrays) {
        return `[${value
        .map((v: any) => TypesPropsResolver({ value: v, type, name, key, blockArrays: true }))
        .join(',')}]`;
    }
    const reslovedScalar = ScalarResolver(typeResolved, value);
    if (!reslovedScalar) {
        const resolvedType = AllTypesProps[typeResolved];
        if (typeof resolvedType === 'object') {
        const argsKeys = Object.keys(resolvedType);
        return `{${argsKeys
            .filter((ak) => value[ak] !== undefined)
            .map(
            (ak) => `${ak}:${TypesPropsResolver({ value: value[ak], type: typeResolved, name: ak })}`
            )}}`;
        }
        return ScalarResolver(AllTypesProps[typeResolved], value) as string;
    }
    return reslovedScalar;
};


const isArrayFunction = (
  parent: string[],
  a: any[]
) => {
  const [values, r] = a;
  const [mainKey, key, ...keys] = parent;
  const keyValues = Object.keys(values).filter((k) => typeof values[k] !== 'undefined');

  if (!keys.length) {
      return keyValues.length > 0
        ? `(${keyValues
            .map(
              (v) =>
                `${v}:${TypesPropsResolver({
                  value: values[v],
                  type: mainKey,
                  name: key,
                  key: v
                })}`
            )
            .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
        : traverseToSeekArrays(parent, r);
    }

  const [typeResolverKey] = keys.splice(keys.length - 1, 1);
  let valueToResolve = ReturnTypes[mainKey][key];
  for (const k of keys) {
    valueToResolve = ReturnTypes[valueToResolve][k];
  }

  const argumentString =
    keyValues.length > 0
      ? `(${keyValues
          .map(
            (v) =>
              `${v}:${TypesPropsResolver({
                value: values[v],
                type: valueToResolve,
                name: typeResolverKey,
                key: v
              })}`
          )
          .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
      : traverseToSeekArrays(parent, r);
  return argumentString;
};


const resolveKV = (k: string, v: boolean | string | { [x: string]: boolean | string }) =>
  typeof v === 'boolean' ? k : typeof v === 'object' ? `${k}{${objectToTree(v)}}` : `${k}${v}`;


const objectToTree = (o: { [x: string]: boolean | string }): string =>
  `{${Object.keys(o).map((k) => `${resolveKV(k, o[k])}`).join(' ')}}`;


const traverseToSeekArrays = (parent: string[], a?: any): string => {
  if (!a) return '';
  if (Object.keys(a).length === 0) {
    return '';
  }
  let b: Record<string, any> = {};
  if (Array.isArray(a)) {
    return isArrayFunction([...parent], a);
  } else {
    if (typeof a === 'object') {
      Object.keys(a)
        .filter((k) => typeof a[k] !== 'undefined')
        .forEach((k) => {
        if (k === '__alias') {
          Object.keys(a[k]).forEach((aliasKey) => {
            const aliasOperations = a[k][aliasKey];
            const aliasOperationName = Object.keys(aliasOperations)[0];
            const aliasOperation = aliasOperations[aliasOperationName];
            b[
              `${aliasOperationName}__alias__${aliasKey}: ${aliasOperationName}`
            ] = traverseToSeekArrays([...parent, aliasOperationName], aliasOperation);
          });
        } else {
          b[k] = traverseToSeekArrays([...parent, k], a[k]);
        }
      });
    } else {
      return '';
    }
  }
  return objectToTree(b);
};  


const buildQuery = (type: string, a?: Record<any, any>) => 
  traverseToSeekArrays([type], a);


const inspectVariables = (query: string) => {
  const regex = /\$\b\w*__ZEUS_VAR__\[?[^!^\]^\s^,^\)^\}]*[!]?[\]]?[!]?/g;
  let result;
  const AllVariables: string[] = [];
  while ((result = regex.exec(query))) {
    if (AllVariables.includes(result[0])) {
      continue;
    }
    AllVariables.push(result[0]);
  }
  if (!AllVariables.length) {
    return query;
  }
  let filteredQuery = query;
  AllVariables.forEach((variable) => {
    while (filteredQuery.includes(variable)) {
      filteredQuery = filteredQuery.replace(variable, variable.split('__ZEUS_VAR__')[0]);
    }
  });
  return `(${AllVariables.map((a) => a.split('__ZEUS_VAR__'))
    .map(([variableName, variableType]) => `${variableName}:${variableType}`)
    .join(', ')})${filteredQuery}`;
};


export const queryConstruct = (t: 'query' | 'mutation' | 'subscription', tName: string, operationName?: string) => (o: Record<any, any>) =>
  `${t.toLowerCase()}${operationName ? ' ' + operationName : ''}${inspectVariables(buildQuery(tName, o))}`;
  

export const fullChainConstruct = (fn: FetchFunction) => (t: 'query' | 'mutation' | 'subscription', tName: string) => (
  o: Record<any, any>,
  options?: OperationOptions,
) => fn(queryConstruct(t, tName, options?.operationName)(o), options?.variables).then((r:any) => { 
  seekForAliases(r)
  return r
});


export const fullSubscriptionConstruct = (fn: SubscriptionFunction) => (
  t: 'query' | 'mutation' | 'subscription',
  tName: string,
) => (o: Record<any, any>, options?: OperationOptions) =>
  fn(queryConstruct(t, tName, options?.operationName)(o));


const seekForAliases = (response: any) => {
  const traverseAlias = (value: any) => {
    if (Array.isArray(value)) {
      value.forEach(seekForAliases);
    } else {
      if (typeof value === 'object') {
        seekForAliases(value);
      }
    }
  };
  if (typeof response === 'object' && response) {
    const keys = Object.keys(response);
    if (keys.length < 1) {
      return;
    }
    keys.forEach((k) => {
      const value = response[k];
      if (k.indexOf('__alias__') !== -1) {
        const [operation, alias] = k.split('__alias__');
        response[alias] = {
          [operation]: value,
        };
        delete response[k];
      }
      traverseAlias(value);
    });
  }
};


export const $ = (t: TemplateStringsArray): any => `ZEUS_VAR$${t.join('')}`;


export const resolverFor = <
  X,
  T extends keyof ValueTypes,
  Z extends keyof ValueTypes[T],
>(
  type: T,
  field: Z,
  fn: (
    args: Required<ValueTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : any,
) => fn as (args?: any,source?: any) => any;


const handleFetchResponse = (
  response: Parameters<Extract<Parameters<ReturnType<typeof fetch>['then']>[0], Function>>[0]
): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response.text().then(text => {
        try { reject(JSON.parse(text)); }
        catch (err) { reject(text); }
      }).catch(reject);
    });
  }
  return response.json();
};

export const apiFetch = (options: fetchOptions) => (query: string, variables: Record<string, any> = {}) => {
    let fetchFunction = fetch;
    let queryString = query;
    let fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      queryString = encodeURIComponent(query);
      return fetchFunction(`${options[0]}?query=${queryString}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetchFunction(`${options[0]}`, {
      body: JSON.stringify({ query: queryString, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      ...fetchOptions
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };
  

export const apiSubscription = (options: chainOptions) => (
    query: string,
  ) => {
    try {
      const queryString = options[0] + '?query=' + encodeURIComponent(query);
      const wsString = queryString.replace('http', 'ws');
      const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString;
      const webSocketOptions = options[1]?.websocket || [host];
      const ws = new WebSocket(...webSocketOptions);
      return {
        ws,
        on: (e: (args: any) => void) => {
          ws.onmessage = (event:any) => {
            if(event.data){
              const parsed = JSON.parse(event.data)
              const data = parsed.data
              if (data) {
                seekForAliases(data);
              }
              return e(data);
            }
          };
        },
        off: (e: (args: any) => void) => {
          ws.onclose = e;
        },
        error: (e: (args: any) => void) => {
          ws.onerror = e;
        },
        open: (e: () => void) => {
          ws.onopen = e;
        },
      };
    } catch {
      throw new Error('No websockets implemented');
    }
  };



const allOperations = {
    "query": "MyHealthQuery",
    "mutation": "MyHealthMutation"
}

export type GenericOperation<O> = O extends 'query'
  ? "MyHealthQuery"
  : O extends 'mutation'
  ? "MyHealthMutation"
  : never

export const Thunder = (fn: FetchFunction) => <
  O extends 'query' | 'mutation',
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
) => <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions) =>
  fullChainConstruct(fn)(operation, allOperations[operation])(o as any, ops) as Promise<InputType<GraphQLTypes[R], Z>>;

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options));  
  
export const SubscriptionThunder = (fn: SubscriptionFunction) => <
  O extends 'query' | 'mutation',
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
) => <Z extends ValueTypes[R]>(
  o: Z | ValueTypes[R],
  ops?: OperationOptions
)=>
  fullSubscriptionConstruct(fn)(operation, allOperations[operation])(
    o as any,
    ops,
  ) as SubscriptionToGraphQL<Z, GraphQLTypes[R]>;

export const Subscription = (...options: chainOptions) => SubscriptionThunder(apiSubscription(options));
export const Zeus = <
  Z extends ValueTypes[R],
  O extends 'query' | 'mutation',
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
  o: Z | ValueTypes[R],
  operationName?: string,
) => queryConstruct(operation, allOperations[operation], operationName)(o as any);
export const Selector = <T extends keyof ValueTypes>(key: T) => ZeusSelect<ValueTypes[T]>();
  

export const Gql = Chain('https://patient-api-dev.cibahealth.com/my-health/graphql')