import React from 'react'
import { useParams } from 'react-router-dom'

import { useModalHandler } from 'hooks/useUtils'

import { useSetPatientHealthPlan } from 'hooks/usePatients'
import { useFetchPatinetHealthPlans } from 'requests/patients'

import LinkButton from 'common/LinkButton'
import request from 'utils/request'
import createNotification from 'utils/createNotification'
import ConfirmationModal from 'common/ConfirmationModal'

export default React.memo(function DeleteHealthPlanButton ({healthPlanID}) {
  const { patientId } = useParams()
  const fetchPatinetHealthPlans = useFetchPatinetHealthPlans()
  const clearPatientHealthPlan = useSetPatientHealthPlan(() => null, [])

  const [isOpen, openModal, closeModal] = useModalHandler()
  const deleteHealthPlan = React.useCallback(() => {
    request({
      method: 'delete',
      url: `/health-plan/${healthPlanID}`
    })
      .then(() => {
        fetchPatinetHealthPlans()
        createNotification({ message: 'Document successfully deleted', type: 'success' })
      })
      .catch((error) => console.log(error))
      .finally(closeModal)
  }, [patientId, clearPatientHealthPlan, closeModal, fetchPatinetHealthPlans])

  return (
    <>
      <LinkButton
        className='delete view-button'
        onClick={openModal}
      >
        Delete
      </LinkButton>
      {isOpen && (
        <ConfirmationModal
          closeModal={closeModal}
          confirmHandler={deleteHealthPlan}
        />
      )}
    </>
  )
})


