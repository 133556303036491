import React from "react";

export default React.memo(function StopStreamListener({
  currentStream,
  videoRef,
}) {
  React.useEffect(() => {
    // handle turn of camero on willUnmount

    return () => {
      if (!currentStream.current) return;
      currentStream.current.getTracks().forEach((track) => {
        track.stop();
      });
    };
  }, [currentStream]);
  return null;
});
