import React from 'react'

import { RegularMediumText } from 'common/texts'

export default React.memo(function EmptyDocuments () {
  return (
    <RegularMediumText>
      Patient currently do not have any documents.
    </RegularMediumText>
  )
})
