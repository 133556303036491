import React from 'react'
import moment from 'moment'
import get from 'lodash/get'

import { useMedicationsTab, useChartOffsets } from 'hooks/patient/useMedications'

import Grid from '../grid'
import GridLabels from './GridLabels'

export default React.memo(function WeekView ({ chartEl }) {
  const weekStart = useMedicationsTab(current => moment(get(current, 'from')))
  const config = React.useMemo(() => {
    return (new Array(7)).fill().map((item, i) => {
      return weekStart.clone().add(i, 'days')
    })
  }, [weekStart])

  const chartOffsets = useChartOffsets(chartEl)

  return (
    <div className='g-flex-grow-1'>
      <Grid config={config} chartOffsets={chartOffsets} />
      <GridLabels config={config} />
    </div>
  )
})
