import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import get from "lodash/get";

import { APPOINTMENTS, CREATE, CONFIGURATE } from "constants/routes";  

import { globalContext, useUser } from "hooks/useGlobalState";
import {
  useAppointmentCreate,
  useSetAppointmentCreate,
} from "hooks/useAppointments";

import LinkButton from "common/LinkButton";
import PatientSelect from "./PatientSelect";
import ProviderSelect from "./ProviderSelect";
import CustomCheckbox from "../../../../common/CustomCheckbox";

export default React.memo(function AppointmentsFilters() {
  const { appointmentCreate } = React.useContext(globalContext);
  const user = useUser();
  const [isAnotherProvider, setIsAnotherProvider] = React.useState(false);
  const history = useHistory();
  const patient = useAppointmentCreate((current) => get(current, "patient"));
  const provider = useAppointmentCreate((current) => get(current, "provider"));
  const setProvider = useSetAppointmentCreate((prev, provider) => ({
    ...prev,
    provider,
  }));
  const clearAppointmentCreate = useSetAppointmentCreate(() => null, []);

  React.useEffect(() => {
    if (appointmentCreate.value) clearAppointmentCreate();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (user && !isAnotherProvider) {
      setProvider(user);
    }
  }, [user, isAnotherProvider]);

  return (
    <StyledWrapper className="g-d-flex g-mb-30">
      <div>
        <PatientSelect />
        <CustomCheckbox
          className="book-with-another-provider"
          checked={isAnotherProvider}
          changeHandler={() => setIsAnotherProvider(!isAnotherProvider)}
          label="Book with another provider"
        />
        {isAnotherProvider && <ProviderSelect />}
      </div>
      {patient && (isAnotherProvider ? provider : true) && (
        <LinkButton
          className="book-button"
          onClick={() =>
            history.push(`/${APPOINTMENTS}/${CREATE}/${CONFIGURATE}`)
          }
        >
          Book an appointment
        </LinkButton>
      )}
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60rem;

  .provider {
    margin-top: 1rem;
  }
  .book-with-another-provider {
    margin-top: 0.5rem;
  }
  .book-button {
    padding: 1.1rem 1.5rem;
    white-space: nowrap;
    width: 20rem;
  }
  @media screen and (max-width: 480px) {
    display: block;
    .book-button {
      margin: 2rem 0 0 0;
      width: 100%;
    }
  }
`;
