import React from 'react'

import { ADMIN_NOTES } from 'constants/routes'; 

import { useSetProviderAdminNotes } from 'hooks/useProviders'
import { useFetchAdminNotes } from 'requests/providers'

import { MediumText } from 'common/texts'
import ProviderTemplate from 'components/providers/ProviderTemplate'
import AdminNotesLoader from 'components/providers/details/admin-notes/AdminNotesLoader'
import NewAdminNoteButton from 'components/providers/details/admin-notes/NewAdminNoteButton'

export default function ProviderAdminNotes () {
  const fetchAdminNotes = useFetchAdminNotes()
  const clearProviderAdminNotes = useSetProviderAdminNotes(() => null, [])

  React.useEffect(() => {
    fetchAdminNotes()
    return clearProviderAdminNotes
    // eslint-disable-next-line
  }, [])

  return (
    <ProviderTemplate currentTab={ADMIN_NOTES}>
      <MediumText className='g-mb-10'>
        Admin notes
      </MediumText>
      <AdminNotesLoader />
      <NewAdminNoteButton />
    </ProviderTemplate>
  )
}
