import React, { FC, useCallback, useEffect, useState } from 'react';

import { SummarySectionEdit } from './SummarySectionEdit';
import { PatientDetails, ProviderDetails, SummarySectionView } from './SummarySectionView';

type SummarySectionProps = {
  loading?: boolean;
  isPreview?: boolean;
  createdAt?: string;
  patient?: PatientDetails;
  provider?: ProviderDetails;
  summary?: string;
  title?: string;
  onChange?: (val: string) => void;
};

export const SummarySection: FC<SummarySectionProps> = ({ summary, loading, title, isPreview, onChange, provider, patient, createdAt }) => {
  const [isEditing, setIsEditing] = useState(false);

  const toggleEdit = useCallback(() => {
    setIsEditing((prev) => !prev);
  }, []);

  const save = useCallback((val) => {
    onChange?.(val);
    toggleEdit();
  }, []);

  if (loading) {
    return <div className="mt-6 h-[15.3rem] rounded-main bg-catskillWhite animate-pulse shadow-row"></div>;
  }

  return isEditing && !isPreview ? (
    <SummarySectionEdit value={summary} onCancel={toggleEdit} onSave={save} />
  ) : (
    <SummarySectionView
      patient={patient}
      provider={provider}
      createdAt={createdAt}
      summary={summary}
      title={title}
      isPreview={isPreview}
      onEdit={toggleEdit}
    />
  );
};
