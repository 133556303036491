import React from 'react'
import { useParams } from 'react-router-dom'

import { globalContext } from 'hooks/useGlobalState'
import { useSetWidgetsList, useSetWidget } from 'hooks/patient/widgets/useWidgets'

import request from 'utils/request'
import createNotification from 'utils/createNotification'
import normalizeWidgetData from 'utils/normalizeWidgetData'

export function useFetchWidgetsList () {
  const { patientId } = useParams()
  const setWidgetsList = useSetWidgetsList((prev, next) => next, [])
  return React.useCallback(() => {
    return request({
      method: 'get',
      url: `/patients/${patientId}/widgets`
    })
      .then(({ data: { data: { widgets } } }) => {
        setWidgetsList(widgets.map(widget => normalizeWidgetData(widget)))
      })
      .catch((error) => console.log(error))
  }, [patientId, setWidgetsList])
}

export function useFetchWidget () {
  const { patientId, widgetId } = useParams()
  const { widgetFilters } = React.useContext(globalContext)
  const setWidget = useSetWidget((prev, next) => next, [])
  return React.useCallback(() => {
    const { start, end } = widgetFilters.value
    return request({
      method: 'get',
      url: `/patients/${patientId}/widget/${widgetId}`,
      params: {
        start,
        end
      }
    })
      .then(({ data: { data: { widget } } }) => {
        setWidget(normalizeWidgetData(widget))
      })
      .catch((error) => console.log(error))
  }, [patientId, widgetFilters, widgetId, setWidget])
}

export function useDeleteWidget (widgetId) {
  const { patientId } = useParams()
  const fetchWidgetsList = useFetchWidgetsList()

  return React.useCallback(() => {
    request({
      method: 'delete',
      url: `/patients/${patientId}/widgets/${widgetId}`
    })
      .then(() => {
        return fetchWidgetsList()
      })
      .then(() => {
        createNotification({ message: 'Widget successfully deleted', type: 'success' })
        return true
      })
      .catch((error) => console.log(error))
  }, [patientId, widgetId, fetchWidgetsList])
}

export function useExportWidgetData (title, data) {
  const { patientId } = useParams()
  return React.useCallback(() => {
    return request({
      method: 'get',
      url: `/patients/${patientId}/widget/export`,
      responseType: 'blob',
      params: {
        title: title,
        widget_data: data
      }
    })
      .then((response) => {
        const { data } = response
        const contentDisposition = response.request.getResponseHeader('Content-Disposition')
        const name = contentDisposition.split('filename')[1].replace(/"/g, '').replace(/=/g, '')
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
      })
      .catch((error) => console.log(error))
  }, [patientId, title, data])
}
