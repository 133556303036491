import React from 'react'

import metrics from 'assets/metrics.jpg'

import { useFetchHeadsUpLink } from 'requests/dashboard'

import { SquarePlate } from 'common/plate/plates'
import { PlateHeader, PlateContent } from 'common/plate/styles'
import { RegularText } from 'common/texts'
import CustomButton from 'common/CustomButton'

export default React.memo(function HealthMetrics ({ className }) {
  const fetchHeadsUpLink = useFetchHeadsUpLink()
  return (
    <SquarePlate className={className}>
      <PlateHeader>Health metrics</PlateHeader>

      <PlateContent>
        <RegularText>
          Deeper integrations with health devices allow you to track the specialized metrics that other health apps can’t offer.
        </RegularText>

        <img
          className='g-w-100 g-mt-15 g-mb-15'
          src={metrics}
          alt='metrics'
        />

        <CustomButton
          className='g-mt-auto'
          clickHandler={fetchHeadsUpLink}
        >
          Your Health Metrics
        </CustomButton>
      </PlateContent>
    </SquarePlate>
  )
})
