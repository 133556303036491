import React from 'react'

import { ColumnWidePlate } from 'common/plate/plates'
import ChatInput from 'common/chat/ChatInput'
import RenameHeader from './RenameHeader'

export default React.memo(function InviteGroupContent () {
  return (
    <ColumnWidePlate>
      <RenameHeader />

      <ChatInput />
    </ColumnWidePlate>
  )
})
