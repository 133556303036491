import React from 'react'
import styled from 'styled-components'

import { VisuallyHidden } from 'common/visuallyHidden'

export default React.memo(function Notification ({ title, message, type, closeHandler }) {
  return (
    <StyledWrapper>
      {/*<b className='type'>{title || `${type}!`}</b>*/}
      {message}
      <button
        className='close'
        onClick={closeHandler}
      >
        <VisuallyHidden>Close alert</VisuallyHidden>
      </button>
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  width: 100%;
  word-break: break-word;
  .type {
    display: block;
    text-transform: uppercase;
  }
  .close {
    position: absolute;
    top: 1.1rem;
    right: 1.2rem;
    width: 1.2rem;
    height: 1.2rem;
    border: solid 0.1rem #223232;
    border-radius: 50%;
    background: #fff;
    transition: all 0.2s;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
    &:active {
      opacity: 1;
      box-shadow: 0 0 0 0.2rem rgba(0,0,0,0.25);
    }
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0.6rem;
      height: 0.1rem;
      background: #223232;
    }
    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
`
