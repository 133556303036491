import React from 'react'

import { APPOINTMENTS } from 'constants/routes'; 

import { useBookedAppointment } from 'hooks/useAppointments'
import { useFetchBookedAppointment } from 'requests/appointments'

import { ColumnWidePlate } from 'common/plate/plates'
import { PlateHeader } from 'common/plate/styles'
import BackButton from 'common/plate/BackButton'
import AppointmentInfoContent from 'components/appointmentsProvider/appointment-info/AppointmentInfoContent'

export default function AppointmentInfo () {
  const bookedAppointment = useBookedAppointment()
  const fetchBookedAppointment = useFetchBookedAppointment()

  React.useEffect(() => {
    fetchBookedAppointment()
    // eslint-disable-next-line
  }, [])

  if (!bookedAppointment) return null

  return (
    <ColumnWidePlate>
      <PlateHeader>
        <BackButton href={`/${APPOINTMENTS}`} />
        Appointment details
      </PlateHeader>
      <AppointmentInfoContent />
    </ColumnWidePlate>
  )
}
