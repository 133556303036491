import React from 'react'

import RemoveSecurity from './RemoveSecurity'
import ConfirmRemoving from './ConfirmRemoving'

export default function RemoveTwoFaWrapper () {
  const [isAttempted, setIsAttempted] = React.useState(false)
  return (
    isAttempted
      ? <ConfirmRemoving />
      : <RemoveSecurity submitHandler={setIsAttempted} />
  )
}
