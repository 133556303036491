import React from 'react'
import styled from 'styled-components'
import get from 'lodash/get'

import { SquarePlate } from 'common/plate/plates'
import { BoldBigText } from 'common/texts'
import DeleteWidgetModal from 'common/widgets/DeleteWidgetModal'
import OuraActivityWidget from './oura/activity/OuraActivityWidget'
import OuraReadinessWidget from './oura/readiness/OuraReadinessWidget'
import OuraSleepWidget from './oura/sleep/OuraSleepWidget'
import WithingsWeightWidget from './withings/weight/WithingsWeightWidget'
import WithingsBloodPressureWidget from './withings/blood-pressure/WithingsBloodPressureWidget'

const config = {
  'oura': {
    'activity': OuraActivityWidget,
    'readiness': OuraReadinessWidget,
    'sleep': OuraSleepWidget
  },
  'withings': {
    'weight': WithingsWeightWidget,
    'blood pressure': WithingsBloodPressureWidget
  }
}

export default React.memo(function SynchronizedWidget ({ className = '', widgetId, device, type, data }) {
  const Component = get(config, `[${device}][${type}]`, null)

  const lastResult = React.useMemo(() => data[data.length - 1] || {}, [data])

  if (!Component) {
    console.warn(`Widget with devise: '${device}' and type: '${type}' not implemented`)
    return null
  }

  return (
    <StyledSquarePlate className={className}>
      <DeleteWidgetModal widgetId={widgetId} />
      <BoldBigText className='title'>
        {type}
      </BoldBigText>
      <Component
        widgetId={widgetId}
        lastResult={lastResult}
        data={data}
      />
    </StyledSquarePlate>
  )
})

const StyledSquarePlate = styled(SquarePlate)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .title {
    text-transform: capitalize;
  }
`
