import React from 'react'
import styled from 'styled-components'

import BackButton from 'common/plate/BackButton'

export default React.memo(function ChatHeaderPlaceholder ({ className = '', href }) {
  return (
    <StyledWrapper className={className}>
      {href && <BackButton href={href} />}
      <div className='icon' />
      <div className='text' />
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 600;
  padding: 1.4rem 2.5rem;
  border-bottom: solid 2px #f1f5f8;
  .icon {
    width: 4rem;
    height: 4rem;
    background: #e9ebef;
    border-radius: 50%;
  }
  .text {
    height: 1.8rem;
    width: 50%;
    margin-left: 1rem;
    background: #e9ebef;
  }
`
