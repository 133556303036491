import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import get from 'lodash/get'

import { useChatUserData } from 'hooks/useMessages'

import UserAvatar from 'common/UserAvatar'

export default React.memo(function OpponentMessage ({ message }) {
  const userData = useChatUserData({ chat_identity: get(message, 'author') })
  const avatarUrl = get(userData, 'avatar_url')
  const firstName = get(userData, 'first_name')
  const lastName = get(userData, 'last_name')
  const fullName = get(userData, 'full_name')
  const timestamp = get(message, 'state.timestamp', '')
  const body = get(message, 'body', '')
  return (
    !message.state.attributes.notification &&
    <StyledWrapper>
      <div className='label-wrapper g-mb-5'>
        <UserAvatar
          className='user-icon'
          firstName={firstName}
          lastName={lastName}
          avatar={avatarUrl}
        />
        <div className='label g-ml-10'>
          {fullName}
        </div>
        <div className='label g-ml-10'>
          {moment.utc(timestamp).local().format('h:mm A')}
        </div>
      </div>
      <div className='body'>
        {body}
      </div>
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  margin-bottom: 1.5rem;
  max-width: 90%;
  .label-wrapper {
    display: flex;
    align-items: center;
    .user-icon {
      width: 2rem;
      height: 2rem;
      font-size: 0.8rem;
      flex-shrink: 0;
    }
    .label {
      font-size: 1.3rem;
      color: #bcc1c1;
    }
  }
  .body {
    display: inline-block;
    padding: 0.8rem;
    font-size: 1.3rem;
    word-break: break-word;
    border-radius: 0.8rem;
    background: #f1f5f8;
  }
`
