import React from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';

import IconLink from './IconLink';

import useTranslations from 'translations';
import { sidebarConfig } from './../../sidebarConfig';
import { useUserContext } from './../../../../context/userContext';
import { useFeatureFlags } from '../../../../hooks/useFeatureFlag';

export default withRouter(
  React.memo(function Footer({ location: { pathname } }) {
    const translations = useTranslations();
    const ff = useFeatureFlags();
    const { role } = useUserContext();

    const sidebarLinks = React.useMemo(() => {
      return sidebarConfig.map(({ path, href, isActiveHref, BeforeIcon, withCounter, roles, featureFlag }) => {
        if (featureFlag && !ff[featureFlag]) {
          return null;
        }
        if (!roles.includes(role)) {
          return null;
        }
        const value = get(translations, path);
        return (
          <IconLink
            key={path}
            value={value}
            href={href}
            BeforeIcon={BeforeIcon}
            isActive={pathname.includes(isActiveHref)}
            withCounter={withCounter}
          />
        );
      });
    }, [translations, pathname, role, ff]);

    return <StyledWrapper>{sidebarLinks}</StyledWrapper>;
  })
);

const StyledWrapper = styled.div`
  display: none;
  @media only screen and (max-width: 1024px) {
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    background: #f1f5f8;
    z-index: 1000;
  }
`;
