import React from 'react'
import styled from 'styled-components'
import debounce from 'lodash/debounce'

import SearchItemPlaceholder from './SearchItemPlaceholder'
import SearchItemsList from './SearchItemsList'

import request from 'utils/request'

export default React.memo(function SearchResults ({ value, isOpen, selectHandler }) {
  const [isLoading, setIsLoading] = React.useState(true)
  const [users, setUsers] = React.useState([])
  const isMounted = React.useRef(false)

  const fetchUsers = React.useCallback((searchText) => {
    setIsLoading(true)
    request({
      method: 'get',
      url: `/providers/members?search=${(searchText)}`
    })
      .then(({ data: { data } }) => {
        if (!isMounted.current) return
        setIsLoading(false)
        setUsers(data.members)
      })
      .catch((error) => {
        console.log(error)
        if (!isMounted) return
        setIsLoading(false)
      })
  }, [setIsLoading, setUsers, isMounted])

  const debounced = React.useRef(debounce((searchText) => {
    fetchUsers(searchText)
  }, 500))

  React.useEffect(() => {
    isMounted.current = true
    debounced.current(value)
    return () => (isMounted.current = false)
  }, [value])

  if (!isOpen) return null

  if (isLoading) {
    return (
      <StyledWrapper>
        <SearchItemPlaceholder />
        <SearchItemPlaceholder />
      </StyledWrapper>
    )
  }

  return (
    <StyledWrapper>
      <SearchItemsList
        users={users}
        selectHandler={selectHandler}
      />
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  position: absolute;
  top: 100%;
  transform: translate(0, 1rem);
  border: solid 1px #dadada;
  background: #fff;
  width: 100%;
  max-width: 30rem;
  max-height: 30rem;
  overflow-y: auto;
`
