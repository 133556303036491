import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { DASHBOARD } from 'constants/routes'

import { ReactComponent as LogoIcon } from 'assets/logo.svg'


export default React.memo(function LogoIcon ({ className = '' }) {
  return (
    <Link to={`/${DASHBOARD}`}>
      <StyledLogoIcon
        className={className}
      />
    </Link>
  )
})

const StyledLogoIcon = styled(LogoIcon)`
  display: block;
`
