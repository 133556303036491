import React from 'react'

import { periodTabsConfig } from 'constants/withings-tabs'

import Tab from './Tab'

export default React.memo(function PeriodTabs ({ period, setPeriod }) {
  return (
    <div className='g-d-flex g-fw-w g-justify-between g-mt-30 g-mb-45'>
      {periodTabsConfig.map((tab, i) => {
        return (
          <Tab
            key={tab.id}
            tab={tab}
            period={period}
            setPeriod={setPeriod}
          />
        )
      })}
    </div>
  )
})
