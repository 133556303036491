import { Thunder, GraphQLTypes, InputType, OperationOptions, ValueTypes } from 'zeus-graphql/patient/zeus';
import { UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
import request from 'utils/request';
import _ from 'lodash';
import createNotification from 'utils/createNotification';
import { PATIENT_API_GRAPHQL_URL } from 'env';
import { useTypedMutation, useTypedQuery } from 'zeus-graphql/patient/reactQuery';
import { getResponseError } from 'utils/getErrors';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { LOGOUT } from '../../../constants/routes';

const useSignOut = () => {
  const history = useHistory();
  return () => {
    history.push(`${LOGOUT}`);
  };
};

export const isNotAuthError = (data: any) => data?.response?.errors?.[0].message === 'User is not authenticated';

export type MutOptions<O extends 'MainMutation', TData extends ValueTypes[O], TResult = InputType<GraphQLTypes[O], TData>> = Omit<
  UseMutationOptions<TResult, Error, Record<string, any>>,
  'mutationKey' | 'mutationFn'
>;

const callToast = (error: any) => {
  const message = getResponseError(error);
  if (error) {
    createNotification({ message, type: 'error' });
  }
};

export const GQL = Thunder(async (query, variables) => {
  return request({
    method: 'post',
    baseURL: PATIENT_API_GRAPHQL_URL,
    data: { query, variables },
  } as any).then(({ data }) => data.data);
});

export const GQLQuery = GQL('query');
export const GQLMutation = GQL('mutation');

export function useQuery<O extends 'MainQuery', TData extends ValueTypes[O], TResult = InputType<GraphQLTypes[O], TData>>(
  queryKey: unknown[],
  query: TData | ValueTypes[O],
  zeusOptions?: OperationOptions & {
    headers?: Record<string, any>;
    errorTypes?: {
      __typename: string;
      message?: string;
      entityName: keyof Partial<InputType<GraphQLTypes[O], TData>>;
    }[];
  },
  options?: Omit<UseQueryOptions<TResult>, 'queryKey' | 'queryFn'>
) {
  const signOut = useSignOut();
  return useTypedQuery(
    queryKey,
    query,
    {
      ...options,
      onSuccess: (data) => {
        if (data) {
          const errorTag = zeusOptions?.errorTypes?.find((type) => _.get(data, `${type.entityName as string}.__typename`) === type.__typename);

          if (errorTag && _.get(data, `${errorTag.entityName as string}`)) {
            const msg = errorTag.message || (_.get(data, `${errorTag.entityName as string}.message`, 'Oops, something went wrong') as string);
            const apolloError = new Error(msg);
            callToast(apolloError);
            options?.onError?.(apolloError);
            return;
          }
        }
        options?.onSuccess?.(data);
      },
      onError: (error: any) => {
        options?.onError?.(error);
        if (isNotAuthError(error)) {
          signOut();
          callToast({
            message: 'Your session has been expired. Please log in again',
          });

          return;
        }

        if (error?.message === 'Failed to fetch') {
          createNotification({ message: 'No connection to the server. Try again later', type: 'error' });
        } else {
          callToast(error);
        }
      },
    },
    zeusOptions,
    PATIENT_API_GRAPHQL_URL,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('idToken')}`,
        TimeZone: moment().format('ZZ'),
        'X-Auth-Source': localStorage.getItem('role') || 'provider',
        ...zeusOptions?.headers,
      },
    }
  );
}
export type GQLArgs<TRes = InputType<GraphQLTypes['MainMutation'], ValueTypes['MainMutation']>> = MutOptions<'MainMutation', ValueTypes['MainMutation'], TRes>;
export function useMutation<
  O extends 'MainMutation',
  TData extends ValueTypes[O],
  TResult = InputType<GraphQLTypes[O], TData>,
  TError extends Error = Error
>(
  mutationKey: unknown[],
  mutation: TData | ValueTypes[O],
  zeusOptions?: OperationOptions & {
    successText?: string;
    errorTypes?: {
      __typename: string;
      message?: string;
      entityName: keyof Partial<InputType<GraphQLTypes[O], TData>>;
    }[];
    headers?: Record<string, any>;
  },
  options?: MutOptions<O, TData, TResult>
) {
  const signOut = useSignOut();

  return useTypedMutation<O, TData, Record<string, any>, TResult, TError>(
    mutationKey,
    mutation,
    {
      ...options,
      onSuccess: (data, variables, ctx) => {
        if (data) {
          const errorTag = zeusOptions?.errorTypes?.find((type) => _.get(data, `${type.entityName as string}.__typename`) === type.__typename);

          if (zeusOptions?.errorTypes?.length && !_.get(data, `${zeusOptions?.errorTypes?.[0]?.__typename as string}.__typename`)) {
            console.warn('Looks like you forgot to add __typename to your error type', zeusOptions?.errorTypes?.[0]?.entityName);
          }

          if (errorTag && _.get(data, `${errorTag.entityName as string}`)) {
            const msg = errorTag.message || (_.get(data, `${errorTag.entityName as string}.message`, 'Oops, something went wrong') as string);
            const apolloError = new Error(msg);
            callToast(apolloError);
            options?.onError?.(apolloError, variables, ctx);
            return;
          }
        }

        options?.onSuccess?.(data, variables, ctx);

        if (zeusOptions?.successText) {
          createNotification({ message: zeusOptions.successText, type: 'success' });
        }
      },
      onError: (error, vars, ctx) => {
        options?.onError?.(error, vars, ctx);
        if (isNotAuthError(error)) {
          signOut();
          callToast({
            message: 'Your session has been expired. Please log in again',
          });
        }

        if (error?.message === 'Failed to fetch') {
          createNotification({ message: 'No connection to the server. Try again later', type: 'error' });
        } else {
          callToast(error);
        }
      },
    },
    zeusOptions,
    PATIENT_API_GRAPHQL_URL,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('idToken')}`,
        TimeZone: moment().format('ZZ'),
        'X-Auth-Source': localStorage.getItem('role') || 'provider',
        ...zeusOptions?.headers,
      },
    }
  );
}
