import React from 'react'
import styled from 'styled-components'

import { ReactComponent as TwilioIcon } from 'assets/twilio.svg'

export default React.memo(function VideoFooter () {
  return (
    <StyledTwilioIcon />
  )
})

const StyledTwilioIcon = styled(TwilioIcon)`
  width: 8rem;
  height: 3rem;
  margin: 1.5rem 0 0 auto;
`
