import React from 'react'

import { useSetScreenWidth } from 'hooks/useUtils'

export default React.memo(function ScreenWidth () {
  const setScreenWidth = useSetScreenWidth((prev, next) => next, [])
  const screenWidthHandler = React.useCallback(() => {
    setScreenWidth(document.body.offsetWidth)
  }, [setScreenWidth])
  React.useEffect(() => {
    window.addEventListener('resize', screenWidthHandler)
    return () => window.removeEventListener('resize', screenWidthHandler)
    // eslint-disable-next-line
  }, [])
  return null
})
