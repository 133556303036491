import React from 'react'
import styled from 'styled-components'

import Modal from 'common/Modal'
import RescheduleButton from './RescheduleButton'
import RefundButton from './RefundButton'
import CancelButton from './CancelButton'

export default function EditModal ({ closeModal, id, status }) {
  console.log('EditModal')
  return (
    <Modal
      title='Select options below'
      closeModal={closeModal}
      minWidth={44}
      maxWidth={44}
    >
      <StyledWrapper>
        <RescheduleButton
          status={status}
          id={id}
        />
        <RefundButton
          id={id}
          closeMainModal={closeModal}
        />
        <CancelButton
          status={status}
          id={id}
          closeMainModal={closeModal}
        />
      </StyledWrapper>
    </Modal>
  )
}

const StyledWrapper = styled.div`
  .button {
    min-width: 14.5rem;
  }
`
