import React from 'react'

import { ColumnWidePlate } from 'common/plate/plates'
import ChatInput from 'common/chat/ChatInput'
import DirectHeader from './DirectHeader'

export default React.memo(function CreateDirectContent () {
  return (
    <ColumnWidePlate>
      <DirectHeader />

      <ChatInput />
    </ColumnWidePlate>
  )
})
