import React from 'react'
import styled from 'styled-components'
import get from 'lodash/get'
import { StyledButton } from './styles'

export default React.memo(function SixMonthAfterProgramWasStarted ({ message }) {
  const href = get(message, 'state.attributes.href')
  return (
    <StyledWrapper>
      <p>We are glad at how far you've come in your health journey! We would like to hear from you about your current health today to improve our personalized treatment plan. Please fill out the following PROMIS 10 form.</p>
      <StyledLink
        className='button g-mt-10 g-mb-5'
        as='a'
        href={href}
        rel='noopener noreferrer'
        target='_blank'
      >
        Go to form
      </StyledLink>
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  .button {
    padding-right: 1rem;
    padding-left: 1rem;
  }
`
const StyledLink = styled(StyledButton)`
  text-decoration: none;
`
