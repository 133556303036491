import React from 'react'

import { useSetMedicationProperty } from 'hooks/usePatients'

import CustomCalendar from 'common/custom-calendar'

export default function StartDate ({ className = '', index, startedAt, validate }) {
  const setMedicationStartedAt = useSetMedicationProperty({ index, field: 'started_at' })
  return (
    <CustomCalendar
      className={className}
      label='Start intake date'
      placeholder='mm.dd.yy'
      isRequred
      isValid={!validate || startedAt}
      selectedDate={startedAt}
      setSelectedDate={setMedicationStartedAt}
    />
  )
}
