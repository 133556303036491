import React from 'react'
import get from 'lodash/get'

import { DAYS_OPTIONS, MONTH_OPTIONS, YEAR_OPTIONS } from 'constants/dates-options'

import { useLocalProfile, useSetLocalProfile } from 'hooks/useProfile'

import CustomSelect from 'common/CustomSelect'

export default React.memo(function BirthDate ({ isLoading }) {
  const birthDate = useLocalProfile(current => get(current, 'birth_date')) || '--'
  const [yyyy, mm, dd] = birthDate.split('-')

  return (
    <div className='g-mt-20 birthday'>
      <CustomSelect
        className='year'
        label='Birth date'
        placeholder='Year'
        isRequired
        value={yyyy}
        options={YEAR_OPTIONS}
        changeHandler={useSetLocalProfile((prev, year) => ({ ...prev, birth_date: [year, mm, dd].join('-') }), [mm, dd])}
        isLoading={isLoading}
      />
      <CustomSelect
        className='day'
        placeholder='Day'
        isRequired
        value={dd}
        options={DAYS_OPTIONS}
        changeHandler={useSetLocalProfile((prev, day) => ({ ...prev, birth_date: [yyyy, mm, day].join('-') }), [yyyy, mm])}
        isLoading={isLoading}
      />
      <CustomSelect
        className='month'
        placeholder='Month'
        isRequired
        value={mm}
        options={MONTH_OPTIONS}
        changeHandler={useSetLocalProfile((prev, month) => ({ ...prev, birth_date: [yyyy, month, dd].join('-') }), [yyyy, dd])}
        isLoading={isLoading}
      />
    </div>
  )
})
