import React from 'react'
import { useParams } from 'react-router-dom'
import get from 'lodash/get'

import { PATIENTS, DETAILS, MEDICATIONS } from 'constants/routes'; 

import { usePatientMedications } from 'hooks/usePatients'

import { PlateHeader } from 'common/plate/styles'
import BackButton from 'common/plate/BackButton'

export default function Header () {
  const { patientId } = useParams()
  const category = usePatientMedications(current => get(current, 'category', ''))
  return (
    <PlateHeader>
      <BackButton href={`/${PATIENTS}/${DETAILS}/${patientId}/${MEDICATIONS}`} />
      {category}
    </PlateHeader>
  )
}
