import React from 'react'

import { useSetLogsFilter } from 'hooks/useLogs'

import SearchableSelect from 'common/searchable-select'

export default React.memo(function UserSelect () {
  const setMember = useSetLogsFilter((prev, { id, type }) => ({ ...prev, member: { id, type } }), [])
  const clearMember = useSetLogsFilter((prev) => ({ ...prev, member: null }), [])
  return (
    <SearchableSelect
      label='Select user'
      placeholder='Enter name of provider or patient'
      searchLink='admins/members'
      searchField='members'
      onSelect={setMember}
      onClear={clearMember}
    />
  )
})
