import React from 'react'

import { useFetchPatinetSummary } from 'requests/patients/summary'

import { RegularText, RegularMediumText } from 'common/texts'
import Modal from 'common/Modal'
import CountButton from 'common/CountButton'
import CustomButton from 'common/CustomButton'

import request from 'utils/request'
import createNotification from 'utils/createNotification'

export default function ChangeVisitsModal ({ closeModal, id, visitType, name, used, amount }) {
  const fetchPatinetSummary = useFetchPatinetSummary()
  const [newAmount, setNewAmount] = React.useState(amount)

  const decreaseVisits = React.useCallback(() => {
    if (newAmount <= used) return
    setNewAmount(current => current - 1)
  }, [setNewAmount, newAmount, used])

  const increaseVisits = React.useCallback(() => {
    setNewAmount(current => current + 1)
  }, [setNewAmount])

  const changeVisitsAttempt = React.useCallback(() => {
    request({
      method: 'put',
      url: `/admins/appointments/visit/edit`,
      data: {
        id,
        amount: newAmount,
        visitType
      }
    })
      .then(({ data: { data } }) => {
        closeModal()
        createNotification({ message: 'Visits successfully updated', type: 'success' })
        fetchPatinetSummary()
      })
      .catch((error) => console.log(error))
  }, [id, visitType, newAmount, closeModal, fetchPatinetSummary])

  return (
    <Modal
      title={name}
      closeModal={closeModal}
      minWidth={46}
      maxWidth={46}
      withSeparator
    >
      <RegularText className='g-mt-15'>
        Add {name.toLocaleLowerCase()}
      </RegularText>
      <div className='g-d-flex g-justify-between g-align-center g-mt-15'>
        <div className='g-d-flex g-align-center'>
          <CountButton
            onClick={decreaseVisits}
            disabled={newAmount <= used}
          >
            -
          </CountButton>
          <RegularMediumText
            className='g-ml-25 g-mr-25'
          >
            {used} / {newAmount}
          </RegularMediumText>
          <CountButton onClick={increaseVisits}>
            +
          </CountButton>
        </div>
        <CustomButton
          clickHandler={changeVisitsAttempt}
          disabled={newAmount === amount}
        >
          Confirm
        </CustomButton>
      </div>
    </Modal>
  )
}
