/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	Query:{
		getProviders:{
			ids:{
				type:"UUID",
				array:true,
				arrayRequired:false,
				required:true
			}
		},
		getCompanies:{
			sort:{
				type:"CompaniesSortType",
				array:false,
				arrayRequired:false,
				required:false
			},
			search:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getParticipant:{
			participantId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getParticipants:{
			page:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			perPage:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			filters:{
				type:"ParticipantsFilterType",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getParticipantProgramModules:{
			participantId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getParticipantProgramCourses:{
			participantId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getModuleProgress:{
			programModuleId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			},
			participantId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getSectionMaterialsFile:{
			programModuleId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			},
			sectionId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getProgramCourses:{
			page:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			perPage:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getProgramCourseGroups:{
			programCourseId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			},
			page:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			perPage:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getGroupModules:{
			programGroupId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getProgramModule:{
			moduleId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			}
		}
	},
	UUID: "String",
	DateTime: "String",
	CompaniesSortType:{
		orderBy:{
			type:"CompanyOrderByEnum",
			array:false,
			arrayRequired:false,
			required:true
		},
		direction:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	CompanyOrderByEnum: "enum",
	OrderDirection: "enum",
	ParticipantsFilterType:{
		ids:{
			type:"UUID",
			array:true,
			arrayRequired:false,
			required:true
		},
		emails:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		groupId:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ModuleDataTypes: "enum",
	Mutation:{
		signUpParticipant:{
			data:{
				type:"SignUpInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateStatus:{
			participantId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			},
			status:{
				type:"ParticipantStatus",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		resendConfirmationLink:{
			email:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		resendConfirmationLinkByToken:{
			token:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		verifyChangePassword:{
			token:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		makeProgress:{
			programModuleId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			},
			sectionId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"PersonalSuccessInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		addProgramModule:{
			programCourseId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ProgramModuleInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		removeProgramModule:{
			moduleId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		assignProgramModuleToGroup:{
			groupId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			},
			moduleId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		addProgramCourseGroup:{
			programCourseId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			},
			name:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			startedAt:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		addParticipantToGroup:{
			programGroupId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			},
			participantId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		removeParticipantFromGroup:{
			programGroupId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			},
			participantId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateProgramModule:{
			moduleId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ProgramModuleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateProgramModuleSection:{
			sectionId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ProgramModuleSectionInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteProgramModuleSection:{
			sectionId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		addProgramModuleSection:{
			moduleId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ProgramModuleSectionInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		removeAdminFromGroupChat:{
			groupId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			},
			adminId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		addAdminToGroupChat:{
			groupId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			},
			adminId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			},
			adminChatIdentity:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		removeMemberFromGroupChat:{
			groupId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			},
			memberId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		addMemberToGroupChat:{
			groupId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			},
			memberId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			},
			memberChatIdentity:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			memberType:{
				type:"MemberTypes",
				array:false,
				arrayRequired:false,
				required:true
			}
		}
	},
	SignUpInput:{
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		firstName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		lastName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		companyId:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:true
		},
		groupId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		memberId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	ParticipantStatus: "enum",
	PersonalSuccessInput:{
		responseId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	ProgramModuleInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		shortTitle:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		startedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:true
		},
		endedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:true
		},
		sections:{
			type:"ProgramModuleSectionInput",
			array:true,
			arrayRequired:true,
			required:true
		}
	},
	ProgramModuleSectionInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		personalSuccess:{
			type:"SectionPersonalSuccessInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		curriculum:{
			type:"SectionCurriculumInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		coachingCall:{
			type:"SectionCoachingCallInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SectionPersonalSuccessInput:{
		formId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SectionCurriculumInput:{
		url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SectionCoachingCallInput:{
		startedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		recordingUrl:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Date: "String",
	ProgramModuleUpdateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		shortTitle:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		startedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:true
		},
		endedAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:true
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	MemberTypes: "enum"
}

export const ReturnTypes: Record<string,any> = {
	Query:{
		getAdmins:"ProviderType",
		getProviders:"ProviderType",
		getCompanies:"CompanyType",
		getParticipant:"ParticipantType",
		getParticipants:"ParticipantTypeConnection",
		me:"ParticipantType",
		getParticipantProgramModules:"ProgramModuleType",
		getParticipantProgramCourses:"ProgramCourseType",
		getModuleProgress:"ModuleProgressType",
		getSectionMaterialsFile:"String",
		getProgramCourses:"ProgramCourseTypeConnection",
		getProgramCourseGroups:"ProgramGroupTypeConnection",
		getGroupModules:"ProgramModuleType",
		getProgramModule:"ProgramModuleType"
	},
	ProviderType:{
		id:"UUID",
		fullName:"String",
		firstName:"String",
		lastName:"String",
		email:"String",
		chatIdentity:"String",
		avatarUrl:"String",
		isAdmin:"Boolean"
	},
	CompanyType:{
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"UUID",
		name:"String"
	},
	ParticipantType:{
		chatIdentity:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"UUID",
		email:"String",
		firstName:"String",
		lastName:"String",
		groupId:"String",
		memberId:"String",
		status:"String",
		cognitoSub:"UUID",
		medicalRecord:"String",
		isTest:"Boolean",
		lastReset:"DateTime",
		programGroupId:"UUID"
	},
	ParticipantTypeConnection:{
		pageInfo:"PageInfo",
		items:"ParticipantType"
	},
	PageInfo:{
		hasNextPage:"Boolean",
		hasPreviousPage:"Boolean",
		currentPage:"Int",
		perPage:"Int",
		lastPage:"Int",
		total:"Int"
	},
	ProgramModuleType:{
		sections:"ProgramModuleSectionType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"UUID",
		shortTitle:"String",
		title:"String",
		startedAt:"DateTime",
		endedAt:"DateTime",
		description:"String",
		current:"Boolean"
	},
	ProgramModuleSectionType:{
		id:"String",
		title:"String",
		type:"ModuleDataTypes",
		description:"String",
		metadata:"PersonalSuccessTypeCurriculumTypeCoachingCallTypeDietitianCallType",
		data:"PersonalSuccessTypeCurriculumTypeCoachingCallTypeDietitianCallType"
	},
	PersonalSuccessTypeCurriculumTypeCoachingCallTypeDietitianCallType:{
		"...on PersonalSuccessType":"PersonalSuccessType",
		"...on CurriculumType":"CurriculumType",
		"...on CoachingCallType":"CoachingCallType",
		"...on DietitianCallType":"DietitianCallType"
	},
	PersonalSuccessType:{
		formId:"String",
		type:"String"
	},
	CurriculumType:{
		url:"String",
		type:"String",
		signedUrl:"String"
	},
	CoachingCallType:{
		url:"String",
		startedAt:"String",
		recordingUrl:"String",
		type:"String"
	},
	DietitianCallType:{
		url:"String",
		startedAt:"String",
		recordingUrl:"String",
		type:"String"
	},
	ProgramCourseType:{
		program:"ProgramType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"UUID",
		startedAt:"DateTime"
	},
	ProgramType:{
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"UUID",
		title:"String"
	},
	ModuleProgressType:{
		id:"String",
		completed:"Boolean",
		sections:"ProgressDataType"
	},
	ProgressDataType:{
		sectionId:"String",
		sectionType:"String",
		metadata:"DietitianCallProgressMetadataTypeCoachingCallProgressMetadataTypeCurriculumProgressMetadataTypePersonalSuccessProgressMetadataType",
		completed:"Boolean"
	},
	DietitianCallProgressMetadataTypeCoachingCallProgressMetadataTypeCurriculumProgressMetadataTypePersonalSuccessProgressMetadataType:{
		"...on DietitianCallProgressMetadataType":"DietitianCallProgressMetadataType",
		"...on CoachingCallProgressMetadataType":"CoachingCallProgressMetadataType",
		"...on CurriculumProgressMetadataType":"CurriculumProgressMetadataType",
		"...on PersonalSuccessProgressMetadataType":"PersonalSuccessProgressMetadataType"
	},
	DietitianCallProgressMetadataType:{
		startDate:"String",
		endDate:"String"
	},
	CoachingCallProgressMetadataType:{
		startDate:"String",
		endDate:"String"
	},
	CurriculumProgressMetadataType:{
		startDate:"String",
		endDate:"String"
	},
	PersonalSuccessProgressMetadataType:{
		startDate:"String",
		endDate:"String",
		responseId:"String"
	},
	ProgramCourseTypeConnection:{
		pageInfo:"PageInfo",
		items:"ProgramCourseType"
	},
	ProgramGroupTypeConnection:{
		pageInfo:"PageInfo",
		items:"ProgramGroupType"
	},
	ProgramGroupType:{
		participants:"ParticipantType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"UUID",
		name:"String",
		startedAt:"DateTime",
		limit:"Int"
	},
	Mutation:{
		signUpParticipant:"ParticipantTypeParticipantDuplicatedError",
		updateStatus:"ParticipantTypeParticipantDneError",
		resendConfirmationLink:"Boolean",
		resendConfirmationLinkByToken:"Boolean",
		verifyChangePassword:"VerifyChangePasswordTokenTypeInvalidVerifyChangePasswordTokenVerifyChangePasswordTokenExpired",
		makeProgress:"ModuleProgressTypeDoesNotBelongToCourseModuleHasNotStarted",
		addProgramModule:"ProgramModuleType",
		removeProgramModule:"Boolean",
		assignProgramModuleToGroup:"ProgramModuleType",
		addProgramCourseGroup:"ProgramGroupType",
		addParticipantToGroup:"ProgramGroupTypeParticipantAlreadyInGroup",
		removeParticipantFromGroup:"ProgramGroupType",
		updateProgramModule:"ProgramModuleType",
		updateProgramModuleSection:"ProgramModuleSectionType",
		deleteProgramModuleSection:"Boolean",
		addProgramModuleSection:"ProgramModuleSectionType",
		removeAdminFromGroupChat:"Boolean",
		addAdminToGroupChat:"Boolean",
		removeMemberFromGroupChat:"Boolean",
		addMemberToGroupChat:"Boolean"
	},
	ParticipantTypeParticipantDuplicatedError:{
		"...on ParticipantType":"ParticipantType",
		"...on ParticipantDuplicatedError":"ParticipantDuplicatedError"
	},
	ParticipantDuplicatedError:{
		message:"String"
	},
	ParticipantTypeParticipantDneError:{
		"...on ParticipantType":"ParticipantType",
		"...on ParticipantDneError":"ParticipantDneError"
	},
	ParticipantDneError:{
		message:"String"
	},
	VerifyChangePasswordTokenTypeInvalidVerifyChangePasswordTokenVerifyChangePasswordTokenExpired:{
		"...on VerifyChangePasswordTokenType":"VerifyChangePasswordTokenType",
		"...on InvalidVerifyChangePasswordToken":"InvalidVerifyChangePasswordToken",
		"...on VerifyChangePasswordTokenExpired":"VerifyChangePasswordTokenExpired"
	},
	VerifyChangePasswordTokenType:{
		email:"String",
		tmpPassword:"String"
	},
	InvalidVerifyChangePasswordToken:{
		message:"String"
	},
	VerifyChangePasswordTokenExpired:{
		message:"String"
	},
	ModuleProgressTypeDoesNotBelongToCourseModuleHasNotStarted:{
		"...on ModuleProgressType":"ModuleProgressType",
		"...on DoesNotBelongToCourse":"DoesNotBelongToCourse",
		"...on ModuleHasNotStarted":"ModuleHasNotStarted"
	},
	DoesNotBelongToCourse:{
		message:"String"
	},
	ModuleHasNotStarted:{
		message:"String"
	},
	ProgramGroupTypeParticipantAlreadyInGroup:{
		"...on ProgramGroupType":"ProgramGroupType",
		"...on ParticipantAlreadyInGroup":"ParticipantAlreadyInGroup"
	},
	ParticipantAlreadyInGroup:{
		message:"String"
	}
}
