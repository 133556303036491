import {
  ACTIVE,
  DASHBOARD,
  PATIENTS,
  APPOINTMENTS,
  MONTH,
  PROVIDERS,
  APPOINTMENTS_ADMIN,
  MESSAGES,
  REPORTS,
  COMPANIES,
  LIBRARY, PROGRAMS,
} from 'constants/routes'

import { ReactComponent as Dashboard } from 'assets/dashboard.svg';
import { ReactComponent as Patients } from 'assets/user-injured-solid.svg';
import { ReactComponent as Companies } from 'assets/companies.svg';
import { ReactComponent as Providers } from 'assets/providers.svg';
import { ReactComponent as Appointments } from 'assets/appointments.svg';
import { ReactComponent as Messages } from 'assets/messages.svg';
import { ReactComponent as Reports } from 'assets/reports.svg';
import { ReactComponent as Sparkles } from 'assets/sparkles.svg';

const translatePath = 'sidebar';
export const sidebarConfig = [
  {
    path: `${translatePath}.dashboard`,
    roles: ['provider', 'admin'],
    href: `/${DASHBOARD}`,
    isActiveHref: `/${DASHBOARD}`,
    BeforeIcon: Dashboard,
    className: 'g-pt-0',
  },
  {
    path: `${translatePath}.patients`,
    roles: ['provider', 'admin'],
    href: `/${PATIENTS}/${ACTIVE}`,
    isActiveHref: `/${PATIENTS}`,
    BeforeIcon: Patients,
  },
  {
    path: `${translatePath}.companies`,
    roles: ['provider', 'admin'],
    href: `/${COMPANIES}`,
    isActiveHref: `/${COMPANIES}`,
    BeforeIcon: Companies,
  },
  {
    path: `${translatePath}.providers`,
    roles: ['admin'],
    href: `/${PROVIDERS}`,
    isActiveHref: `/${PROVIDERS}`,
    BeforeIcon: Providers,
  },
  {
    path: `${translatePath}.appointments`,
    roles: ['provider'],
    href: `/${APPOINTMENTS}/${MONTH}`,
    isActiveHref: `/${APPOINTMENTS}`,
    BeforeIcon: Appointments,
  },
  {
    path: `${translatePath}.appointments`,
    roles: ['admin'],
    href: `/${APPOINTMENTS_ADMIN}`,
    isActiveHref: `/${APPOINTMENTS_ADMIN}`,
    BeforeIcon: Appointments,
  },
  {
    path: `${translatePath}.messages`,
    roles: ['provider', 'admin'],
    href: `/${MESSAGES}`,
    isActiveHref: `/${MESSAGES}`,
    BeforeIcon: Messages,
    withCounter: true,
  },
  {
    path: `${translatePath}.programs`,
    roles: ['admin', 'customAdmin'],
    href: `/${PROGRAMS}`,
    isActiveHref: `/${PROGRAMS}`,
    BeforeIcon: Reports,
  },
  /*{
    path: `${translatePath}.reports`,
    roles: ['admin'],
    href: `/${DASHBOARD}`,
    isActiveHref: `/${REPORTS}`,
    BeforeIcon: Reports,
  },*/
  {
    path: `${translatePath}.library`,
    roles: ['admin', 'provider'],
    featureFlag: 'library',
    href: `/${LIBRARY}`,
    isActiveHref: `/${LIBRARY}`,
    BeforeIcon: Sparkles,
  },
];
