import React from 'react'

import { useUploadPatinetDocument } from 'requests/patients'

import { HintText } from 'common/texts'
import CustomButton from 'common/CustomButton'

export default React.memo(function UploadButton () {
  const [isSended, setIsSended] = React.useState(false)
  const uploadPatinetDocument = useUploadPatinetDocument()

  const changeHandler = React.useCallback((e) => {
    uploadPatinetDocument({
      document: e.target.files[0],
      setIsSended
    })
    e.target.value = ''
  }, [uploadPatinetDocument])

  return (
    <div className='g-d-flex g-align-center g-mt-20'>
      <label>
        <CustomButton
          className='g-mr-25'
          disabled={isSended}
        >
          Upload
        </CustomButton>
        <input
          className='g-d-none'
          type='file'
          accept='.doc, .docx, .pdf'
          onChange={changeHandler}
        />
      </label>
      <HintText>Supported formats PDF, Word</HintText>
    </div>
  )
})
