import React from 'react'
import get from 'lodash/get'

import { usePatinetSummary } from 'hooks/usePatients'

import PlanDetails from './PlanDetails'
import ProgramDetails from './ProgramDetails'
import CompanyDetails from './CompanyDetails'

const config = {
  'plan': PlanDetails,
  'program': ProgramDetails,
  'company': CompanyDetails
}

export default function MembershipDetails ({ isLoading }) {
  const membershipType = usePatinetSummary(current => get(current, 'patient.membership_type'))
  const Component = React.useMemo(() => {
    if (config[membershipType]) return config[membershipType]
    return () => null
  }, [membershipType])

  return <Component isLoading={isLoading} />
}
