import React from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { COMPANIES } from 'constants/routes'
import { globalContext } from 'hooks/useGlobalState';
import { useSetCompanies, useSetCompany, useSetCompanyPatients, useSetCompanyPlans, useSetCompanyPlansMembers } from 'hooks/useCompanies'
import createNotification from 'utils/createNotification'

import request from 'utils/request';
import normalizeParams from 'utils/normalizeParams';
import requestCahce from '../utils/requestCahce'
import { usePatinetsFilter, useSetPatinets, useSetPatinetsPagination } from '../hooks/usePatients'

export function useFetchCompanies() {
  const { companies } = React.useContext(globalContext);
  const setCompanies = useSetCompanies((prev, next) => next, []);
  return React.useCallback(() => {
    if (!companies.value) {
      request({
        method: 'get',
        url: `/companies`,
      })
        .then(
          ({
            data: {
              data: { companies },
            },
          }) => setCompanies(companies)
        )
        .catch((error) => console.log(error));
    }
  }, [companies, setCompanies]);
}

export function useFetchCompany() {
  const { companyId } = useParams();
  const setCompany = useSetCompany((prev, next) => next, []);
  return React.useCallback(() => {
    request({
      method: 'get',
      url: `/companies/${companyId}`,
    })
      .then(
        ({
          data: {
            data: { company },
          },
        }) => setCompany(company)
      )
      .catch((error) => console.log(error));
  }, [companyId, setCompany]);
}

export function useFetchCompanyPatients() {
  const { companyId } = useParams();
  const setCompanyPatients = useSetCompanyPatients((prev, next) => next, []);
  return React.useCallback(
    (params = {}) => {
      request({
        method: 'get',
        url: `/companies/${companyId}/patients`,
        params: normalizeParams(params),
      })
        .then(
          ({
            data: {
              data: { patients },
            },
          }) => setCompanyPatients(patients)
        )
        .catch((error) => console.log(error));
    },
    [companyId, setCompanyPatients]
  );
}

export function useFetchAllPatients () {
  const { status } = 'active'
  const setPatinets = useSetPatinets((prev, next) => next, [])
  const setPatinetsPagination = useSetPatinetsPagination((prev, next) => next, [])
  const per_page = '500'
  return React.useCallback(() => {
    const page = 1;
    request({
      method: 'get',
      url: `/patients`,
      params: normalizeParams({
        status,
        page,
        per_page,
      })
    })
      .then(({ data: { data } }) => {
        setPatinets(data.patients)
        setPatinetsPagination(data.meta)
      })
      .catch((error) => console.log(error))
  }, [status, setPatinets, setPatinetsPagination])
}

export function usePreFetchCompanyPatients(companyId, signal) {

  const setCompanyPatients = useSetCompanyPatients((prev, next) => next, []);
  return React.useCallback(
    (params = {}) => {
      request({
        method: 'get',
        url: `/companies/${companyId}/patients`,
        params: normalizeParams(params),
        signal: signal
      })
        .then(
          ({
            data: {
              data: { patients },
            },
          }) => {
            // setCompanyPatients(patients)
          }
        )
        .catch((error) => console.log(error));
    },
    [companyId, setCompanyPatients]
  );
}

export function useCreateCompany() {
  const history = useHistory();
  const { companyForm } = React.useContext(globalContext);
  return React.useCallback(
    (setIsSended) => {
      setIsSended(true);
      request({
        method: 'post',
        url: `/companies`,
        data: companyForm.value,
      })
        .then(() => {
          createNotification({ message: 'Company successfully created', type: 'success' });
          history.push(`/${COMPANIES}`);
        })
        .catch((error) => console.log(error))
        .finally(() => setIsSended(false));
    },
    [companyForm, history]
  );
}

export function useFetchCompanyPlans() {
  const { companyId } = useParams();
  const body = {"company_id": companyId}
  const setCompanyPlans = useSetCompanyPlans((prev, next) => next, []);
  return React.useCallback(
    (params = {}) => {
      request({
        method: 'get',
        url: `/companies/${companyId}/subscriptions`,
      })
        .then(
          ({
            data: {
              data: { subscriptions },
            },
          }) => setCompanyPlans(subscriptions)
        )
        .catch((error) => console.log(error));
    },
    [companyId, setCompanyPlans]
  );
}

export function useFetchCompanyPlansMembers() {
  const { subscriptionId } = useParams();
  const body = {"subscription_id": subscriptionId}
  const setCompanyPlansMembers = useSetCompanyPlansMembers((prev, next) => next, []);
  return React.useCallback(
    (params = {}) => {
      request({
        method: 'get',
        url: `/company/memberships`,
        params: body,
      })
        .then(
          ({
            data: {
              data: { memberships },
            },
          }) => setCompanyPlansMembers(memberships)
        )
        .catch((error) => console.log(error));
    },
    [subscriptionId, setCompanyPlansMembers]
  );
}
