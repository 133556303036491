import React from 'react'
import styled from 'styled-components'

import UserAvatar from 'common/UserAvatar'

export default React.memo(function SearchItem ({
  id, avatar, firstName, lastName, fullName, type, qualification, selectHandler
}) {
  const handleSelect = React.useCallback(() => {
    selectHandler({ id, type })
  }, [selectHandler, id, type])

  const subName = React.useMemo(() => {
    if (type === 'patient') return 'patient'
    return qualification
  }, [type, qualification])

  return (
    <StyledWrapper onClick={handleSelect}>
      <UserAvatar
        className='avatar'
        avatar={avatar}
        firstName={firstName}
        lastName={lastName}
      />
      <div className='g-ml-10'>
        <div className='name'>
          {fullName}
        </div>
        <div className='sub-name'>
          {subName}
        </div>
      </div>
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  cursor: pointer;
  :hover {
    background: #fbfbfb;
  }
  .avatar {
    width: 3.2rem;
    height: 3.2rem;
  }
  .name {
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-weight: 500;
  }
  .sub-name {
    font-size: 1rem;
    font-weight: 500;
    opacity: 0.3;
    text-transform: uppercase;
  }
`
