import React from 'react';
import styled from 'styled-components';
import get from 'lodash/get';

import { useUser } from 'hooks/useGlobalState';

import MembersList from './MembersList';
import RenameChat from './RenameChat';
import InviteUsers from './InviteUsers';
import DeleteGroupChat from './DeleteGroupChat';
import { permissions } from '../../../../permissions';
import { useUserContext } from '../../../../context/userContext';

export default React.memo(function ChatSettings({ channelType, channel, isOpen, toggleOptions }) {
  const { can } = useUserContext();
  const owner = get(channel, 'state.attributes.owner');
  const isOwner = useUser((current) => get(current, 'id') === owner, [owner]);
  const membersCount = get(channel, 'state.attributes.members.length', 0);

  return (
    <StyledWrapper>
      <div className="count" onClick={toggleOptions}>
        {membersCount} member(s)
      </div>
      {isOpen && (
        <div className="wrapper">
          <MembersList channelType={channelType} channel={channel} />
          {(isOwner || can(permissions.messages.canChangeSettings)) && (
            <>
              {channelType !== 'admin' && <RenameChat />}
              <InviteUsers />
              {channelType !== 'admin' && <DeleteGroupChat />}
            </>
          )}
        </div>
      )}
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  position: relative;
  .count {
    font-size: 1rem;
    font-weight: 500;
    line-height: 2rem;
    color: #bbc0c0;
    text-transform: uppercase;
    transition: all 0.2s;
    cursor: pointer;
    :hover {
      color: #223232;
    }
  }
  .wrapper {
    position: absolute;
    left: 0;
    top: 100%;
    padding: 1.5rem;
    background: #fff;
    border-radius: 4px;
    box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.25);
    z-index: 5;
  }
`;
