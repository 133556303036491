import React from 'react'
import get from 'lodash/get'
import { usePrograms } from 'hooks/usePrograms'
import ProgramPreview from './ProgramPreview'

export default function ProgramsList () {
  const programs = usePrograms()
  const filteredPrograms = programs.sort((a, b) => b.id - a.id);
  const parsedPrograms = React.useMemo(() => {
    return filteredPrograms.map(program => {
      const id = get(program, 'id')
      return (
        <ProgramPreview
          key={id}
          program={program}
        />
      )
    })
  }, [filteredPrograms])

  return parsedPrograms
}
