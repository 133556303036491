import React from 'react'
import get from 'lodash/get'

import { usePatinetProfile } from 'hooks/usePatients'

import { MediumText } from 'common/texts'
import PropertyValue from 'common/PropertyValue'

export default function PrimaryProvider () {
  const patinetProfile = usePatinetProfile()

  const isLoading = React.useMemo(() => !patinetProfile, [patinetProfile])

  const primaryProviderName = get(patinetProfile, 'primary_provider.name', '')
  const primaryProviderAddress = get(patinetProfile, 'primary_provider.address', '')
  const primaryProviderOfficeNumber = get(patinetProfile, 'primary_provider.office_number', '')

  return (
    <>
      <MediumText className='g-mt-30'>
        Primary care provider information
      </MediumText>

      <PropertyValue
        className='g-mt-5'
        isLoading={isLoading}
        property='Provider’s name'
        value={primaryProviderName}
      />
      <PropertyValue
        className='g-mt-5'
        isLoading={isLoading}
        property='Address'
        value={primaryProviderAddress}
      />
      <PropertyValue
        className='g-mt-5 g-mb-30'
        isLoading={isLoading}
        property='Phone'
        value={primaryProviderOfficeNumber}
      />
    </>
  )
}
