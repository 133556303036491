import React from 'react'

import { useIsQuizChanged } from 'hooks/patient/useQuestionnaires'

import RouterPrompt from 'common/RouterPrompt'

export default React.memo(function PromtListener () {
  const isChanged = useIsQuizChanged()
  return  <RouterPrompt when={isChanged} />
})
