import React from 'react'
import styled from 'styled-components'

import { RegularText } from 'common/texts'

export default function BeatsPerMinute ({ className = '', label = '', value = '' }) {
  return (
    <StyledWrapper className={className}>
      <RegularText className='label g-o-50'>{label}</RegularText>
      <RegularText className='value g-mt-10'>{value} bpm</RegularText>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  .label {
    font-size: 1.5rem;
  }
  .value {
    font-size: 2rem;
  }
`
