import React from 'react'
import get from 'lodash/get'

import { useProviderProfile } from 'hooks/useProviders'

import { MediumText } from 'common/texts'
import PropertyValue from 'common/PropertyValue'
import ChangeEmailButton from './change-email-button'

export default function Contactinformation () {
  const providerProfile = useProviderProfile()

  const isLoading = React.useMemo(() => !providerProfile, [providerProfile])

  const phone = get(providerProfile, 'phone', '')
  const email = get(providerProfile, 'email', '')
  const city = get(providerProfile, 'city', '')
  const state = get(providerProfile, 'state', '')
  const zip = get(providerProfile, 'zip', '')
  const address1 = get(providerProfile, 'address_1', '')
  const address2 = get(providerProfile, 'address_2', '')

  return (
    <>
      <MediumText className='g-mt-30'>
        Contact information
      </MediumText>

      <PropertyValue
        className='g-mt-5'
        isLoading={isLoading}
        property='Phone number'
        value={phone}
      />
      <PropertyValue
        className='g-mt-5'
        isLoading={isLoading}
        property='Email'
        value={email}
      />
      <PropertyValue
        className='g-mt-5'
        isLoading={isLoading}
        property='City'
        value={city}
      />
      <PropertyValue
        className='g-mt-5'
        isLoading={isLoading}
        property='State'
        value={state}
      />
      <PropertyValue
        className='g-mt-5'
        isLoading={isLoading}
        property='ZIP'
        value={zip}
      />
      <PropertyValue
        className='g-mt-5'
        isLoading={isLoading}
        property='Street Address 1'
        value={address1}
      />
      <PropertyValue
        className='g-mt-5 g-mb-30'
        isLoading={isLoading}
        property='Street Address 2'
        value={address2}
      />
    </>
  )
}
