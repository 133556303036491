import React from "react";

import { APPOINTMENTS, MONTH } from "constants/routes";

import { ColumnWidePlate } from "common/plate/plates";
import { PlateHeader, PlateContent } from "common/plate/styles";
import BackButton from "common/plate/BackButton";
import AppointmentsFilters from "components/appointmentsProvider/appointments-create/appointments-filters";

export default function AppointmentsCreate() {
  return (
    <ColumnWidePlate>
      <PlateHeader>
        <BackButton href={`/${APPOINTMENTS}/${MONTH}`} />
        Create appointment
      </PlateHeader>

      <PlateContent>
        <AppointmentsFilters />
      </PlateContent>
    </ColumnWidePlate>
  );
}
