import React from 'react'
import get from 'lodash/get'
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'
import styled from 'styled-components'

import { useQuiz, useSetQuiz } from 'hooks/patient/useQuestionnaires'

import { RegularMediumText } from 'common/texts'
import CustomRadio from 'common/CustomRadio'

export default React.memo(function SwitchGroup ({ className = '', metaPath, answerField }) {
  const title = useQuiz(current => get(current, `${metaPath}.title`, ''), [metaPath])
  const answer = useQuiz(current => get(current, `content.answers.${answerField}.value`, ''), [answerField])

  const answerHandler = useSetQuiz((prev, value) => {
    return set(cloneDeep(prev), `content.answers.${answerField}.value`, value)
  }, [answerField])

  const choices = useQuiz(current => get(current, `${metaPath}.choices`, []), [metaPath])

  const parsedOptions = React.useMemo(() => {
    return choices.map(({ value, text }, i) => {
      return (
        <CustomRadio
          key={value}
          className={i ? 'custom-input g-ml-20' : 'custom-input'}
          label={text}
          checked={answer === value}
          changeHandler={() => answerHandler(value)}
        />
      )
    })
  }, [choices, answer, answerHandler])

  return (
    <div className={`${className} g-mt-20`}>
      <RegularMediumText>{title}</RegularMediumText>

      <StyledWrapper className='g-mt-10 g-d-flex'>
        {parsedOptions}
      </StyledWrapper>
    </div>
  )
})

const StyledWrapper = styled.div`
  @media screen and (max-width: 480px) {
    flex-direction: column;
    .custom-input {
      margin-left: 0;
    }
  }
`
