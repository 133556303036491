import React from 'react'

import { RegularMediumText } from 'common/texts'

export default React.memo(function EmptyLogs () {
  return (
    <RegularMediumText className='g-mb-5'>
      Logs not found
    </RegularMediumText>
  )
})
