import React from 'react'
import isEqual from 'lodash/isEqual'

function identity (value) {
  return value
}

// Higher order hook for providing access to a single key of global state
export function createUseGlobalState (stateContext) {
  return (key) => {
    return (fn = identity, deps = []) => {
      const context = React.useContext(stateContext)
      const withFn = React.useCallback(fn, [...deps, fn])
      const stateObservable = context[key]
      const [localCopy, setLocalCopy] = React.useState(withFn(stateObservable.value))
      React.useEffect(() => {
        const sub = stateObservable.subscribe((...args) => {
          if (isEqual(localCopy, withFn(...args))) return
          setLocalCopy(withFn(...args))
        })
        return () => sub.unsubscribe()
      }, [stateObservable, localCopy, setLocalCopy, withFn])
      return localCopy
    }
  }
}

function defaultStateMapper (setter, nextValue) {
  return setter(nextValue)
}

// Higher order hook for providing access to updating a single key of global state
export function createUseSetGlobalState (stateContext) {
  return (key, mapper = defaultStateMapper) => {
    return (fn, deps = []) => {
      const withFn = React.useCallback(fn, [...deps, fn])
      const context = React.useContext(stateContext)
      const stateObservable = context[key]
      const setter = React.useCallback(next => stateObservable.next(next), [stateObservable])
      return React.useCallback((...args) => {
        return mapper(setter, withFn(stateObservable.value, ...args))
      }, [setter, stateObservable, withFn])
    }
  }
}
