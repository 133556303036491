import React from 'react'

import { BLOOD_PRESSURE } from 'constants/routes'; 

import { useDayData, useDaysData } from 'hooks/patient/widgets/useWidgets'

import DaySelector from 'common/widgets/day-selector'
import PressureList from 'components/patients/widgets/synchronized-widget/withings/blood-pressure/pressure/PressureList'
import BPMTemplate from 'components/patients/widgets/synchronized-widget/withings/blood-pressure/BPMTemplate'

export default React.memo(function PatientPressure () {
  const daysData = useDaysData('systolic')
  const dayData = useDayData()

  const label = React.useMemo(() => {
    const systolic = dayData.systolic
    const diastolic = dayData.diastolic
    if (!systolic || !diastolic) return null
    return `${systolic} / ${diastolic}`
  }, [dayData])

  return (
    <BPMTemplate currentTab={BLOOD_PRESSURE}>
      <DaySelector
        data={daysData}
        label={label}
        color='#2f80ed'
      />
      <PressureList />
    </BPMTemplate>
  )
})
