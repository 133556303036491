import React from 'react'

import { ReactComponent as IconCapsule } from 'assets/medication/capsule.svg'
import { ReactComponent as IconPill } from 'assets/medication/pill.svg'
import { ReactComponent as IconInjection } from 'assets/medication/injection.svg'
import { ReactComponent as IconLiquid } from 'assets/medication/liquid.svg'

const iconsConfig = [
  { value: 'capsule', label: <IconCapsule className='icon' /> },
  { value: 'pill', label: <IconPill className='icon' /> },
  { value: 'injection', label: <IconInjection className='icon' /> },
  { value: 'liquid', label: <IconLiquid className='icon' /> }
]

export default iconsConfig
