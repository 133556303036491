import React from "react";
import moment from "moment";
import get from "lodash/get";

import { usePatinetSummary } from "hooks/usePatients";

import { MediumText } from "common/texts";
import PropertyValue from "common/PropertyValue";
import UpdateMembershipButton from "./update-membership-button";
import ChangeVisitsButton from "./change-visits-button/index";
import { useUserContext } from "../../../context/userContext";
import { permissions } from "../../../permissions";

export default function CompanyDetails({ isLoading }) {
  const { can, role } = useUserContext();
  const patinetSummary = usePatinetSummary();
  const packageName = get(patinetSummary, "patient.package", "");
  const statusPackage = get(
    patinetSummary,
    "patient.status_package",
    ""
  ).toLowerCase();
  const expiresIn = get(patinetSummary, "patient.active_until_date", "");
  const parsedExpiresIn =
    expiresIn !== "n/a" ? moment(expiresIn).fromNow() : expiresIn;
  const nextBillDate = get(patinetSummary, "patient.next_bill_date", "");
  const parsedNextBillDate =
    nextBillDate !== "n/a"
      ? moment(nextBillDate).format("Do MMMM YYYY")
      : nextBillDate;

  const visits = get(patinetSummary, "visits", []);
  const parsedVisits = React.useMemo(() => {
    if (!can(permissions.patient.canChangeDoctorVisits)) return null;
    return visits.map((visit) => {
      const property = get(visit, "name", "");
      return (
        <PropertyValue
          key={property}
          className="g-mt-5"
          isLoading={isLoading}
          property={property}
          value={<ChangeVisitsButton {...visit} />}
        />
      );
    });
  }, [visits, isLoading, role]);

  const packageNameInfo = React.useMemo(() => {
    return (
      <PropertyValue
        className="g-mt-5"
        isLoading={isLoading}
        property="Package"
        value={
          packageName === "Type 2 Diabetes Reversal Program" ? (
            <UpdateMembershipButton label={packageName} />
          ) : (
            packageName
          )
        }
      />
    );
  }, [isLoading, packageName]);

  return (
    <>
      <MediumText className="g-mt-20">Company Membership</MediumText>

      {packageNameInfo}

      <PropertyValue
        className="g-mt-5"
        isLoading={isLoading}
        property="Status"
        value={statusPackage}
      />
      <PropertyValue
        className="g-mt-5"
        isLoading={isLoading}
        property="Membership expires in"
        value={<span className="g-red">{parsedExpiresIn}</span>}
      />
      <PropertyValue
        className="g-mt-5"
        isLoading={isLoading}
        property="Next Bill Date"
        value={parsedNextBillDate}
      />
      {parsedVisits}
    </>
  );
}
