import React from 'react'
import styled from 'styled-components'

import { MediumText, HintText } from 'common/texts'

import onlyPositive from 'utils/onlyPositive'
import valueToLabel from 'utils/widgets/valueToLabel'

export default function ReadinessData ({ data }) {
  const normalizedReadinessScore = onlyPositive(data.score)
  return (
    <StyledWrapper>
      <div className='value-wrapper'>
        <MediumText className='g-mb-15'>
          Readiness
        </MediumText>
        <span className='value g-mr-5'>
          {normalizedReadinessScore}
        </span>
        <HintText className='hint g-mb-15'>
          {valueToLabel(normalizedReadinessScore)}
        </HintText>
      </div>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  .value-wrapper {
    @media screen and (max-width: 480px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .value {
    font-size: 3rem;
    @media screen and (max-width: 480px) {
      margin: 0 0.5rem 1.5rem;
    }
  }
`
