import React from 'react'

import { useMedicationsFrequencies } from 'hooks/useOptions'
import { useSetMedicationProperty } from 'hooks/usePatients'

import CustomSelect from 'common/CustomSelect'

export default React.memo(function Frequency ({ className = '', index, frequencyId, validate }) {
  const medicationsFrequencies = useMedicationsFrequencies()
  const setMedicationFrequencyId = useSetMedicationProperty({ index, field: 'frequency_id' })
  return (
    <CustomSelect
      className={className}
      label='Frequency'
      placeholder='Daily'
      isLoading={!medicationsFrequencies}
      isRequred
      isValid={!validate || frequencyId}
      options={medicationsFrequencies}
      value={frequencyId}
      changeHandler={setMedicationFrequencyId}
    />
  )
})
