import React from 'react'
import styled from 'styled-components'

export default function MedicationsListPlaceholder () {
  return (
    <StyledWrapper>
      <div className='placeholder' />
      <div className='placeholder' />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  .placeholder {
    height: 9rem;
    margin-bottom: 2rem;
    border-radius: 0.8rem;
    background-color: #fafbfd;
    box-shadow: 0 0.2rem 0.6rem rgba(0, 0, 0, 0.1);
    @media only screen and (max-width: 480px) {
      height: 14rem;
    }
  }
`
