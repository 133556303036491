import React from 'react';
import styled from 'styled-components';
import UserInfo from '../../UserInfo';
import Menu from './Menu';

export default function Header() {
  return (
    <StyledWrapper>
      <Menu />
      <UserInfo />
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  display: none;
  /* @media only screen and (max-width: 480px) {
    padding: 2rem 1.5rem 1.6rem;
  } */
  @media only screen and (max-width: 1024px) {
    display: flex;
    justify-content: space-between;
    padding: 2rem 3.5rem 1.6rem;
    background: #f1f5f8;
    z-index: 4000;
  }
`;
