import React from 'react'
import styled from 'styled-components'

import { RegularText } from 'common/texts'
import UserAvatar from 'common/UserAvatar'

export default React.memo(function SearchItemPlaceholder () {
  return (
    <StyledWrapper>
      <UserAvatar className='avatar' />
      <RegularText className='name' />
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  cursor: pointer;
  :hover {
    background: #fbfbfb;
  }
  .avatar {
    width: 3.2rem;
    height: 3.2rem;
  }
  .name {
    margin-left: 1rem;
    height: 2.4rem;
    width: 70%;
    background: #d8d8d8;
  }
`
