// Date formatting utils
export const formatDate = (date) => {
  const d = new Date(date);
  d.setHours(12, 0, 0, 0); // Avoid timezone issues by setting a fixed time
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(d.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const generateDateRange = (start, end) => {
  const dates = [];
  let currentDate = new Date(start);
  while (currentDate <= end) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
};

export const checkIsToday = (date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

export const formatDateReadable = (dateString) => {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  return `${formattedDate} ${checkIsToday(date) ? '(today)' : ''}`;
};

//meals grouping utils
export function groupMealsByDate(meals) {
  // Sort meals by date in descending order
  meals.sort((a, b) => new Date(b.date) - new Date(a.date));
  // Group meals by date
  return meals.reduce((acc, meal) => {
    const date = meal.date;
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(meal);
    return acc;
  }, {});
}

export const groupMealsByType = (meals) => {
  return meals.reduce((acc, meal) => {
    if (!acc[meal.type]) {
      acc[meal.type] = [];
    }
    acc[meal.type].push(meal);
    return acc;
  }, {});
};

export function capitalizeFirstLetter(str) {
  if (str === null) {
    return "";
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}