import moment from 'moment';
import { GraphQLTypes, $, ValueTypes, TreatmentPlanStatus, TaskType } from 'zeus-graphql/my-health/zeus';
import { GQLArgs, useMutation } from '../client';
import { UPDATE_TREATMENT_PLAN } from '../constants';
import { TreatmentPlanType, TaskType as TT } from '../queries/getTreatmentPlan';
import omit from 'lodash/omit';

type MutationResponse = Record<'updateTreatmentPlan', GraphQLTypes['TreatmentPlanType'] | GraphQLTypes['TreatmentIsSentError']>;

type Vars = {
  treatmentPlanId: string;
  data: ValueTypes['TreatmentPlanUpdateInput'];
};

type Args = GQLArgs<MutationResponse>;

const convertMedications = (task: TT<'medications'>) => {
  if (task.type !== 'medications') return undefined;
  return omit(
    {
      ...task.details,
      type: 'medications',
      categoryId: task.details.categoryId || (task.details.category?.id as number),
      frequencyId: task.details.frequencyId || (task.details.frequency?.id as number),
      measureId: task.details.measureId || (task.details.measure?.id as number),
      quantity: Number(task.details.quantity || task.details.quantity),
    },
    'category',
    'frequency',
    'measure',
    'startDate',
    'start_date',
    'end_date',
    'endDate'
  );
};

export const prepareToUpdateTreatmentPlan = (plan: Partial<TreatmentPlanType>, patientId?: string): ValueTypes['TreatmentPlanInput'] => {
  return {
    status: (plan.status as TreatmentPlanStatus) || TreatmentPlanStatus.draft,
    summary: plan.summary as string,
    title: plan.title as string,
    patientId,
    tasks: plan.tasks
      ? plan.tasks?.map((task) => {
          return {
            startDate: task.startDate ? moment(task.startDate).format('YYYY-MM-DD') : undefined,
            endDate: task.endDate ? moment(task.endDate).format('YYYY-MM-DD') : undefined,
            activity: task.type === TaskType.activity ? { ...task.details, type: TaskType.activity } : undefined,
            diet: task.type === TaskType.diet ? { ...task.details, type: TaskType.diet } : undefined,
            lab: task.type === TaskType.labs ? task.details : undefined,
            medication: convertMedications(task as any),
            id: task.id || undefined,
            type: task.type as TaskType,
            files: task.files?.map((file) => file.id),
            materials: task.materials?.map((material) => material.materialVersion.id),
          };
        })
      : [],
  };
};

export const useUpdateTreatmentPlan = (args?: Args) => {
  const instance = useMutation(
    [UPDATE_TREATMENT_PLAN],
    {
      updateTreatmentPlan: [
        {
          treatmentPlanId: $`treatmentPlanId`,
          data: $`data`,
        },
        {
          '...on TreatmentIsSentError': {
            __typename: true,
            message: true,
          },
          '...on TreatmentPlanType': {
            id: true,
          },
        },
      ],
    },
    {
      errorTypes: [
        {
          __typename: 'TreatmentIsSentError',
          entityName: 'updateTreatmentPlan',
        },
      ],
      operationName: UPDATE_TREATMENT_PLAN,
    },
    args
  );

  const updateTreatmentPlan = instance.mutateAsync as (args: Vars) => Promise<MutationResponse>;

  return { ...instance, updateTreatmentPlan };
};
