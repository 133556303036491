import React from 'react'
import styled from 'styled-components'
import get from 'lodash/get'
import { useHistory, useParams } from 'react-router-dom'

import { PATIENTS, DETAILS, DOCTOR_NOTES, EDIT, PREVIEW } from 'constants/routes'; 

import { usePatinetDoctorNote, useSetPatinetDoctorNote } from 'hooks/usePatients'
import { useFetchDoctorNote } from 'requests/patients'

import { RegularMediumText, HintText } from 'common/texts'
import DetailsTemplate from 'components/patients/DetailsTemplate'
import CustomTextarea from 'common/CustomTextarea'
import CustomButton from 'common/CustomButton'

export default function PatientEditNoteStep3 () {
  const { patientId, noteId } = useParams()
  const history = useHistory()

  const fetchDoctorNote = useFetchDoctorNote()

  const isLoadedNote = usePatinetDoctorNote()

  const assessment = usePatinetDoctorNote(current => get(current, 'assessment', ''), [])
  const setAssessment = useSetPatinetDoctorNote((prev, assessment) => ({ ...prev, assessment }), [])

  const setCurrentPage = useSetPatinetDoctorNote((prev) => ({ ...prev, draftPage: 3 }), [])

  React.useEffect(() => {
    if (!isLoadedNote) fetchDoctorNote()
    // eslint-disable-next-line
  }, [isLoadedNote])

  React.useEffect(() => {
    setCurrentPage()
    // eslint-disable-next-line
  }, [])

  const toNextStep = React.useCallback(() => {
    history.push({
      pathname: `/${PATIENTS}/${DETAILS}/${patientId}/${DOCTOR_NOTES}/${noteId}/${EDIT}/${PREVIEW}`,
      state: { referrer: `/${PATIENTS}/${DETAILS}/${patientId}/${DOCTOR_NOTES}/${noteId}/${EDIT}/3` }
    })
  }, [history, patientId, noteId])

  const toPrevStep = React.useCallback(() => {
    history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${DOCTOR_NOTES}/${noteId}/${EDIT}/2`)
  }, [history, patientId, noteId])

  return (
    <DetailsTemplate currentTab={DOCTOR_NOTES}>
      <RegularMediumText>
        Assessment
      </RegularMediumText>

      <StyledCustomTextarea
        className='g-mt-20'
        label='Patient assessment'
        placeholder='Free text'
        isRequred
        isLoading={!isLoadedNote}
        isValid={assessment}
        value={assessment}
        changeHandler={setAssessment}
      />

      <StyledButtonGroup>
        <CustomButton
          clickHandler={toNextStep}
          disabled={!assessment}
        >
          Preview
        </CustomButton>

        <HintText
          className='prev-button g-ml-30'
          onClick={toPrevStep}
        >
          Previous
        </HintText>
      </StyledButtonGroup>
    </DetailsTemplate>
  )
}

const StyledCustomTextarea = styled(CustomTextarea)`
  textarea {
    min-height: 50rem;
  }
  .loader-input {
    min-height: 50rem;
  }
`

const StyledButtonGroup = styled.div`
  margin: 3.5rem auto 0 0;
  display: flex;
  align-items: center;
  .prev-button {
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
`
