import React from 'react'
import styled from 'styled-components'

import ChangeEmailModal from './ChangeEmailModal'

export default function ChangeEmailButton ({ email }) {
  const [isOpen, setIsOpen] = React.useState(false)

  const openModal = React.useCallback(() => setIsOpen(true), [setIsOpen])
  const closeModal = React.useCallback(() => setIsOpen(false), [setIsOpen])

  return (
    <>
      <StyledWrapper>
        <span>
          {email}
        </span>
        <span
          className='edit'
          onClick={openModal}
        >
          Edit
        </span>
      </StyledWrapper>
      {isOpen && (
        <ChangeEmailModal
          closeModal={closeModal}
        />
      )}
    </>
  )
}

const StyledWrapper = styled.span`
  .edit {
    color: rgba(34, 50, 50, 0.5);
    margin-left: 1.2rem;
    text-decoration: underline;
    cursor: pointer;
    :hover {
      text-decoration: none;
    }
  }
`
