import React from 'react'
import get from 'lodash/get'

import { useCompanyPatients } from 'hooks/useCompanies'

import PatientPreview from './PatientPreview'

export default function PatientsList () {
  const companyPatients = useCompanyPatients()

  const parsedPatinets = React.useMemo(() => {
    return companyPatients.map(patinet => {
      const id = get(patinet, 'id')
      return (
        <PatientPreview
          key={id}
          patinet={patinet}
        />
      )
    })
  }, [companyPatients])

  return parsedPatinets
}
