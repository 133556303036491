import React from 'react'
import moment from 'moment'
import get from 'lodash/get'

import { useMedicationsTab, useChartOffsets } from 'hooks/patient/useMedications'

import Grid from '../grid'
import GridLabels from './GridLabels'

export default React.memo(function MonthView ({ chartEl }) {
  const monthStart = useMedicationsTab(current => moment(get(current, 'from')))

  const config = React.useMemo(() => {
    const count = monthStart.daysInMonth()
    return (new Array(count)).fill().map((item, i) => {
      return monthStart.clone().add(i, 'days')
    })
  }, [monthStart])

  const chartOffsets = useChartOffsets(chartEl)

  return (
    <div className='g-flex-grow-1'>
      <Grid config={config} chartOffsets={chartOffsets} />
      <GridLabels config={config} />
    </div>
  )
})
