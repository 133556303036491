import React from 'react'

import { useInitialFetchUser } from 'requests/user'
import { useInitialChatData } from 'requests/messages'

export default React.memo(function InitialFetch () {
  const initialFetchUser = useInitialFetchUser()
  const initialChatData = useInitialChatData()
  React.useEffect(() => {
    initialFetchUser()
    initialChatData()
  }, [initialFetchUser, initialChatData])
  return null
})
