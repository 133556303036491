import React from 'react'
import styled from 'styled-components'

export default function MedicationsPlaceholder () {
  return (
    <StyledWrapper className='g-mt-15'>
      <div className='placeholder' />
      <div className='placeholder' />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  .placeholder {
    height: 9rem;
    margin-bottom: 2rem;
    background: #fafbfd;
    box-shadow: 0 0.2rem 0.6rem rgba(0, 0, 0, 0.1);
    border-radius: 0.8rem;
    @media only screen and (max-width: 480px) {
      height: 14rem;
    }
    &:nth-of-type(n+2) {
      margin-top: 2.5rem;
    }
  }
`
