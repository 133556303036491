import React from 'react'
import get from 'lodash/get'
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'
import styled from 'styled-components'

import { useQuiz, useSetQuiz, useSetQuizStep } from 'hooks/patient/useQuestionnaires'

import { RegularMediumText } from 'common/texts'
import CustomButton from 'common/CustomButton'

export default React.memo(function RadioGroup ({ className = '', metaPath, answerField }) {
  const navigateForward = useSetQuizStep(prev => +prev + 1, [])

  const title = useQuiz(current => get(current, `${metaPath}.title`, ''), [metaPath])
  const minWidth = useQuiz(current => get(current, `${metaPath}.minWidth`, 'initial'), [metaPath])
  const answer = useQuiz(current => get(current, `content.answers.${answerField}.value`, ''), [answerField])

  const answerHandler = useSetQuiz((prev, value) => {
    return set(cloneDeep(prev), `content.answers.${answerField}.value`, value)
  }, [answerField])

  const selectHandler = React.useCallback((value) => {
    answerHandler(value)
    navigateForward()
  }, [answerHandler, navigateForward])

  const choices = useQuiz(current => get(current, `${metaPath}.choices`, []), [metaPath])

  const parsedOptions = React.useMemo(() => {
    return choices.map(({ value, text }) => {
      return (
        <div
          key={value}
          className='g-mt-20'
        >
          <StyledCustomButton
            minWidth={minWidth}
            inversion={answer !== value}
            clickHandler={() => selectHandler(value)}
          >
            {text}
          </StyledCustomButton>
        </div>
      )
    }, [])
  }, [choices, answer, selectHandler, minWidth])

  return (
    <div className='g-mt-20'>
      <RegularMediumText>{title}</RegularMediumText>

      {parsedOptions}
    </div>
  )
})

const StyledCustomButton = styled(CustomButton)`
  min-width: ${({ minWidth }) => minWidth};
`
