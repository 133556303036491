import React from "react";

import { globalContext, createUseGlobalState, createUseSetGlobalState } from "hooks/useGlobalState";

export const useBookedAppointments = createUseGlobalState("bookedAppointments");
export const useSetBookedAppointments = createUseSetGlobalState("bookedAppointments");

export const useBookedAppointment = createUseGlobalState("bookedAppointment");
export const useSetBookedAppointment = createUseSetGlobalState("bookedAppointment");

export const useScheduledAppointments = createUseGlobalState("scheduledAppointments");
export const useSetScheduledAppointments = createUseSetGlobalState("scheduledAppointments");

export const useConsultation = createUseGlobalState("consultation");
export const useSetConsultation = createUseSetGlobalState("consultation");

export const useAppointment = createUseGlobalState("appointment");
export const useSetAppointment = createUseSetGlobalState("appointment");

export const useAppointmentsProviders = createUseGlobalState("appointmentsProviders");
export const useSetAppointmentsProviders = createUseSetGlobalState("appointmentsProviders");

export const useAppointmentCreate = createUseGlobalState("appointmentCreate");
export const useSetAppointmentCreate = createUseSetGlobalState("appointmentCreate");

export const useAppointments = createUseGlobalState("appointments");
export const useSetAppointments = createUseSetGlobalState("appointments");

export const useAppointmentsFilters = createUseGlobalState('appointmentsFilters')
export const useSetAppointmentsFilters = createUseSetGlobalState('appointmentsFilters')

export function useHandleDisconnect() {
  const { consultation } = React.useContext(globalContext);
  const clearConsultation = useSetConsultation(() => ({
    appointmentId: null,
    room: null,
    respondData: null,
    participants: [],
    mainVideoTracks: [],
    mainAudioTracks: [],
    localVideoTracks: [],
    isHidden: false,
    localAudioTracks: [],
    isMuted: false,
    chatChannel: null,
  }));
  return React.useCallback(() => {
    const { room, participants } = consultation.value;
    if (room && room.localParticipant.state === "connected") {
      participants.forEach((participant) => participant.removeAllListeners());
      room.localParticipant.tracks.forEach((trackPublication) => trackPublication.track.stop());
      room.localParticipant.removeAllListeners();
      room.disconnect();
      clearConsultation();
    }
  }, [consultation, clearConsultation]);
}
