import React from "react";
import styled from "styled-components";
import CustomSelect from "common/CustomSelect";

function findInterSectionInHoursRanges(hoursRanges) {
  const intersections = [];
  for (let i = 0; i < hoursRanges.length; i++) {
    const currentRange = hoursRanges[i];
    for (let j = i + 1; j < hoursRanges.length; j++) {
      const nextRange = hoursRanges[j];
      if (currentRange[1] > nextRange[0]) {
        intersections.push(j);
      }
    }
  }
  return intersections;
}

const generateTimeOptions = () => {
  const options = [];
  for (let i = 0; i < 24; i++) {
    const hour = i < 10 ? `${i}` : i;
    options.push({
      label: `${i === 0 ? 12 : i < 13 ? hour : hour - 12} ${
        i < 12 ? "AM" : "PM"
      }`,
      value: i,
    });
  }
  return options;
};

export const defaultRange = [6, 8];

const slots = generateTimeOptions();

const filterSlots = (slots, from) => {
  return (slots || []).filter((slot) => {
    return slot.value > from;
  });
};

export const TimeRanges = ({
  ranges = [defaultRange],
  onRangesChange,
  onHasError,
}) => {
  const addRange = () => {
    const lastRange = ranges[ranges.length - 1];
    const lastRangeTo = lastRange[1];
    const newRangeFrom = lastRangeTo < 22 ? lastRangeTo : defaultRange[0];
    const newRangeTo = lastRangeTo < 22 ? lastRangeTo + 1 : defaultRange[1];
    const newRanges = ranges.concat([[newRangeFrom, newRangeTo]]);

    onRangesChange && onRangesChange(newRanges);
  };

  const removeRange = (index) => {
    const newRanges = ranges.filter((_, i) => i !== index);
    onRangesChange && onRangesChange(newRanges);
  };

  const changeRange = React.useCallback(
    (index, value, type) => {
      const newRanges = ranges.map((r) => r.map((p) => p));
      const to = newRanges[index][1];
      newRanges[index][type] = value;
      if (type === 0 && value >= to) {
        newRanges[index][1] = value + 1;
      }

      onRangesChange && onRangesChange(newRanges);
    },
    [ranges, onRangesChange]
  );

  const errorIndexes = React.useMemo(() => {
    return findInterSectionInHoursRanges(ranges);
  }, [ranges]);

  React.useEffect(() => {
    const errors = findInterSectionInHoursRanges(ranges);
    if (errors.length > 0) {
      onHasError && onHasError(true);
    } else {
      onHasError && onHasError(false);
    }
  }, [errorIndexes]);

  return (
    <div>
      {ranges.map(([from, to], index) => {
        const hasError = errorIndexes.includes(index);
        return (
          <Root key={`range-${from}-${to}-${index}`}>
            <div className="range">
              <CustomSelect
                isValid={!hasError}
                isRequred
                className="time-select"
                changeHandler={(value) => changeRange(index, value, 0)}
                value={from}
                options={slots.slice(0, slots.length - 1)}
              />
              <div className="dash">-</div>
              <CustomSelect
                className="time-select"
                isValid={!hasError}
                isRequred
                value={to}
                changeHandler={(value) => changeRange(index, value, 1)}
                options={filterSlots(slots, from)}
              />
            </div>
            {ranges.length < 5 && index === ranges.length - 1 && (
              <div onClick={addRange} className="addRange">
                +
              </div>
            )}
            {ranges.length > 1 && index < ranges.length - 1 && (
              <div onClick={() => removeRange(index)} className="removeRange">
                +
              </div>
            )}
          </Root>
        );
      })}
    </div>
  );
};

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  .range {
    display: flex;
    width: 20rem;
    align-items: center;
  }

  .dash {
    margin: 0 1rem;
    color: #376480;
  }
  .addRange {
    font-size: 2.5rem;
    margin-left: 1rem;
    cursor: pointer;
  }
  .removeRange {
    font-size: 2.5rem;
    margin-left: 1rem;
    cursor: pointer;
    transform: rotate(45deg);
  }

  .time-select .react-dropdown-select {
    border-radius: 24px;
    padding: 3px 13px;
  }
`;
