import React from 'react'

import { REFERRAL } from 'constants/routes'; 

import { useSetReferral } from 'hooks/useReferral'
import { useFetchPatientReferral } from 'requests/referral'

import { MediumText } from 'common/texts'
import DetailsTemplate from 'components/patients/DetailsTemplate'
import Stats from 'components/patients/referral/Stats'
import ReferralLoader from 'components/patients/referral/referral-list/ReferralLoader'

export default function PatientReferral () {
  const fetchPatientReferral = useFetchPatientReferral()
  const clearReferral = useSetReferral(() => null)

  React.useEffect(() => {
    fetchPatientReferral()
    return clearReferral
    // eslint-disable-next-line
  }, [])

  return (
    <DetailsTemplate currentTab={REFERRAL}>
      <Stats />
      <MediumText className='g-mt-25'>
        List of invited friends
      </MediumText>
      <ReferralLoader />
    </DetailsTemplate>
  )
}
