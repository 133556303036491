import { Amplify, Auth } from "aws-amplify";
import { AWS_SOCIAL_LOGIN_DOMAIN, CLIENT_ID, USERPOOL_ID } from "../env";
import { saveSession } from "../utils/saveSession";
import { AmplifyUser, AmplifyUserSession } from "./cognito.types";


Amplify.configure({
  region: "us-east-2",
  userPoolId: `${USERPOOL_ID}`,
  userPoolWebClientId: `${CLIENT_ID}`,
  authenticationFlowType: "USER_PASSWORD_AUTH",
  oauth: {
    domain: AWS_SOCIAL_LOGIN_DOMAIN,
    scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
    redirectSignIn: `${window.location.origin}/login`,
    redirectSignOut: `${window.location.origin}/logout`,
    responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
});
export class Cognito {
  private static instance: Cognito;
  currentUser: AmplifyUser | null = null;
  idToken: string | null = null;
  accessToken: string | null = null;
  refreshToken: string | null = null;
  static getInstance() {
    if (!Cognito.instance) {
      Cognito.instance = new Cognito();
    }
    return Cognito.instance;
  }
  async getUser() {
    if (this.currentUser) return this.currentUser;
    const userPromise = Auth.currentAuthenticatedUser() as Promise<AmplifyUser>;
    this.currentUser = await userPromise;
    return this.currentUser;
  }

  async getSession(): Promise<AmplifyUserSession> {
    const session =
      (await Auth.currentSession()) as unknown as AmplifyUserSession;
    saveSession(session);
    return session;
  }
  refreshSession() {
    return this.getSession();
  }
  async sendOTPCode(
    code: string,
    type: "SOFTWARE_TOKEN_MFA" | null = "SOFTWARE_TOKEN_MFA"
  ) {
    const user = await this.getUser();
    await Auth.confirmSignIn(user, code, type);
    return this.getSession();
  }
  async verifyOTPCode(code: string) {
    const user = await this.getUser();
    await Auth.verifyTotpToken(user, code);
    await this.enableMultiFactorAuthOneTimePassword();
    return this.getSession();
  }
  async getMFAData() {
    const user = await this.getUser();
    return Auth.getPreferredMFA(user, {
      bypassCache: true,
    });
  }
  async associateSoftwareToken() {
    try {
      const user = await this.getUser();
      const session = await Auth.currentSession();
      const code = await Auth.setupTOTP(user);

      const payload = session.getIdToken().payload;

      const userName = payload["cognito:username"];
      const email = payload["email"];
      return `otpauth://totp/CIBA Health (${email || userName})?secret=${code}`;
    } catch (e) {
      console.log(e);

      throw e;
    }
  }
  async enableMultiFactorAuthOneTimePassword() {
    const user = await this.getUser();
    return Auth.setPreferredMFA(user, "TOTP");
  }
  async disableMultiFactorAuthOneTimePassword() {
    const user = await this.getUser();
    return Auth.setPreferredMFA(user, "NOMFA");
  }
  async signInWithEmail(
    username: string,
    password: string
  ): Promise<AmplifyUser> {
    const user = await Auth.signIn(username, password);
    this.currentUser = user;

    if (!user.challengeName) {
      await this.getSession();
    }
    return user;
  }
  async completeNewPassword(password: string) {
    const user = await this.getUser();
    await Auth.completeNewPassword(user, password);
    return this.getSession();
  }

  async signOut() {
    const user = await this.getUser();
    user.signOut();
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("idToken");
  }

  loginWithGoogle() {
    return Auth.federatedSignIn({
      provider: "Google" as any,
    });
  }

  sendForgotPasswordCode(username: string) {
    return Auth.forgotPassword(username);
  }

  forgotPassword(username: string, code: string, password: string) {
    return Auth.forgotPasswordSubmit(username, code, password);
  }

  async changePassword(oldPassword: string, newPassword: string) {
    const user = await this.getUser();
    return Auth.changePassword(user, oldPassword, newPassword);
  }
}
