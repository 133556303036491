import React from 'react'
import styled from 'styled-components'

export default React.memo(function TimeLine ({ label = '' }) {
  return (
    <StyledWrapper>
      <div className='label'>{label}</div>
      <div className='border' />
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  display: flex;
  height: 0;
  .label {
    width: 5rem;
    transform: translate(0, -0.6rem);
  }
  .border {
    flex-grow: 1;
    border-top: dashed 1px #a2c0d4;
  }
`
