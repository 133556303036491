import React from 'react'

export default React.memo(function PagesList ({ pagesCount, page, navigateHandler }) {
  let pagesList = new Array(pagesCount).fill(null).map((item, i) => i + 1)

  const navigateToPage = React.useCallback((item) => {
    if (page === item) return
    navigateHandler(item)
  }, [page, navigateHandler])

  if (pagesCount > 7) {
    if (page <= 4) {
      pagesList = pagesList.slice(0, 5)
      pagesList.push('...')
      pagesList.push(pagesCount)
    } else if (page > 3 && page < pagesCount - 3) {
      pagesList = []
      pagesList.push(1)
      pagesList.push('...')
      pagesList.push(page - 1, page, page + 1)
      pagesList.push('...')
      pagesList.push(pagesCount)
    } else if (page >= pagesCount - 3) {
      pagesList = pagesList.slice(pagesCount - 5)
      pagesList.unshift('...')
      pagesList.unshift(1)
    }
  }

  pagesList = pagesList.map((item, i) => {
    if (item === '...') {
      return (
        <div
          key={i}
          className='pagination-separator'
        >
          ...
        </div>
      )
    }

    return (
      <div
        key={i}
        className={`pagination-page ${item === page ? 'active' : ''}`}
        onClick={() => navigateToPage(item)}
      >
        {item}
      </div>
    )
  })

  return pagesList
})
