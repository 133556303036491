import React from 'react'

export default React.memo(function PatientFeelingNeutral () {
  return (
    <div>
      Do you have any worries?<br />
      If so, write to us and we'll try to find out what we can do to make you feel better!<br />
      Write directly to your provider or to this chat.
    </div>
  )
})
