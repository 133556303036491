import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { PATIENTS, DETAILS, QUESTIONNAIRES, QUIZ, METRICS } from 'constants/routes';
import { CONSENT_FORM } from 'constants/quiz-types'

import { useModalHandler } from 'hooks/useUtils'
import { useCheckQuizAvailability } from 'requests/patients/questionnaires'

import LinkButton from 'common/LinkButton'
import NotAvailableQuizModal from './common/NotAvailableQuizModal'

export default React.memo(function MetricsButton ({ id, type, total, step }) {
  const { patientId } = useParams()
  const history = useHistory()
  const checkQuizAvailability = useCheckQuizAvailability()
  const [isOpen, openModal, closeModal] = useModalHandler()
  const isCompleted = total === step

  const handleClick = React.useCallback(async () => {
    const isAvailable = await checkQuizAvailability(id)
    if (!isAvailable) return openModal()
    history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${QUESTIONNAIRES}/${QUIZ}/${id}/${METRICS}`)
  }, [history, patientId, id, checkQuizAvailability, openModal])

  if (!isCompleted || type === CONSENT_FORM) return null

  return (
    <>
      <LinkButton
        className='g-w-100'
        onClick={handleClick}
      >
        Metrics
      </LinkButton>
      {isOpen && (
        <NotAvailableQuizModal closeModal={closeModal} />
      )}
    </>
  )
})
