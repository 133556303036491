import React from 'react'
import styled from 'styled-components'
import get from 'lodash/get'

import { useProfile } from 'hooks/useProfile'

import { RegularText, MediumText, UppercaseText } from 'common/texts'
import { StyledLine } from './styles'
import UserAvatar from 'common/UserAvatar'

export default React.memo(function DoctorInfo ({ appointment }) {
  const profile = useProfile()

  const avatar = get(profile, 'avatar', null)
  const firstName = get(appointment, 'provider.first_name', '')
  const lastName = get(appointment, 'provider.last_name', '')
  const fullName = get(appointment, 'provider.full_name', '')
  const qualifications = get(appointment, 'provider.qualifications', []).map(({ label }) => label).join(', ')

  return (
    <StyledLine>
      <div className='g-d-flex'>
        <StyledDoctorAvatar
          avatar={avatar}
          firstName={firstName}
          lastName={lastName}
        />
        <div className='g-ml-15'>
          <RegularText className='g-d-flex'>
            <StyledCheckmark />
            Selected provider
          </RegularText>
          <MediumText>
            {fullName}
          </MediumText>
          <UppercaseText>
            {qualifications}
          </UppercaseText>
        </div>
      </div>
    </StyledLine>
  )
})

const StyledDoctorAvatar = styled(UserAvatar)`
  width: 5.3rem;
  height: 5.3rem;
  align-self: center;
`

const StyledCheckmark = styled.span`
  display: block;
  position: relative;
  width: 1.6rem;
  height: 1.6rem;
  background: #43cb1d;
  border-radius: 50%;
  margin: 2px 0.5rem 0 0;
  :after {
    content: '';
    position: absolute;
    left: 0.7rem;
    top: 0.4rem;
    display: block;
    width: 0.3rem;
    height: 0.6rem;
    border: solid 2px #fff;
    border-top: none;
    border-left: none;
    transform: rotate(45deg);
  }
`
