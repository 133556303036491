import React, { FC, useCallback } from 'react';
import classNames from 'clsx';

import { ReactComponent as PDFIcon } from 'assets/pdf.svg';
import { ReactComponent as CloseIcon } from 'assets/close2.svg';
import { useTaskFileUrl } from '../../../../requests/graphql/my-health/queries/getTaskFileUrl';

type FileTypes = 'application/pdf';

type AttachedFileProps = {
  className?: string;
  id?: string;
  filename?: string;
  loading?: boolean;
  type: FileTypes;
  onDelete?: () => void;
};

const fileTypes: Record<FileTypes, FC<React.SVGProps<SVGSVGElement>>> = {
  'application/pdf': PDFIcon,
};

export const AttachedFile: FC<AttachedFileProps> = ({ type, id, filename, onDelete, className, loading }) => {

  const { file } = useTaskFileUrl(id);
  const downloadFile = useCallback(() => {
    if (onDelete) return;
    window.open(file, '_blank');
  }, [file, onDelete]);
  const Icon = fileTypes[type || 'application/pdf'] || fileTypes['application/pdf'];
  return (
    <div
      onClick={downloadFile}
      className={classNames('bg-catskillWhite w-fit rounded-md flex items-center justify-start px-3 py-2 border', className, {
        'animate-pulse border border-dashed': loading,
        'cursor-pointer': !onDelete,
      })}
    >
      <div className="flex items-center">
        <Icon />
        <div className="ml-2.5 text-1420 font-ambit font-semibold text-main">{filename}</div>
        {onDelete && <CloseIcon className="cursor-pointer stroke-danube ml-2.5" onClick={onDelete} />}
      </div>
    </div>
  );
};
