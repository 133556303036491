import React from 'react';
import styled from 'styled-components';
import get from 'lodash/get';

import { useAppointmentCreate, useSetAppointmentCreate } from 'hooks/useAppointments';
import { useFetchQualifications, useFetchProfileQualification } from 'requests/profile';

import CustomSelect from 'common/CustomSelect';
import { useProfileQualificationsStore } from '../../../stores/profile/qualificationStores';
import { useDictionaryStore } from "../../../stores/dictionaryStore";

export default function QualificationSelect(props) {
  const qualification = useAppointmentCreate((current) => get(current, 'qualification'));
  const setQualification = useSetAppointmentCreate((prev, next) => ({ ...prev, qualification: next }));

  const allQualifications = useDictionaryStore((state) => state.qualificationsOptions);
  const qualificationIds = useProfileQualificationsStore((state) => get(state, 'userQualifications.qualifications', []));

  const fetchQualifications = useFetchQualifications();
  const fetchProfileQualification = useFetchProfileQualification(props.providerId);

  React.useEffect(() => {
    fetchQualifications();
    fetchProfileQualification();
    // eslint-disable-next-line
  }, []);

  const options = React.useMemo(() => {
    if (!allQualifications || !qualificationIds) return [];
    return allQualifications.filter((qualification) => qualificationIds.includes(+qualification.value));
  }, [allQualifications, qualificationIds]);

  return (
    <StyledCustomSelect
      className="g-mt-10"
      label="Qualification"
      placeholder="Select qualification"
      isLoading={!allQualifications || !qualificationIds}
      options={options}
      value={qualification}
      changeHandler={setQualification}
    />
  );
}

const StyledCustomSelect = styled(CustomSelect)`
  max-width: initial;
  width: auto;
`;
