import React from 'react'
import { useParams } from 'react-router-dom'

import { PATIENTS, DETAILS, MEDICATIONS } from 'constants/routes'; 

import { PlateHeader } from 'common/plate/styles'
import BackButton from 'common/plate/BackButton'

export default function Header () {
  const { patientId } = useParams()
  return (
    <PlateHeader>
      <BackButton href={`/${PATIENTS}/${DETAILS}/${patientId}/${MEDICATIONS}`} />
      Create medication list
    </PlateHeader>
  )
}
