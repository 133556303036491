import React from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { PATIENTS, DETAILS, MEDICATIONS, CREATE } from 'constants/routes'; 

import CustomButton from 'common/CustomButton'

export default function NewMedicationsListButton () {
  const { patientId } = useParams()
  const history = useHistory()

  const navigateToCreateMedicationsList = React.useCallback(() => {
    history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${MEDICATIONS}/${CREATE}`)
  }, [history, patientId])

  return (
    <CustomButton
      className='g-mt-20'
      clickHandler={navigateToCreateMedicationsList}
    >
      Add new medication list
    </CustomButton>
  )
}
