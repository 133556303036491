import React from 'react'

import { useProviderAdminNotes } from 'hooks/useProviders'

import AdminNotePreview from './AdminNotePreview'

export default function AdminNotesList () {
  const providerAdminNotes = useProviderAdminNotes()
  return React.useMemo(() => {
    return providerAdminNotes.map(note => {
      return (
        <AdminNotePreview
          key={note.id}
          note={note}
        />
      )
    })
  }, [providerAdminNotes])
}
