import React from 'react'
import { Markup } from 'interweave'
import get from 'lodash/get'

export default React.memo(function LeftFromChannel ({ message }) {
  const body = get(message, 'body')
  return (
    <Markup content={body} />
  )
})
