import React from "react";
import get from "lodash/get";

import { usePatinetProfile } from "hooks/usePatients";

import { MediumText } from "common/texts";
import PropertyValue from "common/PropertyValue";
import EmailVerification from "./EmailVerification";
import { useUserContext } from "../../../context/userContext";
import { permissions } from "../../../permissions";

export default function Contactinformation() {
  const patinetProfile = usePatinetProfile();
  const { can } = useUserContext();

  const isLoading = React.useMemo(() => !patinetProfile, [patinetProfile]);

  const phone = get(patinetProfile, "phone", "");
  const email = get(patinetProfile, "email", "");
  const address1 = get(patinetProfile, "address_1", "");
  const city = get(patinetProfile, "city", "");
  const stateContraction = get(patinetProfile, "state", "");
  const zip = get(patinetProfile, "zip", "");
  const patientAddress = `${address1}, ${city}, ${stateContraction} ${zip}`;
  const address2 = get(patinetProfile, "address_2", "");

  return (
    <>
      <MediumText className="g-mt-30">Contact information</MediumText>

      <PropertyValue
        className="g-mt-5"
        isLoading={isLoading}
        property="Phone number"
        value={phone}
      />
      <PropertyValue
        className="g-mt-5"
        isLoading={isLoading}
        property="Email"
        value={email}
      />
      <PropertyValue
        className="g-mt-5"
        isLoading={isLoading}
        property="Patient address"
        value={patientAddress}
      />
      <PropertyValue
        className="g-mt-5"
        isLoading={isLoading}
        property="Street Address 2"
        value={address2}
      />
      {can(permissions.patient.canVerifyEmail) && <EmailVerification />}
    </>
  );
}
