import React from 'react'
import styled from 'styled-components'

import { RegularText } from 'common/texts'
import { ReactComponent as EmptyIcon } from 'assets/medication/empty.svg'
import { ReactComponent as DoneIcon } from 'assets/medication/done.svg'

function Result ({ progress }) {
  if (progress === 100) return (<DoneIcon className='tooltip-icon g-ml-auto' />)
  if (progress === 0) return (<EmptyIcon className='tooltip-icon g-ml-auto' />)
  return (<span className='tooltip-percent g-d-flex g-align-center g-shrink-0'>{`${progress}%`}</span>)
}

export default function TooltipRow ({ medicationName, progress }) {
  return (
    <StyledWrapper className='g-d-flex'>
      <RegularText className='tooltip-text g-flex-grow-1'>
        <span>{medicationName}</span>
      </RegularText>
      <Result progress={progress} />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  align-items: flex-start;
  .tooltip-text {
    position: relative;
    margin-right: 1.4rem;
    &::after {
      content: '';
      position: absolute;
      top: 0.9rem;
      left: 0;
      width: 100%;
      height: 0;
      border-top: 0.1rem dashed #dfdfdf;
    }
    span {
      position: relative;
      z-index: 1;
      background: #ffffff;
      padding-right: 0.5rem;
    }
  }
  .tooltip-icon {
    width: 1.8rem;
    height: 1.8rem;
    flex-shrink: 0;
  }
  .tooltip-percent {
    height: 1.8rem;
  }
`
