import React from 'react'
import moment from 'moment'
import styled from 'styled-components'

import { useSetWidgetFilters } from 'hooks/patient/widgets/useWidgets'

import toRelative from 'utils/toRelative'

const Bar = React.memo(function Bar ({ index, date, dayData, maxValue, color }) {
  const { value } = dayData
  const selectDate = useSetWidgetFilters(prev => ({
    ...prev,
    selectedDay: date,
    start: moment(date).subtract(30, 'days').format('YYYY-MM-DD'),
    end: moment(date).add(7, 'days').format('YYYY-MM-DD')
  }), [date])
  return (
    <StyledBar
      className='bar'
      onClick={selectDate}
      index={index}
      height={toRelative(value, maxValue)}
      color={color}
    />
  )
})

const StyledBar = styled.div`
  position: absolute;
  bottom: 0;
  left: ${({ index }) => (100 / 15) * index + (100 / 15 / 2)}%;
  margin: 0 auto;
  width: 5%;
  height: ${({ height }) => height || 0}%;
  background: ${({ color }) => color};
  transform: translate(-50%, 0);
  transition: all 0.2s;
  cursor: pointer;
`

export default React.memo(function Bars ({ data = [], daysList, color }) {
  const maxValue = Math.max(...data.map(({ value }) => value))
  return (
    <StyledWrapper className='g-mt-10'>
      {daysList.map((date, i) => {
        const dayData = data.find(item => item.date === date) || {}
        return (
          <Bar
            key={date}
            index={i}
            date={date}
            dayData={dayData}
            maxValue={maxValue}
            color={color}
          />
        )
      })}
      <div className='day-line' />
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  justify-content: center;
  align-items: flex-end;
  height: 20rem;
  border-bottom: solid 1px #e9ebef;
  .day-line {
    position: absolute;
    left: 50%;
    bottom: -1.8rem;
    height: 22.5rem;
    width: 2px;
    background: #16123f;
    transform: translate(-50%, 0);
  }
`
