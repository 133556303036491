import React from 'react'
import get from 'lodash/get'

import { useRequestsLoading } from 'hooks/useUtils'
import { useConsultation } from 'hooks/useAppointments'

import ExpirationModal from './ExpirationModal'

const timeBeforeOpen = 60 * 60 * 1000

export default React.memo(function ExpirationModalWrapper () {
  const requestsLoading = useRequestsLoading()
  const [isOpen, setIsOpen] = React.useState(false)
  const timeoutId = React.useRef()
  const participants = useConsultation(current => get(current, 'participants', []))

  React.useEffect(() => {
    clearTimeout(timeoutId.current)
    timeoutId.current = setTimeout(() => {
      setIsOpen(true)
    }, timeBeforeOpen)
    if (participants.length !== 0) {
      clearTimeout(timeoutId.current)
    }
    return () => clearTimeout(timeoutId.current)
  }, [requestsLoading, isOpen, timeoutId, setIsOpen, participants])

  const closeModal = React.useCallback(() => setIsOpen(false), [setIsOpen])

  if (!isOpen) return null

  return (
    <ExpirationModal closeModal={closeModal} />
  )
})
