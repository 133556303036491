import React from 'react'

import { FlexLine } from 'common/plate/styles'
import ChatsList from 'components/messages/ChatsList'
import CreateGroupContent from 'components/messages/create-group-content'

export default function CreateGroup () {
  return (
    <FlexLine>
      <ChatsList withHidden />

      <CreateGroupContent />
    </FlexLine>
  )
}
