import React from 'react'
import styled from 'styled-components'

export default function CalendarHeader ({
  children, onPrev, onNext
}) {
  return (
    <StyledWrapper>
      <div
        className='arrow left-arrow'
        onClick={onPrev}
      />
      {children}
      <div
        className='arrow right-arrow'
        onClick={onNext}
      />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fafbfd;
  padding: 1.5rem 0;
  border-bottom: solid 1px #e9ebef;
  .arrow {
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    border: solid 3px #1d3332;
    transform: rotate(45deg);
    cursor: pointer;
  }
  .left-arrow {
    left: 2rem;
    border-right: none;
    border-top: none;
    transform: rotate(45deg);
  }
  .right-arrow {
    right: 2rem;
    border-left: none;
    border-bottom: none;
  }
`
