import React from 'react'
import styled from 'styled-components'
import get from 'lodash/get'

import { HEALTH_PLANS } from 'constants/routes'; 

import { usePatientHealthPlan, useSetPatientHealthPlan } from 'hooks/usePatients'
import { useCreatePatinetHealthPlan } from 'requests/patients'

import { RegularMediumText, RegularBigText } from 'common/texts'
import DetailsTemplate from 'components/patients/DetailsTemplate'
import CustomTextarea from 'common/CustomTextarea'
import CustomButton from 'common/CustomButton'
import UploadFile from 'components/patients/health-plan/UploadFile'

export default function PatientHealthPlan () {
  const [isSended, setIsSended] = React.useState(false)

  const healthPlan = usePatientHealthPlan(current => get(current, 'healthPlan', ''))
  const setHealthPlan = useSetPatientHealthPlan((prev, healthPlan) => ({ ...prev, healthPlan }), [])

  const createPatinetHealthPlan = useCreatePatinetHealthPlan()

  const sendHandler = React.useCallback(() => {
    createPatinetHealthPlan(setIsSended)
  }, [createPatinetHealthPlan, setIsSended])

  return (
    <DetailsTemplate currentTab={HEALTH_PLANS}>
      <RegularMediumText className='g-mb-10'>
        Health plan
      </RegularMediumText>

      <StyledCustomTextarea
        label='Write health plan for patient'
        placeholder='Free text'
        value={healthPlan}
        changeHandler={setHealthPlan}
      />

      <RegularBigText className='g-mt-20'>
        Attach the file
      </RegularBigText>
      <UploadFile />

      <StyledCustomButton
        disabled={isSended}
        clickHandler={sendHandler}
      >
        Send
      </StyledCustomButton>
    </DetailsTemplate>
  )
}

const StyledCustomTextarea = styled(CustomTextarea)`
  textarea {
    min-height: 30rem;
  }
  .loader-input {
    min-height: 30rem;
  }
`

const StyledCustomButton = styled(CustomButton)`
  margin: 4.5rem auto 0 0;
`
