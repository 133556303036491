import React from 'react';

export type Option<T extends string = string> = {
  label: string;
  value: T;
  disabled?: boolean;
  icon?: (el: { option: Option<T>; isActive: boolean; index: number }) => JSX.Element;
};

export type BasicProps<T extends string = string> = {
  items: Option<T>[];
  activeItem?: Option<T>;
  onChange: (item: Option<T>) => void;
};

type SwitchProps<T extends string> = {
  items: Option<T>[];
  activeItem?: Option<T>;
  className?: string;
  itemClassName?: string;
  onChange: (pill: Option<T>) => void;
  element: (el: { option: Option<T>; isActive: boolean; index: number; icon?: JSX.Element; disabled?: boolean }) => JSX.Element;
};

export const useSwitch = <T extends string = string>({
  items,
  onChange,
  defaultItem,
}: {
  items: Option<T>[];
  defaultItem?: Option<T>;
  onChange?: (item: Option<T>) => void;
}) => {
  const [activeItem, setActiveItem] = React.useState<Option<T> | undefined>(defaultItem || items?.[0]);

  React.useEffect(() => {
    onChange?.(activeItem!);
  }, [activeItem]);

  return [activeItem, setActiveItem] as const;
};

export function Switch<T extends string = string>({ items, activeItem, className, itemClassName, onChange, element }: SwitchProps<T>) {
  return (
    <div className={className}>
      {items.map((item, index) => (
        <div className={itemClassName} onClick={() => onChange(item)} key={item.label}>
          {element({
            option: item,
            isActive: activeItem?.value === item?.value,
            index,
            disabled: item.disabled,
            icon: item?.icon?.({ option: item, isActive: activeItem?.value === item?.value, index }),
          })}
        </div>
      ))}
    </div>
  );
}
