import React from 'react'

import { RegularMediumText } from 'common/texts'

export default React.memo(function EmptyOverview () {
  return (
    <RegularMediumText>
      Patient did not indicate any information
    </RegularMediumText>
  )
})
