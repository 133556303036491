import React from 'react'

import { HEALTH_PLANS } from 'constants/routes';
import { useParams } from 'react-router-dom'
import get from 'lodash/get'
import styled from 'styled-components'

import { useFetchPatinetHealthPlan, useEditPatientHealthPlan } from 'requests/patients'
import { usePatientHealthPlan, useSetPatientHealthPlan} from 'hooks/usePatients'

import { RegularMediumText, RegularBigText } from 'common/texts'
import DetailsTemplate from 'components/patients/DetailsTemplate'
import CustomTextarea from 'common/CustomTextarea'
import CustomButton from 'common/CustomButton'
import UploadFile from 'components/patients/health-plan/UploadFile'
import ListPreviewPlaceholder from 'common/loaders/ListPreviewPlaceholder'

const PatientHealthPlan = React.memo(function PatientHealthPlan () {
  const patientHealthPlan = usePatientHealthPlan()
  const editPatientHealthPlan = useEditPatientHealthPlan()
  const setHealthPlan = useSetPatientHealthPlan((prev, text) => ({ ...prev, text }))

  const sendHandler = React.useCallback(() => {
    editPatientHealthPlan()
  }, [editPatientHealthPlan])

  const isLoading = React.useMemo(() => !patientHealthPlan, [patientHealthPlan])
  if (isLoading) return (<ListPreviewPlaceholder />)
  const text = get(patientHealthPlan, 'text')

  return (
    <>
      <StyledCustomTextarea
        label='Write health plan for patient'
        value={text}
        changeHandler={setHealthPlan}
      />

      <RegularBigText className='g-mt-20'>
        Attach the file
      </RegularBigText>
      <UploadFile/>

      <StyledCustomButton
        clickHandler={sendHandler}
      >
        Send
      </StyledCustomButton>

    </>
  )
});

const StyledCustomTextarea = styled(CustomTextarea)`
  textarea {
    min-height: 30rem;
  }
  .loader-input {
    min-height: 30rem;
  }
`
const StyledCustomButton = styled(CustomButton)`
  margin: 4.5rem auto 0 0;
`


export default function EditHealthPlan () {
  const { healthPlanID } = useParams()
  const fetchPatinetHealthPlan = useFetchPatinetHealthPlan(healthPlanID);

  React.useEffect(() => {
    fetchPatinetHealthPlan()
    // eslint-disable-next-line
  }, [fetchPatinetHealthPlan])

  return (
    <DetailsTemplate currentTab={HEALTH_PLANS}>
      <RegularMediumText className='g-mb-10'>
       Edit health plan
      </RegularMediumText>
      <PatientHealthPlan/>
    </DetailsTemplate>
  )
}


