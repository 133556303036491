import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

export default function SubModal ({
  children, background = '#fff', minWidth, maxWidth, title = '', closeModal
}) {
  return ReactDOM.createPortal(
    <StyledWrapper
      onClick={closeModal}
      minWidth={minWidth}
      maxWidth={maxWidth}
      background={background}
    >
      <div
        className='container'
        onClick={React.useCallback((e) => e.stopPropagation(), [])}
      >
        <div className='header'>
          <div className='title'>{title}</div>

          <div
            className='close-container'
            onClick={closeModal}
          >
            <div className='cross cross-left' />
            <div className='cross cross-right' />
          </div>
        </div>

        {children}
      </div>
    </StyledWrapper>,
    document.getElementById('sub-modal')
  )
}

const StyledWrapper = styled.div`
  overflow-y: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 10000;
  backdrop-filter: blur(5px);
  .container {
    margin: 20vh auto auto;
     min-width: ${({ minWidth }) => minWidth || 60}rem;
    max-width: ${({ maxWidth }) => maxWidth ? maxWidth + 'rem' : 'unset'};
    background: ${({ background }) => background};
    padding: 2.5rem 2.5rem 3rem;
    box-shadow: 0 1px 8px 0 rgba(20, 46, 110, 0.1);
    @media only screen and (max-width: 480px) {
      min-width: unset;
      width: 85%;
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    .title {
      font-size: 1.8rem;
      font-weight: 600;
    }
  }
  .close-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 2.8rem;
    height: 2.8rem;
    border: solid 2px #223232;
    border-radius: 50%;
    transition: all 0.2s;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
    &:active {
      opacity: 1;
      box-shadow: 0 0 0 0.3rem rgba(0,0,0,0.25);
    }
    .cross {
      width: 2px;
      height: 1.3rem;
      background: #223232;
      position: absolute;
      border-radius: 1px;
    }
    .cross-left {
      transform: rotate(45deg);
    }
    .cross-right {
      transform: rotate(-45deg);
    }
  }
`
