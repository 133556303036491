import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import get from 'lodash/get';

import { SETTINGS, PASSWORD, TRAINING_AND_RESOURCES, REFERRAL, FRIEND, LOGOUT } from 'constants/routes';

import { ReactComponent as Settings } from 'assets/settings.svg';
import IconLink from 'common/IconLink';
import IconAnchor from 'common/IconAnchor';

import useTranslations from 'translations';
import { useUserContext } from '../../../context/userContext';

export default React.memo(function Footer() {
  const { pathname } = useLocation();
  const translations = useTranslations();
  const { isAdmin } = useUserContext();
  const translatePath = 'footer';
  const settings = get(translations, `${translatePath}.settings`);
  const help = get(translations, `${translatePath}.help`);
  const refer = get(translations, `${translatePath}.refer`);
  const logout = get(translations, `${translatePath}.logout`);

  const logoutUrl = `/${LOGOUT}`;

  return (
    <StyledWrapper>
      <IconLink value={settings} href={`/${SETTINGS}/${PASSWORD}`} BeforeIcon={Settings} isActive={pathname.includes(`/${SETTINGS}`)} />

      <div className="link-wrapper">
        {!isAdmin && (
          <>
            <IconLink value="Training and resources" href={`/${TRAINING_AND_RESOURCES}`} />
            <IconAnchor className="g-ml-30" value={help} href="https://cibahealth.com/faq-page" target="_blank" />
            <IconLink className="g-ml-30" value={refer} href={`/${REFERRAL}/${FRIEND}`} isActive={pathname.includes(`/${REFERRAL}`)} />
          </>
        )}
        <IconLink className="g-ml-30" value={logout} href={logoutUrl} isActive={pathname.includes(logoutUrl)} />
      </div>
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  display: none;
  .link-wrapper {
    display: flex;
  }
  @media only screen and (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    margin-top: 2.5rem;
  }
`;
