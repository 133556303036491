import React from 'react'
import get from 'lodash/get'
import styled from 'styled-components'

import { HintText } from 'common/texts'

import ListValues from './ListValues'

export default function List ({ answers = [] }) {
  return (
    <StyledList>
      {answers.map((answer, i) => {
        const values = get(answer, 'values', [])

        return (
          <li
            key={i}
            className='g-mt-15'
          >
            <HintText className='g-mb-5'>{answer.title}</HintText>
            <ListValues values={values} />
          </li>
        )
      })}
    </StyledList>
  )
}

const StyledList = styled.ul`
  list-style: none;
  padding: 0 2rem 1rem;
  p {
    line-height: 1.3;
  }
`
