import React from 'react'
import { useHistory } from 'react-router-dom'

import { APPOINTMENTS, MONTH } from 'constants/routes'; 

import CustomTextarea from 'common/CustomTextarea'
import CustomButton from 'common/CustomButton'

import request from 'utils/request'
import createNotification from 'utils/createNotification'

export default React.memo(function CancelConfirmation ({ appointmentId }) {
  const history = useHistory()
  const [text, setText] = React.useState('')

  const cancelHandler = React.useCallback(() => {
    request({
      method: 'delete',
      url: `/appointments/${appointmentId}`,
      data: {
        text
      }
    })
      .then(() => {
        createNotification({ message: 'Appointment successfully canceled', type: 'success' })
        history.push(`/${APPOINTMENTS}/${MONTH}`)
      })
      .catch((error) => console.log(error))
  }, [appointmentId, text, history])

  return (
    <>
      <CustomTextarea
        className='g-mt-20'
        placeholder='Type the reason'
        value={text}
        changeHandler={setText}
      />

      <CustomButton
        className='g-mt-20'
        clickHandler={cancelHandler}
        disabled={!text}
      >
        Yes, cancel appointment
      </CustomButton>
    </>
  )
})
