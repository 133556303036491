// auth routes
export const LOGIN = 'login'
export const TWO_FA_LOGIN = 'two-fa-login'
export const FORGOT_PASSWORD = 'forgot-password'
export const LOGOUT = 'logout'

// profile routes
export const PROFILE = 'profile'
export const INFORMATION = 'information'
export const QUALIFICATION = 'qualification'
export const AVAILABILITY = 'availability'

// sidebar routes
export const DASHBOARD = 'dashboard'
export const PATIENTS = 'patients'
export const COMPANIES = 'companies'
export const PROVIDERS = 'providers'
export const APPOINTMENTS = 'appointments'
export const APPOINTMENTS_ADMIN = 'appointments-admin'
export const MESSAGES = 'messages'
export const REPORTS = 'reports'
export const LIBRARY = 'library'
export const PROGRAMS = 'programs'

// extra dashboard routes
export const ACTIVITY_LOG = 'activity-log'
// footer routes
export const SETTINGS = 'settings'
export const TRAINING_AND_RESOURCES = 'training-and-resources'

// extra patients routes
export const ACTIVE = 'active'
export const INACTIVE = 'inactive'
export const DETAILS = 'details'
export const OVERVIEW = 'overview'
export const SUMMARY = 'summary'
export const TREATMENT_PLANS = 'treatment-plans'
export const QUESTIONNAIRES = 'questionnaires'
export const QUIZ = 'quiz'
export const PRE_ASSESSMENT = 'pre-assessment'
export const PATIENT_MEDICAL_HISTORY = 'patient-medical-history'
export const MENTAL_HEALTH = 'mental-health'
export const RESULTS = 'results'
export const METRICS = 'metrics'
export const PROGRESS = 'progress'
export const HEALTH_PLANS = 'health-plans'
export const DOCTOR_NOTES = 'doctor-notes'
export const CREATE = 'create'
export const EDIT = 'edit'
export const VIEW = 'view'
export const PREVIEW = 'preview'
export const LABS = 'labs'
export const DOCUMENTS = 'documents'
export const REFERRAL = 'referral'
export const ADMIN_NOTES = 'admin-notes'
export const INSURANCE = 'insurance'
export const MEDICATIONS = 'medications'
export const CATEGORY = 'category'
export const PRESCRIPTIONS = 'prescriptions'
export const PRESCRIPTION_PREVIEW = 'prescription-preview'
export const PRESCRIPTION_VIEW = 'prescription-view'
export const WIDGETS = 'widgets'
export const FOOD_JOURNAL = 'food-journal'
export const OURA = 'oura'
export const READINESS_CONTRIBUTORS = 'readiness-contributors'
export const DAILY_MOVEMENT = 'daily-movement'
export const ACTIVITY_CONTRIBUTORS = 'activity-contributors'
export const SLEEP_STAGES = 'sleep-stages'
export const SLEEP_CONTRIBUTORS = 'sleep-contributors'
export const BLOOD_PRESSURE = 'blood-pressure'
export const PULSE = 'pulse'
export const WITHINGS = 'withings'
export const WEIGHT = 'weight'
export const WEIGHT_TIMELINE = 'weight-timeline'

// extra providers routes
export const ALL = 'all'

// extra appointments routes
export const RESCHEDULE = 'reschedule'
export const CHANGE_PROVIDER = 'change-provider'
export const RESCHEDULE_SUCCESS = 'reschedule-success'
export const DAY = 'day'
export const WEEK = 'week'
export const WORK_WEEK = 'work-week'
export const MONTH = 'month'
export const APPOINTMENT_INFO = 'appointment-info'
export const CONSULTATION = 'consultation'
export const CONFIGURATE = 'configurate'

// extra messages
export const DIRECT = 'direct'
export const GROUP = 'group'
export const INVITE = 'invite'
export const RENAME = 'rename'

// extra settings routes
export const PASSWORD = 'password'
export const SECURITY = 'security'
export const TWO_FA_SUCCESS = 'two-fa-success'

// environment check
export const ENVIRONMENT_CHECK = 'environment-check'
export const INTRODUCTION = 'introduction'
export const CAMERA = 'camera'
export const MICROPHONE = 'microphone'
export const SPEAKERS = 'speakers'
export const FINISH = 'finish'

// referral routes
export const FRIEND = 'friend'
export const LIST = 'list'

