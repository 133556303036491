import React from 'react'

import { usePatientHealthPlans } from 'hooks/usePatients'

import PlansList from './PlansList'
import ListPreviewPlaceholder from 'common/loaders/ListPreviewPlaceholder'

export default React.memo(function HealthPlans () {
  const patientHealthPlans = usePatientHealthPlans()

  const isLoading = React.useMemo(() => !patientHealthPlans, [patientHealthPlans])

  if (isLoading) return (<ListPreviewPlaceholder />)

  return (<PlansList />)
})
