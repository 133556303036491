import { useFetchWidgetsList } from '../../../../requests/patients/widgets/widgets';
import React from 'react';
import DetailsTemplate from '../../../../components/patients/DetailsTemplate';
import { FOOD_JOURNAL, WIDGETS } from '../../../../constants/routes';
import { useFetchFoodJournal } from '../../../../requests/patients/food-journal';
import { useFoodJournal } from '../../../../hooks/patient/useFoodJournal';
import MealCalendar from './MealCalendar';

export default function FoodJournal() {
  const fetchFoodJournal = useFetchFoodJournal();
  const foodJournal = useFoodJournal();

  React.useEffect(() => {
    fetchFoodJournal();
    // eslint-disable-next-line
  }, []);

  return <DetailsTemplate currentTab={FOOD_JOURNAL}>{foodJournal && <MealCalendar data={foodJournal} />}</DetailsTemplate>;
}
