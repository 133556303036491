import GlobalPhysicalHealthResult from 'components/patients/questionnaires/quiz-metrics/quiz-metrics-body/global-physical-health/GlobalPhysicalHealthResult'
import GlobalMentalHealthResult from 'components/patients/questionnaires/quiz-metrics/quiz-metrics-body/global-mental-health/GlobalMentalHealthResult'
const config = {
  'MetricRules':{
    'globalPhysicalHealth': {
      'RawScore': {
        'question3': {
          0: 5,
          1: 4,
          2: 3,
          3: 2,
          4: 1,
          5: 0
        },
        'question6': {
          0: 5,
          1: 4,
          2: 3,
          3: 2,
          4: 1,
          5: 0
        },
        'question9': {
          0: 5,
          1: 4,
          2: 3,
          3: 2,
          4: 1,
          5: 0
        },
        'question10': {
          0: 5,
          1: 4,
          2: 4,
          3: 4,
          4: 3,
          5: 3,
          6: 3,
          7: 2,
          8: 2,
          9: 2,
          10: 1
        }
      },
      'TScore': {
        4: 16.2,
        5: 19.9,
        6: 23.5,
        7: 26.7,
        8: 29.6,
        9: 32.4,
        10: 34.9,
        11: 37.4,
        12: 39.8,
        13: 42.3,
        14: 44.9,
        15: 47.7,
        16: 50.8,
        17: 54.1,
        18: 57.7,
        19: 61.9,
        20: 67.7
      }
    },
    'globalMentalHealth': {
      'RawScore': {
        'question2': {
          0: 5,
          1: 4,
          2: 3,
          3: 2,
          4: 1,
          5: 0
        },
        'question4': {
          0: 5,
          1: 4,
          2: 3,
          3: 2,
          4: 1,
          5: 0
        },
        'question5': {
          0: 5,
          1: 4,
          2: 3,
          3: 2,
          4: 1,
          5: 0
        },
        'question8': {
          0: 5,
          1: 4,
          2: 3,
          3: 2,
          4: 1,
          5: 0
        }
      },
      'TScore': {
        4: 21.2,
        5: 25.1,
        6: 28.4,
        7: 31.3,
        8: 33.8,
        9: 36.3,
        10: 38.8,
        11: 41.1,
        12: 43.5,
        13: 45.8,
        14: 48.3,
        15: 50.8,
        16: 53.3,
        17: 56,
        18: 59,
        19: 62.5,
        20: 67.6
      }
    },
  },
  'globalPhysicalHealth': GlobalPhysicalHealthResult,
  'globalMentalHealth': GlobalMentalHealthResult
}

export default config
