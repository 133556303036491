import React from 'react'

import { globalContext, useUser, useSetUser } from 'hooks/useGlobalState'
import { useFetchProfile } from 'requests/profile'

import request from 'utils/request'
import createNotification from 'utils/createNotification'

export function useInitialFetchUser () {
  const user = useUser()
  const setUser = useSetUser((prev, next) => next, [])
  const fetchProfile = useFetchProfile()
  return React.useCallback(() => {
    if (localStorage.getItem('idToken') && !user) {
      return request({
        method: 'get',
        url: `/providers/me`
      })
        .then(({ data }) => {
          localStorage.setItem('provider_id', data.id);
          localStorage.setItem('email', data.email);
          setUser(data)
          fetchProfile()
          return data
        })
        .catch((error) => console.log(error))
    }
  }, [user, setUser, fetchProfile])
}

export function useFetchUser () {
  const setUser = useSetUser((prev, next) => next, [])
  return React.useCallback(() => {
    return request({
      method: 'get',
      url: `/providers/me`
    })
      .then(({ data }) => {
        setUser(data)
        return data
      })
      .catch((error) => console.log(error))
  }, [setUser])
}

export function useUpdatePasswordAttempt () {
  const { user } = React.useContext(globalContext)
  const fetchUser = useFetchUser()
  return React.useCallback((data) => {
    const id = user.value.id
    return request({
      method: 'put',
      url: `/providers/${id}/password`,
      data
    })
      .then(() => {
        createNotification({ message: 'Password successfully changed', type: 'success' })
        localStorage.setItem('passwordHasBeenChanged', 1);
        fetchUser()
      })
      .catch((error) => console.log(error))
  }, [user, fetchUser])
}
