import React from 'react'

import createNotification from 'utils/createNotification'

export default function VolumeAnalyzer ({ setVolume }) {
  const currentSreamRef = React.useRef()
  const analyserRef = React.useRef()
  const microphoneRef = React.useRef()
  const intervalIdRef = React.useRef()

  React.useEffect(() => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        currentSreamRef.current = stream
        const AudioContext = window.AudioContext || window.webkitAudioContext
        const audioContext = new AudioContext()
        const analyser = audioContext.createAnalyser()
        const microphone = audioContext.createMediaStreamSource(stream)
        microphone.connect(analyser)

        analyserRef.current = analyser
        microphoneRef.current = microphone

        analyser.smoothingTimeConstant = 0.8
        analyser.fftSize = 1024

        intervalIdRef.current = setInterval(() => {
          const array = new Uint8Array(analyser.frequencyBinCount)
          analyser.getByteFrequencyData(array)
          let values = 0
          let length = array.length
          array.forEach((item) => (values += item))
          setVolume(values / length)
        }, 100)
      })
      .catch((err) => {
        createNotification({
          title: 'Your camera and microphone are not connected.',
          message: 'Please allow access',
          type: 'error'
        })
      })
    return () => {
      clearInterval(intervalIdRef.current)
      if (analyserRef.current) analyserRef.current.disconnect()
      if (microphoneRef.current) microphoneRef.current.disconnect()
      if (currentSreamRef.current) currentSreamRef.current.getTracks().forEach((track) => track.stop())
    }
    // eslint-disable-next-line
  }, [])

  return null
}
