import React from 'react'

import { useSetAppointmentCreate } from 'hooks/useAppointments'

import SearchableSelect from 'common/searchable-select'

export default React.memo(function PatientSelect () {
  const setProvider = useSetAppointmentCreate((prev, provider) => ({ ...prev, provider }), [])
  const clearProvider = useSetAppointmentCreate((prev) => ({ ...prev, provider: '' }), [])
  return (
    <SearchableSelect
      className='provider'
      label='Provider'
      placeholder='Provider'
      searchLink='providers'
      searchField='providers'
      onSelect={setProvider}
      onClear={clearProvider}
    />
  )
})
