import React from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { PATIENTS, DETAILS, EDIT } from 'constants/routes';

export default function LinkToEdit() {
  const { patientId } = useParams();

  return (
    <StyledLink to={`/${PATIENTS}/${DETAILS}/${patientId}/${EDIT}`} >
      Edit patient profile
    </StyledLink>
  );
}

const StyledLink = styled(Link)`
  align-self: flex-start;
  font-size: 1.2rem;
  color: rgba(34, 50, 50, 0.5);
  text-decoration: underline;
  text-transform: uppercase;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
`;
