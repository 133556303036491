import React from 'react'

import { useDeleteMedicationRow } from 'hooks/usePatients'

import LinkButton from 'common/LinkButton'

export default React.memo(function RemoveButton ({ className = '', index, isLast }) {
  const deleteMedicationRow = useDeleteMedicationRow(index)
  return (
    <LinkButton
      className={className}
      disabled={isLast}
      onClick={deleteMedicationRow}
    >
      Delete
    </LinkButton>
  )
})
