import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'clsx';
import { ReactComponent as ChevronIcon } from 'assets/chevron-down.svg';
import { usePopper } from 'react-popper';
import ReactDOM from 'react-dom';

export type Option = {
  label: string;
  value: string;
};

type SelectFieldProps = {
  isRequired?: boolean;
  label?: string;
  error?: string;
  placeholder?: string;
  options: Option[];
  selected?: Option;
  onSelect: (option: Option) => void;
};

export const SelectField: FC<SelectFieldProps> = ({ onSelect, options, selected, label, isRequired, placeholder, error }) => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef<null | HTMLDivElement>(null);
  const elemRef = useRef<null | HTMLDivElement>(null);
  const { styles, attributes } = usePopper(buttonRef.current, elemRef.current, {
    placement: 'bottom',
  });

  const hasError = !!error;

  const handleSelect = useCallback(
    (option: Option) => () => {
      onSelect(option);
      setIsOpen(false);
    },
    []
  );

  useEffect(() => {
    const onBodyClick = (event: any) => {
      if (elemRef?.current?.contains(event.target) || buttonRef?.current?.contains(event.target)) return;

      setIsOpen(false);
    };
    document.body.addEventListener('click', onBodyClick);
    return () => {
      document.body.removeEventListener('click', onBodyClick);
    };
  }, []);

  return (
    <>
      <div ref={buttonRef} className="">
        {label && (
          <div className="flex mb-1">
            <div className="text-1324 text-coil">{label}</div>
            {isRequired && <div className="text-error text-1324">*</div>}
          </div>
        )}
        <div onClick={() => setIsOpen(true)} className="">
          <div
            className={classNames('cursor-pointer h-10 flex justify-between items-center border  bg-white rounded-[3.2rem] py-2 px-4', {
              'border-error': hasError,
              'border-lavenderGray': !hasError,
            })}
          >
            {selected?.label && <div className="text-main text-1522 font-ambit">{selected?.label || options?.[0]?.label}</div>}
            {!selected?.label && <div className="text-lavenderGray text-1324">{placeholder || 'Empty'}</div>}
            <div className="">
              <ChevronIcon
                className={classNames('fill-main', {
                  'transform rotate-180': isOpen,
                })}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames('text-error text-1214 px-2 py-1', {
          hidden: !hasError,
        })}
      >
        {error}
      </div>
      {ReactDOM.createPortal(
        <div
          className="mt-1 z-10"
          ref={elemRef}
          style={{ ...styles.popper, width: Number(buttonRef?.current?.getBoundingClientRect()?.width || 10) - 10 }}
          {...attributes.popper}
        >
          <ul
            className={classNames('bg-white rounded-main w-full max-h-56 overflow-auto  py-1 shadow-dropdown transition-all duration-300 ease-out', {
              block: isOpen,
              hidden: !isOpen,
            })}
          >
            {options.map((opt) => (
              <li
                key={opt.value}
                onClick={handleSelect(opt)}
                className={classNames('flex w-full text-main text-1522 font-ambit items-center px-5 py-1 cursor-pointer ', {
                  'bg-catskillWhite text-main': selected?.value === opt.value,
                  'hover:text-white hover:bg-main': selected?.value !== opt.value,
                })}
              >
                {opt.label}
              </li>
            ))}
          </ul>
        </div>,
        document.body
      )}
    </>
  );
};
