import React from 'react'
import styled from 'styled-components'

import { useIsMedicationValid, useAddMedicationRow } from 'hooks/usePatients'

import LinkButton from 'common/LinkButton'

export default function AddRowButton () {
  const isMedicationValid = useIsMedicationValid()
  const addMedicationRow = useAddMedicationRow()
  return (
    <StyledLinkButton
      className='g-mt-25 g-mr-auto g-mb-25'
      disabled={!isMedicationValid}
      onClick={addMedicationRow}
    >
      Add new row
    </StyledLinkButton>
  )
}

const StyledLinkButton = styled(LinkButton)`
  line-height: 3rem;
  padding: 0.8rem 2.5rem;
`
