import React from 'react'

import { useModalHandler } from 'hooks/useUtils'
import { useDeletePatientMedication } from 'requests/patients/medication'

import LinkButton from 'common/LinkButton'
import ConfirmationModal from 'common/ConfirmationModal'

export default React.memo(function RemoveButton ({ className = '', id, index, isLast }) {
  const deletePatientMedication = useDeletePatientMedication(id, index)
  const [isOpen, openModal, closeModal] = useModalHandler()

  return (
    <>
      <LinkButton
        className={className}
        disabled={isLast}
        onClick={openModal}
      >
        Delete
      </LinkButton>
      {isOpen && (
        <ConfirmationModal
          closeModal={closeModal}
          confirmHandler={deletePatientMedication}
        />
      )}
    </>
  )
})
