import React from 'react'

import { FRIEND } from 'constants/routes'; 

import { MediumText, HintText } from 'common/texts'
import ReferralTemplate from 'components/referral/ReferralTemplate'
import ShareByEmail from 'components/referral/refer-friend/ShareByEmail'
import ShareByLink from 'components/referral/refer-friend/ShareByLink'
import ShareBySocial from 'components/referral/refer-friend/ShareBySocial'

import request from 'utils/request'

export default function ReferFriend () {
  const [link, setLink] = React.useState('')

  React.useEffect(() => {
    request({
      method: 'get',
      url: `/referrer/link`
    })
      .then(({ data: { data: { link } } }) => setLink(link))
      .catch((error) => console.log(error))
  }, [])

  return (
    <ReferralTemplate
      currentTab={FRIEND}
    >
      <MediumText className='g-mt-30'>
        Share with your patients
      </MediumText>

      <ShareByEmail />

      <ShareByLink link={link} />

      <HintText className='g-mt-30'>
        INVITE BY SOCIALS
      </HintText>

      <ShareBySocial link={link} />
    </ReferralTemplate>
  )
}
