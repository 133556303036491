import React from 'react'
import styled from 'styled-components'

import { PASSWORD } from 'constants/routes'; 

import { useUpdatePasswordAttempt } from 'requests/user'

import validatePassword from 'utils/validatePassword'

import { RegularMediumText } from 'common/texts'
import SettingTemplate from 'components/settings/SettingTemplate'
import InputWithLabel from 'common/InputWithLabel'
import CustomButton from 'common/CustomButton'

export default function Password () {
  const updatePasswordAttempt = useUpdatePasswordAttempt()
  const [currentPassword, setCurrentPassword] = React.useState('')
  const [newPassword, setNewPassword] = React.useState('')
  const [newPasswordConfirmation, setNewPasswordConfirmation] = React.useState('')

  const { isValid, message } = validatePassword(newPassword)

  const handleUpdate = React.useCallback(() => {
    updatePasswordAttempt({
      access_token: localStorage.getItem('accessToken'),
      old_password: currentPassword,
      new_password: newPassword,
    }).then(() => {
      setCurrentPassword('')
      setNewPassword('')
      setNewPasswordConfirmation('')
    });
  }, [updatePasswordAttempt, currentPassword, newPassword, newPasswordConfirmation])

  const updateCondition = (
    currentPassword && newPassword && newPasswordConfirmation && // all field exist
    newPassword === newPasswordConfirmation && // new and confirm match
    currentPassword !== newPassword && // current and new different
    isValid // new valid
  )

  return (
    <SettingTemplate currentTab={PASSWORD}>
      <RegularMediumText className='g-mt-25'>
        Change your password
      </RegularMediumText>

      <InputWithLabel
        className='g-mt-25'
        label='Current password'
        type='password'
        value={currentPassword}
        changeHandler={setCurrentPassword}
        isRequired
        isValid={!currentPassword || currentPassword !== newPassword}
      />
      {(currentPassword && newPassword && currentPassword === newPassword) && (
        <StyledError>Current and new passwords must be different</StyledError>
      )}

      <InputWithLabel
        className='g-mt-20'
        label='New password'
        type='password'
        value={newPassword}
        changeHandler={setNewPassword}
        isRequired
        isValid={!newPassword || isValid}
      />
      {newPassword && (
        <StyledError>{message}</StyledError>
      )}

      <InputWithLabel
        className='g-mt-20'
        label='Confirm password'
        type='password'
        value={newPasswordConfirmation}
        changeHandler={setNewPasswordConfirmation}
        isRequired
        isValid={!newPasswordConfirmation || newPassword === newPasswordConfirmation}
      />
      {(newPasswordConfirmation && newPassword !== newPasswordConfirmation) && (
        <StyledError>Password not match</StyledError>
      )}

      <StyledCustomButton
        disabled={!updateCondition}
        clickHandler={handleUpdate}
      >
        Update
      </StyledCustomButton>
    </SettingTemplate>
  )
}

const StyledCustomButton = styled(CustomButton)`
  margin-top: auto;
`

const StyledError = styled.div`
  color: #f86060;
  font-size: 1.2rem;
  margin-top: 0.5rem;
`
