import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import {
  PATIENTS,
  ACTIVE,
  DETAILS,
  OVERVIEW,
  SUMMARY,
  PROFILE,
  QUESTIONNAIRES,
  PRE_ASSESSMENT,
  HEALTH_PLANS,
  DOCTOR_NOTES,
  LABS,
  TREATMENT_PLANS,
  DOCUMENTS,
  ADMIN_NOTES,
  INSURANCE,
  MEDICATIONS,
  WIDGETS,
  FOOD_JOURNAL,
} from 'constants/routes'

import { useSetPatinetProfile, usePatientFullName, useSetPatientFullName } from 'hooks/usePatients';

import { useFetchPatinetProfile } from 'requests/patients';

import { FullPlate, WideRowPlate } from 'common/plate/plates';
import BackButton from 'common/plate/BackButton';
import { PlateHeader, PlateContent } from 'common/plate/styles';
import TabsSwitcher from 'common/TabsSwitcher';

import { useUserContext } from '../../context/userContext';
import { useFeatureFlags } from '../../hooks/useFeatureFlag';

export default function DetailsTemplate({ children, currentTab }) {
  const { patientId } = useParams();
  const ff = useFeatureFlags();
  const { role } = useUserContext();
  const fetchPatinetProfile = useFetchPatinetProfile();
  const clearPatinetProfile = useSetPatinetProfile(() => null, []);
  const patientFullName = usePatientFullName();
  const clearPatientFullName = useSetPatientFullName(() => '', []);

  React.useEffect(() => {
    fetchPatinetProfile();
    return clearPatinetProfile;
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    return clearPatientFullName;
    // eslint-disable-next-line
  }, []);

  const tabsConfig = React.useMemo(() => {
    return [
      {
        id: OVERVIEW,
        roles: ['admin', 'provider'],
        label: 'Overview',
        href: `/${PATIENTS}/${DETAILS}/${patientId}/${OVERVIEW}`,
      },
      {
        id: SUMMARY,
        roles: ['admin', 'provider'],
        label: 'Summary',
        href: `/${PATIENTS}/${DETAILS}/${patientId}/${SUMMARY}`,
      },

      {
        id: PROFILE,
        roles: ['admin', 'provider'],
        label: 'Profile',
        href: `/${PATIENTS}/${DETAILS}/${patientId}/${PROFILE}`,
      },
      {
        id: QUESTIONNAIRES,
        roles: ['admin', 'provider'],
        label: 'Questionnaires ',
        href: `/${PATIENTS}/${DETAILS}/${patientId}/${QUESTIONNAIRES}/${PRE_ASSESSMENT}`,
      },
      {
        id: DOCTOR_NOTES,
        roles: ['admin', 'provider'],
        label: 'Provider notes',
        href: `/${PATIENTS}/${DETAILS}/${patientId}/${DOCTOR_NOTES}`,
      },
      {
        id: TREATMENT_PLANS,
        roles: ff.treatmentPlan ? ['admin', 'provider'] : [],
        label: 'Treatment plans',
        href: `/${PATIENTS}/${DETAILS}/${patientId}/${TREATMENT_PLANS}`,
      },
      {
        id: HEALTH_PLANS,
        roles: ['admin', 'provider'],
        label: 'Health plan',
        href: `/${PATIENTS}/${DETAILS}/${patientId}/${HEALTH_PLANS}`,
      },
      {
        id: LABS,
        roles: ['admin', 'provider'],
        label: 'Labs',
        href: `/${PATIENTS}/${DETAILS}/${patientId}/${LABS}`,
      },
      {
        id: DOCUMENTS,
        roles: ['admin', 'provider'],
        label: 'Documents',
        href: `/${PATIENTS}/${DETAILS}/${patientId}/${DOCUMENTS}`,
      },
      {
        id: ADMIN_NOTES,
        roles: ['admin', 'provider'],
        label: 'Admin notes',
        href: `/${PATIENTS}/${DETAILS}/${patientId}/${ADMIN_NOTES}`,
      },
      {
        id: INSURANCE,
        roles: ['admin'],
        label: 'Insurance ',
        href: `/${PATIENTS}/${DETAILS}/${patientId}/${INSURANCE}`,
      },
      {
        id: MEDICATIONS,
        roles: ['admin', 'provider'],
        label: 'Medications ',
        href: `/${PATIENTS}/${DETAILS}/${patientId}/${MEDICATIONS}`,
      },
      {
        id: WIDGETS,
        roles: ['admin', 'provider'],
        label: 'RPM ',
        href: `/${PATIENTS}/${DETAILS}/${patientId}/${WIDGETS}`,
      },
      {
        id: FOOD_JOURNAL,
        roles: ['provider'],
        label: 'Food Journal ',
        href: `/${PATIENTS}/${DETAILS}/${patientId}/${FOOD_JOURNAL}`,
      },
      // { id: PRESCRIPTIONS, roles:['admin', 'provider'], label: 'Prescriptions ', href: d.prescriptions)}
    ];
  }, [patientId]);

  const Plate = ff.treatmentPlan ? FullPlate : WideRowPlate;
  return (
    <Plate>
      <PlateHeader>
        <BackButton href={`/${PATIENTS}/${ACTIVE}`} />
        {patientFullName}
      </PlateHeader>

      <PlateContent>
        <StyledTabsSwitcher className="g-mt-20 g-mb-30" tabsConfig={tabsConfig.filter((tab) => tab.roles.includes(role))} currentTab={currentTab} />

        {children}
      </PlateContent>
    </Plate>
  );
}

const StyledTabsSwitcher = styled(TabsSwitcher)`
  a {
    min-width: auto;
    padding-right: 0.55rem;
    padding-left: 0.55rem;
  }
`;
