import React from 'react'

import { DOCUMENTS } from 'constants/routes'; 

import { useFetchPatinetDocuments } from 'requests/patients'

import DetailsTemplate from 'components/patients/DetailsTemplate'
import Documents from 'components/patients/documents/Documents'
import UploadButton from 'components/patients/documents/UploadButton'

export default function PatientDocuments () {
  const fetchPatinetDocuments = useFetchPatinetDocuments()

  React.useEffect(() => {
    fetchPatinetDocuments()
    // eslint-disable-next-line
  }, [])

  return (
    <DetailsTemplate currentTab={DOCUMENTS}>
      <Documents />

      <UploadButton />
    </DetailsTemplate>
  )
}
