import styled from 'styled-components';

export const HeadingH3 = styled.h3`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #223232;
`;

export const HeadingMD = styled.h2`
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #16123f;
`;

export const SubHeading = styled.p`
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #223232;
`;

export const SubHeadingBolder = styled.p`
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #223232;
`;

export const SecondaryText = styled.p`
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #808080;
`;

// export const HeadingH3 = ({ children }) => {
//   return <H3>{children}</H3>;
// };
