import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { PATIENTS, DETAILS, HEALTH_PLANS, CREATE } from 'constants/routes'

import { useSetPatientHealthPlan } from 'hooks/usePatients'

import CustomButton from 'common/CustomButton'

export default React.memo(function NewPlanButton () {
  const { patientId } = useParams()
  const history = useHistory()
  const clearPatientHealthPlan = useSetPatientHealthPlan(() => null, [])

  const createNewNote = React.useCallback(() => {
    clearPatientHealthPlan()
    history.push({
      pathname: `/${PATIENTS}/${DETAILS}/${patientId}/${HEALTH_PLANS}/${CREATE}`,
      state: { referrer: `/${PATIENTS}/${DETAILS}/${patientId}/${HEALTH_PLANS}` }
    })
  }, [history, patientId, clearPatientHealthPlan])

  return (
    <CustomButton
      className='g-mt-auto'
      clickHandler={createNewNote}
    >
      Add  new plan
    </CustomButton>
  )
})
