import React from 'react'

import { useMedicationsList, useSetMedicationsList } from 'hooks/patient/useMedications'
import { useFetchPatientMedicationsProgress } from 'requests/patients/medication'

import MedicationsPlaceholder from '../common/MedicationsPlaceholder'
import EmptyMedications from '../common/EmptyMedications'
import MedicationsList from './MedicationsList'

export default function MedicationsLoader () {
  const fetchPatientMedicationsProgress = useFetchPatientMedicationsProgress()
  const medicationsList = useMedicationsList()
  const cleartMedicationsList = useSetMedicationsList(() => null)

  React.useEffect(() => {
    fetchPatientMedicationsProgress()
    return cleartMedicationsList
    // eslint-disable-next-line
  }, [])

  if (!medicationsList) return <MedicationsPlaceholder />

  if (medicationsList.length === 0) return <EmptyMedications />

  return <MedicationsList />
}
