import React from "react";
import styled from "styled-components";
import moment from "moment";

import UpcomingList from "components/dashboard/upcoming/UpcomingList";
import HealthMetrics from "components/dashboard/HealthMetrics";
import LogsLoader from "../components/dashboard/LogsLoader";
import { useFetchLogs } from "requests/logs";
import { LargeSquarePlate } from "common/plate/plates";
import { PlateHeader, PlateContent } from "../common/plate/styles";
import Footer from "components/dashboard/dashboard/Footer";
import { useUserContext } from "./../context/userContext";

export default function Dashboard() {
  const fetchLogs = useFetchLogs();
  const { isAdmin } = useUserContext();

  React.useEffect(() => {
    if (!isAdmin) return;
    fetchLogs({
      from: moment().add(-7, "d").format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
    });
    // eslint-disable-next-line
  }, [isAdmin]);

  return (
    <StyledWrapper>
      {!isAdmin && (
        <>
          <UpcomingList className="tab" />
          <HealthMetrics className="tab" />
        </>
      )}
      {isAdmin && (
        <LargeSquarePlate>
          <PlateHeader>Latest activity</PlateHeader>

          <PlateContent>
            <LogsLoader />

            <Footer />
          </PlateContent>
        </LargeSquarePlate>
      )}
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;
  @media screen and (max-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    margin: initial;
  }
  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
  }
  .tab {
    margin: 0 1rem 2rem;
    @media screen and (max-width: 1024px) {
      margin: initial;
    }
  }
`;
