import React from "react";
import moment from "moment-timezone";

import { RegularText, MediumText } from "common/texts";
import { StyledLine } from "./styles";
import LinkButton from "common/LinkButton";
import Modal from "common/Modal";
import CancelConfirmation from "./CancelConfirmation";
import { getTzAbbr } from "./../../../utils/tz";

export default React.memo(function DataAndTime({ appointmentId, date }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const openModal = React.useCallback(() => setIsOpen(true), [setIsOpen]);
  const closeModal = React.useCallback(() => setIsOpen(false), [setIsOpen]);
  return (
    <StyledLine>
      <div>
        <RegularText>Date and Time</RegularText>
        <MediumText>
          {moment.utc(date).local().format("D MMMM, YYYY, h:mmA")}{" "}
          {getTzAbbr(date)}
        </MediumText>
      </div>
      <LinkButton onClick={openModal} className="button">
        Cancel
      </LinkButton>
      {isOpen && (
        <Modal title="Are you sure in that?" minWidth={44} maxWidth={44} closeModal={closeModal}>
          <CancelConfirmation appointmentId={appointmentId} />
        </Modal>
      )}
    </StyledLine>
  );
});
