import React from 'react';
import styled from 'styled-components';
import get from 'lodash/get';

import { PROGRAMS } from 'constants/routes';

import { useProgram } from 'hooks/usePrograms';

import { PlateHeader } from 'common/plate/styles';
import BackButton from 'common/plate/BackButton';
;

export default function ProgramHeader() {
  const name = useProgram((current) => get(current, 'name', ''), []);


  return (
    <StyledPlateHeader>
      <BackButton href={`/${PROGRAMS}`} />
      <span className="company-name">{name}</span>
    </StyledPlateHeader>
  );
}


const StyledPlateHeader = styled(PlateHeader)`
  padding: 1.8rem 2.5rem;
  .coupon {
    max-width: 40%;
    font-weight: 400;
    margin-left: auto;
    padding-left: 0.5rem;
    .title {
      margin-bottom: 0.6rem;
    }
    p {
      line-height: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .counter {
    font-weight: 400;
    margin-left: 4rem;
    .title {
      margin-bottom: 0.6rem;
    }
    p {
      line-height: 1;
    }
  }
`;
