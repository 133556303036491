import React from 'react'
import styled from 'styled-components'

import { MediumText } from 'common/texts'
import InfoForPatient from '../common/InfoForPatient'
import PrescriptionsESend from '../common/PrescriptionsESend'

export default function PrescriptionViewContent () {
  return (
    <StyledWrapper>
      <div className='row g-d-flex g-justify-between'>
        <div className='col'>
          <MediumText className='g-mt-20'>For Patient</MediumText>
          <InfoForPatient
            label='Name'
            value='Anna Wright (Female)'
          />
          <InfoForPatient
            label='Date of Birthday'
            value='July 21, 1987 (32 years)'
          />
          <InfoForPatient
            label='Phone number'
            value='+1 325 323 312'
          />
          <InfoForPatient
            label='City/State/ZIP'
            value='New York City, New York, 001324'
          />
          <InfoForPatient
            label='Street Address'
            value='80 Hudson Dr.Freeport, NY 11520'
          />
        </div>
        <div className='col'>
        <MediumText className='g-mt-20'>Pharmacy</MediumText>
          <InfoForPatient
            label='Pharmacy'
            value='New York City Pharmacy'
          />
          <InfoForPatient
            label='Address'
            value='206 1st Avenue, New York, NY 10009,'
          />
          <InfoForPatient
            label='Phone'
            value='+1 212-253-8686'
          />
        </div>
      </div>
      <MediumText className='g-mt-20 g-mb-5'>Prescriptions to e-Send</MediumText>
      <PrescriptionsESend
        name='penicillin G potassium 1000.000 units/50 mL intrevenous solution'
        directions='Take 1 tablet 4 times a day'
        dispence='30 tablet | 15 days | 0 refills'
        note='Take tablets before meal with plenty of water'
        schedule='2, PrimaryDiag/ICD: WrittenDate: 15 August 2020'
        prescibedBy='Glenn Bishop (22 Jockey Hollow Drive Palos Verdes Peninsula, CA 90274, 801-258-2552)'
        covIns='Preffered Level 1-PLANA5 (Retail:No MailOrder:Y lTC:N Speciality:N)'
      />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  .row {
    @media screen and (max-width: 1024px) {
      display: block;
    }
  }
  .col {
    width: 49%;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
`
