import React from 'react'
import styled from 'styled-components'

import { SUMMARY } from 'constants/routes'; 

import { useSetProviderProfile, useSetProviderAppointments } from 'hooks/useProviders'
import { useFetchProviderSummary, useFetchProviderAppointments } from 'requests/providers'

import ProviderTemplate from 'components/providers/ProviderTemplate'
import ProviderInfo from 'components/providers/details/summary/provider-info'
import ProviderAppointments from 'components/providers/details/summary/provider-appointments'

export default function ProviderSummary () {
  const fetchProviderSummary = useFetchProviderSummary()
  const fetchProviderAppointments = useFetchProviderAppointments()
  const clearProviderProfile = useSetProviderProfile(() => null, [])
  const clearProviderAppointments = useSetProviderAppointments(() => null, [])

  React.useEffect(() => {
    fetchProviderSummary()
    fetchProviderAppointments()
    return () => {
      clearProviderProfile()
      clearProviderAppointments()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <ProviderTemplate currentTab={SUMMARY}>
      <StyledWrapper className='g-d-flex g-justify-between'>
        <ProviderInfo />

        <ProviderAppointments />
      </StyledWrapper>
    </ProviderTemplate>
  )
}

const StyledWrapper = styled.div`
  @media only screen and (max-width: 480px) {
    flex-wrap: wrap;
  }
`
