import React from 'react'
import styled from 'styled-components'

import { DASHBOARD, REFERRAL, FRIEND, LIST } from 'constants/routes'; 

import { RowPlate } from 'common/plate/plates'
import { PlateHeader, PlateContent } from 'common/plate/styles'
import BackButton from 'common/plate/BackButton'
import TabsSwitcher from 'common/TabsSwitcher'

const tabsConfig = [
  { id: FRIEND, label: 'Refer a patient', href: `/${REFERRAL}/${FRIEND}` },
  { id: LIST, label: 'Referral list', href: `/${REFERRAL}/${LIST}` }
]

export default React.memo(function ReferralTemplate ({
  currentTab, children
}) {
  return (
    <StyledRowPlate>
      <PlateHeader>
        <BackButton href={`/${DASHBOARD}`} />
        Referral program
      </PlateHeader>
      <PlateContent>
        <TabsSwitcher
          className='g-mt-20'
          tabsConfig={tabsConfig}
          currentTab={currentTab}
        />
        {children}
      </PlateContent>
    </StyledRowPlate>
  )
})

const StyledRowPlate = styled(RowPlate)`
  min-height: 66rem;
`
