import React from "react";
import ReactDOM from "react-dom";
import App from "App";
import "index.css";
import "datepicker.css";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_CALENDAR_CLIENT_ID } from "env";

import { SENTRY } from './env';
import * as Sentry from '@sentry/react';

const isSentry = SENTRY

if (isSentry === 'production') {
  Sentry.init({
    dsn: "https://fb8142b1c8bc8f17c5a619255fa16339@o4504128864845824.ingest.us.sentry.io/4507062047277056",
    integrations: [Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

ReactDOM.render(
  <GoogleOAuthProvider clientId={GOOGLE_CALENDAR_CLIENT_ID}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
  </GoogleOAuthProvider>,
  document.getElementById("root")
);


if (module.hot) {
  // this is necessary for HMR, the only thing we need for the whole app which is nice
  module.hot.accept('App', () => {
    const NextRootContainer = require('App').default;
    ReactDOM.render(<NextRootContainer/>, document.getElementById('root'));
  })
}
