import React from 'react'
import styled from 'styled-components'

import { ReactComponent as DownloadIcon } from 'assets/download.svg'

import { useExportAllData } from 'requests/patients/summary'

export default function ExportButton () {
  const exportAllData = useExportAllData()
  return (
    <StyledWrapper onClick={exportAllData}>
      <DownloadIcon className='icon' />
      Export all data
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: rgba(0,0,0,0.5);
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
  :hover {
    text-decoration: none;
  }
  .icon {
    margin-right: 1rem;
    path {
      fill: #909898;
      stroke: #909898;
    }
  }
`
