import React from 'react'

import NotePreview from './NotePreview'

export default React.memo(function NotesPreview ({ notes }) {
  return notes.map(note => {
    return (
      <NotePreview
        key={note.id}
        note={note}
      />
    )
  })
})
