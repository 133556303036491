import React from 'react'

import { AVAILABILITY } from 'constants/routes'; 

import ProviderTemplate from 'components/providers/ProviderTemplate'
import Availability from 'components/providers/details/availability'

export default function ProviderAvailability () {
  return (
    <ProviderTemplate currentTab={AVAILABILITY}>
      <Availability />
    </ProviderTemplate>
  )
}
