import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import CustomButton from 'common/CustomButton'
import CustomSelect from '../../common/CustomSelect'
import { useSetLocalOptions } from '../../hooks/usePrograms'

export default React.memo(function SelectOptionsButton ({ commonOptions }) {
  const [selectedOption, setSelectedOption] = React.useState(null);
  const placeholderRef = useRef('Add More');
  const updateOptions = useSetLocalOptions((prev, next) => {
    const updatedOptions = Array.isArray(prev) ? [...prev] : [];
    updatedOptions.push(next);
    return updatedOptions;
  });

  useEffect(() => {
    if (selectedOption) {
      updateOptions(selectedOption);
    }
    setSelectedOption(null);
  }, [selectedOption, updateOptions]);

  const commonOptionsSelect = commonOptions?.map(option => ({
    value: option.id,
    label: option.name
  }));

  const handleOnChange = (selectedOptions) => {
    if (selectedOptions && selectedOptions.length > 0) {
      setSelectedOption(selectedOptions);
    } else {
      setSelectedOption(null);
    }
  };

  return (
      <StyledCustomSelect
        className='g-mt-20 g-mr-25'
        placeholder={placeholderRef.current}
        isRequred
        isValid={true}
        value={selectedOption}
        options={commonOptionsSelect}
        changeHandler={handleOnChange}
        isLoading={false}
      />
  )
})

const StyledCustomSelect = styled(CustomSelect)`
  margin-top: 3.6rem;
  margin-left: auto;
  .react-dropdown-select {
    border-radius: 24px;
    border: 1px solid #E9EBEF;
    background: #F9FAFC;
    color: #7d7d7e;
  }
  .react-dropdown-select-dropdown {
    width: 100%;
  }
  &:hover {
    color: #000;
  }
`
