import React from 'react'
import { BehaviorSubject } from 'rxjs'
import isEqual from 'lodash/isEqual'
import moment from 'moment'

import { TODAY } from 'constants/dates-options'
import { WEEK, weekStart, weekEnd } from 'constants/medications-tabs'

export const defaultWidgetFilters = {
  selectedDay: TODAY,
  start: moment(TODAY).subtract(30, 'days').format('YYYY-MM-DD'),
  end: moment(TODAY).add(7, 'days').format('YYYY-MM-DD')
}

export const globalContext = React.createContext({
  currentLanguage: new BehaviorSubject('en'),
  auth: new BehaviorSubject({
    login: '',
    password: ''
  }),
  user: new BehaviorSubject(null),
  // Dashboard
  bookedAppointments: new BehaviorSubject(null),
  logsFilter: new BehaviorSubject({
    member: null,
    from: moment().add(-7, 'd').toDate(),
    to: moment().toDate()
  }),
  logs: new BehaviorSubject(null),
  // Profile
  genders: new BehaviorSubject(null),
  states: new BehaviorSubject(null),
  profile: new BehaviorSubject(null),
  localProfile: new BehaviorSubject(null),
  uploadedAvatar: new BehaviorSubject(null),
  qualifications: new BehaviorSubject(null),
  qualification: new BehaviorSubject(null),
  responsibilities: new BehaviorSubject(null),
  symptomsList: new BehaviorSubject(null),
  availability: new BehaviorSubject(null),
  vacationPeriod: new BehaviorSubject(null),
  selectedTimeTab: new BehaviorSubject(moment().add(0 * 7, 'days').startOf('isoWeek').week()),
  times: new BehaviorSubject(null),
  // Patients
  patientFullName: new BehaviorSubject(''),
  patinets: new BehaviorSubject(null),
  patientTestState: new BehaviorSubject(false),
  patinetsPagination: new BehaviorSubject(null),
  patinetsFilter: new BehaviorSubject({
    type: 'my',
    search: '',
    per_page: 24,
    by_company: '',
    follow_up_required: 0,
  }),
  patinetsStatusOptions: new BehaviorSubject(null),
  patientOverview: new BehaviorSubject(null),
  patinetSummary: new BehaviorSubject(null),
  patinetProfile: new BehaviorSubject(null),
  patientDoctorNotes: new BehaviorSubject(null),
  patientDoctorNote: new BehaviorSubject(null),
  patientSymptomsList: new BehaviorSubject(null),
  patientHealthPlans: new BehaviorSubject(null),
  patientHealthPlan: new BehaviorSubject(null),
  patientLabs: new BehaviorSubject(null),
  patientDocuments: new BehaviorSubject(null),
  patientAdminNotes: new BehaviorSubject(null),
  patientAdminNote: new BehaviorSubject(null),
  patientMedicationsList: new BehaviorSubject(null),
  patientMedications: new BehaviorSubject(null),
  medicationsCategories: new BehaviorSubject(null),
  medicationsMeasures: new BehaviorSubject(null),
  medicationsFrequencies: new BehaviorSubject(null),
  medicationsList: new BehaviorSubject(null),
  medicationsTab: new BehaviorSubject({
    id: WEEK,
    from: weekStart.format('YYYY-MM-DD'),
    to: weekEnd.format('YYYY-MM-DD')
  }),
  medicationsChart: new BehaviorSubject(null),
  widgetsList: new BehaviorSubject(null),
  foodJournal: new BehaviorSubject(null),
  mealnote: new BehaviorSubject({
    meal_id: 0,
    description: ""
  }),
  updateMealnote:new BehaviorSubject({
    id: 0,
    description: ""
  }),
  widgetFilters: new BehaviorSubject(defaultWidgetFilters),
  widget: new BehaviorSubject(null),
  // Companies
  companies: new BehaviorSubject(null),
  company: new BehaviorSubject(null),
  companyFilter: new BehaviorSubject({
    search: ''
  }),
  companyPatients: new BehaviorSubject(null),
  // Programs
  programs: new BehaviorSubject(null),
  program: new BehaviorSubject(null),
  localProgram: new BehaviorSubject(null),
  careOptions: new BehaviorSubject(null),
  localOptions: new BehaviorSubject([]),
  programForm: new BehaviorSubject({}),
  medicalVisits: new BehaviorSubject(null),
  localMedicalVisits: new BehaviorSubject([
    {
      "name":"health-coach",
      "visit_title": "Health Coach visits",
      "id": 1,
      "amount": 0
    },
    {
      "name":"mental-health",
      "visit_title": "Psychiatrist and Therapists visits",
      "id": 3,
      "amount": 0,
    },
    {
      "name":"lifestyle-health",
      "visit_title": "Physician visits",
      "id": 5,
      "amount": 0,
    },
    {
      "name":"nutrition-and-wellness",
      "visit_title": "Nutrition and Dietitian visits",
      "id": 10,
      "amount": 0,
    },
  ]),
  // Appointments
  bookedAppointment: new BehaviorSubject(null),
  scheduledAppointments: new BehaviorSubject([]),
  consultation: new BehaviorSubject({
    appointmentId: null,
    room: null,
    respondData: null,
    participants: [],
    mainVideoTracks: [],
    mainAudioTracks: [],
    localVideoTracks: [],
    isHidden: false,
    localAudioTracks: [],
    isMuted: false,
    chatChannel: null
  }),
  appointmentsFilters: new BehaviorSubject({
    period: 'upcoming',
    provider: '',
    patient: ''
  }),
  appointmentCreate: new BehaviorSubject(null),
  // Messages
  chatTokenData: new BehaviorSubject(null),
  chatClient: new BehaviorSubject(null),
  chatChannels: new BehaviorSubject(null),
  chatUsers: new BehaviorSubject([]),
  messageCounter: new BehaviorSubject(0),
  // Settings
  insuranceCompanies: new BehaviorSubject(null),
  userInsurance: new BehaviorSubject(null),
  // Referral
  referralList: new BehaviorSubject(null),
  referral: new BehaviorSubject(null),


  plans: new BehaviorSubject(null),

  localPatientProfile: new BehaviorSubject(null),
  medicalNumbersList: new BehaviorSubject(null),
  patientInsurance: new BehaviorSubject(null),
  localPatientInsurance: new BehaviorSubject(null),
  companyForm: new BehaviorSubject({}),
  companyPlans: new BehaviorSubject([]),
  companyPlansMembers: new BehaviorSubject([]),
  // Providers
  providersListFilters: new BehaviorSubject({}),
  providerResponsibilities: new BehaviorSubject(null),
  providersList: new BehaviorSubject(null),
  providerProfile: new BehaviorSubject(null),
  providerAppointments: new BehaviorSubject(null),
  providerQualification: new BehaviorSubject(null),
  providerAdminNotes: new BehaviorSubject(null),
  providerAdminNote: new BehaviorSubject(null),
  appointments: new BehaviorSubject(null),
  appointment: new BehaviorSubject(null),
  appointmentsProviders: new BehaviorSubject(null),
  localVideoTracks: new BehaviorSubject([]),
  localAudioTracks: new BehaviorSubject([]),
})

function identity (value) {
  return value
}
// Higher order hook for providing access to a single key of global state
export function createUseGlobalState (key) {
  return (fn = identity, deps = []) => {
    const context = React.useContext(globalContext)
    const withFn = React.useCallback(fn, deps)
    const stateObservable = context[key]
    const [localCopy, setLocalCopy] = React.useState(withFn(stateObservable.value))
    React.useEffect(() => {
      const sub = stateObservable.subscribe((...args) => {
        if (isEqual(localCopy, withFn(...args))) return
        setLocalCopy(withFn(...args))
      })
      return () => sub.unsubscribe()
    }, [stateObservable, localCopy, setLocalCopy, withFn])
    return localCopy
  }
}

function defaultStateMapper (setter, nextValue) {
  return setter(nextValue)
}

// Higher order hook for providing access to updating a single key of global state
export function createUseSetGlobalState (key, mapper = defaultStateMapper) {
  return (fn, deps = []) => {
    const withFn = React.useCallback(fn, deps)
    const context = React.useContext(globalContext)
    const stateObservable = context[key]
    const setter = React.useCallback(next => stateObservable.next(next), [stateObservable])
    return React.useCallback((...args) => {
      return mapper(setter, withFn(stateObservable.value, ...args))
    }, [setter, stateObservable, withFn])
  }
}

export const useCurrentLanguage = createUseGlobalState('currentLanguage')

export const useAuth = createUseGlobalState('auth')
export const useSetAuth = createUseSetGlobalState('auth')

export const useUser = createUseGlobalState('user')
export const useSetUser = createUseSetGlobalState('user')
