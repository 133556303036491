import React from "react";
import { DayAvailability } from "./DayAvailability";
import styled from "styled-components";
import omit from "lodash/omit";
import get from "lodash/get";

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

/**
 * @param {Object} p
 * @param {(args: boolean) => void} p.onHasError
 * @param {{ defaults: Object.<string, number[][]>; specials:{from: string, to:string, hoursCouples: number[][]}[]}} p.availability
 * @param {(args: { defaults: Object.<string, number[][]>; specials:{from: string, to:string, hoursCouples: number[][]}[]}) => void} p.onChange
 */
export const WeekAvailability = ({ availability, onChange, onHasError }) => {
  const defaults = get(availability, "defaults", {});

  const changeRanges = (day) => (ranges) => {

    const newAva = {
      ...availability,
      defaults:
        ranges.length > 0
          ? { ...availability.defaults, [day]: ranges }
          : omit(availability.defaults, day),
    };

    onChange(newAva);
  };

  const totalHours = Object.values(defaults).reduce((acc, ranges) => {
    const hours = ranges.reduce((acc, range) => acc + range[1] - range[0], 0);
    return acc + hours;
  }, 0);

  return (
    <Root>
      {days.map((day, i) => {
        return (
          <DayAvailability
            key={day}
            label={day}
            onHasError={onHasError}
            onRangesChange={changeRanges(i + 1)}
            ranges={defaults[i + 1]}
          />
        );
      })}
      <TotalHours>
        <strong>Total working hours</strong>
        <span>{totalHours}</span>
      </TotalHours>
    </Root>
  );
};

const Root = styled.div`
  max-width: 40.6rem;
`;
const TotalHours = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 2rem;

  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1.3rem 0;
  strong {
    font-weight: 500;
  }
  strong,
  span {
    font-size: 1.3rem;
  }
`;
