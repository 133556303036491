import React from 'react'

import { PRESCRIPTIONS } from 'constants/routes'; 

import { MediumText } from 'common/texts'
import DetailsTemplate from 'components/patients/DetailsTemplate'
import CustomButton from 'common/CustomButton'
import PrescriptionPreview from 'components/patients/prescriptions/common/PrescriptionPreview'

export default function PatientPrescriptions () {
  return (
    <DetailsTemplate currentTab={PRESCRIPTIONS}>
      <MediumText className='g-mb-10'>Patient medications</MediumText>
      <PrescriptionPreview
        label='ZyPREXA 10 mg oral tablet'
        desc='1/2 tablet orally after every meal for 5 days'
        status='pending'
        date='19 August 2020'
      />
      <PrescriptionPreview
        label='Penicillin V Potassium 250 mg oral tablet'
        desc='1 Tablet(s) orally 2 times a day (after meals) for 7 days (0 refills)'
        status='current'
        date='15 August 2020'
      />
      <PrescriptionPreview
        label='ZyPREXA 10 mg oral tablet'
        desc='1/2 tablet orally after every meal for 5 days'
        status='historical'
        date='14 August 2020'
      />
      <CustomButton className='g-mt-20'>New Rx</CustomButton>
    </DetailsTemplate>
  )
}
