import React from 'react'
import styled from 'styled-components'
import { DASHBOARD } from 'constants/routes'


import { WideRowPlate } from 'common/plate/plates'
import { PlateHeader, PlateContent } from 'common/plate/styles'
import BackButton from 'common/plate/BackButton'
import LinkButton from 'common/LinkButton'
import CustomButton from 'common/CustomButton'
import PrescriptionViewContent from 'components/patients/prescriptions/prescription-view/PrescriptionViewContent'
import PrescriptionInfo from 'components/patients/prescriptions/prescription-view/PrescriptionInfo'


export default function PatientPrescriptionView () {
  return (
    <WideRowPlate>
      <PlateHeader>
        <BackButton href={`/${DASHBOARD}`} />
        Prescription
      </PlateHeader>
      <PlateContent>
        <PrescriptionViewContent />
      </PlateContent>
      <StyledWrapper>
        <div className='status g-d-flex'>
          <PrescriptionInfo
            label='Rx status'
            value='Current'
          />
          <PrescriptionInfo
            label='Written date'
            value='15 August 2020'
          />
        </div>
        <div className='button-group g-d-flex'>
          <CustomButton>Download Rx</CustomButton>
          <LinkButton className='button g-ml-20'>Refill and send to Pending</LinkButton>
        </div>
      </StyledWrapper>
    </WideRowPlate>
  )
}

const StyledWrapper = styled.div`
  padding: 2rem 2.5rem 2.7rem;
  border: 0.1rem solid #e9ebef;
  .status {
    @media screen and (max-width: 768px) {
      display: block;
    }
  }
  .button-group {
    margin-top: 3rem;
    .button {
      min-width: 13rem;
      &:nth-child(n+2) {
        @media screen and (max-width: 1024px) {
          margin-left: 0.5rem;
        }
      }
    }
  }
`
