import React from 'react'
import styled from 'styled-components'
import get from 'lodash/get'

import { ADMIN_NOTES } from 'constants/routes';

import { usePatientAdminNote, useSetPatientAdminNote } from 'hooks/usePatients'
import { useCreatePatientAdminNote } from 'requests/patients/patients'

import { MediumText } from 'common/texts'
import DetailsTemplate from 'components/patients/DetailsTemplate'
import CustomTextarea from 'common/CustomTextarea'
import CustomButton from 'common/CustomButton'

export default function PatientCreateAdminNote () {
  const note = usePatientAdminNote(current => get(current, 'note'))
  const setPatientAdminNote = useSetPatientAdminNote((prev, note) => ({ note }))
  const clearPatientAdminNote = useSetPatientAdminNote(() => null)
  const createPatientAdminNote = useCreatePatientAdminNote()

  React.useEffect(() => {
    return clearPatientAdminNote
    // eslint-disable-next-line
  }, [])

  return (
    <DetailsTemplate currentTab={ADMIN_NOTES}>
      <MediumText>
        Admin notes
      </MediumText>
      <StyledCustomTextarea
        label='Enter your notes that available only to you'
        placeholder='Free text'
        value={note}
        changeHandler={setPatientAdminNote}
      />
      <CustomButton
        className='g-mt-35'
        disabled={!note}
        clickHandler={createPatientAdminNote}
      >
        Update
      </CustomButton>
    </DetailsTemplate>
  )
}

const StyledCustomTextarea = styled(CustomTextarea)`
  textarea {
    min-height: 40rem;
  }
`
