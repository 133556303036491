import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import moment from "moment";
import get from "lodash/get";

import {
  APPOINTMENTS,
  APPOINTMENT_INFO,
  DETAILS,
  PATIENTS,
  OVERVIEW,
} from "constants/routes";  

import { RegularText, MediumText } from "common/texts";
import UserAvatar from "common/UserAvatar";
import LinkButton from "common/LinkButton";
import { getTzAbbr } from "./../../../utils/tz";

export default React.memo(function AppointmentPreview({ appointment }) {
  const id = get(appointment, "id");
  const patientId = get(appointment, "patient.id", "");
  const firstName = get(appointment, "patient.first_name", "");
  const lastName = get(appointment, "patient.last_name", "");
  const fullName = get(appointment, "patient.full_name", "");
  const date = get(appointment, "started_at");

  const history = useHistory();

  const navigateToAppointment = React.useCallback(() => {
    history.push(`/${APPOINTMENTS}/${APPOINTMENT_INFO}/${id}`);
  }, [history, id]);

  const navigateToPatient = React.useCallback(() => {
    history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${OVERVIEW}`);
  }, [history, patientId]);

  return (
    <StyledWrapper>
      <div className="time-section">
        <RegularText>Appointment</RegularText>
        <RegularText>
          {moment.utc(date).local().format("D MMMM, YYYY, h:mmA")}{" "}
          {getTzAbbr(date)}
        </RegularText>
      </div>
      <div className="doctor-section">
        <div className="g-d-flex g-align-center">
          <UserAvatar
            className="doctor-avatar"
            firstName={firstName}
            lastName={lastName}
            onClick={navigateToPatient}
          />
          <div className="g-ml-15">
            <MediumText onClick={navigateToPatient}>{fullName}</MediumText>
          </div>
        </div>
        <LinkButton className="view-button" onClick={navigateToAppointment}>
          View
        </LinkButton>
      </div>
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  padding: 1.5rem 2.5rem 2rem;
  border-bottom: solid 1px #e9ebef;
  .time-section {
    display: flex;
    justify-content: space-between;
    opacity: 0.5;
  }
  .doctor-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
  }
  .doctor-avatar {
    min-width: 5.3rem;
    width: 5.3rem;
    height: 5.3rem;
  }
  .view-button {
    width: 10rem;
  }
`;
