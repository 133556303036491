import React from 'react'

import { useCreateCompany } from 'requests/companies'

import CustomButton from 'common/CustomButton'

export default function CompanySubmit ({ setValidate }) {
  const [isSended, setIsSended] = React.useState(false)
  const createCompany = useCreateCompany()
  const handleCreateCompany = React.useCallback(() => {
    setValidate(true)
    createCompany(setIsSended)
  }, [setValidate, createCompany, setIsSended])
  return (
    <div className='g-mt-20'>
      <CustomButton
        disabled={isSended}
        clickHandler={handleCreateCompany}
      >
        Create
      </CustomButton>
    </div>
  )
}
