import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { MESSAGES } from 'constants/routes'; 

import { ReactComponent as Arrow } from 'assets/arrow-down.svg'

import { useScreenWidth } from 'hooks/useUtils'
import { useCreateGroupChat } from 'requests/messages'

import LinkButton from 'common/LinkButton'

import createNotification from 'utils/createNotification'

export default React.memo(function GroupHeader () {
  const history = useHistory()
  const isMobile = useScreenWidth((value) => value <= 480, [])
  const createGroupChat = useCreateGroupChat()
  const [name, setName] = React.useState('')

  const backHandler = React.useCallback(() => history.push(`/${MESSAGES}`), [history])

  const createHandler = React.useCallback(() => {
    const parsedName = name.trim()
    if (!parsedName) return createNotification({ message: 'Invalid name', type: 'error' })
    createGroupChat(parsedName)
  }, [name, createGroupChat])

  return (
    <StyledWrapper>
      {isMobile && <StyledArrow onClick={backHandler} />}
      <span className='input-label'>Chat name:</span>
      <input
        className='input'
        placeholder='Enter group chat name'
        value={name}
        onChange={React.useCallback((e) => setName(e.target.value), [setName])}
      />
      <LinkButton
        disabled={!name}
        onClick={createHandler}
      >
        Create
      </LinkButton>
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  display: flex;
  height: 7rem;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 600;
  padding: 1.4rem 2.5rem;
  border-bottom: solid 2px #f1f5f8;
  .input-label {
    white-space: nowrap;
  }
  .input {
    display: block;
    width: 100%;
    margin: 0 0 0 1rem;
    border: none;
    outline: none;
    font-size: 1.6rem;
    font-weight: 600;
    ::placeholder {
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 2rem;
    }
  }
`

const StyledArrow = styled(Arrow)`
  transform: rotate(90deg);
  flex-shrink: 0;
  width: 3rem;
  height: 2.6rem;
  margin-bottom: 1px;
  padding: 1rem;
  margin-left: -1rem;
`
