import React from 'react';
import moment from 'moment';

import { globalContext } from 'hooks/useGlobalState';
import {
  useSetProfile,
  useSetLocalProfile,
  useSetStates,
  useSetGenders,
  useSetTimes,
} from 'hooks/useProfile';
import { useFetchUser } from 'requests/user';

import request from 'utils/request';
import createNotification from 'utils/createNotification';
import { convertAvailabilityTimezone } from '../components/profile/availability/available-time/helpers';
import { useProfileQualificationsStore } from "../stores/profile/qualificationStores";
import { useDictionaryStore } from "../stores/dictionaryStore";

export function useFetchGenders() {
  const setGenders = useSetGenders((prev, next) => next, []);
  return React.useCallback(() => {
    request({
      method: 'get',
      url: `/options/genders`,
    })
      .then(({ data }) => setGenders(data))
      .catch((error) => console.log(error));
  }, [setGenders]);
}

export function useFetchStates(country) {
  const { states } = React.useContext(globalContext);
  const setStates = useSetStates((prev, next) => next, []);
  return React.useCallback(() => {
    if (states.value) return;
    request({
      method: 'get',
      url: `/options/states?country=${country}`,
    })
      .then(({ data }) => setStates(data))
      .catch((error) => console.log(error));
  }, [setStates, states, country]);
}

export function useFetchProfile() {
  const { user } = React.useContext(globalContext);
  const setProfile = useSetProfile((prev, next) => next, []);
  const setLocalProfile = useSetLocalProfile((prev, next) => next, []);

  return React.useCallback(() => {
    const userId = user.value.id;
    request({
      method: 'get',
      url: `/providers/${userId}`,
    })
      .then(({ data: { data } }) => {
        setProfile(data.profile);
        setLocalProfile(data.profile);
      })
      .catch((error) => console.log(error));
  }, [user, setProfile, setLocalProfile]);
}

export function useUpdateProfile() {
  const { user, localProfile, uploadedAvatar } = React.useContext(globalContext);
  const setProfile = useSetProfile((prev, next) => next, []);
  const setLocalProfile = useSetLocalProfile((prev, next) => next, []);
  const fetchUser = useFetchUser();

  return React.useCallback(() => {
    const userId = user.value.id;
    const profileSnap = localProfile.value;
    const uploadedAvatarSnap = uploadedAvatar.value;
    delete profileSnap.avatar;
    if (uploadedAvatarSnap) profileSnap.avatar = uploadedAvatarSnap;
    request({
      method: 'post',
      url: `/providers/${userId}`,
      data: profileSnap,
    })
      .then(
        ({
          data: {
            data: { profile },
          },
        }) => {
          setProfile(profile);
          setLocalProfile(profile);
          fetchUser();
          createNotification({ message: 'Profile updated', type: 'success' });
        }
      )
      .catch((error) => console.log(error));
  }, [user, setProfile, setLocalProfile, localProfile, uploadedAvatar, fetchUser]);
}

export function useFetchQualifications() {
  const setQualifications = useDictionaryStore((s) => s.setQualificationsOptions);
  return React.useCallback(() => {
    request({
      method: 'get',
      url: `/options/qualifications`,
    })
      .then(({ data }) => setQualifications(data))
      .catch((error) => console.log(error));
  }, [setQualifications]);
}


export function useFetchProfileQualification(providerId = null) {
  const { user } = React.useContext(globalContext);
  const setQualification = useProfileQualificationsStore((s) => s.setUserQualifications);

  return React.useCallback(() => {
    const userId = providerId ?? user.value.id;
    request({
      method: 'get',
      url: `/providers/${userId}/qualification`,
    })
      .then(({ data: { data } }) => setQualification(data))
      .catch((error) => console.log(error));
  }, [user, setQualification]);
}

export function useFetchSymptoms() {
  const setSymptomsList = useDictionaryStore((s) => s.setSymptomsOptions);
  return React.useCallback(
    (responsibility) => {
      request({
        method: 'get',
        url: `/options/symptoms`,
        params: {
          responsibility,
        },
      })
        .then(({ data }) => setSymptomsList(data))
        .catch((error) => console.log(error));
    },
    [setSymptomsList]
  );
}

export function useUpdateQualification() {
  const { user } = React.useContext(globalContext);

  const qualification = useProfileQualificationsStore((s) => s.userQualifications);
  const setQualification = useProfileQualificationsStore((s) => s.setUserQualifications);
  const fetchUser = useFetchUser();

  return React.useCallback(() => {
    const userId = user.value.id;
    request({
      method: 'post',
      url: `/providers/${userId}/qualification`,
      data: qualification,
    })
      .then(({ data: { data } }) => {
        fetchUser();
        setQualification(data);
        createNotification({
          message: 'Qualification updated',
          type: 'success',
        });
      })
      .catch((error) => console.log(error));
  }, [user, qualification, fetchUser, setQualification]);
}

export function useFetchTimes() {
  const { user } = React.useContext(globalContext);
  const setTimesLoading = useSetTimes((prev, loading) => ({ ...prev, loading }), []);
  const setTimes = useSetTimes(
    (prev, work_time, timezone, loading) => ({
      ...prev,
      loading,
      timezone,
      work_time,
      original: work_time,
    }),
    []
  );
  return React.useCallback(() => {
    const userId = user.value.id;
    setTimesLoading(true);
    request({
      method: 'get',
      url: `/providers/${userId}/times`,
    })
      .then(
        ({
          data: {
            data: { work_time, timezone },
          },
        }) => {
          setTimes(work_time, timezone, false);
        }
      )
      .catch((error) => {
        console.log(error);
        setTimesLoading(false);
      });
  }, [user, setTimes]);
}

export function useUpdateTimes() {
  const { user, times } = React.useContext(globalContext);
  const setTimesLoading = useSetTimes((prev, isLoading) => ({ ...prev, isLoading }), []);
  const setTimes = useSetTimes(
    (prev, work_time, loading) => ({
      ...prev,
      loading,
      work_time,
      original: work_time,
    }),
    []
  );
  return React.useCallback(() => {
    const userId = user.value.id;
    setTimesLoading(true);

    const data = times.value.work_time;
    request({
      method: 'post',
      url: `/providers/${userId}/work_time`,
      data,
    })
      .then(() => {
        setTimes(data, false);
        createNotification({
          message: 'Availability updated',
          type: 'success',
        });
      })
      .catch((error) => {
        console.log(error);
        setTimesLoading(false);
      });
  }, [user, setTimes, times]);
}
