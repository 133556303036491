import React from 'react'

import { useReferralList } from 'hooks/useReferral'
import { useFetchReferralList } from 'requests/referral'

import ReferralPlaceholder from './ReferralPlaceholder'
import EmptyReferral from './EmptyReferral'
import ReferralList from './ReferralList'

export default function ReferralLoader () {
  const referralList = useReferralList()
  const fetchReferralList = useFetchReferralList()

  React.useEffect(() => {
    fetchReferralList()
    // eslint-disable-next-line
  }, [])

  if (!referralList) return <ReferralPlaceholder />

  if (referralList.length === 0) return <EmptyReferral />

  return <ReferralList />
}
