import React from 'react'
import styled from 'styled-components'

import { ReactComponent as IconCapsule } from 'assets/medication/capsule.svg'
import { ReactComponent as IconPill } from 'assets/medication/pill.svg'
import { ReactComponent as IconInjection } from 'assets/medication/injection.svg'
import { ReactComponent as IconLiquid } from 'assets/medication/liquid.svg'

const iconsConfig = [
  { value: 'capsule', label: <IconCapsule className='icon' /> },
  { value: 'pill', label: <IconPill className='icon' /> },
  { value: 'injection', label: <IconInjection className='icon' /> },
  { value: 'liquid', label: <IconLiquid className='icon' /> }
]

export default function Icon ({ icon }) {
  const parsedIcon = React.useMemo(() => {
    const iconOption = iconsConfig.find(({ value }) => value === icon)
    if (iconOption) return iconOption.label
    return <IconCapsule />
  }, [icon])
  return (
    <StyledWrapper>
      {parsedIcon}
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0.2rem 0.6rem rgba(0, 0, 0, 0.12);
  svg {
    width: 2rem;
    height: 2rem;
  }
`
