import React from 'react';
import { formatDateReadable, groupMealsByType } from '../utils';
import { ReactComponent as MealNotesIcon } from '../../../../../assets/meal-notes.svg';
import styled from 'styled-components';
import EmptyState from './EmptyState';
import { ReactComponent as EmptyStateIcon } from 'assets/food-journal-empty-state.svg';
import { ReactComponent as EmptyImage } from 'assets/empty-image.svg';
import { HeadingMD, SubHeadingBolder, SecondaryText } from './UI/Typography';

const MealCard = ({ meal, openModal }) => {
  return (
    <div className="relative rounded-lg shadow-md bg-white cursor-pointer" onClick={() => openModal(meal)}>
      <StyledNotes />
      {meal.image_url ? (
        <StyledImg clasName="rounded-t-lg" src={meal.image_url} alt="Meal" />
      ) : (
        <EmptyImg className="rounded-t-lg flex justify-center items-center ">
          <EmptyImage className="w-full aspect-video cover" />
        </EmptyImg>
      )}
      <StyledP>{meal.description || 'No description'}</StyledP>
    </div>
  );
};

const MealColumn = ({ type, meals, openModal }) => {
  return (
    <>
      {meals.map((meal, index) => (
        <div className="flex w-full md:w-1/2 lg:w-1/4 my-2.5 " key={index}>
          <div className="w-full pr-5">
            <div className="flex flex-row justify-between">
              <SubHeadingBolder className="mb-2.5">{type?.charAt(0).toUpperCase() + type?.slice(1)}</SubHeadingBolder>
              <SecondaryText>{meal.notes.length} notes</SecondaryText>
            </div>
            <MealCard key={index} meal={meal} openModal={openModal} />
          </div>
        </div>
      ))}
    </>
  );
};

const Meals = ({ mealsData, mealTypeOrder, openModal, recordsCount, handleGoToLatestRecords, dateFrom, dateTo }) => {
  // Filter mealsData based on date range
  let filteredMealsData = mealsData;
  if (dateFrom && dateTo) {
    filteredMealsData = Object.fromEntries(
      Object.entries(mealsData).filter(([date]) => {
        const currentDate = new Date(date);
        return currentDate >= new Date(dateFrom) && currentDate <= new Date(dateTo);
      })
    );
  }

  return (
    <>
      {/* {JSON.stringify(filteredMealsData)} */}
      {Object.keys(filteredMealsData).length > 0 ? (
        // Render meals if found
        Object.entries(filteredMealsData).map(([date, dailyMeals], index) => (
          <div key={index}>
            <HeadingMD className="my-2.5 mt-5">{formatDateReadable(date)}</HeadingMD>
            <div className="flex flex-wrap">
              {Object.entries(groupMealsByType(dailyMeals))
                .sort((a, b) => mealTypeOrder.indexOf(a[0]) - mealTypeOrder.indexOf(b[0]))
                .map(([type, meals]) => (
                  <MealColumn key={type} type={type} meals={meals} openModal={openModal} />
                ))}
            </div>
          </div>
        ))
      ) : (
        // Render EmptyState if no meals found
        <>
          <EmptyState
            icon={EmptyStateIcon}
            heading={recordsCount ? 'No food records for this date' : 'No food records'}
            subheading="They will appear once the patient adds them."
            buttonText={recordsCount ? 'Go to latest records' : ''}
            onClick={handleGoToLatestRecords}
          />
        </>
      )}
    </>
  );
};

export default Meals;

export const StyledImg = styled.img`
  object-fit: cover;
  aspect-ratio: 16 / 9;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
`;
export const EmptyImg = styled.div`
  object-fit: cover;
  aspect-ratio: 16 / 9;
  background-color: #f1f5f8;
`;
export const StyledP = styled.p`
  font-family: 'Poppins', sans-serif;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #16123f;
  padding: 11px;
`;
export const StyledNotes = styled(MealNotesIcon)`
  position: absolute;
  top: 10px;
  right: 10px;
`;
