import React from 'react'

import { useChatClient, useSetChatChannels } from 'hooks/useMessages'

export default function ChannelRemoved () {
  const chatClient = useChatClient()
  const removeChatChannel = useSetChatChannels((prev, removedChannel) => {
    return prev.filter(channel => channel.sid !== removedChannel.sid)
  }, [])

  React.useEffect(() => {
    chatClient.on('channelRemoved', removeChatChannel)

    return () => {
      chatClient.removeListener('channelRemoved', removeChatChannel)
    };
  }, [chatClient, removeChatChannel])

  return null
}
