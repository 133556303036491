import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import get from 'lodash/get';

import { APPOINTMENTS, CONSULTATION } from 'constants/routes';

import { StyledButton } from './styles';
import { useUserContext } from '../../../../context/userContext';

export default React.memo(function JoinAppointment({ message }) {
  const { isAdmin } = useUserContext();
  const history = useHistory();
  const appointmentId = get(message, 'state.attributes.appointment_id');
  const navigateToAppointment = React.useCallback(() => {
    if (isAdmin) return;
    history.push(`/${APPOINTMENTS}/${CONSULTATION}/${appointmentId}`);
  }, [history, appointmentId, isAdmin]);
  return (
    <StyledWrapper>
      <p>Your appointment starts NOW. Please connect to your appointment by clicking on the button below.</p>
      <StyledButton className="button g-mt-10 g-mb-5" onClick={navigateToAppointment}>
        Join the appointment
      </StyledButton>
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  .button {
    padding-right: 1rem;
    padding-left: 1rem;
  }
`;
