import React from 'react';

import { PROFILE, INFORMATION, QUALIFICATION, AVAILABILITY, DASHBOARD } from 'constants/routes';

import { RowPlate } from 'common/plate/plates';
import BackButton from 'common/plate/BackButton';
import { PlateHeader, PlateContent } from 'common/plate/styles';
import TabsSwitcher from 'common/TabsSwitcher';
import { useUserContext } from './../../context/userContext';

const tabsConfig = [
  { id: INFORMATION, roles: ['admin', 'provider'], label: 'Information', href: `/${PROFILE}/${INFORMATION}` },
  { id: QUALIFICATION, roles: ['admin', 'provider'], label: 'Qualification', href: `/${PROFILE}/${QUALIFICATION}` },
  { id: AVAILABILITY, roles: ['admin', 'provider'], label: 'Availability', href: `/${PROFILE}/${AVAILABILITY}` },
];

export default function ProfileTemplate({ children, currentTab }) {
  const { role } = useUserContext();
  return (
    <RowPlate>
      <PlateHeader>
        <BackButton href={`/${DASHBOARD}`} />
        Your profile
      </PlateHeader>

      <PlateContent>
        <TabsSwitcher role={role} className="g-mt-20 g-mb-30" tabsConfig={tabsConfig} currentTab={currentTab} />

        {children}
      </PlateContent>
    </RowPlate>
  );
}
