import React, { useEffect, useMemo } from 'react';

import { useHistory } from 'react-router-dom';
import { EDIT, LIBRARY } from '../../constants/routes';
import { useLibraryMaterialCategories } from '../../requests/graphql/patient/queries/getLibraryMaterialCategories';
import { useLibraryMaterialTypes } from 'requests/graphql/patient/queries/getLibraryMaterialTypes';
import { useParams } from 'react-router-dom';
import { LibraryMaterialStatuses } from '../../zeus-graphql/patient/zeus';
import { PageTitle } from '../../components/Breadcrumbs';
import { useLibraryMaterial } from '../../requests/graphql/patient/queries/getLibraryMaterial';
import { FullPlate } from '../../common/plate/plates';
import { ReactComponent as TagIcon } from '../../assets/tag.svg';
import { ReactComponent as EditIcon } from '../../assets/edit.svg';
import { ReactComponent as AuthorIcon } from '../../assets/author.svg';
import { ReactComponent as DateIcon } from '../../assets/date.svg';
import capitalize from 'lodash/capitalize';
import moment from 'moment';

export const ViewMaterial = () => {
  const history = useHistory();
  const { materialId } = useParams<{ materialId: string }>();
  const { libraryMaterialCategoriesOptions } = useLibraryMaterialCategories();
  const { libraryMaterialTypesOptions } = useLibraryMaterialTypes();

  const { libraryMaterial } = useLibraryMaterial(materialId);

  const item = useMemo(() => {
    const val = libraryMaterial;
    if (!val) return;
    return {
      title: val.title,
      categoryId: val.category.id,
      content: val.content,
      createdAt: val.createdAt,
      author: `${capitalize(val.createdBy?.profile?.fName || '')} ${capitalize(val.createdBy?.profile?.lName || '')}`,
      type: val.type,
      description: val.description,
      status: val.status as LibraryMaterialStatuses,
    };
  }, [libraryMaterial]);

  const category = useMemo(() => libraryMaterialCategoriesOptions.find((o) => o.value === item?.categoryId)?.label || '', [item]);
  const type = useMemo(() => libraryMaterialTypesOptions.find((o) => o.value === item?.type)?.label || '', [item]);

  useEffect(() => {
    if (libraryMaterial && (!materialId || !item)) {
      history.push(`/${LIBRARY}`);
    }
  }, [libraryMaterial, item, materialId]);

  return (
    <div className="flex justify-center">
      <PageTitle header="View material" crumbs={[{ title: 'Library', url: `/${LIBRARY}` }]} />
      <FullPlate className="self-center w-full max-w-[89.6rem] p-6">
        <div className="flex justify-between border-b pb-3">
          <div className="flex items-center">
            <div className="text-1517 text-manatee font-ambit mr-4">{type}</div>
            <div className="flex rounded-[2.4rem] bg-catskillWhite px-3 py-2">
              <TagIcon />
              <div className="text-main font-ambit text-1214 ml-[.6rem]">{category}</div>
            </div>
          </div>
          <div
            onClick={() => history.push(`/${LIBRARY}/${materialId}/${EDIT}`)}
            className="hover:bg-main group flex px-4 py-2 border border-main rounded-[2.4rem] cursor-pointer transition-all"
          >
            <EditIcon />
            <div className="text-main group-hover:text-white text-1313 ml-2 transition-all">Edit</div>
          </div>
        </div>
        <div className="border-b pb-4">
          <div className="flex justify-between items-center">
            <div className="flex items-center py-4">
              <AuthorIcon />
              <div className="text-1214 font-ambit font-semibold text-manatee ml-2">{item?.author}</div>
            </div>
            <div className="flex items-center">
              <DateIcon />
              <div className="text-1214 font-ambit font-semibold text-manatee ml-2">
                {item?.createdAt ? moment(item?.createdAt).format('MM.DD.YYYY') : ''}
              </div>
            </div>
          </div>
          <div className="text-1822 font-semibold font-ambit text-main mb-2">{item?.title}</div>
          <div className="text-1413 font-semibold font-ambit text-manatee">{item?.description}</div>
        </div>
        <div
          className="py-4 ck-editor-view"
          dangerouslySetInnerHTML={{
            __html: item?.content || '',
          }}
        ></div>
      </FullPlate>
    </div>
  );
};
