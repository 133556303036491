import React from 'react'
import styled from 'styled-components'

export default React.memo(function ListPreviewPlaceholder ({ className = '' }) {
  return (
    <StyledWrapper className={className}>
      <div className='loader-line' />
      <div className='loader-line' />
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  .loader-line {
    height: 10rem;
    margin-bottom: 1.5rem;
    border-radius: 0.8rem;
    background: #fafbfd;
  }
`
