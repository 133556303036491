/* eslint-disable */

import { ValueTypes, GraphQLTypes, InputType, Chain, OperationOptions, chainOptions } from './zeus/index';
import { useQuery, useMutation } from '@tanstack/react-query';
import type { UseQueryOptions, UseMutationOptions } from '@tanstack/react-query';

export function useTypedQuery<O extends 'MainQuery', TData extends ValueTypes[O], TResult = InputType<GraphQLTypes[O], TData>>(
  queryKey: unknown[],
  query: TData | ValueTypes[O],
  options?: Omit<UseQueryOptions<TResult>, 'queryKey' | 'queryFn'>,
  zeusOptions?: OperationOptions,
  host = '',
  hostOptions: chainOptions[1] = {}
) {
  return useQuery<TResult>(queryKey, () => Chain(host, hostOptions)('query')(query, zeusOptions) as Promise<TResult>, options);
}

export function useTypedMutation<
  O extends 'MainMutation',
  TData extends ValueTypes[O],
  TVars extends Record<string, any>,
  TResult = InputType<GraphQLTypes[O], TData>,
  TError = Error
>(
  mutationKey: unknown[],
  mutation: TData | ValueTypes[O],
  options?: Omit<UseMutationOptions<TResult, TError, TVars>, 'mutationKey' | 'mutationFn'>,
  zeusOptions?: OperationOptions,
  host = '',
  hostOptions: chainOptions[1] = {}
) {
  return useMutation<TResult, TError, TVars>(
    (variables) => {
      return Chain(host, {
        ...hostOptions,
        headers: { ...hostOptions?.headers },
      })('mutation')(mutation, {
        ...zeusOptions,
        variables,
      }) as Promise<TResult>;
    },
    { mutationKey, ...options }
  );
}
