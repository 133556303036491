import React from 'react'
import get from 'lodash/get'

import { usePatientHealthPlans } from 'hooks/usePatients'

import PlanPreview from './PlanPreview'

export default React.memo(function PlansList () {
  const patientHealthPlans = usePatientHealthPlans()
  return React.useMemo(() => {
    return patientHealthPlans.map(plan => {
      const id = get(plan, 'id')
      const medicalRecord = get(plan, 'medical_record')
      const created = get(plan, 'created_at')
      const status = get(plan, 'viewed_at')
      const text = get(plan, 'text', '')
      const file = get(plan, 'attachment_url')
      return (
        <PlanPreview
          key={id}
          id={id}
          medicalRecord={medicalRecord}
          created={created}
          status={status}
          text={text}
          file={file}
        />
      )
    })
  }, [patientHealthPlans])
})
