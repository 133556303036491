import React from 'react'
import moment from 'moment'

import { WEEK } from 'constants/routes'; 

import { useSetScheduledAppointments } from 'hooks/useAppointments'
import { useFetchScheduledAppointments } from 'requests/appointments'

import AppointmentsTemplate from 'components/appointmentsProvider/AppointmentsTemplate'
import WeekHeader from 'components/appointmentsProvider/week/WeekHeader'
import WeekGrid from 'components/appointmentsProvider/week/WeekGrid'

export default function WeekAppoitments () {
  const [weekStart, setWeekStart] = React.useState(moment().startOf('week'))
  const fetchScheduledAppointments = useFetchScheduledAppointments()
  const clearScheduledAppointments = useSetScheduledAppointments(() => [], [])

  React.useEffect(() => {
    fetchScheduledAppointments({
      period: 'week',
      period_start: weekStart.format('YYYY-MM-DD')
    })
    return clearScheduledAppointments
    // eslint-disable-next-line
  }, [weekStart])

  return (
    <AppointmentsTemplate currentTab={WEEK}>
      <WeekHeader
        weekStart={weekStart}
        setWeekStart={setWeekStart}
      />

      <WeekGrid
        weekStart={weekStart}
      />
    </AppointmentsTemplate>
  )
}
