import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import get from 'lodash/get'

import { DETAILS, PATIENTS, PROVIDERS, REFERRAL, OVERVIEW } from 'constants/routes'; 

import { RegularText, HintText } from 'common/texts'
import UserAvatar from 'common/UserAvatar'
import LinkButton from 'common/LinkButton'

export default function ReferralPreview ({ user }) {
  const { providerId } = useParams()
  const history = useHistory()
  const id = get(user, 'id')
  const firstName = get(user, 'first_name')
  const lastName = get(user, 'last_name')
  const fullName = get(user, 'full_name')
  const visits = get(user, 'visits')
  const membership = get(user, 'membership')
  const status = get(user, 'status')

  const navigateToPatient = React.useCallback(() => {
    history.push({
      pathname: `/${PATIENTS}/${DETAILS}/${id}/${OVERVIEW}`,
      state: { referrer: `/${PROVIDERS}/${providerId}/${REFERRAL}` }
    })
  }, [history, id, providerId])

  return (
    <StyledWrapper>
      <div className='user g-d-flex'>
        <UserAvatar
          className='avatar'
          firstName={firstName}
          lastName={lastName}
          onClick={navigateToPatient}
        />
        <div className='name g-ml-20'>
          {fullName}
        </div>
      </div>

      <div>
        <HintText>
          Free visits
        </HintText>
        <RegularText>
          {visits}
        </RegularText>
      </div>

      <div>
        <HintText>
          Membership
        </HintText>
        <RegularText>
          {membership}
        </RegularText>
      </div>

      <div>
        <HintText>
          Status
        </HintText>
        <RegularText>
          {status}
        </RegularText>
      </div>

      <LinkButton
        className='view-button'
        onClick={navigateToPatient}
      >
        View profile
      </LinkButton>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  padding: 2.4rem;
  margin-bottom: 1.5rem;
  background: #fafbfd;
  border-radius: 0.8rem;
  display: grid;
  grid-template-columns: 33% 17% 17% 17% 16%;
  .name {
    font-size: 1.6rem;
    font-weight: 700;
    align-self: center;
  }
  .view-button {
    margin: auto 0 auto auto;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    grid-row-gap: 1rem;
    .user {
      grid-column-start: 1;
      grid-column-end: 4;
    }
    .view-button {
      grid-column-start: 1;
      grid-column-end: 4;
      width: 100%;
    }
  }
`
