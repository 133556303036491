import React, { useEffect } from 'react';
import styled from 'styled-components';

import { UppercaseText } from 'common/texts';
import CloseIcon from 'common/CloseIcon';
import SearchResults from './SearchResults';

export default React.memo(function SearchableSelect({ className = '', label, placeholder, searchLink, params, searchField, onSelect, onClear }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [value, setValue] = React.useState('');
  const inputRef = React.useRef(null);
  const elemRef = React.useRef(null);
  const clearValue = React.useCallback(() => {
    setValue('');
    onClear && onClear();
  }, [setValue, onClear]);

  useEffect(() => {
    const onBodyClick = (event) => {
      if (elemRef?.current?.contains(event.target) || inputRef?.current?.contains(event.target)) {
        return;
      }

      setIsOpen(false);
    };
    document.body.addEventListener('click', onBodyClick);
    return () => {
      document.body.removeEventListener('click', onBodyClick);
    };
  }, []);

  const handleSelect = React.useCallback((val) => {
    onSelect(val);
    setIsOpen(false);
  }, [])

  return (
    <StyledWrapper className={className}>
      {label && <UppercaseText className="g-mb-10">{label}</UppercaseText>}
      <StyledSelectWrapper>
        <input
          ref={inputRef}
          className="input"
          placeholder={placeholder}
          value={value}
          onFocus={React.useCallback(() => setIsOpen(true), [setIsOpen])}
          onChange={React.useCallback((e) => setValue(e.target.value), [setValue])}
        />
        {value.length > 2 && (
          <div ref={elemRef} className="">
            <SearchResults
              isOpen={isOpen}
              searchLink={searchLink}
              params={params}
              searchField={searchField}
              value={value}
              setValue={setValue}
              onSelect={handleSelect}
            />
          </div>
        )}
        {value && <CloseIcon className="close-icon" closeHandler={clearValue} />}
      </StyledSelectWrapper>
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  max-width: 27rem;
  width: 100%;
  @media only screen and (max-width: 480px) {
    max-width: initial;
  }
`;

const StyledSelectWrapper = styled.div`
  position: relative;
  width: 100%;
  input {
    display: block;
    width: 100%;
    font-size: 1.3rem;
    line-height: 3rem;
    outline: none;
    padding: 0.8rem 1.6rem;
    border: solid 1px #e9ebef;
    border-radius: 2px;
    &::placeholder {
      font-size: 1.2rem;
      font-weight: 300;
    }
    &:hover {
      border: solid 1px #ccc;
    }
    &:focus {
      box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.25);
    }
  }
  .close-icon {
    position: absolute;
    right: 0.5rem;
    top: 0;
    bottom: 0;
    margin: auto;
  }
`;
