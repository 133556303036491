import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import { ReactComponent as DocumentIcon } from 'assets/document.svg'

import { PreFormatting, UppercaseText, RegularText } from 'common/texts'
import LinkButton from 'common/LinkButton'
import EditHealthPlanButton from 'components/patients/health-plan/EditHealthPlanButton'
import DeleteHealthPlanButton from 'components/patients/health-plan/DeleteHealthPlanButton'
const PlanDetails = React.memo(function PlanDetails ({ text, file }) {
  const fileName = file && file.split('/').slice(-1)[0]
  const normalizedFileName = fileName.split('?')[0]
  return (
    <>
      <UppercaseText className='g-mt-10 g-mb-10'>
        Health plan for patient
      </UppercaseText>
      <PreFormatting className='pseudo-textarea'>
        {text}
      </PreFormatting>
      {file && (
        <div className='g-d-flex g-align-center g-mt-10'>
          <DocumentIcon className='g-shrink-0 g-mr-5' />
          <StyledRegularText>
            <a
              href={file}
              rel='noopener noreferrer'
              target='_blank'
              download
            >
              {normalizedFileName}
            </a>
          </StyledRegularText>
        </div>
      )}
    </>
  )
})

const StyledRegularText = styled(RegularText)`
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export default React.memo(function PlanPreview ({
  id, medicalRecord, created, status, text, file
}) {
  const [isOpen, setIsOpen] = React.useState(false)
  return (
    <StyledPreview>
      <div className='status-wrapper'>
        <div className='mrn g-mt-5 g-mb-5 g-mr-25'>
          <UppercaseText>medical record number</UppercaseText>
          <RegularText>{medicalRecord}</RegularText>
        </div>

        <div className='g-mt-5 g-mb-5 g-d-flex g-justify-between g-align-center g-w-100'>
          <div className='column g-mr-25'>
            <UppercaseText>Date of creation</UppercaseText>
            <RegularText>{moment(created).format('MM.DD.YYYY')}</RegularText>
          </div>

          <div className='column g-mr-25'>
            <UppercaseText>Status</UppercaseText>
            <RegularText>{status ? 'Viewed by patient' : 'Not viewed'}</RegularText>
          </div>

          <DeleteHealthPlanButton
            healthPlanID={id}
          />

          <EditHealthPlanButton
            healthPlanID={id}
          />
          <LinkButton
            className='view-button'
            onClick={React.useCallback(() => setIsOpen(prev => !prev), [setIsOpen])}
          >
            {isOpen ? 'Close' : 'View'}
          </LinkButton>
        </div>
      </div>

      {isOpen && (
        <PlanDetails
          text={text}
          file={file}
        />
      )}
    </StyledPreview>
  )
})

const StyledPreview = styled.div`
  padding: 2.4rem;
  margin-bottom: 1.5rem;
  background: #fafbfd;
  border-radius: 0.8rem;
  .mrn {
    width: 40%;
  }
  .column {
    width: 30%;
  }
  .view-button {
    padding: 0.6rem 3.5rem;
  }
  .status-wrapper {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 768px) {
      flex-wrap: wrap;
    }
    @media only screen and (max-width: 480px) {
      justify-content: flex-start;
    }
  }
  .pseudo-textarea {
    background: #fff;
    padding: 1rem 1.5rem;
    border: solid 1px #e9ebef;
    font-size: 1.6rem;
  }
`
