import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import { HintText, RegularMediumText } from 'common/texts'

import { ReactComponent as DocumentIcon } from 'assets/document.svg'

import { usePatientHealthPlan, useSetPatientHealthPlan } from 'hooks/usePatients'

export default React.memo(function UploadFile () {
  const file = usePatientHealthPlan(current => get(current, 'file'))
  const attachment = usePatientHealthPlan(current => get(current, 'attachment_url'))
  const attachmentFileName = attachment && attachment.split('/').slice(-1)[0].split('?')[0]
  const setFile = useSetPatientHealthPlan((prev, file) => ({ ...prev, file }))
  const setAttachment = useSetPatientHealthPlan((prev, attachment_url) => ({ ...prev, attachment_url }))

  const changeHandler = React.useCallback((e) => {
    setFile(e.target.files[0])
  }, [setFile])

  const clearHandler = React.useCallback(() => {
    setFile(null)
  }, [setFile])

  const clearAttachment = React.useCallback(() => {
    setAttachment(null)
  }, [setAttachment])

  if (file) {
    return (
      <div className='g-d-flex g-align-center g-mt-10'>
        <DocumentIcon className='g-mr-5' />
        <RegularMediumText>{file.name}</RegularMediumText>
        <StyledRemoveButton
          className='g-ml-20'
          onClick={clearHandler}
        >
          <div className='cross cross-left' />
          <div className='cross cross-right' />
        </StyledRemoveButton>
      </div>
    )
  } else if (attachmentFileName) {
    return (
      <div className='g-d-flex g-align-center g-mt-10'>
        <DocumentIcon className='g-mr-5' />
        <RegularMediumText>{attachmentFileName}</RegularMediumText>
        <StyledRemoveButton
          className='g-ml-20'
          onClick={clearAttachment}
        >
          <div className='cross cross-left' />
          <div className='cross cross-right' />
        </StyledRemoveButton>
      </div>
    )
  }

  return (
    <div className='g-mt-20'>
      <label htmlFor='upload-plan'>
        <LinkButton>
          Upload
        </LinkButton>
      </label>
      <input
        id='upload-plan'
        className='g-d-none'
        type='file'
        accept='.doc, .docx, .pdf'
        onChange={changeHandler}
      />

      <HintText>
        Supported formats PDF, Word
      </HintText>
    </div>
  )
})

const LinkButton = styled.div`
  display: inline-block;
  background: #f9fafc;
  border: solid 1px #e9ebef;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.3rem;
  text-align: center;
  line-height: 2.4rem;
  padding: 0.6rem 1.5rem;
  transition: all 0.2s;
  outline: none;
  white-space: nowrap;
  &:hover:not([disabled]) {
    background: #fff;
    cursor: pointer;
  }
  &:active:not([disabled]) {
    box-shadow: 0 0 0 0.3rem rgba(0, 0, 0, 0.25);
  }
  &:disabled {
    opacity: 0.5;
  }
`

const StyledRemoveButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  transition: all 0.2s;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
  &:active {
    opacity: 1;
    box-shadow: 0 0 0 0.3rem rgba(0,0,0,0.25);
  }
  .cross {
    width: 2px;
    height: 1.3rem;
    background: #223232;
    position: absolute;
    border-radius: 1px;
  }
  .cross-left {
    transform: rotate(45deg);
  }
  .cross-right {
    transform: rotate(-45deg);
  }
`
