import React from 'react'
import styled from 'styled-components'

export default function DateSeparator ({
  className = '', value
}) {
  return (
    <StyledWrapper className={className}>
      {value && (
        <span>
          {value}
        </span>
      )}
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  font-size: 1.2rem;
  border-bottom: 1px dashed #a2c0d4;
  height: 1rem;
  margin-bottom: 2rem;
  padding-left: 3rem;
  span {
    background: #fff;
    padding: 0 0.5rem;
    color: rgba(0, 0, 0, 0.5);
  }
`
