import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { PATIENTS, DETAILS, QUESTIONNAIRES, QUIZ, RESULTS } from 'constants/routes'; 

import { useModalHandler } from 'hooks/useUtils'
import { useCheckQuizAvailability } from 'requests/patients/questionnaires'

import CustomButton from 'common/CustomButton'
import NotAvailableQuizModal from '../common/NotAvailableQuizModal'

export default React.memo(function JsonFormButton ({ id, label, isCompleted }) {
  const { patientId } = useParams()
  const history = useHistory()
  const checkQuizAvailability = useCheckQuizAvailability()
  const [isOpen, openModal, closeModal] = useModalHandler()

  const handleClick = React.useCallback(async () => {
    if (isCompleted) return history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${QUESTIONNAIRES}/${QUIZ}/${id}/${RESULTS}`)
    const isAvailable = await checkQuizAvailability(id)
    if (!isAvailable) return openModal()
    history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${QUESTIONNAIRES}/${QUIZ}/${id}`)
  }, [isCompleted, checkQuizAvailability, openModal, history, patientId, id])

  return (
    <>
      <CustomButton
        className='button'
        clickHandler={handleClick}
      >
        {label}
      </CustomButton>
      {isOpen && (
        <NotAvailableQuizModal closeModal={closeModal} />
      )}
    </>
  )
})
