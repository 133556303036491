import {
  createUseGlobalState,
  createUseSetGlobalState,
} from "hooks/useGlobalState";

export const usePatinetsStatusOptions = createUseGlobalState(
  "patinetsStatusOptions"
);
export const useSetPatinetsStatusOptions = createUseSetGlobalState(
  "patinetsStatusOptions"
);

export const useMedicationsCategories = createUseGlobalState(
  "medicationsCategories"
);
export const useSetMedicationsCategories = createUseSetGlobalState(
  "medicationsCategories"
);

export const useMedicationsMeasures = createUseGlobalState(
  "medicationsMeasures"
);
export const useSetMedicationsMeasures = createUseSetGlobalState(
  "medicationsMeasures"
);

export const useMedicationsFrequencies = createUseGlobalState(
  "medicationsFrequencies"
);
export const useSetMedicationsFrequencies = createUseSetGlobalState(
  "medicationsFrequencies"
);

export const usePlans = createUseGlobalState('plans')
export const useSetPlans = createUseSetGlobalState('plans')
