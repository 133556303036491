import React from 'react'
import styled from 'styled-components'

import { HintText } from 'common/texts'

export default function WeightStatsItem ({ label = '', value = '', units = '' }) {
  return (
    <StyledWrapper>
      <HintText>{label}</HintText>
      <p className='value'><span>{value}</span> {units}</p>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  &:nth-child(2) {
    margin-left: 15%;
    @media screen and (max-width: 480px) {
      margin-left: auto;
    }
  }
  .value {
    font-size: 1.6rem;
    span {
      font-size: 5rem;
      line-height: 1;
    }
  }
`
