import React from 'react'

import { useAppointmentsFilters, useAppointments } from 'hooks/useAppointments'
import { useFetchAppointments } from 'requests/appointments'

import AppointmentsPlaceholder from './AppointmentsPlaceholder'
import EmptyAppointments from './EmptyAppointments'
import AppointmentsList from './AppointmentsList'

export default React.memo(function AppointmentsLoader () {
  const { period, provider, patient } = useAppointmentsFilters()
  const fetchAppointments = useFetchAppointments()
  const appointments = useAppointments()

  React.useEffect(() => {
    fetchAppointments({ period, provider, patient })
    // eslint-disable-next-line
  }, [period, provider, patient])

  if (!appointments) return <AppointmentsPlaceholder />

  if (appointments && appointments.length === 0) return <EmptyAppointments />

  return <AppointmentsList />
})
