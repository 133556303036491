import React from 'react'
import styled from 'styled-components'

/**
 *
 * @param {*} param0
 * @param {string} param0.className
 * @param {boolean} param0.checked
 * @param {function} param0.changeHandler
 * @param {string} param0.label
 * @param {boolean} param0.disabled
 * @param {boolean} param0.readOnly
 * @returns
 */
export default function CustomCheckbox ({
  className = '', checked = false, changeHandler, label, disabled, readOnly
}) {
  return (
    <StyledWrapper className={className}>
      {label}
      <input
        checked={checked}
        onChange={changeHandler}
        type='checkbox'
        disabled={disabled}
        readOnly={readOnly}
      />
      <span className='checkmark' />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.label`
  display: block;
  position: relative;
  padding-left: 2.5rem;
  font-size: 1.3rem;
  line-height: 3rem;
  user-select: none;
  cursor: pointer;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 5px;
    border: solid 1px #ccc;
    background-color: #fff;
  }

  input:checked ~ .checkmark {
    background: #16123f;
    border-color: #16123f;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 0.5rem;
    top: 0.3rem;
    width: 0.4rem;
    height: 0.7rem;
    border: solid #fff;
    border-width: 0 0.2rem 0.2rem 0;
    transform: rotate(45deg);
    @media screen and (max-width: 480px) {
      left: 0.4rem;
    }
  }
`
