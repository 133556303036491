import React from 'react'

import { RegularText, MediumText } from 'common/texts'
import { StyledLine } from './styles'

export default React.memo(function PatientProblems ({ reason = '' }) {
  return (
    <StyledLine>
      <div>
        <RegularText>
          Problems with health
        </RegularText>
        <MediumText>
          {reason}
        </MediumText>
      </div>
    </StyledLine>
  )
})
