import React from 'react'
import get from 'lodash/get'
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'

import { useSetQuiz } from 'hooks/patient/useQuestionnaires'

import LinkButton from 'common/LinkButton'

export default React.memo(function AddRow ({ metaPath, text = 'Add row' }) {
  const addRowHandler = useSetQuiz((prev) => {
    const currentRowCount = get(prev, `${metaPath}.rowCount`)
    return set(cloneDeep(prev), `${metaPath}.rowCount`, currentRowCount + 1)
  }, [metaPath])
  return (
    <LinkButton
      className='g-mt-20'
      onClick={addRowHandler}
    >
      {text}
    </LinkButton>
  )
})
