import React from 'react';

import { globalContext } from 'hooks/useGlobalState';
import {
  useSetPatinetsStatusOptions,
  useSetMedicationsCategories,
  useSetPlans,
  useSetMedicationsMeasures,
  useSetMedicationsFrequencies,
} from 'hooks/useOptions';

import request from 'utils/request';
import { useDictionaryStore } from '../stores/dictionaryStore';

export function useFetchPatinetsStatus() {
  const { patinetsStatusOptions } = React.useContext(globalContext);
  const setPatinetsStatusOptions = useSetPatinetsStatusOptions((prev, next) => next, []);
  return React.useCallback(() => {
    if (patinetsStatusOptions.value) return;
    request({
      method: 'get',
      url: `/options/statuses`,
    })
      .then(({ data }) => setPatinetsStatusOptions(data))
      .catch((error) => console.log(error));
  }, [patinetsStatusOptions, setPatinetsStatusOptions]);
}

export function useFetchMedicationsCategories() {
  const { medicationsCategories } = React.useContext(globalContext);
  const setMedicationsCategories = useSetMedicationsCategories((prev, next) => next, []);
  return React.useCallback(() => {
    if (medicationsCategories.value) return;
    request({
      method: 'get',
      url: `/medications/categories`,
    })
      .then(
        ({
          data: {
            data: { categories },
          },
        }) => setMedicationsCategories(categories)
      )
      .catch((error) => console.log(error));
  }, [medicationsCategories, setMedicationsCategories]);
}

export function useFetchMedicationsMeasures() {
  const { medicationsMeasures } = React.useContext(globalContext);
  const setMedicationsMeasures = useSetMedicationsMeasures((prev, next) => next, []);
  return React.useCallback(() => {
    if (medicationsMeasures.value) return;
    request({
      method: 'get',
      url: `/medications/measures`,
    })
      .then(
        ({
          data: {
            data: { measures },
          },
        }) => setMedicationsMeasures(measures)
      )
      .catch((error) => console.log(error));
  }, [medicationsMeasures, setMedicationsMeasures]);
}

export function useFetchMedicationsFrequencies() {
  const { medicationsFrequencies } = React.useContext(globalContext);
  const setMedicationsFrequencies = useSetMedicationsFrequencies((prev, next) => next, []);
  return React.useCallback(() => {
    if (medicationsFrequencies.value) return;
    request({
      method: 'get',
      url: `/medications/frequencies`,
    })
      .then(
        ({
          data: {
            data: { frequencies },
          },
        }) => setMedicationsFrequencies(frequencies)
      )
      .catch((error) => console.log(error));
  }, [medicationsFrequencies, setMedicationsFrequencies]);
}

export function useFetchQualifications() {
  const { qualifications } = React.useContext(globalContext);
  const setQualifications = useDictionaryStore((state) => state.setQualificationsOptions);
  return React.useCallback(() => {
    if (qualifications.value) return;
    request({
      method: 'get',
      url: `/options/qualifications`,
    })
      .then(({ data }) => setQualifications(data))
      .catch((error) => console.log(error));
  }, [qualifications, setQualifications]);
}

export function useFetchPlans() {
  const { plans } = React.useContext(globalContext);
  const setPlans = useSetPlans((prev, next) => next, []);
  return React.useCallback(() => {
    if (plans.value) return;
    request({
      method: 'get',
      url: `/options/plans`,
    })
      .then(({ data }) => setPlans(data))
      .catch((error) => console.log(error));
  }, [plans, setPlans]);
}
