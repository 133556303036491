import React from 'react'
import { useHistory } from 'react-router-dom'

import { ENVIRONMENT_CHECK, FINISH } from 'constants/routes'; 

import CustomButoon from 'common/CustomButton'
import LinkButton from 'common/LinkButton'

import Hint from './Hint'

export default function ButtonGroup () {
  const history = useHistory()
  const [isHintOpen, setIsHintOpen] = React.useState(false)

  const navigateToFinish = React.useCallback(() => {
    history.push(`/${ENVIRONMENT_CHECK}/${FINISH}`)
  }, [history])

  const openHints = React.useCallback(() => {
    setIsHintOpen(true)
  }, [setIsHintOpen])

  return (
    <>
      <div className='g-d-flex g-mt-30'>
        <CustomButoon
          clickHandler={navigateToFinish}
        >
          Yes, sound is working
        </CustomButoon>
        <LinkButton
          className='g-ml-20'
          onClick={openHints}
        >
          No, sound is not working
        </LinkButton>
      </div>

      <Hint isOpen={isHintOpen} />
    </>
  )
}
