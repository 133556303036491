import React from 'react'
import styled from 'styled-components'

import CloseIcon from 'common/CloseIcon'
import SearchResults from './SearchResults'

export default React.memo(function UserSearch ({ placeholder, selectHandler }) {
  const ref = React.useRef()
  const [isOpen, setIsOpen] = React.useState(false)
  const [value, setValue] = React.useState('')
  const clearValue = React.useCallback(() => setValue(''), [setValue])

  const toggleOptions = React.useCallback(() => setIsOpen(current => !current), [setIsOpen])
  const closeOptions = React.useCallback(() => setIsOpen(false), [setIsOpen])

  React.useEffect(() => {
    function handleClick (e) {
      if (ref.current && ref.current.contains(e.target)) return
      closeOptions()
    }
    window.addEventListener('click', handleClick)
    return () => window.removeEventListener('click', handleClick)
  }, [closeOptions])

  return (
    <StyledWrapper className='g-w-100'>
      <div
        ref={ref}
        className='g-p-relative g-w-100'
      >
        <input
          className='input'
          placeholder={placeholder}
          value={value}
          onFocus={toggleOptions}
          onChange={React.useCallback((e) => setValue(e.target.value), [setValue])}
        />
        {value.length > 2 && (
          <SearchResults
            isOpen={isOpen}
            value={value}
            selectHandler={selectHandler}
          />
        )}
      </div>
      {value && <CloseIcon closeHandler={clearValue} />}
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .input {
    display: block;
    width: 100%;
    margin: 0 0 0 1rem;
    border: none;
    outline: none;
    font-size: 1.6rem;
    font-weight: 600;
    ::placeholder {
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 2rem;
    }
  }
`
