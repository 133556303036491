import React from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import get from "lodash/get";
import { Cognito } from "../libs/cognito";

import { useSetUser } from "hooks/useGlobalState";
import { useSetRequestsLoading } from "hooks/useUtils";

import createNotification from "utils/createNotification";
import { saveSession } from "./../utils/saveSession";
import { LOGIN } from "constants/routes";

const cognito = Cognito.getInstance();
let requests = [];
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const blockedMessages = [
  "Token could not be parsed from the request.",
  "Sorry, Doctor not available",
];

function isBlockedMessage(error) {
  return blockedMessages.find(
    (message) => get(error, "response.data.message") === message
  );
}

export default React.memo(function InterceptorsHandler() {
  const history = useHistory();
  const setRequestsLoading = useSetRequestsLoading((prev, next) => next, []);
  const clearUser = useSetUser(() => null, []);

  React.useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        const currentUrl = get(config, "url");
        requests.push(currentUrl);
        setRequestsLoading(true);
        return config;
      },
      (error) => Promise.reject(error)
    );

    axios.interceptors.response.use(
      (response) => {
        const currentUrl = get(response, "config.url");
        requests = requests.filter((url) => url !== currentUrl);
        if (requests.length === 0) setRequestsLoading(false);
        return response;
      },
      (error) => {
        const currentUrl = get(error, "response.config.url");
        requests = requests.filter((url) => url !== currentUrl);
        if (requests.length === 0) setRequestsLoading(false);
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
          if (isRefreshing) {
            return new Promise(function (resolve, reject) {
              failedQueue.push({ resolve, reject });
            })
              .then((token) => {
                originalRequest.headers["Authorization"] = "Bearer " + token;
                return axios(originalRequest);
              })
              .catch((err) => {
                return Promise.reject(err);
              });
          }

          originalRequest._retry = true;
          isRefreshing = true;

          return new Promise(function (resolve, reject) {
            if (!localStorage.getItem("idToken")) return;
            cognito
              .refreshSession()
              .then((session) => {
                saveSession(session);
                const token = session.idToken.jwtToken;
                originalRequest.headers["Authorization"] = "Bearer " + token;
                processQueue(null, token);
                resolve(axios(originalRequest));
              })
              .catch((err) => {
                processQueue(err, null);
                reject(err);
                clearUser();
                localStorage.removeItem("idToken");
                localStorage.removeItem("refreshToken");
                history.push(`/${LOGIN}`);
              })
              .then(() => {
                isRefreshing = false;
              });
          });
        }
        if (error.response.status === 401 && originalRequest._retry) {
          cognito
            .getSession()
            .then((session) => {
              const exp = get(session, "idToken.payload.exp");

              if (exp * 1000 > Date.now()) {
                createNotification({
                  message:
                    "Sorry, your user has not found. Please contact us support@cibahealth.com",
                  type: "error",
                });
              }
            })
            .finally(() => {
              cognito.signOut();
              history.push(`/${LOGIN}`);
              clearUser();
            });

          return Promise.reject(error);
        }
        if (error.response.status === 403) {
          get(error, "response.data.errors", []).forEach((item) =>
            createNotification({ message: item, type: "error" })
          );
          return Promise.reject(error);
        }
        if (error.response.status === 422) {
          Object.values(get(error, "response.data.errors", {})).forEach(
            (items) => {
              items.forEach((item) =>
                createNotification({ message: item, type: "error" })
              );
            }
          );
          return Promise.reject(error);
        }
        if (isBlockedMessage(error)) return Promise.reject(error);

        if (error.response.data.message)
          createNotification({
            message: error.response.data.message,
            type: "error",
          });
        return Promise.reject(error);
      }
    );
    // eslint-disable-next-line
  }, []);

  return null;
});
