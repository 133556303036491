import React from 'react'

import { MESSAGES } from 'constants/routes'; 

import { useGetGroupChannelName } from 'hooks/useMessages'
import { useScreenWidth } from 'hooks/useUtils'

import BackButton from 'common/plate/BackButton'
import ChatSettings from './chat-settings'
import { StyledWrapper, StyledGroupChatIcon, StyledSubGroupChatIcon } from './styles'

export default React.memo(function GroupHeader ({ channel }) {
  const isMobile = useScreenWidth((value) => value <= 480, [])
  const getGroupChannelName = useGetGroupChannelName(channel)
  const name = getGroupChannelName(channel)

  const ref = React.useRef()
  const [isOpen, setIsOpen] = React.useState(false)

  const toggleOptions = React.useCallback(() => setIsOpen(current => !current), [setIsOpen])
  const closeOptions = React.useCallback(() => setIsOpen(false), [setIsOpen])

  React.useEffect(() => {
    function handleClick (e) {
      if (ref.current && ref.current.contains(e.target)) return
      closeOptions()
    }
    window.addEventListener('click', handleClick)
    return () => window.removeEventListener('click', handleClick)
  }, [closeOptions])

  return (
    <StyledWrapper>
      {isMobile && <BackButton href={`/${MESSAGES}`} />}

      <div
        ref={ref}
        className='g-d-flex g-align-center'
      >
        <StyledGroupChatIcon
          className='g-mr-10'
          onClick={toggleOptions}
        />

        <div>
          <div className='name'>
            {name}
          </div>
          <ChatSettings
            channelType='group'
            channel={channel}
            isOpen={isOpen}
            toggleOptions={toggleOptions}
          />
        </div>
      </div>

      <div className='g-d-flex g-align-center g-ml-auto g-shrink-0'>
        <div className='sub-name g-mr-5'>
          Group chat
        </div>
        <StyledSubGroupChatIcon />
      </div>
    </StyledWrapper>
  )
})
