import React from 'react'
import styled from 'styled-components'

import { ReactComponent as IconHeart } from 'assets/bpm-heart.svg'
import { ReactComponent as IconPulse } from 'assets/bpm-pulse.svg'

export default function PressurePreview ({ widgetId, pulse }) {
  const strokeColor = React.useMemo(() => {
    if (pulse) return '55, 182, 243'
    return '207, 216, 220'
  }, [pulse])

  return (
    <StyledWrapper className='g-d-flex g-align-center g-justify-center g-w-100 g-mt-30'>
      <div className='wrapper g-w-100'>
        <svg className='donut-chart' width='100%' height='100%' viewBox='0 0 42 42'>
          <defs>
            <linearGradient id={`gradient-pressure-${widgetId}`} x1='0%' y1='0%' x2='85%' y2='85%'>
              <stop offset='25%' stopColor={'rgb(' + strokeColor + ')'} />
              <stop offset='100%' stopColor={'rgba(' + strokeColor + ', 0.2)'} />
            </linearGradient>
          </defs>
          <circle
            cx='21'
            cy='21'
            r='15.91549430918954'
            fill='none'
            stroke={`url(#gradient-pressure-${widgetId})`}
            strokeWidth='3'
            strokeDasharray='85 100'
            strokeDashoffset='0'
          />
        </svg>
        <div className='wrapper-data'>
          <div className='data g-d-flex'>
            <span className='value'>{pulse || 0}</span>
            <span className='units g-ml-5'>
              <IconHeart />
              bpm
            </span>
          </div>
          <IconPulse className='icon-pulse' />
        </div>
      </div>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  flex-direction: column;
  .wrapper {
    position: relative;
  }
  .donut-chart {
    display: block;
    width: 20.5rem;
    height: 20.5rem;
    margin: 0 auto;
    transform: rotate(-90deg);
  }
  .wrapper-data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .data {
    align-items: flex-end;
  }
  .value {
    font-size: 3rem;
    line-height: 2.4rem;
  }
  .units {
    position: relative;
    padding-top: 1.1rem;
    line-height: 1;
    font-size: 1.3rem;
    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 1rem;
      height: 0.9rem;
    }
  }
  .icon-pulse {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 3.7rem);
    width: 3.7rem;
    height: 2rem;
  }
`
