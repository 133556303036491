/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	MyHealthQuery:{
		getTreatmentPlan:{
			treatmentPlanId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getTreatmentPlans:{
			filters:{
				type:"TreatmentPlanFilterType",
				array:false,
				arrayRequired:false,
				required:false
			},
			page:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			perPage:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getTaskFileUrl:{
			fileId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getPatientTasks:{
			date:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getPatientTasksDates:{
			fromDate:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			},
			toDate:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getPatientMedications:{
			page:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			perPage:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			filters:{
				type:"MedicationsFilterType",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getPatientMedication:{
			medicationId:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		}
	},
	UUID: "String",
	DateTime: "String",
	JSON: "String",
	Date: "String",
	Decimal: "String",
	TreatmentPlanFilterType:{
		patientId:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"TreatmentPlanStatus",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TreatmentPlanStatus: "enum",
	MedicationsFilterType:{
		status:{
			type:"FilterStatus",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FilterStatus: "enum",
	MyHealthMutation:{
		uploadTaskFiles:{
			files:{
				type:"Upload",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		createTreatmentPlan:{
			data:{
				type:"TreatmentPlanInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateTreatmentPlan:{
			treatmentPlanId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"TreatmentPlanUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteTreatmentPlan:{
			treatmentPlanId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		completeTask:{
			taskId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			},
			date:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		unCompleteTask:{
			taskId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			},
			date:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		takeMedication:{
			medicationId:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			date:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		unTakeMedication:{
			medicationId:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			date:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			}
		}
	},
	Upload: "String",
	TreatmentPlanInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		summary:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		patientId:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:true
		},
		status:{
			type:"TreatmentPlanStatus",
			array:false,
			arrayRequired:false,
			required:true
		},
		tasks:{
			type:"TaskInput",
			array:true,
			arrayRequired:true,
			required:true
		}
	},
	TaskInput:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"TaskType",
			array:false,
			arrayRequired:false,
			required:true
		},
		startDate:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		endDate:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		diet:{
			type:"DietInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		activity:{
			type:"ActivityInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		medication:{
			type:"MedicationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		lab:{
			type:"LabInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		materials:{
			type:"UUID",
			array:true,
			arrayRequired:false,
			required:true
		},
		files:{
			type:"UUID",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	TaskType: "enum",
	DietInput:{
		summary:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		duration:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	ActivityInput:{
		summary:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		duration:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	MedicationInput:{
		categoryId:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		},
		startDate:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		endDate:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		measureId:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		},
		frequencyId:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		},
		quantity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	LabInput:{
		summary:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	TreatmentPlanUpdateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		summary:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"TreatmentPlanStatus",
			array:false,
			arrayRequired:false,
			required:false
		},
		tasks:{
			type:"TaskInput",
			array:true,
			arrayRequired:false,
			required:true
		}
	}
}

export const ReturnTypes: Record<string,any> = {
	MyHealthQuery:{
		getTreatmentPlan:"TreatmentPlanType",
		getTreatmentPlans:"TreatmentPlanTypeConnection",
		getTaskFileUrl:"String",
		getPatientTasks:"PatientTaskGqlType",
		getPatientTasksDates:"Date",
		getPatientMedications:"MedicationContentTypeConnection",
		getMedicationCategories:"MedicationCategoryType",
		getMedicationFrequencies:"MedicationFrequencyType",
		getMedicationMeasures:"MedicationMeasureType",
		getPatientMedication:"MedicationContentDetailType"
	},
	TreatmentPlanType:{
		tasks:"TaskGqlType",
		createdBy:"ProviderType",
		updatedBy:"ProviderType",
		taskAmount:"Int",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"UUID",
		title:"String",
		summary:"String",
		status:"String",
		deletedAt:"DateTime",
		sentAt:"DateTime"
	},
	TaskGqlType:{
		title:"String",
		files:"TaskFileType",
		details:"JSON",
		materials:"TaskMaterialType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"UUID",
		type:"String",
		startDate:"DateTime",
		endDate:"DateTime",
		status:"String"
	},
	TaskFileType:{
		id:"UUID",
		fileName:"String",
		objectName:"String",
		fileType:"String",
		createdAt:"DateTime",
		uploadFinishedAt:"DateTime"
	},
	TaskMaterialType:{
		materialVersion:"LibraryMaterialType",
		id:"UUID"
	},
	LibraryMaterialType:{
		category:"LibraryMaterialCategoryType",
		createdBy:"ProviderType",
		updatedBy:"ProviderType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"UUID",
		title:"String",
		description:"String",
		type:"String",
		content:"String",
		currentVersion:"Boolean",
		materialId:"UUID",
		status:"String",
		deletedAt:"DateTime"
	},
	LibraryMaterialCategoryType:{
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"UUID",
		slug:"String",
		title:"String"
	},
	ProviderType:{
		profile:"ProfileType",
		providerInfo:"ProviderInformationType",
		qualifications:"ProviderQualificationType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"String",
		email:"String",
		isSuper:"Boolean",
		isSupport:"Boolean"
	},
	ProfileType:{
		avatarUrl:"String",
		gender:"GenderType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"Int",
		profileableId:"String",
		profileableType:"String",
		fName:"String",
		lName:"String",
		birthDate:"Date",
		phone:"String",
		city:"String",
		zip:"String",
		address1:"String",
		address2:"String",
		biography:"String",
		avatar:"String",
		deletedAt:"DateTime"
	},
	GenderType:{
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"Int",
		name:"String",
		slug:"String"
	},
	ProviderInformationType:{
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"Int",
		education:"String",
		practices:"String",
		experienceYears:"Int",
		shortSummary:"String",
		qualificationTitle:"String"
	},
	ProviderQualificationType:{
		qualification:"QualificationType",
		id:"Int"
	},
	QualificationType:{
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"Int",
		name:"String",
		title:"String",
		slug:"String",
		type:"String",
		icon:"String",
		cost:"Decimal"
	},
	TreatmentPlanTypeConnection:{
		pageInfo:"PageInfo",
		items:"TreatmentPlanType"
	},
	PageInfo:{
		hasNextPage:"Boolean",
		hasPreviousPage:"Boolean",
		currentPage:"Int",
		perPage:"Int",
		lastPage:"Int",
		total:"Int"
	},
	PatientTaskGqlType:{
		title:"String",
		files:"TaskFileType",
		details:"JSON",
		materials:"TaskMaterialType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"UUID",
		type:"String",
		startDate:"DateTime",
		endDate:"DateTime",
		status:"String",
		completedAt:"DateTime"
	},
	MedicationContentTypeConnection:{
		pageInfo:"PageInfo",
		items:"MedicationContentType"
	},
	MedicationContentType:{
		measure:"MedicationMeasureType",
		frequency:"MedicationFrequencyType",
		medicationsList:"MedicationType",
		duration:"Int",
		total:"Decimal",
		totalProgress:"Decimal",
		completedDays:"Int",
		totalPerDay:"Decimal",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"Int",
		medication:"String",
		icon:"String",
		dosage:"Decimal",
		note:"String",
		startedAt:"DateTime",
		endedAt:"DateTime"
	},
	MedicationMeasureType:{
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"Int",
		measure:"String"
	},
	MedicationFrequencyType:{
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"Int",
		frequency:"String",
		totalPerDay:"Int"
	},
	MedicationType:{
		category:"MedicationCategoryType",
		patient:"PatientType",
		provider:"ProviderType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"Int"
	},
	MedicationCategoryType:{
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"Int",
		category:"String"
	},
	PatientType:{
		profile:"ProfileType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"String",
		email:"String",
		emailVerifiedAt:"DateTime",
		medicalRecord:"String",
		experianceDone:"Boolean",
		trialEndsAt:"DateTime",
		termsAgree:"Boolean",
		noInsurance:"Boolean",
		firstVisitedAt:"DateTime",
		lastVisitedAt:"DateTime",
		deletedAt:"DateTime",
		noPrimaryProvider:"Boolean"
	},
	MedicationContentDetailType:{
		measure:"MedicationMeasureType",
		frequency:"MedicationFrequencyType",
		medicationsList:"MedicationType",
		duration:"Int",
		total:"Decimal",
		totalProgress:"Decimal",
		completedDays:"Int",
		totalPerDay:"Decimal",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"Int",
		medication:"String",
		icon:"String",
		dosage:"Decimal",
		note:"String",
		startedAt:"DateTime",
		endedAt:"DateTime",
		takenToday:"Decimal"
	},
	MyHealthMutation:{
		uploadTaskFiles:"UploadedFilesType",
		createTreatmentPlan:"TreatmentPlanType",
		updateTreatmentPlan:"TreatmentPlanTypeTreatmentIsSentError",
		deleteTreatmentPlan:"TreatmentPlanDeletedTypeTreatmentIsSentError",
		completeTask:"Boolean",
		unCompleteTask:"Boolean",
		takeMedication:"Decimal",
		unTakeMedication:"Decimal"
	},
	UploadedFilesType:{
		files:"UploadFileInfoTypeTooLargeFileFileTypeIsNotSupported"
	},
	UploadFileInfoTypeTooLargeFileFileTypeIsNotSupported:{
		"...on UploadFileInfoType":"UploadFileInfoType",
		"...on TooLargeFile":"TooLargeFile",
		"...on FileTypeIsNotSupported":"FileTypeIsNotSupported"
	},
	UploadFileInfoType:{
		fileName:"String",
		originalFileName:"String",
		fileType:"String",
		id:"UUID"
	},
	TooLargeFile:{
		message:"String",
		originalFileName:"String"
	},
	FileTypeIsNotSupported:{
		message:"String",
		originalFileName:"String"
	},
	TreatmentPlanTypeTreatmentIsSentError:{
		"...on TreatmentPlanType":"TreatmentPlanType",
		"...on TreatmentIsSentError":"TreatmentIsSentError"
	},
	TreatmentIsSentError:{
		message:"String"
	},
	TreatmentPlanDeletedTypeTreatmentIsSentError:{
		"...on TreatmentPlanDeletedType":"TreatmentPlanDeletedType",
		"...on TreatmentIsSentError":"TreatmentIsSentError"
	},
	TreatmentPlanDeletedType:{
		deleted:"Boolean"
	}
}