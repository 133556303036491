import React from 'react'
import get from 'lodash/get'

import { useQuiz } from 'hooks/patient/useQuestionnaires'

import { PlateHeader } from 'common/plate/styles'
import BackHandler from './BackHandler'

export default React.memo(function QuizHeader () {
  const title = useQuiz(current => get(current, 'name', ''))

  return (
    <PlateHeader>
      <BackHandler />
      {title}
    </PlateHeader>
  )
})
