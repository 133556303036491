import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import get from 'lodash/get'
import { useHistory } from 'react-router-dom'

import { APPOINTMENTS, APPOINTMENT_INFO, WEEK } from 'constants/routes'; 

import { useScheduledAppointments } from 'hooks/useAppointments'

export default React.memo(function DataLine ({ hour, days }) {
  const history = useHistory()
  const scheduledAppointments = useScheduledAppointments()

  const parsedDays = React.useMemo(() => {
    return days.map((day, i) => {
      const parsedDay = moment.utc(day).local().format('YYYY-MM-DD')
      const appointment = scheduledAppointments.find(item => moment.utc(item.started_at).local().format('YYYY-MM-DD HH:mm:ss') === `${parsedDay} ${hour}`)
      if (!appointment) {
        return (
          <StyledPlaceholder key={i} />
        )
      }

      const id = get(appointment, 'id')
      const fullName = get(appointment, 'patient.full_name')
      const startedAt = get(appointment, 'started_at')
      const start = moment.utc(startedAt).local().format('hh:mm A')
      const end = moment.utc(startedAt).local().add(1, 'hours').format('hh:mm A')

      return (
        <div
          key={i}
          className='day-container'
          onClick={() => {
            history.push({
              pathname: `/${APPOINTMENTS}/${APPOINTMENT_INFO}/${id}`,
              state: { referrer: `/${APPOINTMENTS}/${WEEK}` }
            })
          }}
        >
          <div className='name'>{fullName}</div>
          <div className='time'>{start} - {end}</div>
        </div>
      )
    })
  }, [history, days, hour, scheduledAppointments])

  return (
    <StyledWrapper>
      {parsedDays}
    </StyledWrapper>
  )
})

const StyledPlaceholder = styled.div`
  width: ${100 / 7}%;
  border-right: solid 1px #f1f5f8;
  min-height: 7rem;
  padding: 0.5rem;
`

const StyledWrapper = styled.div`
  margin: 0 7rem;
  display: flex;
  border-left: solid 1px #f1f5f8;
  .day-container {
    width: ${100 / 7}%;
    border-right: solid 1px #f1f5f8;
    min-height: 7rem;
    padding: 0.5rem;
    background: #16123f;
    cursor: pointer;
  }
  .name {
    font-size: 1.3rem;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .time {
    margin-top: 0.5rem;
    color: #fff;
  }
`
