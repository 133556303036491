import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import { MESSAGES } from 'constants/routes'; 

import { useScreenWidth } from 'hooks/useUtils'
import { useCreateDirectChat } from 'requests/messages'

import { ReactComponent as Arrow } from 'assets/arrow-down.svg'

import UserSearch from 'common/chat/user-search'

export default React.memo(function DirectHeader () {
  const history = useHistory()
  const isMobile = useScreenWidth((value) => value <= 480, [])
  const createDirectChat = useCreateDirectChat()

  const backHandler = React.useCallback(() => history.push(`/${MESSAGES}`), [history])

  return (
    <StyledWrapper>
      {isMobile && <StyledArrow onClick={backHandler} />}
      <span>To:</span>
      <UserSearch
        placeholder='Enter the name of the provider or patient'
        selectHandler={createDirectChat}
      />
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  display: flex;
  height: 7rem;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 600;
  padding: 1.4rem 2.5rem;
  border-bottom: solid 2px #f1f5f8;
  .input {
    display: block;
    width: 100%;
    margin: 0 0 0 1rem;
    border: none;
    outline: none;
    font-size: 1.6rem;
    font-weight: 600;
    ::placeholder {
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 2rem;
    }
  }
`

const StyledArrow = styled(Arrow)`
  transform: rotate(90deg);
  width: 3rem;
  height: 2.6rem;
  margin-bottom: 1px;
  padding: 1rem;
  margin-left: -1rem;
`
