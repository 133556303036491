import React from 'react'
import { useParams, Link } from 'react-router-dom'
import styled from 'styled-components'

import { PATIENTS, DETAILS, WIDGETS, WITHINGS, WEIGHT_TIMELINE } from 'constants/routes'; 

import { useDayData, useDaysData } from 'hooks/patient/widgets/useWidgets'

import { RowPlate } from 'common/plate/plates'
import { PlateHeader, PlateContent } from 'common/plate/styles'
import BackButton from 'common/plate/BackButton'
import ExportData from 'common/widgets/ExportData'
import DaySelector from 'common/widgets/day-selector'
import WeightStats from 'components/patients/widgets/synchronized-widget/withings/weight/weight-stats'
import BMI from 'components/patients/widgets/synchronized-widget/withings/weight/BMI'
import BodyComposition from 'components/patients/widgets/synchronized-widget/withings/weight/BodyComposition'

export default function PatientWeight () {
  const { patientId, widgetId } = useParams()
  const daysData = useDaysData('weight')
  const dayData = useDayData()

  const label = React.useMemo(() => {
    const weight = dayData.weight
    if (!weight) return null
    return `${weight} lb`
  }, [dayData])

  return (
    <RowPlate>
      <PlateHeader>
        <BackButton href={`/${PATIENTS}/${DETAILS}/${patientId}/${WIDGETS}`} />
        Weight
        <ExportData
          title='weight lb'
          field='weight'
        />
      </PlateHeader>
      <DaySelector
        data={daysData}
        label={label}
        color='#27bee6'
      />
      <PlateContent>
        <StyledWrapper>
          <WeightStats />
          <BMI />
          <BodyComposition />
        </StyledWrapper>
        <StyledLink to={`/${PATIENTS}/${DETAILS}/${patientId}/${WIDGETS}/${WITHINGS}/${widgetId}/${WEIGHT_TIMELINE}`}>
          VIEW WEIGHT TIMELINE
        </StyledLink>
      </PlateContent>
    </RowPlate>
  )
}

const StyledWrapper = styled.div`
  padding-right: 4rem;
  padding-left: 4rem;
  @media screen and (max-width: 768px) {
    padding-right: 0;
    padding-left: 0;
  }
`

const StyledLink = styled(Link)`
  margin-top: 4rem;
  font-size: 1.2rem
`
