import React from 'react'
import get from 'lodash/get'

import { useConsultation, useSetConsultation } from 'hooks/useAppointments'

import { ReactComponent as VideoIcon } from 'assets/video/video.svg'
import { ReactComponent as VideoOffIcon } from 'assets/video/video-off.svg'

import { IconWrapper } from './style'

export default React.memo(function VideoHandler ({ className = '' }) {
  const isHidden = useConsultation(current => get(current, 'isHidden'))
  const toggleHidden = useSetConsultation(prev => {
    const isHidden = get(prev, 'isHidden')
    const localVideoTracks = get(prev, 'localVideoTracks')
    if (isHidden) {
      localVideoTracks.forEach(track => track.enable())
    } else {
      localVideoTracks.forEach(track => track.disable())
    }
    return { ...prev, isHidden: !isHidden }
  })

  return (
    <IconWrapper
      className={className}
      onClick={toggleHidden}
    >
      {isHidden
        ? <VideoOffIcon />
        : <VideoIcon />
      }
    </IconWrapper>
  )
})
