import React from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { PATIENTS, DETAILS, MEDICATIONS } from 'constants/routes'; 

import { useIsMedicationValid } from 'hooks/usePatients'
import { useUpdatePatientMedications } from 'requests/patients/medication'

import CustomButton from 'common/CustomButton'

export default function UpdateButton () {
  const { patientId } = useParams()
  const history = useHistory()
  const isMedicationValid = useIsMedicationValid()
  const updatePatientMedications = useUpdatePatientMedications()

  const handlePatientMedicationsUpdate = React.useCallback(async () => {
    const success = await updatePatientMedications()
    if (success) history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${MEDICATIONS}`)
  }, [updatePatientMedications, history, patientId])

  return (
    <CustomButton
      className='g-mt-auto'
      disabled={!isMedicationValid}
      clickHandler={handlePatientMedicationsUpdate}
    >
      Update
    </CustomButton>
  )
}
