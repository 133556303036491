import React from 'react'

import { RegularMediumText } from 'common/texts'

export default function EmptyMedications () {
  return (
    <RegularMediumText>
      You do not have any Medications.
    </RegularMediumText>
  )
}
