import React from 'react'
import styled from 'styled-components'
import get from 'lodash/get'

import { useConsultation } from 'hooks/useAppointments'

import VideoHandler from 'common/video-controls/VideoHandler'
import AudioHandler from 'common/video-controls/AudioHandler'
import CancelHandler from 'common/video-controls/CancelHandler'

export default React.memo(function VideoControls () {
  const room = useConsultation(current => get(current, 'room'))

  if (!room) return null

  return (
    <StyledWrapper>
      <VideoHandler />
      <AudioHandler />
      <CancelHandler />
    </StyledWrapper>
  )
})

export const StyledWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 3.5rem;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10;
  @media screen and (max-width: 480px) {
    left: 37%;
  }
`
