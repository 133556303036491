import React from 'react'
import get from 'lodash/get'

import { useConsultation, useSetConsultation } from 'hooks/useAppointments'

const trackpubsToTracks = trackMap => {
  return Array.from(trackMap.values())
    .map(publication => publication.track)
    .filter(track => track !== null)
}

export default React.memo(function LocalVideoListeners () {
  const localParticipant = useConsultation(current => get(current, 'room.localParticipant'))

  const setLocalVideoTracks = useSetConsultation((prev, localVideoTracks) => ({ ...prev, localVideoTracks }), [])
  const addLocalVideoTracks = useSetConsultation((prev, next) => ({ ...prev, localVideoTracks: [...prev.localVideoTracks, next] }), [])
  const filterLocalVideoTracks = useSetConsultation((prev, next) => ({ ...prev, localVideoTracks: prev.localVideoTracks.filter(v => v !== next) }), [])

  const setLocalAudioTracks = useSetConsultation((prev, localAudioTracks) => ({ ...prev, localAudioTracks }), [])
  const addLocalAudioTracks = useSetConsultation((prev, next) => ({ ...prev, localAudioTracks: [...prev.localAudioTracks, next] }), [])
  const filterLocalAudioTracks = useSetConsultation((prev, next) => ({ ...prev, localAudioTracks: prev.localAudioTracks.filter(a => a !== next) }), [])

  React.useEffect(() => {
    const trackSubscribed = track => {
      if (track.kind === 'video') {
        addLocalVideoTracks(track)
      } else {
        addLocalAudioTracks(track)
      }
    }

    const trackUnsubscribed = track => {
      if (track.kind === 'video') {
        filterLocalVideoTracks(track)
      } else {
        filterLocalAudioTracks(track)
      }
    }
    if (localParticipant) {
      setLocalVideoTracks(trackpubsToTracks(localParticipant.videoTracks))
      setLocalAudioTracks(trackpubsToTracks(localParticipant.audioTracks))

      localParticipant.on('trackSubscribed', trackSubscribed)
      localParticipant.on('trackUnsubscribed', trackUnsubscribed)
    }
    // eslint-disable-next-line
  }, [localParticipant])

  return null
})
