import React from 'react'
import styled from 'styled-components'

import { useUpdatePatinetStatus } from 'requests/patients'

import Modal from 'common/Modal'
import CustomButton from 'common/CustomButton'
import { RegularMediumText } from 'common/texts'
import CustomTextarea from 'common/CustomTextarea'
import CustomRadio from 'common/CustomRadio'

const radioConfig = [
  'Completed the program',
  'No longer working with the company',
  'Never completed on-boarding',
  'Moved to non-covered state',
  'Unresponsive',
  'No longer interested'
]

export default React.memo(function UpdateSummaryModal ({
  closeModal, inactiveReason = null, inactiveDescription = ''
}) {
  const updatePatinetStatus = useUpdatePatinetStatus()
  const [reason, setReason] = React.useState(inactiveReason)
  const [description, setDescription] = React.useState(inactiveDescription)

  const handleChange = React.useCallback((value) => {
    setReason(value)
  }, [setReason])

  const handleUpdateStatus = React.useCallback(() => {
    let statusData = { status: 'inactive', reason }
    if (description) statusData.description = description
    updatePatinetStatus(statusData)
    closeModal()
  }, [updatePatinetStatus, closeModal, reason, description])

  const options = React.useMemo(() => {
    return radioConfig.map((value) => {
      return (
        <CustomRadio
          key={(value)}
          className='radio g-mb-5'
          value={value}
          label={value}
          checked={value === reason}
          changeHandler={handleChange}
        />
      )
    })
  }, [handleChange, reason])

  return (
    <Modal
      title='Inactive status'
      minWidth='55'
      maxWidth='55'
      closeModal={closeModal}
    >
      <RegularMediumText>Specify the reason</RegularMediumText>
      <StyledWtapper className='g-mt-15'>
        {options}
      </StyledWtapper>
      <CustomTextarea
        className='g-mt-15'
        label='Description'
        value={description}
        changeHandler={setDescription}
      />
      <CustomButton
        disabled={!reason}
        className='g-mt-20'
        clickHandler={handleUpdateStatus}
      >
        Update
      </CustomButton>
    </Modal>
  )
})

const StyledWtapper = styled.div`
  column-count: 2;
  .radio {
    line-height: 2.3rem;
  }
`
