import React from 'react'
import { HintText, RegularText } from 'common/texts'

export default function PressureValue ({
  className = '', labelClassName = '', label = '', valueClassName = '', value = ''
}) {
  return (
    <div className={className}>
      <HintText className={labelClassName}>{label}</HintText>
      <RegularText className={valueClassName}><span>{value}</span> mmHg</RegularText>
    </div>
  )
}
