import React from 'react'
import styled from 'styled-components'
import get from 'lodash/get'

import { useReferral } from 'hooks/useReferral'

import ReferralPreview from './ReferralPreview'

export default function ReferralList () {
  const referralList = useReferral(current => get(current, 'list'))

  const parsedReferralList = React.useMemo(() => {
    return referralList.map(user => {
      const id = get(user, 'id')
      return (
        <ReferralPreview
          key={id}
          user={user}
        />
      )
    })
  }, [referralList])

  return (
    <StyledWrapper className='g-mt-15'>
      {parsedReferralList}
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  overflow-y: auto;
  max-height: 56rem;
`
