import React from 'react'
import styled from 'styled-components'

import { UppercaseText } from 'common/texts'

export default React.memo(function CustomTextarea ({
  className = '', label, placeholder = '', isRequred, value, changeHandler, isValid, isLoading, readOnly
}) {
  const handleChange = React.useCallback(e => changeHandler(e.target.value), [changeHandler])

  if (isLoading) {
    return (
      <StyledLoader className={className}>
        {label && <div className='loader-label' />}
        <div className='loader-input' />
      </StyledLoader>
    )
  }

  return (
    <StyledWrapper className={className}>
      {label && (
        <UppercaseText className='g-mb-10'>
          {label}
          {isRequred && <span className='required'>*</span>}
        </UppercaseText>
      )}
      <StyledTextarea
        isRequred={isRequred}
        isValid={isValid}
        readOnly={readOnly}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
      />
    </StyledWrapper>
  )
})

const StyledLoader = styled.div`
  .loader-label {
    height: 1.8rem;
    width: 25%;
    margin-bottom: 1rem;
    background: #e9ebef;
  }
  .loader-input {
    height: 12rem;
    background: #e9ebef;
  }
`

const StyledWrapper = styled.div`
  width: 100%;
  .required {
    display: inline-block;
    color: #f86060;
    font-size: 1.3rem;
    line-height: 1.8rem;
    margin-left: 1px;
  }
`

const StyledTextarea = styled.textarea`
  display: block;
  width: 100%;
  height: 12rem;
  font-size: 1.3rem;
  padding: 1rem 1.5rem;
  border: solid 1px #e9ebef;
  resize: none;
  outline: none;
  &::placeholder {
    font-weight: 300;
  }
  &:hover {
    border: solid 1px #ccc;
  }
  &:focus {
    box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.25);
  }
  ${({ isValid, isRequred }) => isRequred && !isValid && `
    border-color: #f86060;
    &::placeholder {
      color: #f86060;
    }
    &:hover {
      border: solid 1px #f86060;
    }
    &:focus {
      box-shadow: 0 0 0 0.1rem #f86060;
    }
  `};
`
