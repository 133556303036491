import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import get from 'lodash/get'
import moment from 'moment'

import { PATIENTS, DETAILS, OVERVIEW } from 'constants/routes';

import UserAvatar from 'common/UserAvatar'
import LinkButton from 'common/LinkButton'
import { useLocation } from 'react-router-dom';

export default React.memo(function PatientCard ({ patinet }) {
  const { pathname, search } = useLocation()
  const history = useHistory()

  const id = get(patinet, 'id', '')
  const firstName = get(patinet, 'first_name', '')
  const lastName = get(patinet, 'last_name', '')
  const fullName = get(patinet, 'full_name', '')
  const medicalRecord = get(patinet, 'medical_record', '')
  const lastAppointmentModel = get(patinet, 'last_appointment', '')
  const lastActivityModel = get(patinet, 'last_visit', '')
  const lastAppointment = lastAppointmentModel ? moment(lastAppointmentModel).format('MMMM DD, YYYY') : 'n/a'
  const lastActivity = lastActivityModel ? moment(lastActivityModel).format('MMMM DD, YYYY') : 'n/a'

  const moveToDetails = React.useCallback(() => {
    history.push({
      pathname: `/${PATIENTS}/${DETAILS}/${id}/${OVERVIEW}`,
      state: { referrer: pathname + search }
    })
  }, [history, id, pathname, search])

  return (
    <StyledWrapper>
      <div className='g-d-flex'>
        <UserAvatar
          className='g-mr-15'
          firstName={firstName}
          lastName={lastName}
          onClick={moveToDetails}
        />
        <div className='name'>
          <div>{fullName}</div>
          <div><span className='half-opacity'>MRN</span> {medicalRecord}</div>
        </div>
      </div>

      {lastAppointment && (
        <>
          <div className='hint-text g-mt-10'>
            Last Appointment
          </div>
          <div className='regular-text'>
            {lastAppointment}
          </div>
        </>
      )}

      {lastActivity && (
        <>
          <div className='hint-text g-mt-10'>
            Last Activity
          </div>
          <div className='regular-text'>
            {lastActivity}
          </div>
        </>
      )}

      <LinkButton
        onClick={moveToDetails}
        className='details g-mt-auto'
      >
        Details
      </LinkButton>
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #fafbfd;
  padding: 1.5rem 2rem 2rem 2rem;
  border-radius: 0.8rem;
  margin: 1rem;
  width: calc(33.3% - 2rem);
  min-height: 24rem;
  @media screen and (max-width: 768px) {
    width: calc(50% - 2rem);
  }
  @media screen and (max-width: 480px) {
    width: 100%;
  }
  .name {
    font-size: 1.5rem;
  }
  .half-opacity {
    opacity: 0.5;
  }
  .hint-text {
    font-size: 1.3rem;
    line-height: 2rem;
    opacity: 0.5;
  }
  .regular-text {
    font-size: 1.3rem;
    line-height: 2rem;
  }
  .details {
    padding: 0.6rem 2rem;
    width: 48%;
  }
`
