import React from 'react'
import styled from 'styled-components'

import { HintText } from 'common/texts'

export default function HalfCircleIndicator ({
  className = '', label = '', value = 0, valueInDeg = 0, units = '', isExcellent
}) {
  return (
    <StyledWrapper
      className={className}
      valueInDeg={valueInDeg}
    >
      <div className='wrapper'>
        <div className='back-line' />
        <div className='indecator' />
        <div className='cover'>
          <div className='label-wrapper'>
            <div className='label'>
              {label}
            </div>
            <div className='value'>
              {value} {units}
            </div>
          </div>
        </div>
      </div>
      {isExcellent && <HintText className='g-mt-10'>Excellent</HintText>}
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  width: 100%;
  max-width: 26rem;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  .wrapper {
    position: relative;
    width: 92%;
    height: 0;
    margin: 0 auto;
    padding-bottom: 46%;
    overflow: hidden;
    .back-line {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 200%;
      background: #e8e9ed;
      border-radius: 50%;
    }
    .indecator {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 200%;
      background: linear-gradient(0deg, rgba(76, 93, 238, 1) 50%, transparent 50%);
      background-size: ${({ valueInDeg }) => valueInDeg <= 30 ? 50 : 100}% 100%;
      background-repeat: no-repeat;
      border-radius: 50%;
      transform: ${({ valueInDeg }) => `rotate(${valueInDeg}deg)`};
    }
    .cover {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: calc(100% - 4rem);
      height: calc(200% - 4rem);
      background: #fff;
      border-radius: 50%;
      clip: rect(0, 100%, 50%, 0);
      transform: translate(2rem, 50%);
      .label-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 50%;
        padding-top: 3rem;
        .label {
          font-size: 1rem;
        }
        .value {
          font-size: 2rem;
        }
      }
    }
  }
`
