import React from 'react'
import get from 'lodash/get'

import { globalContext } from 'hooks/useGlobalState'
import { usePatientDocuments } from 'hooks/usePatients'

import DocumentPreview from './DocumentPreview'

export default React.memo(function DocumentsList () {
  const { user } = React.useContext(globalContext)
  const patientDocuments = usePatientDocuments()

  const parsedPatientDocuments = React.useMemo(() => {
    return patientDocuments.map(document => {
      const id = get(document, 'id')
      const authorId = get(document, 'author.id', '')
      const name = get(document, 'name', '')
      const uploaded = get(document, 'uploaded', '')
      const uploadedBy = authorId === user.value.id ? 'You' : get(document, 'author.full_name', '')
      return (
        <DocumentPreview
          key={id}
          id={id}
          name={name}
          uploaded={uploaded}
          uploadedBy={uploadedBy}
          isOwner={authorId === user.value.id}
        />
      )
    })
  }, [patientDocuments, user])

  return parsedPatientDocuments
})
