import { useState, useLayoutEffect, useCallback } from 'react';
import { Cognito } from '../libs/cognito';
import { AmplifyUserSession } from '../libs/cognito.types';
import { UserRole } from '../types';

const cognito = Cognito.getInstance();

const providerPermissions = ['editPatient'];

export type UseMeValueType = {
  isInited: boolean;
  role: UserRole;
  isAdmin: boolean;
  session: AmplifyUserSession | null;
  can: (permission: string | string[]) => boolean;
};

const isAdmin = (session: AmplifyUserSession | null) => {
  return Number(session?.idToken.payload['custom:isAdmin']);
};

export const useMe = () => {
  const [isInited, setIsInited] = useState(false);
  const [session, setSession] = useState<AmplifyUserSession | null>(null);
  const [role, setRole] = useState<UserRole>('provider');
  const can = useCallback(
    (permission: string | string[]) => {
      if (isAdmin(session)) return true;

      const toCheck = Array.isArray(permission) ? permission : [permission];
      return toCheck.every((p) => !!providerPermissions?.includes(p));
    },
    [session]
  );

  useLayoutEffect(() => {
    cognito.getSession().then((session) => {
      setSession(session);
      const role = isAdmin(session) ? 'admin' : 'provider';
      localStorage.setItem('role', role);
      setRole(role);
      setIsInited(true);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isInited,
    role,
    isAdmin: role === 'admin',
    session,
    can,
  };
};
