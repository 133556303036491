import React from 'react'

import { usePatinetDoctorNotes } from 'hooks/usePatients'

import NotesList from './NotesList'
import ListPreviewPlaceholder from 'common/loaders/ListPreviewPlaceholder'

export default React.memo(function DoctorNotes () {
  const patinetDoctorNotes = usePatinetDoctorNotes()

  const isLoading = React.useMemo(() => !patinetDoctorNotes, [patinetDoctorNotes])

  if (isLoading) return <ListPreviewPlaceholder />

  return <NotesList />
})
