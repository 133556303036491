import React from 'react'

import { INFORMATION } from 'constants/routes'; 

import { useSetProviderProfile } from 'hooks/useProviders'
import { useFetchProviderInformation } from 'requests/providers'

import ProviderTemplate from 'components/providers/ProviderTemplate'
import PersonalInformation from 'components/providers/details/information/PersonalInformation'
import Contactinformation from 'components/providers/details/information/Contactinformation'

export default function ProviderInformation () {
  const fetchProviderInformation = useFetchProviderInformation()
  const clearProviderProfile = useSetProviderProfile(() => null, [])

  React.useEffect(() => {
    fetchProviderInformation()
    return clearProviderProfile
    // eslint-disable-next-line
  }, [])

  return (
    <ProviderTemplate currentTab={INFORMATION}>
      <PersonalInformation />

      <Contactinformation />
    </ProviderTemplate>
  )
}
