import React from 'react'

import { PULSE } from 'constants/routes'; 

import { useDayData, useDaysData } from 'hooks/patient/widgets/useWidgets'

import DaySelector from 'common/widgets/day-selector'
import PulseData from 'components/patients/widgets/synchronized-widget/withings/blood-pressure/pulse/PulseData'
import BPMTemplate from 'components/patients/widgets/synchronized-widget/withings/blood-pressure/BPMTemplate'

export default React.memo(function PatientPulse () {
  const daysData = useDaysData('pulse')
  const dayData = useDayData()

  const label = React.useMemo(() => {
    const pulse = dayData.pulse
    if (!pulse) return null
    return `${pulse} bpm`
  }, [dayData])

  return (
    <BPMTemplate currentTab={PULSE}>
      <DaySelector
        data={daysData}
        label={label}
        color='#19b5ff'
      />
      <PulseData />
    </BPMTemplate>
  )
})
