import { $ } from 'zeus-graphql/my-health/zeus';
import { useQuery } from '../client';
import { GET_TASK_FILE_URL } from '../constants';

export const useTaskFileUrl = (fileId?: string) => {
  const { data, isLoading, error } = useQuery(
    [GET_TASK_FILE_URL, fileId],
    {
      getTaskFileUrl: [
        {
          fileId: $`fileId`,
        },
        true,
      ],
    },
    {
      operationName: GET_TASK_FILE_URL,
      variables: {
        fileId,
      },
    },
    {
      enabled: !!fileId,
    }
  );

  return {
    file: data?.getTaskFileUrl,
    isLoading,
    error,
  };
};
