import React, { FC, useCallback, useEffect, useState } from 'react';

import { ReactComponent as LabsIcon } from 'assets/labs.svg';

import { SectionHeader } from '../../SectionHeader';

import { SectionFooter } from '../../SectionFooter';
import { LabsEditItem } from './LabsSectionEditItem';
import { TaskType } from 'requests/graphql/my-health/queries/getTreatmentPlan';
import { AddSectionButton } from '../../AddSectionButton';
import _, { debounce } from 'lodash';
import * as yup from 'yup';
import { validate } from 'utils/extractErrors';

type LabsEditProps = {
  tasks?: TaskType<'labs'>[];
  onCancel: () => void;
  onDelete: () => void;
  onDeleteSection: (index: number) => void;
  onSave: (val: TaskType<'labs'>[]) => void;
  onAddNew?: () => void;
};

const schema = yup.array().of(
  yup.object().shape({
    endDate: yup.string().required('Due date is required'),
    details: yup.object().shape({
      summary: yup.string().required('Summary is required'),
    }),
  })
);

export const LabsEdit: FC<LabsEditProps> = ({ tasks, onAddNew, onCancel, onSave, onDelete, onDeleteSection }) => {
  const [currentTasks, setCurrentTasks] = useState(tasks || []);
  const [errors, setErrors] = useState<Record<string, any>[]>([]);

  const handleChangeTask = useCallback(
    (task, id) => {
      const newTasks = currentTasks?.map((d, i) => (i === id ? task : d)) || [];

      if (errors.length > 0) {
        validate(schema, newTasks, setErrors, () => setErrors([]));
      }
      setCurrentTasks(newTasks);
    },
    [currentTasks, errors]
  );

  const errorsMessages = (di: number) => ({
    summary: errors?.[di]?.name,
    dueDate: errors?.[di]?.dueDate,
  });

  const handleSubmit = (submit: () => void) => {
    return () => {
      validate(schema, currentTasks || [], setErrors, submit);
    };
  };

  const save = useCallback(() => {
    const tasks = currentTasks || [];
    onSave(tasks);
  }, [currentTasks]);

  useEffect(() => {
    setCurrentTasks(tasks || []);
  }, [tasks]);

  return (
    <div className="mb-6">
      <div className="shadow-row p-6 border rounded-main">
        <div className="pb-6">
          <SectionHeader title="Labs" icon={<LabsIcon />} isEditing={true} onDelete={onDelete} />
        </div>
        {currentTasks?.map((task, di) => (
          <LabsEditItem
            hasDeleteSection={di > 0}
            onDeleteSection={() => onDeleteSection(di)}
            errors={errorsMessages(di)}
            key={task.id || 'lab' + di}
            task={task}
            onChange={debounce((d) => handleChangeTask(d, di), 400)}
          />
        ))}
        <SectionFooter onCancel={onCancel} onSave={handleSubmit(save)} />
      </div>
      {onAddNew && <AddSectionButton onClick={onAddNew} />}
    </div>
  );
};
