import React from 'react'
import styled from 'styled-components'

export default React.memo(function Tab ({ tab, currentTab, setCurrentTab }) {
  const { id, label, count, done } = tab
  return (
    <StyledWrapper
      isSelected={currentTab === id}
      onClick={() => setCurrentTab(id)}
    >
      <div className='label'>
        {label}
      </div>
      <div className='counter'>
        {done}/{count}
      </div>
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3rem;
  margin-bottom: -1px;
  padding: 0.5rem 0;
  border-bottom: 1px solid ${({ isSelected }) => isSelected ? '#223232' : 'transparent'};
  transition: border 0.2s;
  cursor: pointer;
  :hover {
    border-bottom-color: #223232;
  }
  .label {
    font-size: 1.3rem;
    line-height: 2.8rem;
    white-space: nowrap;
  }
  .counter {
    margin-left: 1rem;
    padding: 0 0.9rem;
    background: #223232;
    border-radius: 0.8rem;
    font-size: 1rem;
    line-height: 2rem;
    color: #fff;
    white-space: nowrap;
  }
`
