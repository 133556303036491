import React from 'react';
import styled from 'styled-components';
import { WideRowPlate } from 'common/plate/plates';
import { PlateHeader, PlateContent } from 'common/plate/styles';
import { MediumText } from 'common/texts';
import CustomButton from 'common/CustomButton';

import ButtonGroup from 'components/environment-check/speakers/ButtonGroup';
import { ReactComponent as Speaker } from 'assets/environment-check/speaker.svg';
import audioSample from 'assets/audio-sample.mp3';

export default function Speakers() {
  const audioRef = React.useRef(null);
  const [isPlaying, setIsPlaying] = React.useState(false);

  const play = () => {
    audioRef.current.play();
    setIsPlaying(true);
  };
  React.useEffect(() => {
    const audio = new Audio(audioSample);
    audio.loop = true;
    audioRef.current = audio;
    return () => {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  }, []);
  return (
    <WideRowPlate>
      <PlateHeader>Step 3 of 4 - Speakers</PlateHeader>

      <PlateContent>
        <AudioCheck className="g-align-self-start g-mt-20">
          <Speaker className="icon g-ml-30" />
        </AudioCheck>

        {isPlaying && <MediumText className="g-mt-20">Do you hear a sound?</MediumText>}
        {!isPlaying && <MediumText className="g-mt-20">To check a sound please click the "Play" button</MediumText>}
        {!isPlaying && (
          <div className="g-d-flex g-mt-30">
            <CustomButton clickHandler={play}>Play</CustomButton>
          </div>
        )}
        {isPlaying && <ButtonGroup />}
      </PlateContent>
    </WideRowPlate>
  );
}

const AudioCheck = styled.div`
  .icon {
    display: block;
    width: 6rem;
    height: 6rem;
  }
`;
