import React from 'react'
import moment from 'moment'

import { RegularText } from 'common/texts'

export default React.memo(function DayLabel ({ className = '', date }) {
  if (!date) {
    return (
      <RegularText className={className}>
        There has been no activity in the last 30 days
      </RegularText>
    )
  }
  return (
    <RegularText className={className}>
      {moment(date).format('dddd, D MMMM YYYY')}
    </RegularText>
  )
})
