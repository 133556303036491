import React from 'react'
import get from 'lodash/get'

import { useQuiz, useQuizStep, useSetQuizStep } from 'hooks/patient/useQuestionnaires'

import CustomButton from 'common/CustomButton'

export default React.memo(function SubmitButton () {
  const step = useQuizStep()
  const withSubmit = useQuiz(current => get(current, `content.pages[${step}].withSubmit`, ''), [step])
  const submitHandler = useSetQuizStep(prev => +prev + 1, [])

  if (!withSubmit) return null

  return (
    <CustomButton
      clickHandler={submitHandler}
    >
      Submit
    </CustomButton>
  )
})
