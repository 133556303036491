import React from 'react';
import styled from 'styled-components';
import { useHistory, Link } from 'react-router-dom';
import get from 'lodash/get';

import { PROFILE, INFORMATION, SETTINGS, PASSWORD, ENVIRONMENT_CHECK, INTRODUCTION, LOGOUT } from 'constants/routes';

import { useUser } from 'hooks/useGlobalState';
import { useProfile } from 'hooks/useProfile';
import { useFetchHeadsUpLink } from 'requests/dashboard';

import UserAvatar from 'common/UserAvatar';
import CustomButton from 'common/CustomButton';
import Notifications from './Notifications';
import { useUserContext } from './../../context/userContext';

export default React.memo(function UserInfo() {
  const history = useHistory();
  const { role, isAdmin } = useUserContext();
  const fetchHeadsUpLink = useFetchHeadsUpLink();
  const firstName = useUser((current) => get(current, 'first_name') || '', []);
  const lastName = useUser((current) => get(current, 'last_name') || '', []);
  // const role = useUser((current) => get(current, 'role') || '', [])
  const avatar = useProfile((current) => get(current, 'avatar'), []);

  const moveToProfile = React.useCallback(() => {
    history.push(`/${PROFILE}/${INFORMATION}`);
  }, [history]);

  return (
    <StyledWrapper>
      {!isAdmin && (
        <CustomButton className="metrics" clickHandler={fetchHeadsUpLink} inversion>
          Add data
        </CustomButton>
      )}

      <Notifications />

      <div className="user-avatar g-p-relative">
        <UserAvatar className="g-ml-15 g-mr-15" avatar={avatar} firstName={firstName} lastName={lastName} onClick={moveToProfile} />
        <div className="dropdown">
          <div className="links">
            <Link className="link" to={`/${PROFILE}/${INFORMATION}`}>
              Profile
            </Link>
            <Link className="link" to={`/${SETTINGS}/${PASSWORD}`}>
              Settings
            </Link>

            <Link className="link" to={`/${ENVIRONMENT_CHECK}/${INTRODUCTION}`}>
              Environment check
            </Link>
            <Link className="link" to={`/${LOGOUT}`}>
              Log out
            </Link>
          </div>
        </div>
      </div>

      <div className="info" onClick={moveToProfile}>
        <div className="name">
          {firstName} {lastName}
        </div>
        <div className="role">{role}</div>
      </div>
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  .metrics {
    padding: 1rem 2rem;
    margin: auto 1.5rem;
    white-space: nowrap;
  }
  .user-avatar:hover {
    .dropdown {
      display: block;
    }
  }
  .dropdown {
    display: none;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    padding-top: 1rem;
    z-index: 10;
    .links {
      border-radius: 0.8rem;
      padding: 1rem 2rem;
      background: #fff;
      .link {
        display: block;
        text-decoration: none;
        font-size: 1.2rem;
        opacity: 0.5;
        padding: 0.5rem 0rem;
        white-space: nowrap;
        :hover {
          text-decoration: underline;
        }
      }
    }
  }
  .dropdown:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, 1rem);
    width: 0;
    height: 0;
    border-left: 1.5rem solid transparent;
    border-right: 1.5rem solid transparent;
    border-bottom: 0.8rem solid #fff;
  }
  .info {
    margin-right: 1rem;
    cursor: pointer;
    .name {
      max-width: 20em;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 1.3rem;
      @media only screen and (max-width: 480px) {
        max-width: 10em;
      }
      @media only screen and (max-width: 768px) {
        max-width: 14em;
      }
    }
    .role {
      font-size: 1.2rem;
      line-height: 1rem;
      opacity: 0.5;
      text-transform: uppercase;
    }
  }
`;
