import React from 'react'
import styled from 'styled-components'

import Checkmark from 'assets/checkmark.png'

export default React.memo(function SuccessIcon () {
  return (
    <StyledWrapper>
      <img src={Checkmark} alt='' />
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  position: relative;
  height: 10rem;
  width: 10rem;
  background: #34bb0f;
  border-radius: 50%;
  :before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 10.8rem;
    width: 10.8rem;
    background: rgba(67, 203, 29, 0.19);
    border-radius: 50%;
    z-index: 1
  }
  img {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: block;
    min-width: 4rem;
    width: 4rem;
    height: 3rem;
    z-index: 1
  }
`
