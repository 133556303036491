import React from 'react'
import { useParams } from 'react-router-dom'

import { PATIENTS, DETAILS, WIDGETS, WITHINGS, WEIGHT } from 'constants/routes'; 
import { TYPE_FAT_RATIO } from 'constants/withings-types'
import { typeTabsConfig, periodTabsConfig, WEEK } from 'constants/withings-tabs'

import { useUser } from 'hooks/useGlobalState'
import { useFetchWidget } from 'requests/patients/widgets/widgets'

import { RowPlate } from 'common/plate/plates'
import { PlateHeader, PlateContent } from 'common/plate/styles'
import BackButton from 'common/plate/BackButton'
import LocalSwitcher from 'common/local-tabs/LocalSwitcher'
import DateSelector from 'components/patients/widgets/synchronized-widget/withings/weight/date-selector'
import PeriodTabs from 'components/patients/widgets/synchronized-widget/withings/weight/period-tabs'

export default React.memo(function PatientWeightTimeline () {
  const { patientId, widgetId } = useParams()
  const user = useUser(curent => !!curent)
  const fetchWidget = useFetchWidget()
  const [currentTab, setCurrentTab] = React.useState(TYPE_FAT_RATIO)
  const [period, setPeriod] = React.useState(WEEK)

  React.useEffect(() => {
    if (user) {
      fetchWidget(periodTabsConfig.find(item => item.id === period))
    }
    // eslint-disable-next-line
  }, [user, period])

  return (
    <RowPlate>
      <PlateHeader>
        <BackButton href={`/${PATIENTS}/${DETAILS}/${patientId}/${WIDGETS}/${WITHINGS}/${widgetId}/${WEIGHT}`} />
        Weight timeline
      </PlateHeader>
      <PlateContent>
        <LocalSwitcher
          tabsConfig={typeTabsConfig}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
        <DateSelector
          currentTab={currentTab}
          period={period}
        />
        <PeriodTabs
          period={period}
          setPeriod={setPeriod}
        />
      </PlateContent>
    </RowPlate>
  )
})
