import React from 'react';

import { WideRowPlate } from 'common/plate/plates';
import { PlateContent } from 'common/plate/styles';
import CompaniesHeader from 'components/companies/companies/CompaniesHeader';
import CompaniesLoader from 'components/companies/companies-loader';

export default function Companies() {
  return (
    <WideRowPlate>
      <CompaniesHeader />

      <PlateContent>
        <CompaniesLoader />
      </PlateContent>
    </WideRowPlate>
  );
}
