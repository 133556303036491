import React from 'react'
import styled from 'styled-components'

import { StyledCell } from '../styles'

export default React.memo(function GridLabels ({ config }) {
  const parsedLabels = React.useMemo(() => {
    return config.map((time, i) => {
      return (
        <StyledCell
          key={i}
          className={`label-wrapper ${time.isAfter() ? 'inactive' : ''}`}
        >
          {time.format('D')}
        </StyledCell>
      )
    })
  }, [config])
  return (
    <StyledWrapper className='g-d-flex g-mt-15'>
      {parsedLabels}
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  .label-wrapper {
    text-align: center;
  }
  .label-wrapper.inactive {
    opacity: 0.2;
  }
`
