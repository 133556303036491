/* eslint-disable */

import extractFiles from 'utils/extractFiles';

import { ValueTypes, GraphQLTypes, InputType, Chain, OperationOptions, chainOptions, Zeus } from './zeus/index';
import { useQuery, useMutation } from '@tanstack/react-query';
import type { UseQueryOptions, UseMutationOptions } from '@tanstack/react-query';

export function useTypedQuery<O extends 'MyHealthQuery', TData extends ValueTypes[O], TResult = InputType<GraphQLTypes[O], TData>>(
  queryKey: unknown[],
  query: TData | ValueTypes[O],
  options?: Omit<UseQueryOptions<TResult>, 'queryKey' | 'queryFn'>,
  zeusOptions?: OperationOptions,
  host = '',
  hostOptions: chainOptions[1] = {}
) {
  return useQuery<TResult>({
    queryKey,
    queryFn: () => Chain(host, hostOptions)('query')(query, zeusOptions) as Promise<TResult>,
    ...options,
  });
}

export function useTypedMutation<
  O extends 'MyHealthMutation',
  TData extends ValueTypes[O],
  TVars extends Record<string, any>,
  TResult = InputType<GraphQLTypes[O], TData>,
  TError = Error
>(
  mutationKey: unknown[],
  mutation: TData | ValueTypes[O],
  options?: Omit<UseMutationOptions<TResult, TError, TVars>, 'mutationKey' | 'mutationFn'>,
  zeusOptions?: OperationOptions,
  host = '',
  hostOptions: chainOptions[1] = {}
) {
  return useMutation<TResult, TError, TVars>(
    (variables) => {
      if ((hostOptions?.headers as any)?.['Content-Type'] === 'multipart/form-data') {
        const operations = Zeus('mutation', mutation, zeusOptions?.operationName);
        const { clone, files } = extractFiles(variables, 'variables');
        const formData = new FormData();

        formData.append('operations', JSON.stringify({ query: operations, variables }));

        const map = Array.from(files).reduce<Record<string, string[]>>((acc, el, i) => {
          return { ...acc, [i + '']: (el as [File, [string]])[1] };
        }, {});

        formData.append('map', JSON.stringify(map));

        Array.from(files).forEach((f, i) => {
          const [file, [path]] = f as [File, [string]];

          formData.append(i + '', file);
        });

        hostOptions.body = formData;
        if (hostOptions.headers) {
          delete (hostOptions as any).headers['Content-Type'];
        }
      }

      return Chain(host, {
        ...hostOptions,
        headers: { ...hostOptions?.headers },
      })('mutation')(mutation, {
        ...zeusOptions,
        variables,
      }) as Promise<TResult>;
    },
    { mutationKey, ...options }
  );
}
