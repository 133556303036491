import React from "react";
import get from "lodash/get";
import moment from "moment";

import { SUMMARY } from "constants/routes";

import { useModalHandler } from "hooks/useUtils";
import { usePatinetsStatusOptions } from "hooks/useOptions";
import {
  usePatinetSummary,
  useSetPatinetSummary,
  usePatientTestState,
} from "hooks/usePatients";
import { useFetchPatinetsStatus } from "requests/options";
import {
  useFetchPatinetSummary,
  useChangeTestPatientTestState,
  useUpdatePatinetStatus,
} from "requests/patients";

import { MediumText } from "common/texts";
import DetailsTemplate from "components/patients/DetailsTemplate";
import MembershipDetails from "components/patients/summary/MembershipDetails";
import CustomSelect from "common/CustomSelect";
import PropertyValue from "common/PropertyValue";

import UpdateSummaryModal from "components/patients/summary/UpdateSummaryModal";
import { useUserContext } from "./../../../context/userContext";
import ActionsButtons from "./../../../components/patients/summary/actions-buttons/index";
import { permissions } from "../../../permissions";

export default function PatientSummary() {
  const patinetsStatusOptions = usePatinetsStatusOptions();
  const patinetSummary = usePatinetSummary((current) =>
    get(current, "patient")
  );
  const { can } = useUserContext();
  const fetchPatinetsStatus = useFetchPatinetsStatus();
  const fetchPatinetSummary = useFetchPatinetSummary();
  const updatePatinetStatus = useUpdatePatinetStatus();
  const testPatientState = usePatientTestState();
  const toggleTestPatientState = useChangeTestPatientTestState();

  const clearPatinetSummary = useSetPatinetSummary(() => null, []);
  const [isOpen, openModal, closeModal] = useModalHandler();

  const handleUpdateStatus = React.useCallback(
    (status) => {
      if (status === "inactive") {
        openModal();
        return null;
      }
      updatePatinetStatus({ status });
    },
    [updatePatinetStatus, openModal]
  );

  React.useEffect(() => {
    fetchPatinetsStatus();
    fetchPatinetSummary();
    return clearPatinetSummary;
    // eslint-disable-next-line
  }, []);

  const isLoading = React.useMemo(
    () => !patinetsStatusOptions || !patinetSummary,
    [patinetsStatusOptions, patinetSummary]
  );

  const patientStatus = get(patinetSummary, "patient_status.status", "");
  const patientStatusUpdatedAt = get(
    patinetSummary,
    "patient_status.updated_at",
    ""
  );
  const formattedUpdatedAt = moment(patientStatusUpdatedAt).isValid()
    ? moment(patientStatusUpdatedAt).format("D MMMM YYYY")
    : "";
  const patientInactiveReason = get(
    patinetSummary,
    "patient_status.reason",
    ""
  );
  const patientInactiveDescription = get(
    patinetSummary,
    "patient_status.description",
    ""
  );

  const medicalRecord = get(patinetSummary, "medical_record", "");
  const patientCompany = get(patinetSummary, "company", null);
  const promoterScore = get(patinetSummary, "nps", null);

  const noInsurance = get(patinetSummary, "no_insurance", false);

  const primaryGroup =
    get(patinetSummary, "insurances.primary.group", "") || "n/a";
  const primaryMember =
    get(patinetSummary, "insurances.primary.member", "") || "n/a";
  const primaryCompany =
    get(patinetSummary, "insurances.primary.company", "") || "n/a";

  const secondaryGroup =
    get(patinetSummary, "insurances.secondary.group", "") || "n/a";
  const secondaryMember =
    get(patinetSummary, "insurances.secondary.member", "") || "n/a";
  const secondaryCompany =
    get(patinetSummary, "insurances.secondary.company", "") || "n/a";

  return (
    <DetailsTemplate currentTab={SUMMARY}>
      <CustomSelect
        label="Patient status"
        isLoading={isLoading}
        options={patinetsStatusOptions}
        value={patientStatus}
        changeHandler={handleUpdateStatus}
      />

      {formattedUpdatedAt && (
        <PropertyValue
          className="g-mt-20"
          isLoading={isLoading}
          property="Status changed"
          value={formattedUpdatedAt}
        />
      )}

      {patientInactiveReason && (
        <PropertyValue
          className="g-mt-5"
          isLoading={isLoading}
          property="Inactive state reason"
          value={patientInactiveReason}
          actionText="Edit"
          onClick={openModal}
        />
      )}

      {patientInactiveDescription && (
        <PropertyValue
          className="g-mt-5"
          isLoading={isLoading}
          property="Description"
          value={patientInactiveDescription}
        />
      )}

      <PropertyValue
        className="g-mt-20"
        isLoading={isLoading}
        property="Medical Record Number"
        value={medicalRecord}
      />

      {patientCompany && (
        <PropertyValue
          isLoading={isLoading}
          property="Company name"
          value={patientCompany}
        />
      )}

      {promoterScore && (
        <PropertyValue
          isLoading={isLoading}
          property="Likelihood to recommend (NPS)"
          value={promoterScore}
        />
      )}

      {can(permissions.patient.canSetTestPatients) && (
        <PropertyValue
          isLoading={isLoading}
          property="Test Patient"
          value={
            <input
              type="checkbox"
              style={{ cursor: "pointer" }}
              checked={testPatientState}
              onChange={() => toggleTestPatientState()}
            />
          }
        />
      )}

      <MembershipDetails isLoading={isLoading} />

      {!noInsurance && (
        <>
          <MediumText className="g-mt-30">
            Primary insurance information
          </MediumText>

          <PropertyValue
            className="g-mt-5"
            isLoading={isLoading}
            property="Group ID"
            value={primaryGroup}
          />
          <PropertyValue
            className="g-mt-5"
            isLoading={isLoading}
            property="Member ID"
            value={primaryMember}
          />
          <PropertyValue
            className="g-mt-5"
            isLoading={isLoading}
            property="Insurance company"
            value={primaryCompany}
          />

          <MediumText className="g-mt-30">
            Secondary insurance information
          </MediumText>

          <PropertyValue
            className="g-mt-5"
            isLoading={isLoading}
            property="Group ID"
            value={secondaryGroup}
          />
          <PropertyValue
            className="g-mt-5"
            isLoading={isLoading}
            property="Member ID"
            value={secondaryMember}
          />
          <PropertyValue
            className="g-mt-5 g-mb-30"
            isLoading={isLoading}
            property="Insurance company"
            value={secondaryCompany}
          />
        </>
      )}
      <ActionsButtons />
      {isOpen && (
        <UpdateSummaryModal
          inactiveReason={patientInactiveReason}
          inactiveDescription={patientInactiveDescription}
          closeModal={closeModal}
        />
      )}
    </DetailsTemplate>
  );
}
