import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { MESSAGES, DIRECT, GROUP } from 'constants/routes'; 

import { ReactComponent as StartChat } from 'assets/start-chat.svg'

import { useScreenWidth } from 'hooks/useUtils'

export default React.memo(function CreateChat () {
  const history = useHistory()
  const isMobile = useScreenWidth((value) => value <= 480, [])
  const ref = React.useRef()
  const [isOpen, setIsOpen] = React.useState(false)

  const toggleOptions = React.useCallback(() => setIsOpen(current => !current), [setIsOpen])
  const closeOptions = React.useCallback(() => setIsOpen(false), [setIsOpen])

  React.useEffect(() => {
    function handleClick (e) {
      if (ref.current && ref.current.contains(e.target)) return
      closeOptions()
    }
    window.addEventListener('click', handleClick)
    return () => window.removeEventListener('click', handleClick)
  }, [closeOptions])

  const navigateToDirect = React.useCallback(() => history.push(`/${MESSAGES}/${DIRECT}`), [history])
  const navigateToGroup = React.useCallback(() => history.push(`/${MESSAGES}/${GROUP}`), [history])

  return (
    <StyledWrapper
      ref={ref}
      className='g-d-flex g-justify-between g-align-center'
      isMobile={isMobile}
    >
      Chats
      <div className='create-wrapper'>
        <StartChat
          className='icon-start'
          onClick={toggleOptions}
        />
        {isOpen && (
          <div className='drop-down'>
            <div className='options-wrapper'>
              <div
                className='option'
                onClick={navigateToDirect}
              >
                Direct message
              </div>
              <div
                className='option'
                onClick={navigateToGroup}
              >
                Create group chat
              </div>
            </div>
          </div>
        )}
      </div>
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  .create-wrapper {
    position: relative;
    .icon-start {
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;
      :hover {
        opacity: 0.5;
      }
    }
    .drop-down {
      position: absolute;
      top: 100%;
      ${({ isMobile }) => isMobile ? 'right: 0' : 'left: 0'};
      padding-top: 1rem;
      z-index: 10;
      .options-wrapper {
        background: #fff;
        padding: 1rem 1.5rem;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        .option {
          color: #909898;
          font-size: 1.3rem;
          font-weight: 400;
          line-height: 2.4rem;
          white-space: nowrap;
          cursor: pointer;
          :hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
`
