import React from 'react'

import { MediumText } from 'common/texts'
import InfoForPatient from '../common/InfoForPatient'
import PrescriptionsESend from '../common/PrescriptionsESend'
import PrescriptionFields from './PrescriptionFields'

export default function PrescriptionPreviewContent () {
  return (
    <>
      <PrescriptionFields />
      <MediumText className='g-mt-20'>For Patient</MediumText>
      <InfoForPatient
        label='Name'
        value='Anna Wright (Female)'
      />
      <InfoForPatient
        label='Date of Birthday'
        value='July 21, 1987 (32 years)'
      />
      <InfoForPatient
        label='Phone number'
        value='+1 325 323 312'
      />
      <InfoForPatient
        label='City/State/ZIP'
        value='New York City, New York, 001324'
      />
      <InfoForPatient
        label='Street Address'
        value='80 Hudson Dr.Freeport, NY 11520'
      />
      <MediumText className='g-mt-20 g-mb-5'>Prescriptions to e-Send</MediumText>
      <PrescriptionsESend
        name='Collagen'
        directions='Take 2 tablet(s) 1 to 3 times a day'
        dispence='1200 Mg | 30 days | 0 refills'
        note='Title: Pharmacy – Pain management'
        schedule='2, PrimaryDiag/ICD: WrittenDate: 15 August 2020'
        prescibedBy='Glenn Bishop (22 Jockey Hollow Drive Palos Verdes Peninsula, CA 90274, 801-258-2552)'
        covIns='Preffered Level 1-PLANA5 (Retail:No MailOrder:Y lTC:N Speciality:N)'
      />
    </>
  )
}
