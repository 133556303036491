import React from 'react'
import moment from 'moment'

import { WORK_WEEK } from 'constants/routes'; 

import { useSetScheduledAppointments } from 'hooks/useAppointments'
import { useFetchScheduledAppointments } from 'requests/appointments'

import AppointmentsTemplate from 'components/appointmentsProvider/AppointmentsTemplate'
import WorkWeekHeader from 'components/appointmentsProvider/work-week/WorkWeekHeader'
import WorkWeekGrid from 'components/appointmentsProvider/work-week/WorkWeekGrid'

export default function WorkWeekAppoitments () {
  const [workWeekStart, setWorkWeekStart] = React.useState(moment().startOf('isoWeek'))
  const fetchScheduledAppointments = useFetchScheduledAppointments()
  const clearScheduledAppointments = useSetScheduledAppointments(() => [], [])

  React.useEffect(() => {
    fetchScheduledAppointments({
      period: 'work-week',
      period_start: workWeekStart.format('YYYY-MM-DD')
    })
    return clearScheduledAppointments
    // eslint-disable-next-line
  }, [workWeekStart])

  return (
    <AppointmentsTemplate currentTab={WORK_WEEK}>
      <WorkWeekHeader
        workWeekStart={workWeekStart}
        setWorkWeekStart={setWorkWeekStart}
      />

      <WorkWeekGrid
        workWeekStart={workWeekStart}
      />
    </AppointmentsTemplate>
  )
}
