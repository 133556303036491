import React from 'react';
import styled from 'styled-components';

import { useIsFormValid } from 'hooks/patient/useProfile';
import { useUpdatePatientProfile } from 'requests/patients/patients';
import CustomButton from 'common/CustomButton';

export default React.memo(function UpdateButton() {
  const { isLoading, updateProfile } = useUpdatePatientProfile();
  const isFormValid = useIsFormValid();

  return (
    <StyledCustomButton disabled={!isFormValid || isLoading} clickHandler={updateProfile}>
      {isLoading ? 'Submiting' : 'Update'}
    </StyledCustomButton>
  );
});

const StyledCustomButton = styled(CustomButton)`
  margin-top: 3.6rem;
`;
