import React from 'react'

import { useSetMedicationProperty } from 'hooks/usePatients'

import CustomCalendar from 'common/custom-calendar'

export default function EndDate ({ className = '', index, startedAt, endedAt, validate }) {
  const setMedicationEndedAt = useSetMedicationProperty({ index, field: 'ended_at' })
  return (
    <CustomCalendar
      className={className}
      label='End intake date'
      placeholder='mm.dd.yy'
      isRequred
      isValid={!validate || (endedAt && +(new Date(endedAt)) > +(new Date(startedAt)))}
      minDate={typeof startedAt === 'string' ? new Date(startedAt) : startedAt}
      selectedDate={endedAt}
      setSelectedDate={setMedicationEndedAt}
    />
  )
}
