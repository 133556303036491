import React from 'react'

import { ColumnWidePlate } from 'common/plate/plates'
import ChatInput from 'common/chat/ChatInput'
import GroupHeader from './GroupHeader'

export default React.memo(function CreateGroupContent () {
  return (
    <ColumnWidePlate>
      <GroupHeader />

      <ChatInput />
    </ColumnWidePlate>
  )
})
