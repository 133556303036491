import React from 'react'
import styled from 'styled-components'

import PeriodSelect from './PeriodSelect'
import ProviderSelect from './ProviderSelect'
import PatientSelect from './PatientSelect'
import DownloadButton from './DownloadButton'

export default React.memo(function AppointmentsFilters () {
  return (
    <StyledWrapper className='g-d-flex g-justify-between g-mb-30'>
      <ProviderSelect />
      <PatientSelect />
      <PeriodSelect />
      <DownloadButton />
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  .patient {
    margin: 0 1.5rem;
  }
  .download {
    margin-left: 1.5rem;
  }
  @media screen and (max-width: 480px) {
    display: block;
    .patient {
      margin: 1rem 0 0 0;
    }
    .period {
      margin: 1rem 0 0 0;
    }
    .download {
      margin: 3rem 0 0 0;
    }
  }
`
