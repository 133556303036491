import React, { FC, useCallback, useEffect, useState } from 'react';
import { TaskType } from 'requests/graphql/my-health/queries/getTreatmentPlan';

import { LabsEdit } from './LabsSectionEdit';
import { LabsView } from './LabsSectionView';

type LabsSectionProps = {
  tasks?: TaskType<'labs'>[];
  isPreview?: boolean;
  onChange?: (currentTasks: TaskType<'labs'>[]) => void;
  onAddNew?: () => void;
};

export const LabsSection: FC<LabsSectionProps> = ({ tasks, isPreview, onChange, onAddNew }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentTasks, setCurrentTasks] = useState(tasks || []);

  useEffect(() => {
    setCurrentTasks(tasks || []);

    const maybeHasNew = tasks?.some((task) => task.isNew);

    if (maybeHasNew) {
      setIsEditing(true);
    }
  }, [tasks]);

  const toggleEdit = useCallback(() => {
    setIsEditing((prev) => !prev);
  }, []);

  const save = useCallback((tasks: TaskType<'labs'>[]) => {
    onChange?.(tasks.map((t) => (t.isNew !== undefined ? { ...t, isNew: false } : t)));
    toggleEdit();
  }, []);

  const deleteTasks = useCallback(() => {
    setCurrentTasks([]);
    onChange?.([]);
    toggleEdit();
  }, []);

  const handleCancel = useCallback(() => {
    onChange?.(tasks?.filter((d) => d.id) || []);
    toggleEdit();
  }, [tasks]);

  const deleteSection = useCallback(
    (index: number) => {
      const newTasks = currentTasks.filter((d, i) => i !== index);
      onChange?.(newTasks);
    },
    [currentTasks]
  );

  return isEditing && !isPreview ? (
    <LabsEdit
      onDeleteSection={deleteSection}
      onSave={save}
      onDelete={deleteTasks}
      onCancel={handleCancel}
      onAddNew={onAddNew}
      tasks={currentTasks}
    />
  ) : (
    <LabsView
      tasks={currentTasks}
      isPreview={isPreview}
      onDelete={deleteTasks}
      onEdit={toggleEdit}
    />
  );
};
