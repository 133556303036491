import React from 'react'
import styled from 'styled-components'
import get from 'lodash/get'

import MedicationsPreviewHeader from './MedicationsPreviewHeader'
import MedicationPreview from './MedicationPreview'

export default React.memo(function MedicationsPreview ({ medications }) {
  const category = get(medications, 'category', '')
  const medicationsListId = get(medications, 'id', '')
  const medicationsSubList = get(medications, 'medications', '')

  const parsedMedicationsSubList = React.useMemo(() => {
    return medicationsSubList.map((medication, i) => {
      return (
        <MedicationPreview
          key={i}
          medicationsListId={medicationsListId}
          medication={medication}
        />
      )
    })
  }, [medicationsSubList, medicationsListId])

  return (
    <StyledWrapper className='g-mb-25'>
      <MedicationsPreviewHeader
        category={category}
        medicationsListId={medicationsListId}
      />
      {parsedMedicationsSubList}
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  border-bottom: solid 1px #e9ebef;
`
