import React from 'react'
import get from 'lodash/get'
import moment from 'moment'

import { createUseGlobalState, createUseSetGlobalState } from 'hooks/useGlobalState'

export const useMedicationsList = createUseGlobalState('medicationsList')
export const useSetMedicationsList = createUseSetGlobalState('medicationsList')

export const useMedicationsTab = createUseGlobalState('medicationsTab')
export const useSetMedicationsTab = createUseSetGlobalState('medicationsTab')

export const useMedicationsChart = createUseGlobalState('medicationsChart')
export const useSetMedicationsChart = createUseSetGlobalState('medicationsChart')

export function useFlatMedicationsList () {
  return useMedicationsList(current => {
    if (!current) return null
    const flatList = []
    current.forEach(({ category, medications }) => {
      medications.forEach(medication => {
        flatList.push({ ...medication, category })
      })
    })
    return flatList
  })
}

function getMedicationTrack (data, time) {
  return get(data, 'tracker').find(({ date }) => date === time.format('YYYY-MM-DD')) || {}
}

export function useMedicationsDayProgress (time) {
  return useMedicationsChart(current => {
    if (!current) return 0
    let result = 0
    current.forEach(medication => {
      const { taken_dosage: takenDosage, total_dosage: totalDosage } = getMedicationTrack(medication, time)
      if (!takenDosage || !totalDosage) return
      result += ((takenDosage / totalDosage) * 100) / current.length
    })
    result = Math.round(result)
    return result
  }, [time])
}

export function useMedicationsProgressTooltip (time) {
  return useMedicationsChart(current => {
    if (!current) return null
    const flatList = []
    current.forEach(medication => {
      const { taken_dosage: takenDosage, total_dosage: totalDosage } = getMedicationTrack(medication, time)
      let progress = 0
      if (takenDosage && totalDosage) progress = Math.round((takenDosage / totalDosage) * 100)
      // const currentDate = moment(time).format('YYYY-MM-DD')
      // const startDate = moment(medication.started_at).format('YYYY-MM-DD')
      flatList.push({
        medication: medication.medication,
        progress
        // hideTooltip: moment(currentDate).isBefore(startDate)
      })
    })
    return flatList
  }, [time])
}

export function useRange () {
  const { from, to } = useMedicationsTab()
  return React.useMemo(() => {
    const now = moment(from)
    const end = moment(to)
    const dates = []
    while (now.isSameOrBefore(end)) {
      dates.push(now.clone())
      now.add(1, 'days')
    }
    return dates
  }, [from, to])
}

export function useMedicationsSeries () {
  const range = useRange()
  return useMedicationsChart(current => {
    if (!current) return []
    return current.map((medication) => {
      return {
        name: medication.medication,
        data: range
          .map((time) => {
            const { taken_dosage: takenDosage, total_dosage: totalDosage } = getMedicationTrack(medication, time)
            if (!takenDosage || !totalDosage) {
              if (time.isAfter()) return null
              return [+time, 0]
            }
            return [+time, Math.round((takenDosage / totalDosage) * 100)]
          })
          .filter(item => item)
      }
    })
  }, [range])
}

export function useChartOffsets (chartEl) {
  const [chartOffsets, setChartOffsets] = React.useState({
    right: 0,
    left: 0
  })
  const setOffsets = function () {
    if (chartEl.current) {
      setChartOffsets({
        right: chartEl.current.getBoundingClientRect().right,
        left: chartEl.current.getBoundingClientRect().left
      })
    }
  }
  React.useEffect(() => {
    setOffsets()
    window.addEventListener('resize', setOffsets)
    return () => window.removeEventListener('resize', setOffsets)
    // eslint-disable-next-line
  }, [])
  return chartOffsets
}
