import React from 'react'

import { usePatientDocuments } from 'hooks/usePatients'

import DocumentsPlaceholder from './DocumentsPlaceholder'
import EmptyDocuments from './EmptyDocuments'
import DocumentsList from './DocumentsList'

export default React.memo(function Documents () {
  const patientDocuments = usePatientDocuments()

  if (!patientDocuments) return <DocumentsPlaceholder />

  if (patientDocuments && patientDocuments.length === 0) return <EmptyDocuments />

  return <DocumentsList />
})
