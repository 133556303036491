import React from 'react';
import styled from 'styled-components';
import get from 'lodash/get';

import { useLocalPatientProfile, useSetLocalPatientProfile } from 'hooks/usePatients';
import { useIsPatientProfileLoading, VALIDATION_RULES } from 'hooks/patient/useProfile';

import { FlexLine } from 'common/plate/styles';
import { RegularBigText, HintText } from 'common/texts';
import InputWithLabel from 'common/InputWithLabel';
import CustomSelect from 'common/CustomSelect';
import request from '../../../utils/request';
import { CURRENT_COUNTRY } from '../../../env';

const country = CURRENT_COUNTRY;

export default React.memo(function ContactInformation() {
  const isLoading = useIsPatientProfileLoading();
  const localPatientProfile = useLocalPatientProfile();
  const [states, setStates] = React.useState([]);
  const email = get(localPatientProfile, 'email', '');
  const phone = get(localPatientProfile, 'phone', '');
  const city = get(localPatientProfile, 'city', '');
  const state = get(localPatientProfile, 'state', '');
  const zip = get(localPatientProfile, 'zip', '');
  const phonePrefix = country === 'USA' ? '+1' : '+971';
  const zipLabel = country === 'USA' ? 'Zip' : 'P.O. Box';
  const zipPlaceholder = country === 'USA' ? 'Your zip code' : 'Your P.O. Box';
  const address1 = get(localPatientProfile, 'address_1', '');
  const address2 = get(localPatientProfile, 'address_2', '');

  const isEmailValid = VALIDATION_RULES['email'](email);
  const isPhoneValid = VALIDATION_RULES['phone'](phone);
  const isCityValid = VALIDATION_RULES['city'](city);
  const isStateValid = VALIDATION_RULES['state'](state);
  const isZipValid = VALIDATION_RULES['zip'](zip);

  React.useEffect(() => {
    (async () => {
      const { data } = await request({
        url: `/options/states?country=${country}`,
        method: 'GET',
      });
      setStates(data);
    })();
  }, [country]);
  return (
    <StyledWrapper>
      <RegularBigText className="g-mt-15">Contact information</RegularBigText>
      <FlexLine>
        <InputWithLabel
          className="g-mr-25 g-mt-15"
          label="Email"
          placeholder="Your Email"
          isRequred
          readOnly
          isValid={isEmailValid}
          value={email}
          changeHandler={useSetLocalPatientProfile((prev, email) => ({ ...prev, email }), [])}
          isLoading={isLoading}
        />
        <div className="g-d-flex g-w-100">
          <StyledHintText className="g-mt-auto g-mr-10 g-mb-10">{phonePrefix}</StyledHintText>
          <InputWithLabel
            className="phone g-mt-15"
            label="Phone"
            placeholder="Your phone number"
            isRequred
            isValid={isPhoneValid}
            value={phone}
            changeHandler={useSetLocalPatientProfile((prev, phone) => ({ ...prev, phone }), [])}
            isLoading={isLoading}
          />
        </div>
      </FlexLine>
      <FlexLine>
        <InputWithLabel
          className="g-mr-25 g-mt-20"
          label="City"
          placeholder="Your city"
          isRequred
          isValid={isCityValid}
          value={city}
          changeHandler={useSetLocalPatientProfile((prev, city) => ({ ...prev, city }), [])}
          isLoading={isLoading}
        />
        <CustomSelect
          className="g-mr-25 g-mt-20"
          label="State"
          placeholder="Your state"
          isRequred
          isValid={isStateValid}
          value={state}
          options={states}
          changeHandler={useSetLocalPatientProfile((prev, state) => ({ ...prev, state }), [])}
          isLoading={isLoading}
        />
        <InputWithLabel
          className="zip g-mt-20"
          label={zipLabel}
          placeholder={zipPlaceholder}
          isRequred
          isValid={isZipValid}
          value={zip}
          changeHandler={useSetLocalPatientProfile((prev, zip) => ({ ...prev, zip }), [])}
          isLoading={isLoading}
        />
      </FlexLine>
      <FlexLine>
        <InputWithLabel
          className="g-mt-20 g-mr-25"
          label="Street address 1"
          placeholder="Your address"
          isRequred
          value={address1}
          changeHandler={useSetLocalPatientProfile((prev, address1) => ({ ...prev, address_1: address1 }), [])}
          isLoading={isLoading}
        />
        <InputWithLabel
          className="g-mt-20"
          label="Street address 2"
          placeholder="Your address"
          value={address2}
          changeHandler={useSetLocalPatientProfile((prev, address2) => ({ ...prev, address_2: address2 }), [])}
          isLoading={isLoading}
        />
      </FlexLine>
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  .phone {
    flex-shrink: 0;
    @media screen and (max-width: 480px) {
      flex-shrink: 1;
    }
  }
  .zip {
    width: 145px;
  }
`;

const StyledHintText = styled(HintText)`
  line-height: 2.7rem;
`;
