import Comment from 'components/patients/questionnaires/quiz/quiz-body/elements/Comment'
import Matrixdynamic from 'components/patients/questionnaires/quiz/quiz-body/elements/Matrixdynamic'
import Dropdown from 'components/patients/questionnaires/quiz/quiz-body/elements/Dropdown'
import Text from 'components/patients/questionnaires/quiz/quiz-body/elements/Text'
import RadioGroup from 'components/patients/questionnaires/quiz/quiz-body/elements/RadioGroup'
import SwitchGroup from 'components/patients/questionnaires/quiz/quiz-body/elements/SwitchGroup'
import Expression from 'components/patients/questionnaires/quiz/quiz-body/elements/Expression'
import Checkbox from 'components/patients/questionnaires/quiz/quiz-body/elements/Checkbox'
import NextButton from 'components/patients/questionnaires/quiz/quiz-body/elements/NextButton'
import SubmitButton from 'components/patients/questionnaires/quiz/quiz-body/elements/SubmitButton'
import MultipleText from 'components/patients/questionnaires/quiz/quiz-body/elements/MultipleText'
import Html from 'components/patients/questionnaires/quiz/quiz-body/elements/Html'

const config = {
  'matrixdynamic': Matrixdynamic,
  'comment': Comment,
  'dropdown': Dropdown,
  'text': Text,
  'radiogroup': RadioGroup,
  'switchgroup': SwitchGroup,
  'expression': Expression,
  'checkbox': Checkbox,
  'nextbutton': NextButton,
  'submitbutton': SubmitButton,
  'multipletext': MultipleText,
  'html': Html
}

export default config
