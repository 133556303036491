import React from 'react';
import styled, { css } from 'styled-components';

import { UppercaseText } from 'common/texts';

/**
 *
 * @param {*} param0
 * @param {string} param0.label
 * @param {string} param0.className
 * @param {string} param0.placeholder
 * @param {string} param0.type
 * @param {boolean} param0.readOnly
 * @param {string} param0.value
 * @param {function} param0.changeHandler
 * @param {boolean} param0.isRequred
 * @param {boolean} param0.isValid
 * @param {boolean} param0.isLoading
 * @param {React.RefObject} param0.inputRef
 * @param {boolean} param0.disabled
 * @param {function} param0.onEnter
 * @returns
 */
export default function InputWithLabel({
  label,
  className = '',
  placeholder = '',
  type = 'text',
  readOnly,
  value,
  changeHandler,
  isRequred,
  isValid,
  isLoading,
  inputRef,
  disabled,
  onEnter,
}) {
  const handleChange = React.useCallback((e) => changeHandler(e.target.value), [changeHandler]);

  if (isLoading) {
    return (
      <StyledLoader className={className}>
        {label && <div className="loader-label" />}
        <div className="loader-input" />
      </StyledLoader>
    );
  }

  return (
    <StyledWrapper className={className}>
      {label && (
        <UppercaseText className="g-mb-10">
          {label}
          {isRequred && <span className="required">*</span>}
        </UppercaseText>
      )}
      <StyledInput
        ref={inputRef}
        type={type}
        isRequred={isRequred}
        isValid={isValid}
        onKeyUp={(event) => {
          if (onEnter && event.keyCode === 13) {
            onEnter();
          }
        }}
        readOnly={readOnly}
        value={value}
        min={"0"}
        placeholder={placeholder}
        onChange={handleChange}
        disabled={disabled}
      />
    </StyledWrapper>
  );
}

const StyledLoader = styled.div`
  max-width: 27rem;
  width: 100%;
  @media only screen and (max-width: 480px) {
    max-width: initial;
  }
  .loader-label {
    height: 1.8rem;
    width: 50%;
    margin-bottom: 1rem;
    background: #e9ebef;
  }
  .loader-input {
    height: 4.8rem;
    background: #e9ebef;
  }
`;

const StyledWrapper = styled.div`
  max-width: 27rem;
  width: 100%;
  .required {
    display: inline-block;
    color: #f86060;
    font-size: 1.3rem;
    line-height: 1.8rem;
    margin-left: 1px;
  }
  @media only screen and (max-width: 480px) {
    max-width: initial;
  }
`;

const StyledInput = styled.input`
  display: block;
  width: 100%;
  font-size: 1.3rem;
  line-height: 3rem;
  outline: none;
  padding: 0.8rem 1.6rem;
  border: solid 1px #e9ebef;
  border-radius: 2px;
  &::placeholder {
    font-size: 1.2rem;
    font-weight: 300;
  }
  &:hover:enabled {
    border: solid 1px #ccc;
  }
  &:focus {
    box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.25);
  }
  ${({ isValid, isRequred }) =>
    isRequred &&
    !isValid &&
    css`
      border-color: #f86060;
      &::placeholder {
        color: #f86060;
      }
      &:hover {
        border: solid 1px #f86060;
      }
      &:focus {
        box-shadow: 0 0 0 0.1rem #f86060;
      }
    `};
`;
