import _ from 'lodash';
import { InfiniteData, QueryClient, useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { $, GraphQLTypes, InputType, LibraryMaterialTypes, Selector } from 'zeus-graphql/patient/zeus';
import { GQLQuery } from '../client';
import { GET_LIBRARY_MATERIALS } from '../constants';
import { queryClientUpdateItem } from '../../../../utils/queryClientUpdateItem';

type GetParticipantsProps = {
  perPage?: number;
  page?: number;
  filters?: {
    categoryIds?: string[];
    search?: string;
    type?: LibraryMaterialTypes;
  };
};

const category = Selector('LibraryMaterialCategoryType')({
  id: true,
  title: true,
  slug: true,
  __typename: true,
});
const provider = Selector('ProviderType')({
  profile: {
    fName: true,
    lName: true,
  },
});

const material = Selector('LibraryMaterialType')({
  id: true,
  category,
  type: true,
  title: true,
  materialId: true,
  description: true,
  content: true,
  createdAt: true,
  createdBy: provider,
  status: true,
});

const materialConnection = Selector('LibraryMaterialTypeConnection')({
  pageInfo: {
    hasNextPage: true,
    hasPreviousPage: true,
    total: true,
    perPage: true,
    currentPage: true,
    lastPage: true,
    __typename: true,
  },
  items: material,
});

export type LibraryMaterialCategoryType = InputType<GraphQLTypes['LibraryMaterialCategoryType'], typeof category>;
export type ProviderType = InputType<GraphQLTypes['ProviderType'], typeof provider>;
export type LibraryMaterialType = InputType<GraphQLTypes['LibraryMaterialType'], typeof material>;
export type LibraryMaterialTypeConnection = InputType<GraphQLTypes['LibraryMaterialTypeConnection'], typeof materialConnection>;

export const getLibraryMaterials = async (variables: GetParticipantsProps = {}) => {
  const { perPage = 10, page = 1, filters = {} } = variables;
  const result = await GQLQuery(
    {
      getLibraryMaterials: [
        {
          perPage: $`perPage`,
          page: $`page`,
          filters: $`filters`,
        },
        materialConnection,
      ],
    },
    {
      operationName: GET_LIBRARY_MATERIALS,
      variables: {
        perPage,
        page,
        filters,
      },
    }
  );

  return result.getLibraryMaterials;
};

export const useUpdateMaterialCache = () => {
  const queryClient = useQueryClient();
  return queryClientUpdateItem<LibraryMaterialType, GraphQLTypes['PageInfo'], LibraryMaterialTypeConnection>(queryClient, GET_LIBRARY_MATERIALS);
};

export const useLibraryMaterials = (variables: GetParticipantsProps = {}, forceEnable?: boolean) => {
  const { perPage = 10, page = 1, filters = {} } = variables;

  const { data, isLoading, error, refetch, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfiniteQuery(
    [GET_LIBRARY_MATERIALS, variables],
    ({ pageParam }) => getLibraryMaterials({ perPage, page: pageParam || page, filters }),
    {
      refetchOnMount: false,
      enabled: forceEnable || filters.type !== undefined || Number(filters?.categoryIds?.length) > 0 || Number(filters.search?.length) > 0,
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.pageInfo?.currentPage < lastPage?.pageInfo?.lastPage) {
          return pages.length + 1;
        }

        return undefined;
      },
    }
  );

  const libraryMaterials = data?.pages?.reduce((acc: LibraryMaterialType[], page: InfiniteData<LibraryMaterialTypeConnection>['pages']['0']) => {
    return [...acc, ...page.items];
  }, []);

  return {
    hasNextPage,
    libraryMaterials,
    fetchNextPage,
    refetch,
    isFetchingNextPage,
    isLoading,
    error,
  };
};
