import React, { FC } from 'react';

type AddSectionButtonProps = {
  onClick: () => void;
};

export const AddSectionButton: FC<AddSectionButtonProps> = ({ onClick }) => {
  return (
    <div onClick={onClick} className="border-b border-manatee border-dashed h-2 relative my-4">
      <div className="absolute top-1 left-0 w-full h-full flex justify-center items-center">
        <div className="cursor-pointer border border-manatee rounded-full h-4 w-4 flex justify-center items-center text-manatee text-1220 bg-alabaster">
          +
        </div>
      </div>
    </div>
  );
};
