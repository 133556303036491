import React, { useCallback, useEffect, useState } from 'react';

import { DETAILS, PATIENTS, TREATMENT_PLANS } from 'constants/routes';

import { useHistory, useParams } from 'react-router-dom';
import { FullPlate } from 'common/plate/plates';
import { usePatinetSummary, usePatinetProfile, usePatientFullName } from 'hooks/usePatients';
import { PageTitle } from 'components/Breadcrumbs';

import { TreatmentPlanEdit } from 'components/patients/treatment-plans/TreatmentPlanEdit/TreatmentPlanEdit';
import { useFetchPatinetSummary, useFetchPatinetProfile } from 'requests/patients';
import { TreatmentPlanType } from 'requests/graphql/my-health/queries/getTreatmentPlan';
import { useCreateTreatmentPlan } from 'requests/graphql/my-health/mutations/createTreatmentPlan';
import { prepareToUpdateTreatmentPlan } from 'requests/graphql/my-health/mutations/updateTreatmentPlan';
import { TreatmentPlanStatus } from '../../../zeus-graphql/my-health/zeus';
import { PatientDetails, ProviderDetails } from 'components/patients/treatment-plans/TreatmentPlanEdit/sections/SummarySection/SummarySectionView';
import { useProfile } from 'hooks/useProfile';
import createNotification from 'utils/createNotification';

export default function TreatmentPlanNewContainer() {
  const { patientId } = useParams<{ patientId: string; planId: string }>();
  const fetchPatinetSummary = useFetchPatinetSummary();
  const fetchPatinetProfile = useFetchPatinetProfile();
  const patientSummary = usePatinetSummary();
  const patientProfile = usePatinetProfile();
  const providerProfile = useProfile();
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const { createTreatmentPlan } = useCreateTreatmentPlan();

  const patientFullName = usePatientFullName();

  useEffect(() => {
    setLoading(true);
    Promise.all([fetchPatinetProfile(), fetchPatinetSummary()]).finally(() => setLoading(false));
  }, []);

  const saveDraft = useCallback(
    async (plan: Partial<TreatmentPlanType>) => {
      const data = prepareToUpdateTreatmentPlan(
        {
          summary: plan.summary as string,
          tasks: plan.tasks,
          title: plan.title as string,
          status: TreatmentPlanStatus.draft,
        },
        patientId
      );

      const result = await createTreatmentPlan({
        data,
      });

      if (result.createTreatmentPlan?.id) {
        createNotification({
          type: 'success',
          message: 'Treatment plan saved as draft',
        });

        history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${TREATMENT_PLANS}/edit/${result.createTreatmentPlan.id}`);
      }
    },
    [patientId]
  );

  const saveAndPush = useCallback(
    async (planToPublish: Partial<TreatmentPlanType>) => {
      if (!planToPublish) return;



      const data = prepareToUpdateTreatmentPlan(
        {
          summary: planToPublish.summary as string,
          tasks: planToPublish.tasks,
          title: planToPublish.title as string,
          status: TreatmentPlanStatus.sent,
        },
        patientId
      );

      const result = await createTreatmentPlan({
        data,
      });

      if (result.createTreatmentPlan?.id) {
        createNotification({
          type: 'success',
          message: 'Treatment plan saved and published',
        });
        history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${TREATMENT_PLANS}/view/${result.createTreatmentPlan.id}`);
      }
    },
    [patientId]
  );

  const patientInfo: PatientDetails = {
    avatarUrl: patientProfile?.avatar_url,
    fName: patientProfile?.first_name,
    lName: patientProfile?.last_name,
    mrn: patientSummary?.patient.medical_record,
  };

  const providerInfo: ProviderDetails = {
    avatarUrl: providerProfile?.avatar_url || providerProfile?.avatar,
    fName: providerProfile?.first_name,
    lName: providerProfile?.last_name,
    avatar: providerProfile?.avatar,
  };

  return (
    <FullPlate>
      <PageTitle
        header={patientFullName ? `Add plan for ${patientFullName}` : ''}
        crumbs={[{ title: `Treatment plans`, url: `/${PATIENTS}/${DETAILS}/${patientId}/${TREATMENT_PLANS}` }]}
      />
      <TreatmentPlanEdit
        patientId={patientId}
        onSaveAndPush={saveAndPush}
        provider={providerInfo}
        patient={patientInfo}
        onSaveDraft={saveDraft}
        loading={loading}
      />
    </FullPlate>
  );
}
