import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import { ReactComponent as Calendar } from 'assets/calendar.svg'

export default React.memo(function CalendarInput ({ value, setIsOpen }) {
  const placeholder = 'mm / dd / yyyy h:mm'
  return (
    <StyledWrapper onClick={React.useCallback(() => setIsOpen((prev) => !prev), [setIsOpen])}>
      <span className='label'>
        {(value && moment.utc(value).local().format('MM / DD / YYYY h:mm A')) || placeholder}
      </span>
      <Calendar />
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.2rem;
  padding: 1rem 1.6rem;
  border: solid 1px #e9ebef;
  .label {
    font-size: 1.3rem;
  }
  cursor: pointer;
  &:hover {
    border: solid 1px #ccc;
  }
  &:active {
    box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.25);
  }
`
