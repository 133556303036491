import React from 'react'
import get from 'lodash/get'

import { usePatientOverview } from 'hooks/usePatients'

import OverviewPlaceholder from './OverviewPlaceholder'
import EmptyOverview from './EmptyOverview'
import OverviewList from './OverviewList'

export default function OverviewLoader () {
  const patientOverview = usePatientOverview()
  const appointments = get(patientOverview, 'appointments', [])
  const questionnaires = get(patientOverview, 'questionnaires', [])
  const [hideQuestionnaires, setHideQuestionnaires] = React.useState(true)

  React.useEffect(() => {
    let i = 0
    while (i < questionnaires.length) {
      if (questionnaires.length === 0) break
      if (questionnaires[i]['answerId'] !== null) {
        setHideQuestionnaires(false)
        break
      }
      i++
    }
  }, [questionnaires, setHideQuestionnaires])

  if (!patientOverview) return <OverviewPlaceholder />

  if (appointments.length === 0 && hideQuestionnaires) return <EmptyOverview />

  return <OverviewList />
}
