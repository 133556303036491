import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import get from 'lodash/get';

import { ReactComponent as Arrow } from 'assets/arrow-down.svg';

/**
 * A button that navigates back to the previous page
 * @param {object} p
 * @param {string=} p.href - The href to go back to
 * @param {function=} p.navigationHandler - A function to handle the navigation
 * @returns {JSX.Element}
 */
export default function BackButton({ href, navigationHandler }) {
  const location = useLocation();
  const referrer = get(location, 'state.referrer');

  if (navigationHandler) {
    return (
      <StyledDiv onClick={navigationHandler}>
        <StyledArrow />
        <div className="text">Back</div>
      </StyledDiv>
    );
  }

  return (
    <StyledLink to={referrer || href}>
      <StyledArrow />
      <div className="text">Back</div>
    </StyledLink>
  );
}

const mainStyles = css`
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  .text {
    font-size: 1.3rem;
    font-weight: 300;
    color: #000;
    opacity: 0.5;
    margin: 0 2.5rem 0 0.8rem;
  }
`;

const StyledDiv = styled.div`
  ${mainStyles}
`;

const StyledLink = styled(Link)`
  ${mainStyles}
`;

const StyledArrow = styled(Arrow)`
  transform: rotate(90deg);
  width: 1rem;
  height: 0.6rem;
  margin-bottom: 1px;
`;
