import React from 'react'

import { useCurrentLanguage } from 'hooks/useGlobalState'
import en from './en'

const translationsConfig = {
  en
}

export default function useTranslations () {
  const currentLanguage = useCurrentLanguage()
  return React.useMemo(() => {
    return translationsConfig[currentLanguage]
  }, [currentLanguage])
}
