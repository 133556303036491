import React, { FC, useCallback } from 'react';
import { TaskType } from '../../../../requests/graphql/my-health/queries/getTreatmentPlan';
import { AttachedFile } from '../TreatmentPlanEdit/AttachedFile';
import { AttachFileButton } from './AttachFile';

type TreatmentPlanFilesProps = {
  files: TaskType['files'];
  uploadingFiles: File[];
  onDelete: (id: string) => void;
  onAttach: (file: File | File[]) => void;
};
export const TreatmentPlanFiles: FC<TreatmentPlanFilesProps> = ({ files, uploadingFiles, onDelete, onAttach }) => {
  const handleDelete = useCallback((id: string) => () => onDelete(id), []);
  return (
    <div className="flex mb-6">
      {files?.map((file, fi) => (
        <AttachedFile
          onDelete={handleDelete(file?.id)}
          key={file?.id || 'file' + fi}
          className="mr-4"
          type={file?.fileType as any}
          filename={file?.fileName}
        />
      ))}
      {uploadingFiles?.map((file, fi) => (
        <AttachedFile loading key={file.name} className="mr-4" type={file.type as any} filename={file.name} />
      ))}
      <AttachFileButton isMultiple onAttach={onAttach} />
    </div>
  );
};
