import React from 'react'

import { COMPANIES } from 'constants/routes'; 

import { useSetCompanyForm } from 'hooks/useCompanies'

import { LargeSquarePlate } from 'common/plate/plates'
import { PlateHeader, PlateContent } from 'common/plate/styles'
import BackButton from 'common/plate/BackButton'
import CompanyPhoto from 'components/companies/create-company/CompanyPhoto'
import CompanyForm from 'components/companies/create-company/CompanyForm'
import CompanySubmit from 'components/companies/create-company/CompanySubmit'

export default function CreateCompany () {
  const clearCompanyForm = useSetCompanyForm(() => ({}), [])
  const [validate, setValidate] = React.useState(false)

  React.useEffect(() => {
    return clearCompanyForm
  }, [clearCompanyForm])

  return (
    <LargeSquarePlate>
      <PlateHeader>
        <BackButton href={`/${COMPANIES}`} />
        Add new company
      </PlateHeader>

      <PlateContent>
        <CompanyPhoto />
        <CompanyForm validate={validate} />
        <CompanySubmit setValidate={setValidate} />
      </PlateContent>
    </LargeSquarePlate>
  )
}
