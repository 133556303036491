import React from 'react'
import styled from 'styled-components'
import get from 'lodash/get'

import LinkButton from 'common/LinkButton'
import Icon from 'components/patients/medications/common/medications/medication-preview-components/Icon'
import Name from 'components/patients/medications/common/medications/medication-preview-components/Name'
import Dosage from 'components/patients/medications/common/medications/medication-preview-components/Dosage'
import Measure from 'components/patients/medications/common/medications/medication-preview-components/Measure'
import Frequency from 'components/patients/medications/common/medications/medication-preview-components/Frequency'
import StartDate from 'components/patients/medications/common/medications/medication-preview-components/StartDate'
import EndDate from 'components/patients/medications/common/medications/medication-preview-components/EndDate'
import Note from 'components/patients/medications/common/medications/medication-preview-components/Note'
import RemoveButton from './RemoveButton'

export default React.memo(function MedicationPreview ({ index, isLast, medication }) {
  const id = get(medication, 'id')
  const icon = get(medication, 'icon')
  const medicationName = get(medication, 'medication') || ''
  const dosage = get(medication, 'dosage') || ''
  const measureId = get(medication, 'measure_id')
  const frequencyId = get(medication, 'frequency_id')
  const note = get(medication, 'note') || ''
  const startedAt = get(medication, 'started_at')
  const endedAt = get(medication, 'ended_at')

  const [isOpen, setIsOpen] = React.useState(false)
  const toggleNote = React.useCallback(() => setIsOpen(prev => !prev), [setIsOpen])

  return (
    <StyledWrapper className='preview'>
      <Icon
        className='icon-select'
        index={index}
        icon={icon}
      />
      <Name
        className='name'
        index={index}
        medicationName={medicationName}
      />
      <Frequency
        className='frequency'
        index={index}
        frequencyId={frequencyId}
      />
      <LinkButton
        className='note-button'
        onClick={toggleNote}
      >
        {isOpen ? 'Hide' : 'Add note'}
      </LinkButton>
      <Note
        className='note'
        isOpen={isOpen}
        index={index}
        note={note}
      />
      <Dosage
        className='dosage'
        index={index}
        dosage={dosage}
      />
      <Measure
        className='measure'
        index={index}
        measureId={measureId}
      />
      <StartDate
        className='start-date'
        index={index}
        startedAt={startedAt}
      />
      <EndDate
        className='end-date'
        index={index}
        startedAt={startedAt}
        endedAt={endedAt}
      />
      <RemoveButton
        className='remove-button'
        id={id}
        index={index}
        isLast={isLast}
      />
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  display: grid;
  align-items: flex-end;
  grid-template-columns: 8fr 12fr 12fr 12fr 12fr;
  grid-gap: 1.5rem;
  padding: 2.5rem;
  margin: 0 -2.5rem;
  border-top: 1px solid #e9ebef;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .icon-select {
    @media screen and (max-width: 768px) {
      grid-column-start: 1;
      grid-column-end: 2;
    }
    .icon {
      display: block;
      margin: auto;
      height: 3rem;
    }
  }
  .name {
    max-width: initial;
    grid-column-start: 2;
    grid-column-end: 4;
    @media screen and (max-width: 768px) {
      grid-column-start: 2;
      grid-column-end: 5;
    }
    @media screen and (max-width: 480px) {
      grid-column-start: 2;
      grid-column-end: 7;
    }
  }
  .frequency {
    @media screen and (max-width: 768px) {
      grid-column-start: 5;
      grid-column-end: 7;
    }
    @media screen and (max-width: 480px) {
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }
  .note-button {
    height: 4.8rem;
    @media screen and (max-width: 768px) {
      grid-column-start: 1;
      grid-column-end: 7;
    }
    @media screen and (max-width: 480px) {
      grid-column-start: 4;
      grid-column-end: 7;
    }
  }
  .note {
    grid-column-start: 1;
    grid-column-end: 6;
    @media screen and (max-width: 768px) {
      grid-column-start: 1;
      grid-column-end: 7;
    }
  }
  .dosage {
    max-width: initial;
    @media screen and (max-width: 768px) {
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }
  .measure {
    max-width: initial;
    @media screen and (max-width: 768px) {
      grid-column-start: 4;
      grid-column-end: 7;
    }
  }
  .start-date {
    max-width: initial;
    @media screen and (max-width: 768px) {
      grid-column-start: 1;
      grid-column-end: 4;
    }
    .calendar-input {
      height: 4.8rem;
    }
  }
  .end-date {
    max-width: initial;
    @media screen and (max-width: 768px) {
      grid-column-start: 4;
      grid-column-end: 7;
    }
    .calendar-input {
      height: 4.8rem;
    }
    .calendar-window {
      @media screen and (max-width: 1024px) {
        transform: translate(-50%, -50%);
      }
    }
  }
  .remove-button {
    height: 4.8rem;
    @media screen and (max-width: 768px) {
      grid-column-start: 1;
      grid-column-end: 7;
    }
  }
`
