import React from 'react'

import { HEALTH_PLANS } from 'constants/routes';

import { useFetchPatinetHealthPlans } from 'requests/patients'

import { RegularMediumText } from 'common/texts'
import DetailsTemplate from 'components/patients/DetailsTemplate'
import HealthPlans from 'components/patients/health-plan/HealthPlans'
import NewPlanButton from 'components/patients/health-plan/NewPlanButton'

export default function PatientHealthPlans () {
  const fetchPatinetHealthPlans = useFetchPatinetHealthPlans()

  React.useEffect(() => {
    fetchPatinetHealthPlans()
    // eslint-disable-next-line
  }, [])

  return (
    <DetailsTemplate currentTab={HEALTH_PLANS}>
      <RegularMediumText className='g-mb-10'>
        Health plan
      </RegularMediumText>

      <HealthPlans />

      <NewPlanButton />
    </DetailsTemplate>
  )
}
