import React from 'react'

import { useWidgetsList } from 'hooks/patient/widgets/useWidgets'

import { SquarePlate } from 'common/plate/plates'
import SynchronizedWidget from './synchronized-widget'

export default React.memo(function WidgetsList () {
  const widgetsList = useWidgetsList()

  const parsedWidgetsList = React.useMemo(() => {
    if (!widgetsList) {
      return (
        <>
          <SquarePlate className='tab' />
          <SquarePlate className='tab' />
        </>
      )
    }

    return widgetsList.map(({ id, device, type, data }) => {
      if (!device) return null
      return (
        <SynchronizedWidget
          key={id}
          className='tab'
          widgetId={id}
          device={device}
          type={type}
          data={data}
        />
      )
    })
  }, [widgetsList])

  return parsedWidgetsList
})
