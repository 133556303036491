import React from 'react'
import styled from 'styled-components'

import { UppercaseText, RegularText, RegularMediumText } from 'common/texts'
import CircularIndicator from './CircularIndicator'

export default function ActivityPreview ({
  targetCalories, percentageValue = '', calActive = 0, inactive = ''
}) {
  return (
    <div className='g-d-flex g-align-center g-w-100 g-mt-30'>
      <div className='g-w-50 g-text-align-center'>
        <StyledRegularText className='value'>
          {targetCalories || 'NO GOAL'}
        </StyledRegularText>
        <CircularIndicator
          goalProgress={percentageValue ?? 0}
          currentCalories={calActive}
        />
        <StyledRegularText className='value'>
          cal
        </StyledRegularText>
      </div>
      <div className='g-w-50'>
        <div className='g-d-flex g-align-center g-w-100'>
          <UppercaseText>
            Inactive time:
          </UppercaseText>
          <RegularMediumText className='g-ml-10'>
            {inactive}
          </RegularMediumText>
        </div>
        <div className='g-d-flex g-align-center g-w-100 g-mt-5'>
          <UppercaseText>
            Goal progress:
          </UppercaseText>
          <RegularMediumText className='g-ml-10'>
            {percentageValue}%
          </RegularMediumText>
        </div>
      </div>
    </div>
  )
}

const StyledRegularText = styled(RegularText)`
  font-size: 1.3rem;
  text-transform: uppercase;
`
