import React, { ReactNode } from "react";
import { ReactComponent as ArrowIcon } from 'assets/arrow-icon-link-btn.svg';


interface LinkButtonProps {
  href: string;
  children: ReactNode;
  className?: string;
}

const LinkButton: React.FC<LinkButtonProps> = ({ href, children, className, ...rest }) => {
  return (
    // <span className="flex flex-row">
     <a
      href={href}
      className={`my-3 flex items-center font-medium text-16px24px text-linkBlue hover:underline underline-offset-8 ${className}`}
      {...rest}
    >
      {children}
       <ArrowIcon className="ml-3"/>
    </a>
    // </span>
    
  );
};

export default LinkButton;