import React from 'react'
import styled from 'styled-components'

export default React.memo(function ChatListPlaceholder ({ className = '' }) {
  return (
    <StyledWrapper className={className}>
      <div className='icon' />
      <div className='text' />
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5rem 1.5rem 1.5rem 2.2rem;
  border-bottom: solid 1px #e9ebef;
  .icon {
    width: 4rem;
    height: 4rem;
    background: #e9ebef;
    border-radius: 50%;
  }
  .text {
    height: 1.8rem;
    width: 50%;
    margin-left: 1rem;
    background: #e9ebef;
  }
`
