import React from 'react'
import styled from 'styled-components'
import get from 'lodash/get'

import SwitcherTab from './SwitcherTab'

export default React.memo(function LocalSwitcher ({
  className = '', tabsConfig = [], currentTab, setCurrentTab
}) {
  const tabs = React.useMemo(() => {
    return tabsConfig.map(({ id, label, isRequred }, i) => {
      let isSeparatorNeed = true
      if (id === currentTab) isSeparatorNeed = false
      if (get(tabsConfig[i + 1], 'id') === currentTab) isSeparatorNeed = false
      if (tabsConfig.length === i + 1) isSeparatorNeed = false
      return (
        <SwitcherTab
          key={id}
          id={id}
          label={label}
          isRequred={isRequred}
          isActive={id === currentTab}
          setCurrentTab={setCurrentTab}
          isSeparatorNeed={isSeparatorNeed}
        />
      )
    })
  }, [tabsConfig, currentTab, setCurrentTab])
  return (
    <StyledWrapper className={className}>
      {tabs}
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  display: inline-flex;
  align-self: flex-start;
  flex-wrap: wrap;
  background: #eee;
  border: solid 2px #eee;
  border-radius: 0.8rem;
`
