import { AmplifyUserSession } from "../libs/cognito.types";

export const saveSession = (session?: AmplifyUserSession) => {
  if (!session) return;
  const accessToken = session?.accessToken.jwtToken;
  const idToken = session?.idToken.jwtToken;
  const refreshToken = session?.refreshToken.jwtToken;
  window.localStorage.setItem("idToken", `${idToken}`);
  window.localStorage.setItem("accessToken", `${accessToken}`);
  window.localStorage.setItem("refreshToken", `${refreshToken}`);
};
