import cloneDeep from 'lodash/cloneDeep'

export function parseChannel (channel) {
  const {
    sid,
    uniqueName,
    dateCreated,
    dateUpdated,
    state,
    lastMessage,
    lastConsumedMessageIndex
  } = channel
  return {
    sid,
    uniqueName,
    dateCreated,
    dateUpdated,
    state: cloneDeep(state),
    lastMessage: cloneDeep(lastMessage),
    lastConsumedMessageIndex,
    channelClass: channel
  }
}

export function parseChannels (channels) {
  return channels.map(channel => parseChannel(channel))
}

export function updateChannels (channels, updatedChannel) {
  return channels.map(channel => {
    if (channel.sid !== updatedChannel.sid) return channel
    return parseChannel(updatedChannel)
  })
}

export function parseMessage (message) {
  const {
    sid,
    author,
    state,
    body,
    index,
    timestamp
  } = message
  return {
    sid,
    author,
    state: cloneDeep(state),
    body,
    timestamp,
    index,
    messageClass: message
  }
}

export function parseMessages (messages) {
  return messages.map(message => parseMessage(message))
}

export function addMessage (messages, newMessage) {
  if (!messages) return [parseMessage(newMessage)]
  return [...messages, parseMessage(newMessage)]
}

export function updateMessages (messages, updatedMessage) {
  return messages.map(message => {
    if (message.sid !== updatedMessage.sid) return message
    return parseMessage(updatedMessage)
  })
}

export function removeMessage (messages, removedMessage) {
  return messages.filter(({ sid }) => sid !== removedMessage.sid)
}
