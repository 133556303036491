import React from 'react'
import styled from 'styled-components'

import { ReactComponent as ChatIcon } from 'assets/video/chat-icon.svg'

export default React.memo(function ChatIconComponent ({ setIsChatHidden }) {
  const toggleChat = React.useCallback(() => {
    setIsChatHidden(current => !current)
  }, [setIsChatHidden])

  return (
    <StyledWrapper onClick={toggleChat}>
      <ChatIcon />
    </StyledWrapper>
  )
})

export const StyledWrapper = styled.div`
  position: absolute;
  right: 2rem;
  top: 2rem;
  z-index: 10;
  width: 4.8rem;
  height: 4.8rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  cursor: pointer;
  :hover {
    opacity: 0.75;
  }
  :active {
    opacity: 0.5;
  }
`
