import React from 'react'
import styled from 'styled-components'

import PrevButton from './PrevButton'
import PagesList from './PagesList'
import NextButton from './NextButton'

export default function Pagination ({
  className = '', pagesCount = 1, page = 1, navigateHandler = () => { }
}) {
  if (!pagesCount || pagesCount <= 1) return null

  return (
    <StyledWrapper className={className}>
      <PrevButton
        page={page}
        navigateHandler={navigateHandler}
      />

      <PagesList
        pagesCount={pagesCount}
        page={page}
        navigateHandler={navigateHandler}
      />

      <NextButton
        pagesCount={pagesCount}
        page={page}
        navigateHandler={navigateHandler}
      />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;

  .pagination-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 0.375rem 1rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    color: #16123f;
    font-size: 1.3rem;
    font-weight: 600;
    width: 7rem;
    text-align: center;
    cursor: pointer;
    .icon {
      width: 0.8rem;
    }
  }

  .pagination-button:hover {
    border-color: #bfc4cb;
  }

  .pagination-button:active {
    border-color: #a9b0b8;
  }

  .prev {
    margin: 0 0.5rem 0 0;
    .icon {
      transform: rotate(90deg);
    }
  }

  .next {
    margin: 0 0 0 0.5rem;
    .icon {
      transform: rotate(-90deg);
    }
  }

  .disabled {
    background-color: #e9edf1 !important;
    color: #b0b0b1 !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    cursor: auto !important;
    .icon {
      fill: #b0b0b1;
    }
  }

  .pagination-page {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    color: #16123f;
    font-size: 1.3rem;
    font-weight: 400;
    width: 4rem;
    text-align: center;
    padding: 0.375rem 0;
    margin: 0 0.5rem;
    cursor: pointer;
  }

  .pagination-page:hover {
    border-color: #bfc4cb;
  }

  .active {
    color: #fff;
    font-weight: 600;
    background: #16123f;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    cursor: auto !important;
  }

  .pagination-separator {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    color: #354052;
    font-size: 1.3rem;
    font-weight: 400;
    width: 4rem;
    text-align: center;
    padding: 0.375rem 0;
    margin: 0 0.5rem;
  }
`
