import React from 'react'

import { useProviderProfile } from 'hooks/useProviders'

import InfoPlaceholder from './InfoPlaceholder'
import Info from './Info'

export default React.memo(function ProviderInfo () {
  const providerProfile = useProviderProfile()

  if (!providerProfile) return <InfoPlaceholder />

  return <Info />
})
