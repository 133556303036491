import React, { FC, useState } from 'react';
import classNames from 'clsx';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';

type DatePicker = {
  isRequired?: boolean;
  label?: string;
  className?: string;
  error?: string;
  maxDate?: Date;
  minDate?: Date;
  placeholder?: string;
  value?: string | Date | null;
  onChange: (date: Date | null) => void;
};

export const DatePicker: FC<DatePicker> = ({ onChange, value, label, isRequired, error, className, maxDate, minDate }) => {
  let val = value;

  if (value instanceof String || value) {
    val = moment(value).toDate();
  }

  const hasError = !!error;
  return (
    <div className="w-full">
      {label && (
        <div className="flex mb-1">
          <div className="text-1324 text-coil">{label}</div>
          {isRequired && <div className={classNames('text-error text-1324')}>*</div>}
        </div>
      )}
      <ReactDatePicker
        maxDate={maxDate}
        minDate={minDate}
        className={classNames(
          'placeholder:text-lavenderGray focus:outline-main placeholder:text-1324 w-full h-10 text-main text-1522 font-ambit border bg-white rounded-[3.2rem] px-4',
          className,
          {
            'border-error': hasError,
            'border-lavenderGray': !hasError,
          }
        )}
        selected={val as Date}
        onChange={onChange}
      />
      <div
        className={classNames('text-error text-1214 px-2 py-1', {
          hidden: !hasError,
        })}
      >
        {error}
      </div>
    </div>
  );
};
