export type SelectOption = {
  value: string;
  label: string;
};
export const sortOptions = (a: SelectOption, b: SelectOption) => {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
};
