import React from 'react'

import { useDayData } from 'hooks/patient/widgets/useWidgets'

import WeightListItem from './WeightListItem'

export default function WeightList () {
  const fatRatio = useDayData('fatRatio')
  const muscleMass = useDayData('muscleMass')
  const boneMass = useDayData('boneMass')

  return (
    <>
      <WeightListItem
        label='Body fat'
        value={`${fatRatio || 0} %`}
        bgColor='#38dee8'
      />
      <WeightListItem
        label='Body muscle'
        value={`${muscleMass || 0} lb`}
        bgColor='#0dc3f5'
      />
      <WeightListItem
        label='Bone mass'
        value={`${boneMass || 0} lb`}
        bgColor='#0a7dc7'
      />
    </>
  )
}
