import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import get from 'lodash/get';

import { PROGRAMS, COMPANIES } from 'constants/routes';

import { MediumText, HintText, RegularText } from 'common/texts';
import UserAvatar from 'common/UserAvatar';
import LinkButton from 'common/LinkButton';
import DeletePlanButton from './DeletePlanButton';
import { permissions } from '../../../../permissions';
import { useUserContext } from '../../../../context/userContext';

export default function PlansPreview({ plan }) {
  const { can } = useUserContext();
  const { companyId } = useParams();
  const history = useHistory();

  const name = get(plan, 'plan.name');
  const subscriptionId = get(plan, 'id')
  const navigateToPreview = React.useCallback(() => {
    history.push({
      pathname: `/${COMPANIES}/${PROGRAMS}/${subscriptionId}`,
      state: { referrer: `/${COMPANIES}/${companyId}` },
    });
  }, [history, companyId]);

  return (
    <StyledWrapper>
      <div className="name">
        <MediumText>{name}</MediumText>
      </div>

      <LinkButton className="view" onClick={navigateToPreview}>
        View
      </LinkButton>
      {can(permissions.companies.canDeletePatient) && <DeletePlanButton subscriptionId={subscriptionId} />}
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafbfd;
  border: 1px solid #e9ebef;
  padding: 2rem;
  margin-top: 1.5rem;
  @media only screen and (max-width: 480px) {
    flex-wrap: wrap;
  }
  .name {
    width: 35%;
    margin-left: 1.5rem;
    @media only screen and (max-width: 480px) {
      width: calc(100% - 5.9rem);
    }
  }
  .status {
    width: 15%;
    @media only screen and (max-width: 480px) {
      margin-top: 1rem;
      width: 33%;
    }
  }
  .gender {
    width: 15%;
    @media only screen and (max-width: 480px) {
      margin-top: 1rem;
      width: 33%;
    }
  }
  .mrn {
    width: 15%;
    @media only screen and (max-width: 480px) {
      margin-top: 1rem;
      width: 33%;
    }
  }
  .view {
    flex-shrink: 0;
    padding: 0.6rem 3.5rem;
    @media only screen and (max-width: 480px) {
      margin-top: 1rem;
      width: 100%; // 48%
    }
  }
  .delete {
    flex-shrink: 0;
    padding: 0.6rem 3.5rem;
    margin-left: 3.6rem;
    @media only screen and (max-width: 480px) {
      margin-top: 1rem;
      margin-left: 0;
      width: 48%;
    }
  }
`;
