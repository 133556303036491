import React from 'react'
import styled from 'styled-components'
import get from 'lodash/get'
import ImageUploading from 'react-images-uploading'

import { useLocalProfile, useUploadedAvatar, useSetUploadedAvatar } from 'hooks/useProfile'

import { RegularBigText } from 'common/texts'

import UserAvatar from 'common/UserAvatar'
import LinkButton from 'common/LinkButton'

const maxMbFileSize = 1 * 1024 * 1024

export default React.memo(function ProfilePhoto () {
  const uploadedAvatar = useUploadedAvatar()
  const setUploadedAvatar = useSetUploadedAvatar((prev, next) => next, [])
  const firstName = useLocalProfile(current => get(current, 'first_name', ''))
  const lastName = useLocalProfile(current => get(current, 'last_name', ''))
  const avatar = useLocalProfile(current => get(current, 'avatar'))

  const avatarSrc = uploadedAvatar || avatar

  React.useEffect(() => {
    return () => setUploadedAvatar(null)
    // eslint-disable-next-line
  }, [])

  const handleImageUpload = React.useCallback((images) => {
    setUploadedAvatar(images[0].dataURL)
  }, [setUploadedAvatar])

  return (
    <StyledWrapper>
      <RegularBigText>
        Profile photo
      </RegularBigText>

      <div className='g-d-flex g-align-center g-mt-15 g-mb-15'>
        <StyledUserAvatar
          avatar={avatarSrc}
          firstName={firstName}
          lastName={lastName}
        />
        <ImageUploading
          onChange={handleImageUpload}
          defaultValue={[{ dataURL: avatar }]}
          maxFileSize={maxMbFileSize}
          acceptType={['jpg', 'jpeg', 'png']}
        >
          {({ onImageUpload }) => {
            return (
              <div>
                <LinkButton onClick={onImageUpload}>
                  Upload
                </LinkButton>
                <div className='hint'>
                  Recommended size 100x100
                </div>
              </div>
            )
          }}
        </ImageUploading>
      </div>
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  .hint {
    font-size: 1.3rem;
    line-height: 2.4rem;
    color: #000;
    opacity: 0.5;
  }
`

const StyledUserAvatar = styled(UserAvatar)`
  width: 8rem;
  height: 8rem;
  margin-right: 2rem;
`
