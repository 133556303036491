/* eslint-disable */

import { AllTypesProps, ReturnTypes } from './const';
type ZEUS_INTERFACES = GraphQLTypes["PrimaryCareProviderInterface"] | GraphQLTypes["PatientProfileInterface"] | GraphQLTypes["PatientContactInterface"] | GraphQLTypes["PatientEmergencyInterface"]
type ZEUS_UNIONS = GraphQLTypes["ChatProviderTypeChatPatientType"] | GraphQLTypes["CompanyPlanTypeCompanyDoesNotHaveMemberships"] | GraphQLTypes["AppointmentTypeNoAvailableVisitsProviderNotAvailableProviderHasNotQualification"] | GraphQLTypes["IntTypeCannotCancelBefore24HoursAppointmentInThePast"] | GraphQLTypes["MembershipInfoTypeCompanyDoesNotHaveActiveMembershipsCompanyNoCapacityNoCompanyByCodePatientHasMembership"] | GraphQLTypes["UploadFileInfoTypeTooLargeFileFileTypeIsNotSupported"] | GraphQLTypes["UpdatePasswordSamePasswordErrorPasswordUpdateLimitExceededError"] | GraphQLTypes["UpdatePasswordConfirmCodeExpiredError"] | GraphQLTypes["LibraryMaterialTypeMaterialDuplicatedError"]

export type ValueTypes = {
    ["MainQuery"]: AliasType<{
getAppointments?: [{	filters?:ValueTypes["AppointmentsFilterType"] | null,	sort?:ValueTypes["AppointmentsSortType"] | null},ValueTypes["AppointmentType"]],
getAppointmentVideoToken?: [{	appointmentId:number},ValueTypes["VideoTokenType"]],
getAppointmentChatToken?: [{	appointmentId:number},ValueTypes["ChatTokenType"]],
getChatMembers?: [{	members:ValueTypes["MemberFilterType"][]},ValueTypes["MembersType"]],
	getCancelReasons?:ValueTypes["CancelReasonType"],
states?: [{	filters?:ValueTypes["StateFilter"] | null},ValueTypes["StatesInfo"]],
	genders?:ValueTypes["GendersInfo"],
	relationships?:ValueTypes["RelationshipsInfo"],
	me?:ValueTypes["PatientInfo"],
	getMyProgramInfo?:ValueTypes["MembershipInfoType"],
	getPatientExperienceProgress?:ValueTypes["PatientExperienceProgressType"],
	getMyInsurances?:ValueTypes["PatientInsurancesInfoType"],
	getAvailableQualifications?:ValueTypes["QualificationType"],
	getChatToken?:ValueTypes["ConversationTokenType"],
	getMyCareTeam?:ValueTypes["CareTeamType"],
getPatientLabs?: [{	page:number,	perPage:number},ValueTypes["LabTypeConnection"]],
getLabUrl?: [{	labId:number},boolean],
getProgramByCoupon?: [{	activationCode:string},ValueTypes["CompanyPlanTypeCompanyDoesNotHaveMemberships"]],
getProviders?: [{	filters?:ValueTypes["ProvidersFilterType"] | null,	sort?:ValueTypes["ProvidersSortType"] | null},ValueTypes["ProviderType"]],
getProviderSlots?: [{	filters:ValueTypes["ProviderSlotFilterType"]},boolean],
getLibraryMaterials?: [{	page:number,	perPage:number,	filters?:ValueTypes["LibraryMaterialFilterType"] | null},ValueTypes["LibraryMaterialTypeConnection"]],
getLibraryMaterial?: [{	materialId:ValueTypes["UUID"]},ValueTypes["LibraryMaterialType"]],
	getLibraryMaterialCategories?:ValueTypes["LibraryMaterialCategoryType"],
	getLibraryMaterialTypes?:boolean,
	getPatientQuestionnaires?:ValueTypes["GroupedQuestionnairesType"],
getPatientQuestionnaire?: [{	questionnaireId:number},ValueTypes["QuestionnaireType"]],
	getPatientConsentForm?:ValueTypes["QuestionnaireType"],
		__typename?: boolean
}>;
	["AppointmentType"]: AliasType<{
	provider?:ValueTypes["ProviderType"],
	patient?:ValueTypes["PatientType"],
	channel?:ValueTypes["ChanelType"],
	createdAt?:boolean,
	updatedAt?:boolean,
	id?:boolean,
	type?:boolean,
	reason?:boolean,
	startedAt?:boolean,
	timeZone?:boolean,
	rate?:boolean,
	status?:boolean,
	subStatus?:boolean,
	rescheduleReason?:boolean,
	canceledText?:boolean,
	canceledBy?:boolean,
	adminCanceled?:boolean,
	integrationsMeta?:boolean,
	deletedAt?:boolean,
	agreementPolicy?:boolean,
	agreementCancel?:boolean,
	visitId?:boolean,
	visitType?:boolean,
	review?:boolean,
		__typename?: boolean
}>;
	["ProviderType"]: AliasType<{
	profile?:ValueTypes["ProfileType"],
	providerInfo?:ValueTypes["ProviderInformationType"],
	qualifications?:ValueTypes["ProviderQualificationType"],
	createdAt?:boolean,
	updatedAt?:boolean,
	id?:boolean,
	email?:boolean,
	isSuper?:boolean,
	isSupport?:boolean,
		__typename?: boolean
}>;
	["ProfileType"]: AliasType<{
	avatarUrl?:boolean,
	gender?:ValueTypes["GenderType"],
	createdAt?:boolean,
	updatedAt?:boolean,
	id?:boolean,
	profileableId?:boolean,
	profileableType?:boolean,
	fName?:boolean,
	lName?:boolean,
	birthDate?:boolean,
	phone?:boolean,
	city?:boolean,
	zip?:boolean,
	address1?:boolean,
	address2?:boolean,
	biography?:boolean,
	avatar?:boolean,
	deletedAt?:boolean,
		__typename?: boolean
}>;
	["GenderType"]: AliasType<{
	createdAt?:boolean,
	updatedAt?:boolean,
	id?:boolean,
	name?:boolean,
	slug?:boolean,
		__typename?: boolean
}>;
	/** Date with time (isoformat) */
["DateTime"]:unknown;
	/** Date (isoformat) */
["Date"]:unknown;
	["ProviderInformationType"]: AliasType<{
	createdAt?:boolean,
	updatedAt?:boolean,
	id?:boolean,
	education?:boolean,
	practices?:boolean,
	experienceYears?:boolean,
	shortSummary?:boolean,
	qualificationTitle?:boolean,
		__typename?: boolean
}>;
	["ProviderQualificationType"]: AliasType<{
	qualification?:ValueTypes["QualificationType"],
	id?:boolean,
		__typename?: boolean
}>;
	["QualificationType"]: AliasType<{
	createdAt?:boolean,
	updatedAt?:boolean,
	id?:boolean,
	name?:boolean,
	title?:boolean,
	slug?:boolean,
	type?:boolean,
	icon?:boolean,
	cost?:boolean,
		__typename?: boolean
}>;
	/** Decimal (fixed-point) */
["Decimal"]:unknown;
	["PatientType"]: AliasType<{
	profile?:ValueTypes["ProfileType"],
	createdAt?:boolean,
	updatedAt?:boolean,
	id?:boolean,
	email?:boolean,
	emailVerifiedAt?:boolean,
	medicalRecord?:boolean,
	experianceDone?:boolean,
	trialEndsAt?:boolean,
	termsAgree?:boolean,
	noInsurance?:boolean,
	firstVisitedAt?:boolean,
	lastVisitedAt?:boolean,
	deletedAt?:boolean,
	noPrimaryProvider?:boolean,
		__typename?: boolean
}>;
	["ChanelType"]: AliasType<{
	createdAt?:boolean,
	updatedAt?:boolean,
	id?:boolean,
	channelSid?:boolean,
	channelUniqueName?:boolean,
	channelFrendlyName?:boolean,
		__typename?: boolean
}>;
	/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
["JSON"]:unknown;
	["AppointmentsFilterType"]: {
	status?:ValueTypes["AppointmentStatusEnum"][],
	type?:ValueTypes["AppointmentTypeEnum"] | null
};
	["AppointmentStatusEnum"]:AppointmentStatusEnum;
	["AppointmentTypeEnum"]:AppointmentTypeEnum;
	["AppointmentsSortType"]: {
	orderBy:ValueTypes["AppointmentsOrderByEnum"],
	direction:ValueTypes["OrderDirection"]
};
	["AppointmentsOrderByEnum"]:AppointmentsOrderByEnum;
	["OrderDirection"]:OrderDirection;
	["VideoTokenType"]: AliasType<{
	roomName?:boolean,
	roomTitle?:boolean,
	token?:boolean,
	participants?:ValueTypes["ChatProviderTypeChatPatientType"],
		__typename?: boolean
}>;
	["ChatProviderTypeChatPatientType"]: AliasType<{		["...on ChatProviderType"] : ValueTypes["ChatProviderType"],
		["...on ChatPatientType"] : ValueTypes["ChatPatientType"]
		__typename?: boolean
}>;
	["ChatProviderType"]: AliasType<{
	profile?:ValueTypes["ChatProfileType"],
	providerInfo?:ValueTypes["ProviderInformationType"],
	qualifications?:ValueTypes["ProviderQualificationType"],
	createdAt?:boolean,
	updatedAt?:boolean,
	id?:boolean,
	email?:boolean,
	isSuper?:boolean,
	isSupport?:boolean,
	chatIdentity?:boolean,
		__typename?: boolean
}>;
	["ChatProfileType"]: AliasType<{
	avatarUrl?:boolean,
	fName?:boolean,
	lName?:boolean,
	avatar?:boolean,
		__typename?: boolean
}>;
	["ChatPatientType"]: AliasType<{
	profile?:ValueTypes["ChatProfileType"],
	createdAt?:boolean,
	updatedAt?:boolean,
	id?:boolean,
	email?:boolean,
	emailVerifiedAt?:boolean,
	medicalRecord?:boolean,
	experianceDone?:boolean,
	trialEndsAt?:boolean,
	termsAgree?:boolean,
	noInsurance?:boolean,
	firstVisitedAt?:boolean,
	lastVisitedAt?:boolean,
	deletedAt?:boolean,
	noPrimaryProvider?:boolean,
	chatIdentity?:boolean,
		__typename?: boolean
}>;
	["ChatTokenType"]: AliasType<{
	channelSid?:boolean,
	channelUniqueName?:boolean,
	token?:boolean,
	participants?:ValueTypes["ChatProviderTypeChatPatientType"],
		__typename?: boolean
}>;
	["MembersType"]: AliasType<{
	members?:ValueTypes["ChatProviderTypeChatPatientType"],
		__typename?: boolean
}>;
	["MemberFilterType"]: {
	type:ValueTypes["ProfileTypes"],
	id:ValueTypes["UUID"]
};
	["ProfileTypes"]:ProfileTypes;
	["UUID"]:unknown;
	["CancelReasonType"]: AliasType<{
	id?:boolean,
	name?:boolean,
		__typename?: boolean
}>;
	["StatesInfo"]: AliasType<{
	value?:boolean,
	label?:boolean,
		__typename?: boolean
}>;
	["StateFilter"]: {
	country?:ValueTypes["Countries"] | null
};
	["Countries"]:Countries;
	["GendersInfo"]: AliasType<{
	value?:boolean,
	label?:boolean,
		__typename?: boolean
}>;
	["RelationshipsInfo"]: AliasType<{
	value?:boolean,
	label?:boolean,
		__typename?: boolean
}>;
	["PatientInfo"]: AliasType<{
	id?:boolean,
	email?:boolean,
	companyId?:boolean,
	noPrimaryProvider?:boolean,
	termsAgree?:boolean,
	primaryCareProvider?:ValueTypes["PrimaryCareProviderInfoType"],
	profile?:ValueTypes["PatientProfileInfoType"],
	contact?:ValueTypes["PatientContactInfoType"],
	emergency?:ValueTypes["PatientEmergencyInfoType"],
		__typename?: boolean
}>;
	["PrimaryCareProviderInfoType"]: AliasType<{
	name?:boolean,
	address?:boolean,
	officeNumber?:boolean,
		__typename?: boolean
}>;
	["PrimaryCareProviderInterface"]:AliasType<{
		name?:boolean,
	address?:boolean,
	officeNumber?:boolean;
		['...on PrimaryCareProviderInfoType']?: Omit<ValueTypes["PrimaryCareProviderInfoType"],keyof ValueTypes["PrimaryCareProviderInterface"]>;
		__typename?: boolean
}>;
	["PatientProfileInfoType"]: AliasType<{
	firstName?:boolean,
	lastName?:boolean,
	birthDate?:boolean,
	avatar?:boolean,
	gender?:ValueTypes["GendersInfo"],
	avatarUrl?:boolean,
		__typename?: boolean
}>;
	["PatientProfileInterface"]:AliasType<{
		firstName?:boolean,
	lastName?:boolean,
	birthDate?:boolean,
	avatar?:boolean;
		['...on PatientProfileInfoType']?: Omit<ValueTypes["PatientProfileInfoType"],keyof ValueTypes["PatientProfileInterface"]>;
		__typename?: boolean
}>;
	["PatientContactInfoType"]: AliasType<{
	phone?:boolean,
	city?:boolean,
	zipCode?:boolean,
	streetAddress1?:boolean,
	streetAddress2?:boolean,
	state?:ValueTypes["StatesInfo"],
		__typename?: boolean
}>;
	["PatientContactInterface"]:AliasType<{
		phone?:boolean,
	city?:boolean,
	zipCode?:boolean,
	streetAddress1?:boolean,
	streetAddress2?:boolean;
		['...on PatientContactInfoType']?: Omit<ValueTypes["PatientContactInfoType"],keyof ValueTypes["PatientContactInterface"]>;
		__typename?: boolean
}>;
	["PatientEmergencyInfoType"]: AliasType<{
	fullName?:boolean,
	phone?:boolean,
	relationship?:ValueTypes["RelationshipsInfo"],
		__typename?: boolean
}>;
	["PatientEmergencyInterface"]:AliasType<{
		fullName?:boolean,
	phone?:boolean;
		['...on PatientEmergencyInfoType']?: Omit<ValueTypes["PatientEmergencyInfoType"],keyof ValueTypes["PatientEmergencyInterface"]>;
		__typename?: boolean
}>;
	["MembershipInfoType"]: AliasType<{
	id?:boolean,
	subscriptionId?:boolean,
	patientId?:boolean,
	status?:boolean,
	startedAt?:boolean,
	activeUntil?:boolean,
	plan?:ValueTypes["PlanInfoType"],
	visits?:ValueTypes["VisitInfoType"],
		__typename?: boolean
}>;
	["PlanInfoType"]: AliasType<{
	id?:boolean,
	name?:boolean,
	baseName?:boolean,
	slug?:boolean,
	type?:boolean,
	packageType?:boolean,
	billed?:boolean,
	monthsOfCare?:boolean,
	doctorVisit?:boolean,
	coachVisit?:boolean,
	description?:boolean,
		__typename?: boolean
}>;
	["VisitInfoType"]: AliasType<{
	id?:boolean,
	name?:boolean,
	icon?:boolean,
	type?:boolean,
	visitType?:boolean,
	amount?:boolean,
	used?:boolean,
		__typename?: boolean
}>;
	["PatientExperienceProgressType"]: AliasType<{
	done?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	id?:boolean,
	name?:boolean,
	slug?:boolean,
	sortOrder?:boolean,
		__typename?: boolean
}>;
	["PatientInsurancesInfoType"]: AliasType<{
	noInsurance?:boolean,
	insurances?:ValueTypes["PatientInsurancesType"],
		__typename?: boolean
}>;
	["PatientInsurancesType"]: AliasType<{
	primary?:ValueTypes["PatientInsuranceInfoType"],
	secondary?:ValueTypes["PatientInsuranceInfoType"],
		__typename?: boolean
}>;
	["PatientInsuranceInfoType"]: AliasType<{
	id?:boolean,
	group?:boolean,
	member?:boolean,
	type?:boolean,
	insuranceCompany?:ValueTypes["InsuranceCompanyInfoType"],
		__typename?: boolean
}>;
	["InsuranceCompanyInfoType"]: AliasType<{
	id?:boolean,
	name?:boolean,
	slug?:boolean,
		__typename?: boolean
}>;
	["ConversationTokenType"]: AliasType<{
	token?:boolean,
	chatIdentity?:boolean,
		__typename?: boolean
}>;
	["CareTeamType"]: AliasType<{
	provider?:ValueTypes["ProviderType"],
	channelSid?:boolean,
		__typename?: boolean
}>;
	["LabTypeConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	items?:ValueTypes["LabType"],
		__typename?: boolean
}>;
	["PageInfo"]: AliasType<{
	hasNextPage?:boolean,
	hasPreviousPage?:boolean,
	currentPage?:boolean,
	perPage?:boolean,
	lastPage?:boolean,
	total?:boolean,
		__typename?: boolean
}>;
	["LabType"]: AliasType<{
	createdAt?:boolean,
	updatedAt?:boolean,
	id?:boolean,
	authorType?:boolean,
	authorId?:boolean,
	name?:boolean,
	path?:boolean,
	deletedAt?:boolean,
		__typename?: boolean
}>;
	["CompanyPlanTypeCompanyDoesNotHaveMemberships"]: AliasType<{		["...on CompanyPlanType"] : ValueTypes["CompanyPlanType"],
		["...on CompanyDoesNotHaveMemberships"] : ValueTypes["CompanyDoesNotHaveMemberships"]
		__typename?: boolean
}>;
	["CompanyPlanType"]: AliasType<{
	id?:boolean,
	name?:boolean,
	baseName?:boolean,
	endsAt?:boolean,
	status?:boolean,
	slug?:boolean,
	type?:boolean,
	packageType?:boolean,
	price?:boolean,
	discount?:boolean,
	billed?:boolean,
	monthsOfCare?:boolean,
	description?:boolean,
	isSinglePaymentOption?:boolean,
	visits?:ValueTypes["VisitType"],
		__typename?: boolean
}>;
	["VisitType"]: AliasType<{
	qualification?:boolean,
	available?:boolean,
	title?:boolean,
		__typename?: boolean
}>;
	["CompanyDoesNotHaveMemberships"]: AliasType<{
	message?:boolean,
		__typename?: boolean
}>;
	["ProvidersFilterType"]: {
	available?:boolean | null,
	qualifications?:number[]
};
	["ProvidersSortType"]: {
	orderBy:ValueTypes["ProviderOrderByEnum"],
	direction:ValueTypes["OrderDirection"]
};
	["ProviderOrderByEnum"]:ProviderOrderByEnum;
	["ProviderSlotFilterType"]: {
	providerId:string
};
	["LibraryMaterialTypeConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	items?:ValueTypes["LibraryMaterialType"],
		__typename?: boolean
}>;
	["LibraryMaterialType"]: AliasType<{
	category?:ValueTypes["LibraryMaterialCategoryType"],
	createdBy?:ValueTypes["ProviderType"],
	updatedBy?:ValueTypes["ProviderType"],
	createdAt?:boolean,
	updatedAt?:boolean,
	id?:boolean,
	title?:boolean,
	description?:boolean,
	type?:boolean,
	content?:boolean,
	currentVersion?:boolean,
	materialId?:boolean,
	status?:boolean,
	deletedAt?:boolean,
		__typename?: boolean
}>;
	["LibraryMaterialCategoryType"]: AliasType<{
	createdAt?:boolean,
	updatedAt?:boolean,
	id?:boolean,
	slug?:boolean,
	title?:boolean,
		__typename?: boolean
}>;
	["LibraryMaterialFilterType"]: {
	categoryIds?:ValueTypes["UUID"][],
	type?:ValueTypes["LibraryMaterialTypes"] | null,
	status?:ValueTypes["LibraryMaterialStatuses"] | null,
	search?:string | null
};
	["LibraryMaterialTypes"]:LibraryMaterialTypes;
	["LibraryMaterialStatuses"]:LibraryMaterialStatuses;
	["GroupedQuestionnairesType"]: AliasType<{
	mentalHealth?:ValueTypes["QuestionnairesType"],
	patientForms?:ValueTypes["QuestionnairesType"],
	patientMedicalHistory?:ValueTypes["QuestionnairesType"],
	preAssessment?:ValueTypes["QuestionnairesType"],
		__typename?: boolean
}>;
	["QuestionnairesType"]: AliasType<{
	count?:boolean,
	done?:boolean,
	label?:boolean,
	items?:ValueTypes["QuestionnaireType"],
		__typename?: boolean
}>;
	["QuestionnaireType"]: AliasType<{
	estimate?:boolean,
	group?:boolean,
	id?:boolean,
	name?:boolean,
	step?:boolean,
	total?:boolean,
	type?:boolean,
	content?:boolean,
		__typename?: boolean
}>;
	["MainMutation"]: AliasType<{
storeAppointment?: [{	data:ValueTypes["StoreAppointmentInput"]},ValueTypes["AppointmentTypeNoAvailableVisitsProviderNotAvailableProviderHasNotQualification"]],
markAppointmentPresence?: [{	appointmentId:number,	patientId:ValueTypes["UUID"]},boolean],
cancelAppointment?: [{	appointmentId:number,	cancelReasonId:number,	cancelText?:string | null,	confirmCancellation?:boolean | null},ValueTypes["IntTypeCannotCancelBefore24HoursAppointmentInThePast"]],
updateProfile?: [{	data:ValueTypes["UpdateProfileInput"]},ValueTypes["PatientInfo"]],
	acceptTermsAndPolicy?:boolean,
subscribeToProgram?: [{	activationCode:string},ValueTypes["MembershipInfoTypeCompanyDoesNotHaveActiveMembershipsCompanyNoCapacityNoCompanyByCodePatientHasMembership"]],
uploadAvatar?: [{	file:ValueTypes["Upload"]},ValueTypes["UploadFileInfoTypeTooLargeFileFileTypeIsNotSupported"]],
updatePatientInsurances?: [{	data:ValueTypes["UpdatePatientInsurancesInput"]},ValueTypes["PatientInsurancesInfoType"]],
updatePassword?: [{	data:ValueTypes["UpdatePasswordInput"]},ValueTypes["UpdatePasswordSamePasswordErrorPasswordUpdateLimitExceededError"]],
confirmForgotPassword?: [{	data:ValueTypes["ConfirmForgotPasswordInput"]},ValueTypes["UpdatePasswordConfirmCodeExpiredError"]],
createLibraryMaterial?: [{	data:ValueTypes["LibraryMaterialInput"]},ValueTypes["LibraryMaterialTypeMaterialDuplicatedError"]],
updateLibraryMaterial?: [{	materialId:ValueTypes["UUID"],	data:ValueTypes["LibraryMaterialUpdateInput"]},ValueTypes["LibraryMaterialTypeMaterialDuplicatedError"]],
deleteLibraryMaterial?: [{	materialId:ValueTypes["UUID"]},boolean],
createLibraryMaterialCategory?: [{	title:string},ValueTypes["LibraryMaterialCategoryType"]],
saveQuestionnaireAnswer?: [{	questionnaireId:number,	answer:ValueTypes["QuestionnaireAnswerInput"]},boolean],
		__typename?: boolean
}>;
	["AppointmentTypeNoAvailableVisitsProviderNotAvailableProviderHasNotQualification"]: AliasType<{		["...on AppointmentType"] : ValueTypes["AppointmentType"],
		["...on NoAvailableVisits"] : ValueTypes["NoAvailableVisits"],
		["...on ProviderNotAvailable"] : ValueTypes["ProviderNotAvailable"],
		["...on ProviderHasNotQualification"] : ValueTypes["ProviderHasNotQualification"]
		__typename?: boolean
}>;
	["NoAvailableVisits"]: AliasType<{
	message?:boolean,
		__typename?: boolean
}>;
	["ProviderNotAvailable"]: AliasType<{
	message?:boolean,
		__typename?: boolean
}>;
	["ProviderHasNotQualification"]: AliasType<{
	message?:boolean,
		__typename?: boolean
}>;
	["StoreAppointmentInput"]: {
	providerId:string,
	datetime:string,
	agreementPolicy:boolean,
	agreementCancel:boolean,
	reason?:string | null,
	qualification?:number | null,
	timezone?:string | null,
	patientId?:string | null
};
	["IntTypeCannotCancelBefore24HoursAppointmentInThePast"]: AliasType<{		["...on IntType"] : ValueTypes["IntType"],
		["...on CannotCancelBefore24Hours"] : ValueTypes["CannotCancelBefore24Hours"],
		["...on AppointmentInThePast"] : ValueTypes["AppointmentInThePast"]
		__typename?: boolean
}>;
	["IntType"]: AliasType<{
	id?:boolean,
		__typename?: boolean
}>;
	["CannotCancelBefore24Hours"]: AliasType<{
	message?:boolean,
		__typename?: boolean
}>;
	["AppointmentInThePast"]: AliasType<{
	message?:boolean,
		__typename?: boolean
}>;
	["UpdateProfileInput"]: {
	noPrimaryProvider?:boolean | null,
	primaryCareProvider?:ValueTypes["PrimaryCareProviderInfoInput"] | null,
	profile?:ValueTypes["PatientProfileInfoInput"] | null,
	contact?:ValueTypes["PatientContactInfoInput"] | null,
	emergency?:ValueTypes["PatientEmergencyInfoInput"] | null
};
	["PrimaryCareProviderInfoInput"]: {
	name?:string | null,
	address?:string | null,
	officeNumber?:string | null
};
	["PatientProfileInfoInput"]: {
	firstName?:string | null,
	lastName?:string | null,
	birthDate?:ValueTypes["Date"] | null,
	avatar?:string | null,
	gender?:number | null
};
	["PatientContactInfoInput"]: {
	phone?:string | null,
	city?:string | null,
	zipCode?:string | null,
	streetAddress1?:string | null,
	streetAddress2?:string | null,
	state?:string | null
};
	["PatientEmergencyInfoInput"]: {
	fullName?:string | null,
	phone?:string | null,
	relationship?:string | null
};
	["MembershipInfoTypeCompanyDoesNotHaveActiveMembershipsCompanyNoCapacityNoCompanyByCodePatientHasMembership"]: AliasType<{		["...on MembershipInfoType"] : ValueTypes["MembershipInfoType"],
		["...on CompanyDoesNotHaveActiveMemberships"] : ValueTypes["CompanyDoesNotHaveActiveMemberships"],
		["...on CompanyNoCapacity"] : ValueTypes["CompanyNoCapacity"],
		["...on NoCompanyByCode"] : ValueTypes["NoCompanyByCode"],
		["...on PatientHasMembership"] : ValueTypes["PatientHasMembership"]
		__typename?: boolean
}>;
	["CompanyDoesNotHaveActiveMemberships"]: AliasType<{
	message?:boolean,
		__typename?: boolean
}>;
	["CompanyNoCapacity"]: AliasType<{
	message?:boolean,
		__typename?: boolean
}>;
	["NoCompanyByCode"]: AliasType<{
	message?:boolean,
		__typename?: boolean
}>;
	["PatientHasMembership"]: AliasType<{
	message?:boolean,
		__typename?: boolean
}>;
	["UploadFileInfoTypeTooLargeFileFileTypeIsNotSupported"]: AliasType<{		["...on UploadFileInfoType"] : ValueTypes["UploadFileInfoType"],
		["...on TooLargeFile"] : ValueTypes["TooLargeFile"],
		["...on FileTypeIsNotSupported"] : ValueTypes["FileTypeIsNotSupported"]
		__typename?: boolean
}>;
	["UploadFileInfoType"]: AliasType<{
	fileName?:boolean,
	originalFileName?:boolean,
	fileType?:boolean,
	id?:boolean,
		__typename?: boolean
}>;
	["TooLargeFile"]: AliasType<{
	message?:boolean,
	originalFileName?:boolean,
		__typename?: boolean
}>;
	["FileTypeIsNotSupported"]: AliasType<{
	message?:boolean,
	originalFileName?:boolean,
		__typename?: boolean
}>;
	["Upload"]:unknown;
	["UpdatePatientInsurancesInput"]: {
	noInsurance:boolean,
	insurances?:ValueTypes["PatientInsurancesInput"] | null
};
	["PatientInsurancesInput"]: {
	primary?:ValueTypes["PatientInsuranceInput"] | null,
	secondary?:ValueTypes["PatientInsuranceInput"] | null
};
	["PatientInsuranceInput"]: {
	group?:string | null,
	member?:string | null,
	insuranceCompany?:string | null
};
	["UpdatePasswordSamePasswordErrorPasswordUpdateLimitExceededError"]: AliasType<{		["...on UpdatePassword"] : ValueTypes["UpdatePassword"],
		["...on SamePasswordError"] : ValueTypes["SamePasswordError"],
		["...on PasswordUpdateLimitExceededError"] : ValueTypes["PasswordUpdateLimitExceededError"]
		__typename?: boolean
}>;
	["UpdatePassword"]: AliasType<{
	email?:boolean,
		__typename?: boolean
}>;
	["SamePasswordError"]: AliasType<{
	message?:boolean,
		__typename?: boolean
}>;
	["PasswordUpdateLimitExceededError"]: AliasType<{
	message?:boolean,
		__typename?: boolean
}>;
	["UpdatePasswordInput"]: {
	accessToken:string,
	oldPassword:string,
	newPassword:string
};
	["UpdatePasswordConfirmCodeExpiredError"]: AliasType<{		["...on UpdatePassword"] : ValueTypes["UpdatePassword"],
		["...on ConfirmCodeExpiredError"] : ValueTypes["ConfirmCodeExpiredError"]
		__typename?: boolean
}>;
	["ConfirmCodeExpiredError"]: AliasType<{
	message?:boolean,
		__typename?: boolean
}>;
	["ConfirmForgotPasswordInput"]: {
	email:string,
	code:string,
	newPassword:string
};
	["LibraryMaterialTypeMaterialDuplicatedError"]: AliasType<{		["...on LibraryMaterialType"] : ValueTypes["LibraryMaterialType"],
		["...on MaterialDuplicatedError"] : ValueTypes["MaterialDuplicatedError"]
		__typename?: boolean
}>;
	["MaterialDuplicatedError"]: AliasType<{
	message?:boolean,
		__typename?: boolean
}>;
	["LibraryMaterialInput"]: {
	title:string,
	description:string,
	type:ValueTypes["LibraryMaterialTypes"],
	categoryId:ValueTypes["UUID"],
	content:string,
	status?:ValueTypes["LibraryMaterialStatuses"] | null
};
	["LibraryMaterialUpdateInput"]: {
	title?:string | null,
	description?:string | null,
	type?:ValueTypes["LibraryMaterialTypes"] | null,
	categoryId?:ValueTypes["UUID"] | null,
	content?:string | null,
	status?:ValueTypes["LibraryMaterialStatuses"] | null
};
	["QuestionnaireAnswerInput"]: {
	answer:ValueTypes["JSON"],
	step:number,
	score?:number | null
}
  }

export type ModelTypes = {
    ["MainQuery"]: {
		getAppointments:ModelTypes["AppointmentType"][],
	getAppointmentVideoToken:ModelTypes["VideoTokenType"],
	getAppointmentChatToken:ModelTypes["ChatTokenType"],
	getChatMembers:ModelTypes["MembersType"],
	getCancelReasons:ModelTypes["CancelReasonType"][],
	states:ModelTypes["StatesInfo"][],
	genders:ModelTypes["GendersInfo"][],
	relationships:ModelTypes["RelationshipsInfo"][],
	me?:ModelTypes["PatientInfo"],
	getMyProgramInfo?:ModelTypes["MembershipInfoType"],
	getPatientExperienceProgress:ModelTypes["PatientExperienceProgressType"][],
	getMyInsurances?:ModelTypes["PatientInsurancesInfoType"],
	getAvailableQualifications:ModelTypes["QualificationType"][],
	getChatToken:ModelTypes["ConversationTokenType"],
	getMyCareTeam:ModelTypes["CareTeamType"][],
	getPatientLabs:ModelTypes["LabTypeConnection"],
	getLabUrl:string,
	getProgramByCoupon?:ModelTypes["CompanyPlanTypeCompanyDoesNotHaveMemberships"],
	getProviders:ModelTypes["ProviderType"][],
	getProviderSlots:string[],
	getLibraryMaterials:ModelTypes["LibraryMaterialTypeConnection"],
	getLibraryMaterial?:ModelTypes["LibraryMaterialType"],
	getLibraryMaterialCategories:ModelTypes["LibraryMaterialCategoryType"][],
	getLibraryMaterialTypes:string[],
	getPatientQuestionnaires:ModelTypes["GroupedQuestionnairesType"],
	getPatientQuestionnaire:ModelTypes["QuestionnaireType"],
	getPatientConsentForm?:ModelTypes["QuestionnaireType"]
};
	["AppointmentType"]: {
		provider?:ModelTypes["ProviderType"],
	patient?:ModelTypes["PatientType"],
	channel?:ModelTypes["ChanelType"],
	createdAt?:ModelTypes["DateTime"],
	updatedAt?:ModelTypes["DateTime"],
	id:number,
	type?:string,
	reason?:string,
	startedAt:ModelTypes["DateTime"],
	timeZone:string,
	rate?:number,
	status:string,
	subStatus?:string,
	rescheduleReason?:string,
	canceledText?:string,
	canceledBy?:string,
	adminCanceled:boolean,
	integrationsMeta:ModelTypes["JSON"],
	deletedAt?:ModelTypes["DateTime"],
	agreementPolicy:boolean,
	agreementCancel:boolean,
	visitId?:number,
	visitType?:string,
	review?:string
};
	["ProviderType"]: {
		profile:ModelTypes["ProfileType"],
	providerInfo?:ModelTypes["ProviderInformationType"],
	qualifications?:ModelTypes["ProviderQualificationType"][],
	createdAt?:ModelTypes["DateTime"],
	updatedAt?:ModelTypes["DateTime"],
	id:string,
	email:string,
	isSuper:boolean,
	isSupport:boolean
};
	["ProfileType"]: {
		avatarUrl?:string,
	gender?:ModelTypes["GenderType"],
	createdAt?:ModelTypes["DateTime"],
	updatedAt?:ModelTypes["DateTime"],
	id:number,
	profileableId:string,
	profileableType:string,
	fName?:string,
	lName?:string,
	birthDate?:ModelTypes["Date"],
	phone?:string,
	city?:string,
	zip?:string,
	address1?:string,
	address2?:string,
	biography?:string,
	avatar?:string,
	deletedAt?:ModelTypes["DateTime"]
};
	["GenderType"]: {
		createdAt?:ModelTypes["DateTime"],
	updatedAt?:ModelTypes["DateTime"],
	id:number,
	name:string,
	slug:string
};
	/** Date with time (isoformat) */
["DateTime"]:any;
	/** Date (isoformat) */
["Date"]:any;
	["ProviderInformationType"]: {
		createdAt?:ModelTypes["DateTime"],
	updatedAt?:ModelTypes["DateTime"],
	id:number,
	education?:string,
	practices?:string,
	experienceYears?:number,
	shortSummary?:string,
	qualificationTitle?:string
};
	["ProviderQualificationType"]: {
		qualification:ModelTypes["QualificationType"],
	id:number
};
	["QualificationType"]: {
		createdAt?:ModelTypes["DateTime"],
	updatedAt?:ModelTypes["DateTime"],
	id:number,
	name:string,
	title:string,
	slug:string,
	type?:string,
	icon?:string,
	cost?:ModelTypes["Decimal"]
};
	/** Decimal (fixed-point) */
["Decimal"]:any;
	["PatientType"]: {
		profile:ModelTypes["ProfileType"],
	createdAt?:ModelTypes["DateTime"],
	updatedAt?:ModelTypes["DateTime"],
	id:string,
	email:string,
	emailVerifiedAt?:ModelTypes["DateTime"],
	medicalRecord?:string,
	experianceDone:boolean,
	trialEndsAt?:ModelTypes["DateTime"],
	termsAgree:boolean,
	noInsurance:boolean,
	firstVisitedAt?:ModelTypes["DateTime"],
	lastVisitedAt?:ModelTypes["DateTime"],
	deletedAt?:ModelTypes["DateTime"],
	noPrimaryProvider:boolean
};
	["ChanelType"]: {
		createdAt?:ModelTypes["DateTime"],
	updatedAt?:ModelTypes["DateTime"],
	id:number,
	channelSid:string,
	channelUniqueName:string,
	channelFrendlyName?:string
};
	/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
["JSON"]:any;
	["AppointmentsFilterType"]: GraphQLTypes["AppointmentsFilterType"];
	["AppointmentStatusEnum"]: GraphQLTypes["AppointmentStatusEnum"];
	["AppointmentTypeEnum"]: GraphQLTypes["AppointmentTypeEnum"];
	["AppointmentsSortType"]: GraphQLTypes["AppointmentsSortType"];
	["AppointmentsOrderByEnum"]: GraphQLTypes["AppointmentsOrderByEnum"];
	["OrderDirection"]: GraphQLTypes["OrderDirection"];
	["VideoTokenType"]: {
		roomName:string,
	roomTitle:string,
	token:string,
	participants:ModelTypes["ChatProviderTypeChatPatientType"][]
};
	["ChatProviderTypeChatPatientType"]:ModelTypes["ChatProviderType"] | ModelTypes["ChatPatientType"];
	["ChatProviderType"]: {
		profile:ModelTypes["ChatProfileType"],
	providerInfo?:ModelTypes["ProviderInformationType"],
	qualifications?:ModelTypes["ProviderQualificationType"][],
	createdAt?:ModelTypes["DateTime"],
	updatedAt?:ModelTypes["DateTime"],
	id:string,
	email:string,
	isSuper:boolean,
	isSupport:boolean,
	chatIdentity?:string
};
	["ChatProfileType"]: {
		avatarUrl?:string,
	fName?:string,
	lName?:string,
	avatar?:string
};
	["ChatPatientType"]: {
		profile:ModelTypes["ChatProfileType"],
	createdAt?:ModelTypes["DateTime"],
	updatedAt?:ModelTypes["DateTime"],
	id:string,
	email:string,
	emailVerifiedAt?:ModelTypes["DateTime"],
	medicalRecord?:string,
	experianceDone:boolean,
	trialEndsAt?:ModelTypes["DateTime"],
	termsAgree:boolean,
	noInsurance:boolean,
	firstVisitedAt?:ModelTypes["DateTime"],
	lastVisitedAt?:ModelTypes["DateTime"],
	deletedAt?:ModelTypes["DateTime"],
	noPrimaryProvider:boolean,
	chatIdentity?:string
};
	["ChatTokenType"]: {
		channelSid:string,
	channelUniqueName:string,
	token:string,
	participants:ModelTypes["ChatProviderTypeChatPatientType"][]
};
	["MembersType"]: {
		members:ModelTypes["ChatProviderTypeChatPatientType"][]
};
	["MemberFilterType"]: GraphQLTypes["MemberFilterType"];
	["ProfileTypes"]: GraphQLTypes["ProfileTypes"];
	["UUID"]:any;
	["CancelReasonType"]: {
		id:number,
	name:string
};
	["StatesInfo"]: {
		value:string,
	label:string
};
	["StateFilter"]: GraphQLTypes["StateFilter"];
	["Countries"]: GraphQLTypes["Countries"];
	["GendersInfo"]: {
		value:number,
	label:string
};
	["RelationshipsInfo"]: {
		value:string,
	label:string
};
	["PatientInfo"]: {
		id:string,
	email:string,
	companyId:number,
	noPrimaryProvider:boolean,
	termsAgree:boolean,
	primaryCareProvider?:ModelTypes["PrimaryCareProviderInfoType"],
	profile?:ModelTypes["PatientProfileInfoType"],
	contact?:ModelTypes["PatientContactInfoType"],
	emergency?:ModelTypes["PatientEmergencyInfoType"]
};
	["PrimaryCareProviderInfoType"]: {
		name?:string,
	address?:string,
	officeNumber?:string
};
	["PrimaryCareProviderInterface"]: ModelTypes["PrimaryCareProviderInfoType"];
	["PatientProfileInfoType"]: {
		firstName?:string,
	lastName?:string,
	birthDate?:ModelTypes["Date"],
	avatar?:string,
	gender?:ModelTypes["GendersInfo"],
	avatarUrl?:string
};
	["PatientProfileInterface"]: ModelTypes["PatientProfileInfoType"];
	["PatientContactInfoType"]: {
		phone?:string,
	city?:string,
	zipCode?:string,
	streetAddress1?:string,
	streetAddress2?:string,
	state?:ModelTypes["StatesInfo"]
};
	["PatientContactInterface"]: ModelTypes["PatientContactInfoType"];
	["PatientEmergencyInfoType"]: {
		fullName?:string,
	phone?:string,
	relationship?:ModelTypes["RelationshipsInfo"]
};
	["PatientEmergencyInterface"]: ModelTypes["PatientEmergencyInfoType"];
	["MembershipInfoType"]: {
		id?:number,
	subscriptionId?:number,
	patientId?:string,
	status?:string,
	startedAt?:ModelTypes["Date"],
	activeUntil?:ModelTypes["Date"],
	plan?:ModelTypes["PlanInfoType"],
	visits?:ModelTypes["VisitInfoType"][]
};
	["PlanInfoType"]: {
		id?:number,
	name?:string,
	baseName?:string,
	slug?:string,
	type?:string,
	packageType?:string,
	billed?:string,
	monthsOfCare?:string,
	doctorVisit?:number,
	coachVisit?:number,
	description?:string
};
	["VisitInfoType"]: {
		id?:number,
	name?:string,
	icon?:string,
	type?:string,
	visitType?:string,
	amount?:number,
	used?:number
};
	["PatientExperienceProgressType"]: {
		done:boolean,
	createdAt?:ModelTypes["DateTime"],
	updatedAt?:ModelTypes["DateTime"],
	id:number,
	name:string,
	slug:string,
	sortOrder?:number
};
	["PatientInsurancesInfoType"]: {
		noInsurance:boolean,
	insurances?:ModelTypes["PatientInsurancesType"]
};
	["PatientInsurancesType"]: {
		primary?:ModelTypes["PatientInsuranceInfoType"],
	secondary?:ModelTypes["PatientInsuranceInfoType"]
};
	["PatientInsuranceInfoType"]: {
		id?:number,
	group?:string,
	member?:string,
	type?:string,
	insuranceCompany?:ModelTypes["InsuranceCompanyInfoType"]
};
	["InsuranceCompanyInfoType"]: {
		id?:number,
	name?:string,
	slug?:string
};
	["ConversationTokenType"]: {
		token:string,
	chatIdentity:string
};
	["CareTeamType"]: {
		provider:ModelTypes["ProviderType"],
	channelSid?:string
};
	["LabTypeConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	items:ModelTypes["LabType"][]
};
	["PageInfo"]: {
		hasNextPage:boolean,
	hasPreviousPage:boolean,
	currentPage:number,
	perPage:number,
	lastPage:number,
	total:number
};
	["LabType"]: {
		createdAt?:ModelTypes["DateTime"],
	updatedAt?:ModelTypes["DateTime"],
	id:number,
	authorType:string,
	authorId:string,
	name?:string,
	path:string,
	deletedAt?:ModelTypes["DateTime"]
};
	["CompanyPlanTypeCompanyDoesNotHaveMemberships"]:ModelTypes["CompanyPlanType"] | ModelTypes["CompanyDoesNotHaveMemberships"];
	["CompanyPlanType"]: {
		id?:number,
	name?:string,
	baseName?:string,
	endsAt?:ModelTypes["Date"],
	status?:string,
	slug?:string,
	type?:string,
	packageType?:string,
	price?:ModelTypes["Decimal"],
	discount?:number,
	billed?:string,
	monthsOfCare?:string,
	description?:string,
	isSinglePaymentOption?:boolean,
	visits?:ModelTypes["VisitType"][]
};
	["VisitType"]: {
		qualification?:string,
	available?:number,
	title?:string
};
	["CompanyDoesNotHaveMemberships"]: {
		message:string
};
	["ProvidersFilterType"]: GraphQLTypes["ProvidersFilterType"];
	["ProvidersSortType"]: GraphQLTypes["ProvidersSortType"];
	["ProviderOrderByEnum"]: GraphQLTypes["ProviderOrderByEnum"];
	["ProviderSlotFilterType"]: GraphQLTypes["ProviderSlotFilterType"];
	["LibraryMaterialTypeConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	items:ModelTypes["LibraryMaterialType"][]
};
	["LibraryMaterialType"]: {
		category:ModelTypes["LibraryMaterialCategoryType"],
	createdBy?:ModelTypes["ProviderType"],
	updatedBy?:ModelTypes["ProviderType"],
	createdAt?:ModelTypes["DateTime"],
	updatedAt?:ModelTypes["DateTime"],
	id:ModelTypes["UUID"],
	title:string,
	description:string,
	type:string,
	content:string,
	currentVersion:boolean,
	materialId:ModelTypes["UUID"],
	status:string,
	deletedAt?:ModelTypes["DateTime"]
};
	["LibraryMaterialCategoryType"]: {
		createdAt?:ModelTypes["DateTime"],
	updatedAt?:ModelTypes["DateTime"],
	id:ModelTypes["UUID"],
	slug:string,
	title:string
};
	["LibraryMaterialFilterType"]: GraphQLTypes["LibraryMaterialFilterType"];
	["LibraryMaterialTypes"]: GraphQLTypes["LibraryMaterialTypes"];
	["LibraryMaterialStatuses"]: GraphQLTypes["LibraryMaterialStatuses"];
	["GroupedQuestionnairesType"]: {
		mentalHealth?:ModelTypes["QuestionnairesType"],
	patientForms?:ModelTypes["QuestionnairesType"],
	patientMedicalHistory?:ModelTypes["QuestionnairesType"],
	preAssessment?:ModelTypes["QuestionnairesType"]
};
	["QuestionnairesType"]: {
		count:number,
	done:number,
	label:string,
	items:ModelTypes["QuestionnaireType"][]
};
	["QuestionnaireType"]: {
		estimate:number,
	group:string,
	id:number,
	name:string,
	step:number,
	total:number,
	type:string,
	content:ModelTypes["JSON"]
};
	["MainMutation"]: {
		storeAppointment:ModelTypes["AppointmentTypeNoAvailableVisitsProviderNotAvailableProviderHasNotQualification"],
	markAppointmentPresence:boolean,
	cancelAppointment:ModelTypes["IntTypeCannotCancelBefore24HoursAppointmentInThePast"],
	updateProfile:ModelTypes["PatientInfo"],
	acceptTermsAndPolicy:boolean,
	subscribeToProgram:ModelTypes["MembershipInfoTypeCompanyDoesNotHaveActiveMembershipsCompanyNoCapacityNoCompanyByCodePatientHasMembership"],
	uploadAvatar:ModelTypes["UploadFileInfoTypeTooLargeFileFileTypeIsNotSupported"],
	updatePatientInsurances:ModelTypes["PatientInsurancesInfoType"],
	updatePassword:ModelTypes["UpdatePasswordSamePasswordErrorPasswordUpdateLimitExceededError"],
	confirmForgotPassword:ModelTypes["UpdatePasswordConfirmCodeExpiredError"],
	createLibraryMaterial:ModelTypes["LibraryMaterialTypeMaterialDuplicatedError"],
	updateLibraryMaterial:ModelTypes["LibraryMaterialTypeMaterialDuplicatedError"],
	deleteLibraryMaterial:boolean,
	createLibraryMaterialCategory:ModelTypes["LibraryMaterialCategoryType"],
	saveQuestionnaireAnswer:boolean
};
	["AppointmentTypeNoAvailableVisitsProviderNotAvailableProviderHasNotQualification"]:ModelTypes["AppointmentType"] | ModelTypes["NoAvailableVisits"] | ModelTypes["ProviderNotAvailable"] | ModelTypes["ProviderHasNotQualification"];
	["NoAvailableVisits"]: {
		message:string
};
	["ProviderNotAvailable"]: {
		message:string
};
	["ProviderHasNotQualification"]: {
		message:string
};
	["StoreAppointmentInput"]: GraphQLTypes["StoreAppointmentInput"];
	["IntTypeCannotCancelBefore24HoursAppointmentInThePast"]:ModelTypes["IntType"] | ModelTypes["CannotCancelBefore24Hours"] | ModelTypes["AppointmentInThePast"];
	["IntType"]: {
		id:number
};
	["CannotCancelBefore24Hours"]: {
		message:string
};
	["AppointmentInThePast"]: {
		message:string
};
	["UpdateProfileInput"]: GraphQLTypes["UpdateProfileInput"];
	["PrimaryCareProviderInfoInput"]: GraphQLTypes["PrimaryCareProviderInfoInput"];
	["PatientProfileInfoInput"]: GraphQLTypes["PatientProfileInfoInput"];
	["PatientContactInfoInput"]: GraphQLTypes["PatientContactInfoInput"];
	["PatientEmergencyInfoInput"]: GraphQLTypes["PatientEmergencyInfoInput"];
	["MembershipInfoTypeCompanyDoesNotHaveActiveMembershipsCompanyNoCapacityNoCompanyByCodePatientHasMembership"]:ModelTypes["MembershipInfoType"] | ModelTypes["CompanyDoesNotHaveActiveMemberships"] | ModelTypes["CompanyNoCapacity"] | ModelTypes["NoCompanyByCode"] | ModelTypes["PatientHasMembership"];
	["CompanyDoesNotHaveActiveMemberships"]: {
		message:string
};
	["CompanyNoCapacity"]: {
		message:string
};
	["NoCompanyByCode"]: {
		message:string
};
	["PatientHasMembership"]: {
		message:string
};
	["UploadFileInfoTypeTooLargeFileFileTypeIsNotSupported"]:ModelTypes["UploadFileInfoType"] | ModelTypes["TooLargeFile"] | ModelTypes["FileTypeIsNotSupported"];
	["UploadFileInfoType"]: {
		fileName?:string,
	originalFileName?:string,
	fileType?:string,
	id?:ModelTypes["UUID"]
};
	["TooLargeFile"]: {
		message:string,
	originalFileName?:string
};
	["FileTypeIsNotSupported"]: {
		message:string,
	originalFileName?:string
};
	["Upload"]:any;
	["UpdatePatientInsurancesInput"]: GraphQLTypes["UpdatePatientInsurancesInput"];
	["PatientInsurancesInput"]: GraphQLTypes["PatientInsurancesInput"];
	["PatientInsuranceInput"]: GraphQLTypes["PatientInsuranceInput"];
	["UpdatePasswordSamePasswordErrorPasswordUpdateLimitExceededError"]:ModelTypes["UpdatePassword"] | ModelTypes["SamePasswordError"] | ModelTypes["PasswordUpdateLimitExceededError"];
	["UpdatePassword"]: {
		email:string
};
	["SamePasswordError"]: {
		message:string
};
	["PasswordUpdateLimitExceededError"]: {
		message:string
};
	["UpdatePasswordInput"]: GraphQLTypes["UpdatePasswordInput"];
	["UpdatePasswordConfirmCodeExpiredError"]:ModelTypes["UpdatePassword"] | ModelTypes["ConfirmCodeExpiredError"];
	["ConfirmCodeExpiredError"]: {
		message:string
};
	["ConfirmForgotPasswordInput"]: GraphQLTypes["ConfirmForgotPasswordInput"];
	["LibraryMaterialTypeMaterialDuplicatedError"]:ModelTypes["LibraryMaterialType"] | ModelTypes["MaterialDuplicatedError"];
	["MaterialDuplicatedError"]: {
		message:string
};
	["LibraryMaterialInput"]: GraphQLTypes["LibraryMaterialInput"];
	["LibraryMaterialUpdateInput"]: GraphQLTypes["LibraryMaterialUpdateInput"];
	["QuestionnaireAnswerInput"]: GraphQLTypes["QuestionnaireAnswerInput"]
    }

export type GraphQLTypes = {
    ["MainQuery"]: {
	__typename: "MainQuery",
	getAppointments: Array<GraphQLTypes["AppointmentType"]>,
	getAppointmentVideoToken: GraphQLTypes["VideoTokenType"],
	getAppointmentChatToken: GraphQLTypes["ChatTokenType"],
	getChatMembers: GraphQLTypes["MembersType"],
	getCancelReasons: Array<GraphQLTypes["CancelReasonType"]>,
	states: Array<GraphQLTypes["StatesInfo"]>,
	genders: Array<GraphQLTypes["GendersInfo"]>,
	relationships: Array<GraphQLTypes["RelationshipsInfo"]>,
	me?: GraphQLTypes["PatientInfo"],
	getMyProgramInfo?: GraphQLTypes["MembershipInfoType"],
	getPatientExperienceProgress: Array<GraphQLTypes["PatientExperienceProgressType"]>,
	getMyInsurances?: GraphQLTypes["PatientInsurancesInfoType"],
	getAvailableQualifications: Array<GraphQLTypes["QualificationType"]>,
	getChatToken: GraphQLTypes["ConversationTokenType"],
	getMyCareTeam: Array<GraphQLTypes["CareTeamType"]>,
	getPatientLabs: GraphQLTypes["LabTypeConnection"],
	getLabUrl: string,
	getProgramByCoupon?: GraphQLTypes["CompanyPlanTypeCompanyDoesNotHaveMemberships"],
	getProviders: Array<GraphQLTypes["ProviderType"]>,
	getProviderSlots: Array<string>,
	getLibraryMaterials: GraphQLTypes["LibraryMaterialTypeConnection"],
	getLibraryMaterial?: GraphQLTypes["LibraryMaterialType"],
	getLibraryMaterialCategories: Array<GraphQLTypes["LibraryMaterialCategoryType"]>,
	getLibraryMaterialTypes: Array<string>,
	getPatientQuestionnaires: GraphQLTypes["GroupedQuestionnairesType"],
	getPatientQuestionnaire: GraphQLTypes["QuestionnaireType"],
	getPatientConsentForm?: GraphQLTypes["QuestionnaireType"]
};
	["AppointmentType"]: {
	__typename: "AppointmentType",
	provider?: GraphQLTypes["ProviderType"],
	patient?: GraphQLTypes["PatientType"],
	channel?: GraphQLTypes["ChanelType"],
	createdAt?: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"],
	id: number,
	type?: string,
	reason?: string,
	startedAt: GraphQLTypes["DateTime"],
	timeZone: string,
	rate?: number,
	status: string,
	subStatus?: string,
	rescheduleReason?: string,
	canceledText?: string,
	canceledBy?: string,
	adminCanceled: boolean,
	integrationsMeta: GraphQLTypes["JSON"],
	deletedAt?: GraphQLTypes["DateTime"],
	agreementPolicy: boolean,
	agreementCancel: boolean,
	visitId?: number,
	visitType?: string,
	review?: string
};
	["ProviderType"]: {
	__typename: "ProviderType",
	profile: GraphQLTypes["ProfileType"],
	providerInfo?: GraphQLTypes["ProviderInformationType"],
	qualifications?: Array<GraphQLTypes["ProviderQualificationType"]>,
	createdAt?: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"],
	id: string,
	email: string,
	isSuper: boolean,
	isSupport: boolean
};
	["ProfileType"]: {
	__typename: "ProfileType",
	avatarUrl?: string,
	gender?: GraphQLTypes["GenderType"],
	createdAt?: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"],
	id: number,
	profileableId: string,
	profileableType: string,
	fName?: string,
	lName?: string,
	birthDate?: GraphQLTypes["Date"],
	phone?: string,
	city?: string,
	zip?: string,
	address1?: string,
	address2?: string,
	biography?: string,
	avatar?: string,
	deletedAt?: GraphQLTypes["DateTime"]
};
	["GenderType"]: {
	__typename: "GenderType",
	createdAt?: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"],
	id: number,
	name: string,
	slug: string
};
	/** Date with time (isoformat) */
["DateTime"]:any;
	/** Date (isoformat) */
["Date"]:any;
	["ProviderInformationType"]: {
	__typename: "ProviderInformationType",
	createdAt?: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"],
	id: number,
	education?: string,
	practices?: string,
	experienceYears?: number,
	shortSummary?: string,
	qualificationTitle?: string
};
	["ProviderQualificationType"]: {
	__typename: "ProviderQualificationType",
	qualification: GraphQLTypes["QualificationType"],
	id: number
};
	["QualificationType"]: {
	__typename: "QualificationType",
	createdAt?: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"],
	id: number,
	name: string,
	title: string,
	slug: string,
	type?: string,
	icon?: string,
	cost?: GraphQLTypes["Decimal"]
};
	/** Decimal (fixed-point) */
["Decimal"]:any;
	["PatientType"]: {
	__typename: "PatientType",
	profile: GraphQLTypes["ProfileType"],
	createdAt?: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"],
	id: string,
	email: string,
	emailVerifiedAt?: GraphQLTypes["DateTime"],
	medicalRecord?: string,
	experianceDone: boolean,
	trialEndsAt?: GraphQLTypes["DateTime"],
	termsAgree: boolean,
	noInsurance: boolean,
	firstVisitedAt?: GraphQLTypes["DateTime"],
	lastVisitedAt?: GraphQLTypes["DateTime"],
	deletedAt?: GraphQLTypes["DateTime"],
	noPrimaryProvider: boolean
};
	["ChanelType"]: {
	__typename: "ChanelType",
	createdAt?: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"],
	id: number,
	channelSid: string,
	channelUniqueName: string,
	channelFrendlyName?: string
};
	/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
["JSON"]:any;
	["AppointmentsFilterType"]: {
		status?: Array<GraphQLTypes["AppointmentStatusEnum"]>,
	type?: GraphQLTypes["AppointmentTypeEnum"]
};
	["AppointmentStatusEnum"]: AppointmentStatusEnum;
	["AppointmentTypeEnum"]: AppointmentTypeEnum;
	["AppointmentsSortType"]: {
		orderBy: GraphQLTypes["AppointmentsOrderByEnum"],
	direction: GraphQLTypes["OrderDirection"]
};
	["AppointmentsOrderByEnum"]: AppointmentsOrderByEnum;
	["OrderDirection"]: OrderDirection;
	["VideoTokenType"]: {
	__typename: "VideoTokenType",
	roomName: string,
	roomTitle: string,
	token: string,
	participants: Array<GraphQLTypes["ChatProviderTypeChatPatientType"]>
};
	["ChatProviderTypeChatPatientType"]:{
	__typename:"ChatProviderType" | "ChatPatientType"
	['...on ChatProviderType']: '__union' & GraphQLTypes["ChatProviderType"];
	['...on ChatPatientType']: '__union' & GraphQLTypes["ChatPatientType"];
};
	["ChatProviderType"]: {
	__typename: "ChatProviderType",
	profile: GraphQLTypes["ChatProfileType"],
	providerInfo?: GraphQLTypes["ProviderInformationType"],
	qualifications?: Array<GraphQLTypes["ProviderQualificationType"]>,
	createdAt?: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"],
	id: string,
	email: string,
	isSuper: boolean,
	isSupport: boolean,
	chatIdentity?: string
};
	["ChatProfileType"]: {
	__typename: "ChatProfileType",
	avatarUrl?: string,
	fName?: string,
	lName?: string,
	avatar?: string
};
	["ChatPatientType"]: {
	__typename: "ChatPatientType",
	profile: GraphQLTypes["ChatProfileType"],
	createdAt?: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"],
	id: string,
	email: string,
	emailVerifiedAt?: GraphQLTypes["DateTime"],
	medicalRecord?: string,
	experianceDone: boolean,
	trialEndsAt?: GraphQLTypes["DateTime"],
	termsAgree: boolean,
	noInsurance: boolean,
	firstVisitedAt?: GraphQLTypes["DateTime"],
	lastVisitedAt?: GraphQLTypes["DateTime"],
	deletedAt?: GraphQLTypes["DateTime"],
	noPrimaryProvider: boolean,
	chatIdentity?: string
};
	["ChatTokenType"]: {
	__typename: "ChatTokenType",
	channelSid: string,
	channelUniqueName: string,
	token: string,
	participants: Array<GraphQLTypes["ChatProviderTypeChatPatientType"]>
};
	["MembersType"]: {
	__typename: "MembersType",
	members: Array<GraphQLTypes["ChatProviderTypeChatPatientType"]>
};
	["MemberFilterType"]: {
		type: GraphQLTypes["ProfileTypes"],
	id: GraphQLTypes["UUID"]
};
	["ProfileTypes"]: ProfileTypes;
	["UUID"]:any;
	["CancelReasonType"]: {
	__typename: "CancelReasonType",
	id: number,
	name: string
};
	["StatesInfo"]: {
	__typename: "StatesInfo",
	value: string,
	label: string
};
	["StateFilter"]: {
		country?: GraphQLTypes["Countries"]
};
	["Countries"]: Countries;
	["GendersInfo"]: {
	__typename: "GendersInfo",
	value: number,
	label: string
};
	["RelationshipsInfo"]: {
	__typename: "RelationshipsInfo",
	value: string,
	label: string
};
	["PatientInfo"]: {
	__typename: "PatientInfo",
	id: string,
	email: string,
	companyId: number,
	noPrimaryProvider: boolean,
	termsAgree: boolean,
	primaryCareProvider?: GraphQLTypes["PrimaryCareProviderInfoType"],
	profile?: GraphQLTypes["PatientProfileInfoType"],
	contact?: GraphQLTypes["PatientContactInfoType"],
	emergency?: GraphQLTypes["PatientEmergencyInfoType"]
};
	["PrimaryCareProviderInfoType"]: {
	__typename: "PrimaryCareProviderInfoType",
	name?: string,
	address?: string,
	officeNumber?: string
};
	["PrimaryCareProviderInterface"]: {
	__typename:"PrimaryCareProviderInfoType",
	name?: string,
	address?: string,
	officeNumber?: string
	['...on PrimaryCareProviderInfoType']: '__union' & GraphQLTypes["PrimaryCareProviderInfoType"];
};
	["PatientProfileInfoType"]: {
	__typename: "PatientProfileInfoType",
	firstName?: string,
	lastName?: string,
	birthDate?: GraphQLTypes["Date"],
	avatar?: string,
	gender?: GraphQLTypes["GendersInfo"],
	avatarUrl?: string
};
	["PatientProfileInterface"]: {
	__typename:"PatientProfileInfoType",
	firstName?: string,
	lastName?: string,
	birthDate?: GraphQLTypes["Date"],
	avatar?: string
	['...on PatientProfileInfoType']: '__union' & GraphQLTypes["PatientProfileInfoType"];
};
	["PatientContactInfoType"]: {
	__typename: "PatientContactInfoType",
	phone?: string,
	city?: string,
	zipCode?: string,
	streetAddress1?: string,
	streetAddress2?: string,
	state?: GraphQLTypes["StatesInfo"]
};
	["PatientContactInterface"]: {
	__typename:"PatientContactInfoType",
	phone?: string,
	city?: string,
	zipCode?: string,
	streetAddress1?: string,
	streetAddress2?: string
	['...on PatientContactInfoType']: '__union' & GraphQLTypes["PatientContactInfoType"];
};
	["PatientEmergencyInfoType"]: {
	__typename: "PatientEmergencyInfoType",
	fullName?: string,
	phone?: string,
	relationship?: GraphQLTypes["RelationshipsInfo"]
};
	["PatientEmergencyInterface"]: {
	__typename:"PatientEmergencyInfoType",
	fullName?: string,
	phone?: string
	['...on PatientEmergencyInfoType']: '__union' & GraphQLTypes["PatientEmergencyInfoType"];
};
	["MembershipInfoType"]: {
	__typename: "MembershipInfoType",
	id?: number,
	subscriptionId?: number,
	patientId?: string,
	status?: string,
	startedAt?: GraphQLTypes["Date"],
	activeUntil?: GraphQLTypes["Date"],
	plan?: GraphQLTypes["PlanInfoType"],
	visits?: Array<GraphQLTypes["VisitInfoType"]>
};
	["PlanInfoType"]: {
	__typename: "PlanInfoType",
	id?: number,
	name?: string,
	baseName?: string,
	slug?: string,
	type?: string,
	packageType?: string,
	billed?: string,
	monthsOfCare?: string,
	doctorVisit?: number,
	coachVisit?: number,
	description?: string
};
	["VisitInfoType"]: {
	__typename: "VisitInfoType",
	id?: number,
	name?: string,
	icon?: string,
	type?: string,
	visitType?: string,
	amount?: number,
	used?: number
};
	["PatientExperienceProgressType"]: {
	__typename: "PatientExperienceProgressType",
	done: boolean,
	createdAt?: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"],
	id: number,
	name: string,
	slug: string,
	sortOrder?: number
};
	["PatientInsurancesInfoType"]: {
	__typename: "PatientInsurancesInfoType",
	noInsurance: boolean,
	insurances?: GraphQLTypes["PatientInsurancesType"]
};
	["PatientInsurancesType"]: {
	__typename: "PatientInsurancesType",
	primary?: GraphQLTypes["PatientInsuranceInfoType"],
	secondary?: GraphQLTypes["PatientInsuranceInfoType"]
};
	["PatientInsuranceInfoType"]: {
	__typename: "PatientInsuranceInfoType",
	id?: number,
	group?: string,
	member?: string,
	type?: string,
	insuranceCompany?: GraphQLTypes["InsuranceCompanyInfoType"]
};
	["InsuranceCompanyInfoType"]: {
	__typename: "InsuranceCompanyInfoType",
	id?: number,
	name?: string,
	slug?: string
};
	["ConversationTokenType"]: {
	__typename: "ConversationTokenType",
	token: string,
	chatIdentity: string
};
	["CareTeamType"]: {
	__typename: "CareTeamType",
	provider: GraphQLTypes["ProviderType"],
	channelSid?: string
};
	["LabTypeConnection"]: {
	__typename: "LabTypeConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	items: Array<GraphQLTypes["LabType"]>
};
	["PageInfo"]: {
	__typename: "PageInfo",
	hasNextPage: boolean,
	hasPreviousPage: boolean,
	currentPage: number,
	perPage: number,
	lastPage: number,
	total: number
};
	["LabType"]: {
	__typename: "LabType",
	createdAt?: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"],
	id: number,
	authorType: string,
	authorId: string,
	name?: string,
	path: string,
	deletedAt?: GraphQLTypes["DateTime"]
};
	["CompanyPlanTypeCompanyDoesNotHaveMemberships"]:{
	__typename:"CompanyPlanType" | "CompanyDoesNotHaveMemberships"
	['...on CompanyPlanType']: '__union' & GraphQLTypes["CompanyPlanType"];
	['...on CompanyDoesNotHaveMemberships']: '__union' & GraphQLTypes["CompanyDoesNotHaveMemberships"];
};
	["CompanyPlanType"]: {
	__typename: "CompanyPlanType",
	id?: number,
	name?: string,
	baseName?: string,
	endsAt?: GraphQLTypes["Date"],
	status?: string,
	slug?: string,
	type?: string,
	packageType?: string,
	price?: GraphQLTypes["Decimal"],
	discount?: number,
	billed?: string,
	monthsOfCare?: string,
	description?: string,
	isSinglePaymentOption?: boolean,
	visits?: Array<GraphQLTypes["VisitType"]>
};
	["VisitType"]: {
	__typename: "VisitType",
	qualification?: string,
	available?: number,
	title?: string
};
	["CompanyDoesNotHaveMemberships"]: {
	__typename: "CompanyDoesNotHaveMemberships",
	message: string
};
	["ProvidersFilterType"]: {
		available?: boolean,
	qualifications?: Array<number>
};
	["ProvidersSortType"]: {
		orderBy: GraphQLTypes["ProviderOrderByEnum"],
	direction: GraphQLTypes["OrderDirection"]
};
	["ProviderOrderByEnum"]: ProviderOrderByEnum;
	["ProviderSlotFilterType"]: {
		providerId: string
};
	["LibraryMaterialTypeConnection"]: {
	__typename: "LibraryMaterialTypeConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	items: Array<GraphQLTypes["LibraryMaterialType"]>
};
	["LibraryMaterialType"]: {
	__typename: "LibraryMaterialType",
	category: GraphQLTypes["LibraryMaterialCategoryType"],
	createdBy?: GraphQLTypes["ProviderType"],
	updatedBy?: GraphQLTypes["ProviderType"],
	createdAt?: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"],
	id: GraphQLTypes["UUID"],
	title: string,
	description: string,
	type: string,
	content: string,
	currentVersion: boolean,
	materialId: GraphQLTypes["UUID"],
	status: string,
	deletedAt?: GraphQLTypes["DateTime"]
};
	["LibraryMaterialCategoryType"]: {
	__typename: "LibraryMaterialCategoryType",
	createdAt?: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"],
	id: GraphQLTypes["UUID"],
	slug: string,
	title: string
};
	["LibraryMaterialFilterType"]: {
		categoryIds?: Array<GraphQLTypes["UUID"]>,
	type?: GraphQLTypes["LibraryMaterialTypes"],
	status?: GraphQLTypes["LibraryMaterialStatuses"],
	search?: string
};
	["LibraryMaterialTypes"]: LibraryMaterialTypes;
	["LibraryMaterialStatuses"]: LibraryMaterialStatuses;
	["GroupedQuestionnairesType"]: {
	__typename: "GroupedQuestionnairesType",
	mentalHealth?: GraphQLTypes["QuestionnairesType"],
	patientForms?: GraphQLTypes["QuestionnairesType"],
	patientMedicalHistory?: GraphQLTypes["QuestionnairesType"],
	preAssessment?: GraphQLTypes["QuestionnairesType"]
};
	["QuestionnairesType"]: {
	__typename: "QuestionnairesType",
	count: number,
	done: number,
	label: string,
	items: Array<GraphQLTypes["QuestionnaireType"]>
};
	["QuestionnaireType"]: {
	__typename: "QuestionnaireType",
	estimate: number,
	group: string,
	id: number,
	name: string,
	step: number,
	total: number,
	type: string,
	content: GraphQLTypes["JSON"]
};
	["MainMutation"]: {
	__typename: "MainMutation",
	storeAppointment: GraphQLTypes["AppointmentTypeNoAvailableVisitsProviderNotAvailableProviderHasNotQualification"],
	markAppointmentPresence: boolean,
	cancelAppointment: GraphQLTypes["IntTypeCannotCancelBefore24HoursAppointmentInThePast"],
	updateProfile: GraphQLTypes["PatientInfo"],
	acceptTermsAndPolicy: boolean,
	subscribeToProgram: GraphQLTypes["MembershipInfoTypeCompanyDoesNotHaveActiveMembershipsCompanyNoCapacityNoCompanyByCodePatientHasMembership"],
	uploadAvatar: GraphQLTypes["UploadFileInfoTypeTooLargeFileFileTypeIsNotSupported"],
	updatePatientInsurances: GraphQLTypes["PatientInsurancesInfoType"],
	updatePassword: GraphQLTypes["UpdatePasswordSamePasswordErrorPasswordUpdateLimitExceededError"],
	confirmForgotPassword: GraphQLTypes["UpdatePasswordConfirmCodeExpiredError"],
	createLibraryMaterial: GraphQLTypes["LibraryMaterialTypeMaterialDuplicatedError"],
	updateLibraryMaterial: GraphQLTypes["LibraryMaterialTypeMaterialDuplicatedError"],
	deleteLibraryMaterial: boolean,
	createLibraryMaterialCategory: GraphQLTypes["LibraryMaterialCategoryType"],
	saveQuestionnaireAnswer: boolean
};
	["AppointmentTypeNoAvailableVisitsProviderNotAvailableProviderHasNotQualification"]:{
	__typename:"AppointmentType" | "NoAvailableVisits" | "ProviderNotAvailable" | "ProviderHasNotQualification"
	['...on AppointmentType']: '__union' & GraphQLTypes["AppointmentType"];
	['...on NoAvailableVisits']: '__union' & GraphQLTypes["NoAvailableVisits"];
	['...on ProviderNotAvailable']: '__union' & GraphQLTypes["ProviderNotAvailable"];
	['...on ProviderHasNotQualification']: '__union' & GraphQLTypes["ProviderHasNotQualification"];
};
	["NoAvailableVisits"]: {
	__typename: "NoAvailableVisits",
	message: string
};
	["ProviderNotAvailable"]: {
	__typename: "ProviderNotAvailable",
	message: string
};
	["ProviderHasNotQualification"]: {
	__typename: "ProviderHasNotQualification",
	message: string
};
	["StoreAppointmentInput"]: {
		providerId: string,
	datetime: string,
	agreementPolicy: boolean,
	agreementCancel: boolean,
	reason?: string,
	qualification?: number,
	timezone?: string,
	patientId?: string
};
	["IntTypeCannotCancelBefore24HoursAppointmentInThePast"]:{
	__typename:"IntType" | "CannotCancelBefore24Hours" | "AppointmentInThePast"
	['...on IntType']: '__union' & GraphQLTypes["IntType"];
	['...on CannotCancelBefore24Hours']: '__union' & GraphQLTypes["CannotCancelBefore24Hours"];
	['...on AppointmentInThePast']: '__union' & GraphQLTypes["AppointmentInThePast"];
};
	["IntType"]: {
	__typename: "IntType",
	id: number
};
	["CannotCancelBefore24Hours"]: {
	__typename: "CannotCancelBefore24Hours",
	message: string
};
	["AppointmentInThePast"]: {
	__typename: "AppointmentInThePast",
	message: string
};
	["UpdateProfileInput"]: {
		noPrimaryProvider?: boolean,
	primaryCareProvider?: GraphQLTypes["PrimaryCareProviderInfoInput"],
	profile?: GraphQLTypes["PatientProfileInfoInput"],
	contact?: GraphQLTypes["PatientContactInfoInput"],
	emergency?: GraphQLTypes["PatientEmergencyInfoInput"]
};
	["PrimaryCareProviderInfoInput"]: {
		name?: string,
	address?: string,
	officeNumber?: string
};
	["PatientProfileInfoInput"]: {
		firstName?: string,
	lastName?: string,
	birthDate?: GraphQLTypes["Date"],
	avatar?: string,
	gender?: number
};
	["PatientContactInfoInput"]: {
		phone?: string,
	city?: string,
	zipCode?: string,
	streetAddress1?: string,
	streetAddress2?: string,
	state?: string
};
	["PatientEmergencyInfoInput"]: {
		fullName?: string,
	phone?: string,
	relationship?: string
};
	["MembershipInfoTypeCompanyDoesNotHaveActiveMembershipsCompanyNoCapacityNoCompanyByCodePatientHasMembership"]:{
	__typename:"MembershipInfoType" | "CompanyDoesNotHaveActiveMemberships" | "CompanyNoCapacity" | "NoCompanyByCode" | "PatientHasMembership"
	['...on MembershipInfoType']: '__union' & GraphQLTypes["MembershipInfoType"];
	['...on CompanyDoesNotHaveActiveMemberships']: '__union' & GraphQLTypes["CompanyDoesNotHaveActiveMemberships"];
	['...on CompanyNoCapacity']: '__union' & GraphQLTypes["CompanyNoCapacity"];
	['...on NoCompanyByCode']: '__union' & GraphQLTypes["NoCompanyByCode"];
	['...on PatientHasMembership']: '__union' & GraphQLTypes["PatientHasMembership"];
};
	["CompanyDoesNotHaveActiveMemberships"]: {
	__typename: "CompanyDoesNotHaveActiveMemberships",
	message: string
};
	["CompanyNoCapacity"]: {
	__typename: "CompanyNoCapacity",
	message: string
};
	["NoCompanyByCode"]: {
	__typename: "NoCompanyByCode",
	message: string
};
	["PatientHasMembership"]: {
	__typename: "PatientHasMembership",
	message: string
};
	["UploadFileInfoTypeTooLargeFileFileTypeIsNotSupported"]:{
	__typename:"UploadFileInfoType" | "TooLargeFile" | "FileTypeIsNotSupported"
	['...on UploadFileInfoType']: '__union' & GraphQLTypes["UploadFileInfoType"];
	['...on TooLargeFile']: '__union' & GraphQLTypes["TooLargeFile"];
	['...on FileTypeIsNotSupported']: '__union' & GraphQLTypes["FileTypeIsNotSupported"];
};
	["UploadFileInfoType"]: {
	__typename: "UploadFileInfoType",
	fileName?: string,
	originalFileName?: string,
	fileType?: string,
	id?: GraphQLTypes["UUID"]
};
	["TooLargeFile"]: {
	__typename: "TooLargeFile",
	message: string,
	originalFileName?: string
};
	["FileTypeIsNotSupported"]: {
	__typename: "FileTypeIsNotSupported",
	message: string,
	originalFileName?: string
};
	["Upload"]:any;
	["UpdatePatientInsurancesInput"]: {
		noInsurance: boolean,
	insurances?: GraphQLTypes["PatientInsurancesInput"]
};
	["PatientInsurancesInput"]: {
		primary?: GraphQLTypes["PatientInsuranceInput"],
	secondary?: GraphQLTypes["PatientInsuranceInput"]
};
	["PatientInsuranceInput"]: {
		group?: string,
	member?: string,
	insuranceCompany?: string
};
	["UpdatePasswordSamePasswordErrorPasswordUpdateLimitExceededError"]:{
	__typename:"UpdatePassword" | "SamePasswordError" | "PasswordUpdateLimitExceededError"
	['...on UpdatePassword']: '__union' & GraphQLTypes["UpdatePassword"];
	['...on SamePasswordError']: '__union' & GraphQLTypes["SamePasswordError"];
	['...on PasswordUpdateLimitExceededError']: '__union' & GraphQLTypes["PasswordUpdateLimitExceededError"];
};
	["UpdatePassword"]: {
	__typename: "UpdatePassword",
	email: string
};
	["SamePasswordError"]: {
	__typename: "SamePasswordError",
	message: string
};
	["PasswordUpdateLimitExceededError"]: {
	__typename: "PasswordUpdateLimitExceededError",
	message: string
};
	["UpdatePasswordInput"]: {
		accessToken: string,
	oldPassword: string,
	newPassword: string
};
	["UpdatePasswordConfirmCodeExpiredError"]:{
	__typename:"UpdatePassword" | "ConfirmCodeExpiredError"
	['...on UpdatePassword']: '__union' & GraphQLTypes["UpdatePassword"];
	['...on ConfirmCodeExpiredError']: '__union' & GraphQLTypes["ConfirmCodeExpiredError"];
};
	["ConfirmCodeExpiredError"]: {
	__typename: "ConfirmCodeExpiredError",
	message: string
};
	["ConfirmForgotPasswordInput"]: {
		email: string,
	code: string,
	newPassword: string
};
	["LibraryMaterialTypeMaterialDuplicatedError"]:{
	__typename:"LibraryMaterialType" | "MaterialDuplicatedError"
	['...on LibraryMaterialType']: '__union' & GraphQLTypes["LibraryMaterialType"];
	['...on MaterialDuplicatedError']: '__union' & GraphQLTypes["MaterialDuplicatedError"];
};
	["MaterialDuplicatedError"]: {
	__typename: "MaterialDuplicatedError",
	message: string
};
	["LibraryMaterialInput"]: {
		title: string,
	description: string,
	type: GraphQLTypes["LibraryMaterialTypes"],
	categoryId: GraphQLTypes["UUID"],
	content: string,
	status?: GraphQLTypes["LibraryMaterialStatuses"]
};
	["LibraryMaterialUpdateInput"]: {
		title?: string,
	description?: string,
	type?: GraphQLTypes["LibraryMaterialTypes"],
	categoryId?: GraphQLTypes["UUID"],
	content?: string,
	status?: GraphQLTypes["LibraryMaterialStatuses"]
};
	["QuestionnaireAnswerInput"]: {
		answer: GraphQLTypes["JSON"],
	step: number,
	score?: number
}
    }
export const enum AppointmentStatusEnum {
	CANCELLED = "CANCELLED",
	COMPLETED = "COMPLETED",
	PLANED = "PLANED",
	EXPIRED = "EXPIRED",
	REFUNDED = "REFUNDED",
	IN_PROGRESS = "IN_PROGRESS"
}
export const enum AppointmentTypeEnum {
	UPCOMING = "UPCOMING",
	HISTORY = "HISTORY"
}
export const enum AppointmentsOrderByEnum {
	STARTED_AT = "STARTED_AT"
}
export const enum OrderDirection {
	DESC = "DESC",
	ASC = "ASC"
}
export const enum ProfileTypes {
	PATIENT = "PATIENT",
	PROVIDER = "PROVIDER",
	ADMIN = "ADMIN"
}
export const enum Countries {
	USA = "USA",
	UAE = "UAE"
}
export const enum ProviderOrderByEnum {
	STARTED_AT = "STARTED_AT",
	NAME = "NAME"
}
export const enum LibraryMaterialTypes {
	guides = "guides"
}
export const enum LibraryMaterialStatuses {
	draft = "draft",
	published = "published"
}
export class GraphQLError extends Error {
    constructor(public response: GraphQLResponse) {
      super("");
      console.error(response);
    }
    toString() {
      return "GraphQL Response Error";
    }
  }


export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<
  UnwrapPromise<ReturnType<T>>
>;
export type ZeusHook<
  T extends (
    ...args: any[]
  ) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>
> = ZeusState<ReturnType<T>[N]>;

type WithTypeNameValue<T> = T & {
  __typename?: boolean;
};
type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};
export interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}
type DeepAnify<T> = {
  [P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
type IsArray<T, U> = T extends Array<infer R> ? InputType<R, U>[] : InputType<T, U>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;

type IsInterfaced<SRC extends DeepAnify<DST>, DST> = FlattenArray<SRC> extends ZEUS_INTERFACES | ZEUS_UNIONS
  ? {
      [P in keyof SRC]: SRC[P] extends '__union' & infer R
        ? P extends keyof DST
          ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P]>
          : {}
        : never;
    }[keyof DST] &
      {
        [P in keyof Omit<
          Pick<
            SRC,
            {
              [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P;
            }[keyof DST]
          >,
          '__typename'
        >]: IsPayLoad<DST[P]> extends boolean ? SRC[P] : IsArray<SRC[P], DST[P]>;
      }
  : {
      [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends boolean ? SRC[P] : IsArray<SRC[P], DST[P]>;
    };

export type MapType<SRC, DST> = SRC extends DeepAnify<DST> ? IsInterfaced<SRC, DST> : never;
export type InputType<SRC, DST> = IsPayLoad<DST> extends { __alias: infer R }
  ? {
      [P in keyof R]: MapType<SRC, R[P]>;
    } &
      MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>>
  : MapType<SRC, IsPayLoad<DST>>;
type Func<P extends any[], R> = (...args: P) => R;
type AnyFunc = Func<any, any>;
export type ArgsType<F extends AnyFunc> = F extends Func<infer P, any> ? P : never;
export type OperationOptions = {
  variables?: Record<string, any>;
  operationName?: string;
};
export type SubscriptionToGraphQL<Z, T> = {
  ws: WebSocket;
  on: (fn: (args: InputType<T, Z>) => void) => void;
  off: (fn: (e: { data?: InputType<T, Z>; code?: number; reason?: string; message?: string }) => void) => void;
  error: (fn: (e: { data?: InputType<T, Z>; errors?: string[] }) => void) => void;
  open: () => void;
};
export type SelectionFunction<V> = <T>(t: T | V) => T;
export type fetchOptions = ArgsType<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (
  ...args: infer R
) => WebSocket
  ? R
  : never;
export type chainOptions =
  | [fetchOptions[0], fetchOptions[1] & {websocket?: websocketOptions}]
  | [fetchOptions[0]];
export type FetchFunction = (
  query: string,
  variables?: Record<string, any>,
) => Promise<any>;
export type SubscriptionFunction = (query: string) => any;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;



export const ZeusSelect = <T>() => ((t: any) => t) as SelectionFunction<T>;

export const ScalarResolver = (scalar: string, value: any) => {
  switch (scalar) {
    case 'String':
      return  `${JSON.stringify(value)}`;
    case 'Int':
      return `${value}`;
    case 'Float':
      return `${value}`;
    case 'Boolean':
      return `${value}`;
    case 'ID':
      return `"${value}"`;
    case 'enum':
      return `${value}`;
    case 'scalar':
      return `${value}`;
    default:
      return false;
  }
};


export const TypesPropsResolver = ({
    value,
    type,
    name,
    key,
    blockArrays
}: {
    value: any;
    type: string;
    name: string;
    key?: string;
    blockArrays?: boolean;
}): string => {
    if (value === null) {
        return `null`;
    }
    let resolvedValue = AllTypesProps[type][name];
    if (key) {
        resolvedValue = resolvedValue[key];
    }
    if (!resolvedValue) {
        throw new Error(`Cannot resolve ${type} ${name}${key ? ` ${key}` : ''}`)
    }
    const typeResolved = resolvedValue.type;
    const isArray = resolvedValue.array;
    const isArrayRequired = resolvedValue.arrayRequired;
    if (typeof value === 'string' && value.startsWith(`ZEUS_VAR$`)) {
        const isRequired = resolvedValue.required ? '!' : '';
        let t = `${typeResolved}`;
        if (isArray) {
          if (isRequired) {
              t = `${t}!`;
          }
          t = `[${t}]`;
          if(isArrayRequired){
            t = `${t}!`;
          }
        }else{
          if (isRequired) {
                t = `${t}!`;
          }
        }
        return `\$${value.split(`ZEUS_VAR$`)[1]}__ZEUS_VAR__${t}`;
    }
    if (isArray && !blockArrays) {
        return `[${value
        .map((v: any) => TypesPropsResolver({ value: v, type, name, key, blockArrays: true }))
        .join(',')}]`;
    }
    const reslovedScalar = ScalarResolver(typeResolved, value);
    if (!reslovedScalar) {
        const resolvedType = AllTypesProps[typeResolved];
        if (typeof resolvedType === 'object') {
        const argsKeys = Object.keys(resolvedType);
        return `{${argsKeys
            .filter((ak) => value[ak] !== undefined)
            .map(
            (ak) => `${ak}:${TypesPropsResolver({ value: value[ak], type: typeResolved, name: ak })}`
            )}}`;
        }
        return ScalarResolver(AllTypesProps[typeResolved], value) as string;
    }
    return reslovedScalar;
};


const isArrayFunction = (
  parent: string[],
  a: any[]
) => {
  const [values, r] = a;
  const [mainKey, key, ...keys] = parent;
  const keyValues = Object.keys(values).filter((k) => typeof values[k] !== 'undefined');

  if (!keys.length) {
      return keyValues.length > 0
        ? `(${keyValues
            .map(
              (v) =>
                `${v}:${TypesPropsResolver({
                  value: values[v],
                  type: mainKey,
                  name: key,
                  key: v
                })}`
            )
            .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
        : traverseToSeekArrays(parent, r);
    }

  const [typeResolverKey] = keys.splice(keys.length - 1, 1);
  let valueToResolve = ReturnTypes[mainKey][key];
  for (const k of keys) {
    valueToResolve = ReturnTypes[valueToResolve][k];
  }

  const argumentString =
    keyValues.length > 0
      ? `(${keyValues
          .map(
            (v) =>
              `${v}:${TypesPropsResolver({
                value: values[v],
                type: valueToResolve,
                name: typeResolverKey,
                key: v
              })}`
          )
          .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
      : traverseToSeekArrays(parent, r);
  return argumentString;
};


const resolveKV = (k: string, v: boolean | string | { [x: string]: boolean | string }) =>
  typeof v === 'boolean' ? k : typeof v === 'object' ? `${k}{${objectToTree(v)}}` : `${k}${v}`;


const objectToTree = (o: { [x: string]: boolean | string }): string =>
  `{${Object.keys(o).map((k) => `${resolveKV(k, o[k])}`).join(' ')}}`;


const traverseToSeekArrays = (parent: string[], a?: any): string => {
  if (!a) return '';
  if (Object.keys(a).length === 0) {
    return '';
  }
  let b: Record<string, any> = {};
  if (Array.isArray(a)) {
    return isArrayFunction([...parent], a);
  } else {
    if (typeof a === 'object') {
      Object.keys(a)
        .filter((k) => typeof a[k] !== 'undefined')
        .forEach((k) => {
        if (k === '__alias') {
          Object.keys(a[k]).forEach((aliasKey) => {
            const aliasOperations = a[k][aliasKey];
            const aliasOperationName = Object.keys(aliasOperations)[0];
            const aliasOperation = aliasOperations[aliasOperationName];
            b[
              `${aliasOperationName}__alias__${aliasKey}: ${aliasOperationName}`
            ] = traverseToSeekArrays([...parent, aliasOperationName], aliasOperation);
          });
        } else {
          b[k] = traverseToSeekArrays([...parent, k], a[k]);
        }
      });
    } else {
      return '';
    }
  }
  return objectToTree(b);
};  


const buildQuery = (type: string, a?: Record<any, any>) => 
  traverseToSeekArrays([type], a);


const inspectVariables = (query: string) => {
  const regex = /\$\b\w*__ZEUS_VAR__\[?[^!^\]^\s^,^\)^\}]*[!]?[\]]?[!]?/g;
  let result;
  const AllVariables: string[] = [];
  while ((result = regex.exec(query))) {
    if (AllVariables.includes(result[0])) {
      continue;
    }
    AllVariables.push(result[0]);
  }
  if (!AllVariables.length) {
    return query;
  }
  let filteredQuery = query;
  AllVariables.forEach((variable) => {
    while (filteredQuery.includes(variable)) {
      filteredQuery = filteredQuery.replace(variable, variable.split('__ZEUS_VAR__')[0]);
    }
  });
  return `(${AllVariables.map((a) => a.split('__ZEUS_VAR__'))
    .map(([variableName, variableType]) => `${variableName}:${variableType}`)
    .join(', ')})${filteredQuery}`;
};


export const queryConstruct = (t: 'query' | 'mutation' | 'subscription', tName: string, operationName?: string) => (o: Record<any, any>) =>
  `${t.toLowerCase()}${operationName ? ' ' + operationName : ''}${inspectVariables(buildQuery(tName, o))}`;
  

export const fullChainConstruct = (fn: FetchFunction) => (t: 'query' | 'mutation' | 'subscription', tName: string) => (
  o: Record<any, any>,
  options?: OperationOptions,
) => fn(queryConstruct(t, tName, options?.operationName)(o), options?.variables).then((r:any) => { 
  seekForAliases(r)
  return r
});


export const fullSubscriptionConstruct = (fn: SubscriptionFunction) => (
  t: 'query' | 'mutation' | 'subscription',
  tName: string,
) => (o: Record<any, any>, options?: OperationOptions) =>
  fn(queryConstruct(t, tName, options?.operationName)(o));


const seekForAliases = (response: any) => {
  const traverseAlias = (value: any) => {
    if (Array.isArray(value)) {
      value.forEach(seekForAliases);
    } else {
      if (typeof value === 'object') {
        seekForAliases(value);
      }
    }
  };
  if (typeof response === 'object' && response) {
    const keys = Object.keys(response);
    if (keys.length < 1) {
      return;
    }
    keys.forEach((k) => {
      const value = response[k];
      if (k.indexOf('__alias__') !== -1) {
        const [operation, alias] = k.split('__alias__');
        response[alias] = {
          [operation]: value,
        };
        delete response[k];
      }
      traverseAlias(value);
    });
  }
};


export const $ = (t: TemplateStringsArray): any => `ZEUS_VAR$${t.join('')}`;


export const resolverFor = <
  X,
  T extends keyof ValueTypes,
  Z extends keyof ValueTypes[T],
>(
  type: T,
  field: Z,
  fn: (
    args: Required<ValueTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : any,
) => fn as (args?: any,source?: any) => any;


const handleFetchResponse = (
  response: Parameters<Extract<Parameters<ReturnType<typeof fetch>['then']>[0], Function>>[0]
): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response.text().then(text => {
        try { reject(JSON.parse(text)); }
        catch (err) { reject(text); }
      }).catch(reject);
    });
  }
  return response.json();
};

export const apiFetch = (options: fetchOptions) => (query: string, variables: Record<string, any> = {}) => {
    let fetchFunction = fetch;
    let queryString = query;
    let fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      queryString = encodeURIComponent(query);
      return fetchFunction(`${options[0]}?query=${queryString}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetchFunction(`${options[0]}`, {
      body: JSON.stringify({ query: queryString, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      ...fetchOptions
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };
  

export const apiSubscription = (options: chainOptions) => (
    query: string,
  ) => {
    try {
      const queryString = options[0] + '?query=' + encodeURIComponent(query);
      const wsString = queryString.replace('http', 'ws');
      const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString;
      const webSocketOptions = options[1]?.websocket || [host];
      const ws = new WebSocket(...webSocketOptions);
      return {
        ws,
        on: (e: (args: any) => void) => {
          ws.onmessage = (event:any) => {
            if(event.data){
              const parsed = JSON.parse(event.data)
              const data = parsed.data
              if (data) {
                seekForAliases(data);
              }
              return e(data);
            }
          };
        },
        off: (e: (args: any) => void) => {
          ws.onclose = e;
        },
        error: (e: (args: any) => void) => {
          ws.onerror = e;
        },
        open: (e: () => void) => {
          ws.onopen = e;
        },
      };
    } catch {
      throw new Error('No websockets implemented');
    }
  };



const allOperations = {
    "query": "MainQuery",
    "mutation": "MainMutation"
}

export type GenericOperation<O> = O extends 'query'
  ? "MainQuery"
  : O extends 'mutation'
  ? "MainMutation"
  : never

export const Thunder = (fn: FetchFunction) => <
  O extends 'query' | 'mutation',
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
) => <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions) =>
  fullChainConstruct(fn)(operation, allOperations[operation])(o as any, ops) as Promise<InputType<GraphQLTypes[R], Z>>;

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options));  
  
export const SubscriptionThunder = (fn: SubscriptionFunction) => <
  O extends 'query' | 'mutation',
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
) => <Z extends ValueTypes[R]>(
  o: Z | ValueTypes[R],
  ops?: OperationOptions
)=>
  fullSubscriptionConstruct(fn)(operation, allOperations[operation])(
    o as any,
    ops,
  ) as SubscriptionToGraphQL<Z, GraphQLTypes[R]>;

export const Subscription = (...options: chainOptions) => SubscriptionThunder(apiSubscription(options));
export const Zeus = <
  Z extends ValueTypes[R],
  O extends 'query' | 'mutation',
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
  o: Z | ValueTypes[R],
  operationName?: string,
) => queryConstruct(operation, allOperations[operation], operationName)(o as any);
export const Selector = <T extends keyof ValueTypes>(key: T) => ZeusSelect<ValueTypes[T]>();
  

export const Gql = Chain('https://patient-api-dev.cibahealth.com/graphql')