import React from "react";
import { useUserContext } from "../../../../context/userContext";
import { permissions } from "../../../../permissions";

import DeletePatientButton from "./DeletePatientButton";
import ExportButton from "./ExportButton";

export default function ActionsButtons() {
  const { can } = useUserContext();
  return (
    <div className="g-d-flex g-justify-between">
      {can(permissions.patient.canExportAllData) && <ExportButton />}
      {can(permissions.patient.canDeletePatient) && <DeletePatientButton />}
    </div>
  );
}
