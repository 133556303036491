import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import NotificationsCounter from 'common/NotificationsCounter'

export default React.memo(function IconLink ({
  value, href, BeforeIcon, isActive, withCounter
}) {
  if (!value || !href) return null
  return (
    <StyledLink
      to={href}
      isactive={isActive.toString()}
    >
      <div className='icon-wrapper'>
        <BeforeIcon className='icon' />
        {withCounter && <NotificationsCounter className='counter' />}
      </div>
      <div className='label'>{value}</div>
    </StyledLink>
  )
})

const StyledLink = styled(Link)`
  display: block;
  width: 100%;
  text-align: center;
  padding: 1.6rem 0 0.8rem;
  color: ${({ isactive }) => isactive === 'true' ? '#000' : 'rgba(0, 0, 0, 0.5)'};
  text-decoration: none;
  .icon-wrapper {
    position: relative;
    .icon {
      display: block;
      margin: auto;
      width: 2.5rem;
      height: 2.5rem;
      opacity: ${({ isactive }) => isactive === 'true' ? 1 : 0.5};
    }
  }
  .label {
    font-size: 1.2rem;
    line-height: 2.4rem;
    opacity: ${({ isactive }) => isactive === 'true' ? 1 : 0.5};
  }
`
