import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useFetchUser } from 'requests/user';
import { useFetchProfile } from 'requests/profile';
import AuthTemplate from 'components/auth/AuthTemplate';
import InputWithLabel from 'common/InputWithLabel';
import CustomButton from 'common/CustomButton';
import createNotification from 'utils/createNotification';
import useTranslations from 'translations';
import { Cognito } from 'libs/cognito';
import { DASHBOARD } from 'constants/routes';

const cognito = Cognito.getInstance();

export default function TwoFALogin() {
  const history = useHistory();
  const fetchUser = useFetchUser();
  const fetchProfile = useFetchProfile();

  const [state, setState] = useState({
    hasError: false,
    validate: false,
    code: '',
  });

  const { title } = useTranslations().twoFALogin;

  const updateState = (newState) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  };

  const isValid = React.useMemo(() => {
    if (!state.validate) return true;
    if (state.code.length !== 6) return false;
    if (state.hasError) return false;
    return true;
  }, [state.validate, state.code, state.hasError]);

  const handleChangeCode = (newCode) => {
    updateState({ code: newCode });
  };

  const handleSubmit = React.useCallback(async () => {
    if (state.code.length !== 6) {
      createNotification({
        message: 'Please enter valid verification code',
        type: 'error',
      });
      return;
    }
    updateState({ validate: true });
    updateState({ hasError: false });

    try {
      await cognito.sendOTPCode(state.code);
      try {
        history.push(`/${DASHBOARD}`);
        await fetchUser();
        await fetchProfile();
        createNotification({ message: 'Login success', type: 'success' });
      } catch {
        createNotification({ message: "Can't get user data", type: 'error' });
      }
    } catch (e) {
      console.log(e);
      updateState({ hasError: true });
      createNotification({ message: 'Invalid code', type: 'error' });
    }
  }, [state.code, history, fetchUser, fetchProfile]);

  return (
    <AuthTemplate title={title}>
      <StyledInput
        placeholder="Verification Code (6 digits)"
        isRequred
        isValid={isValid}
        onEnter={handleSubmit}
        value={state.code}
        changeHandler={handleChangeCode}
      />

      <CustomButton className="g-mt-15" clickHandler={handleSubmit}>
        Continue
      </CustomButton>

      <p className="g-mt-30">
        If you accidentally deleted the application from your mobile device or lost access to your phone, simply enter the one-time code that you
        wrote down during activation.
      </p>
    </AuthTemplate>
  );
}

const StyledInput = styled(InputWithLabel)`
  max-width: initial;
`;
