import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import get from 'lodash/get';

import { APPOINTMENTS_ADMIN, CREATE, CONFIGURATE } from 'constants/routes';

import { globalContext } from 'hooks/useGlobalState';
import { useAppointmentCreate, useSetAppointmentCreate } from 'hooks/useAppointments';

import LinkButton from 'common/LinkButton';
import ProviderSelect from './ProviderSelect';
import PatientSelect from './PatientSelect';

export default React.memo(function AppointmentsFilters() {
  const { appointmentCreate } = React.useContext(globalContext);
  const history = useHistory();
  const patient = useAppointmentCreate((current) => get(current, 'patient'));
  const provider = useAppointmentCreate((current) => get(current, 'provider'));
  const clearAppointmentCreate = useSetAppointmentCreate(() => null, []);

  React.useEffect(() => {
    if (appointmentCreate.value) clearAppointmentCreate();
    // eslint-disable-next-line
  }, []);

  return (
    <StyledWrapper className="g-d-flex g-mb-30">
      <PatientSelect />
      <ProviderSelect />
      {patient && provider && (
        <LinkButton className="book-button" onClick={() => history.push(`/${APPOINTMENTS_ADMIN}/${CREATE}/${CONFIGURATE}`)}>
          Book an appointment
        </LinkButton>
      )}
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  .provider {
    margin: 0 0 0 4rem;
  }
  .book-button {
    padding: 1.1rem 1.5rem;
    margin: auto 0 0 4rem;
    white-space: nowrap;
  }
  @media screen and (max-width: 480px) {
    display: block;
    .provider {
      margin: 1rem 0 0 0;
    }
    .book-button {
      margin: 2rem 0 0 0;
      width: 100%;
    }
  }
`;
