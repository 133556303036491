import React from 'react'

import { usePatientSymptomsList } from 'hooks/usePatients'

import { UppercaseText } from 'common/texts'
import SymptomsList from './SymptomsList'

export default React.memo(function SymptomsGroups () {
  const patientSymptomsList = usePatientSymptomsList()
  return React.useMemo(() => {
    return patientSymptomsList.map(({ title, symptoms }, i) => {
      return (
        <div key={i} className='g-mt-20'>
          <UppercaseText>{title}</UppercaseText>
          <SymptomsList
            className={(patientSymptomsList.length - 1) === i ? 'g-mb-45' : ''}
            symptoms={symptoms}
          />
        </div>
      )
    })
  }, [patientSymptomsList])
})
