import React from 'react'
import Video from 'twilio-video'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import get from 'lodash/get'

import { ENVIRONMENT_CHECK, INTRODUCTION } from 'constants/routes'; 

import { useConsultation, useSetConsultation } from 'hooks/useAppointments'

import { RowPlate } from 'common/plate/plates'
import { PlateContent } from 'common/plate/styles'
import VideoHeader from './VideoHeader'
import VideoComponent from './video-component/VideoComponent'
import VideoFooter from './VideoFooter'
import SecureInfo from './SecureInfo'

import createNotification from 'utils/createNotification'

function Message () {
  return (
    <span>
      Please allow access or check your devices on the&nbsp;
      <Link to={`/${ENVIRONMENT_CHECK}/${INTRODUCTION}`}>
        Enviroment check page
      </Link>.
    </span>
  )
}

export default React.memo(function VideoContent ({ setIsChatHidden }) {
  const room = useConsultation(current => get(current, 'room'))
  const setRoom = useSetConsultation((prev, room) => ({ ...prev, room }), [])

  const respondData = useConsultation(current => get(current, 'respondData'))

  const participants = useConsultation(current => get(current, 'participants'))
  const participantConnected = useSetConsultation((prev, next) => ({ ...prev, participants: [...prev.participants, next] }), [])
  const participantDisconnected = useSetConsultation((prev, next) => ({ ...prev, participants: prev.participants.filter(p => p !== next) }), [])

  React.useEffect(() => {
    if (respondData && !room) {
      async function handleDevices () {
        const token = get(respondData, 'token')
        const name = get(respondData, 'room_name')
        let localTracks = []
        try {
          await navigator.mediaDevices.getUserMedia({ video: true })
          const devices = await navigator.mediaDevices.enumerateDevices()
          const videoDevices = devices.filter(({ kind }) => kind === 'videoinput')
          let videoOptions = true
          const selectedDevice = localStorage.getItem('cameraId')
          const isDeviceExist = videoDevices.find(({ deviceId }) => deviceId === selectedDevice)
          if (isDeviceExist) {
            videoOptions = { deviceId: selectedDevice }
          }
          localTracks = await Video.createLocalTracks({
            audio: true,
            video: videoOptions
          })

        } catch (err) {
          console.log(err)
          createNotification({
            title: 'Your camera and microphone are not connected.',
            message: <Message />,
            type: 'error'
          })
        }
        const room = await Video.connect(token, { name, tracks: localTracks })
        setRoom(room)
        room.on('participantConnected', participantConnected)
        room.on('participantDisconnected', participantDisconnected)
        room.participants.forEach(participantConnected)
      }
      handleDevices()
    }
    // eslint-disable-next-line
  }, [respondData, room])

  return (
    <StylesWrapper>
      <RowPlate>
        <VideoHeader
          patientId={get(respondData, `partisipiants.patient.id`)}
          avatar={get(respondData, `partisipiants.patient.avatar_url`)}
          firstName={get(respondData, `partisipiants.patient.first_name`)}
          lastName={get(respondData, `partisipiants.patient.last_name`)}
          fullName={get(respondData, `partisipiants.patient.full_name`)}
          isStarted={!!participants.length}
        />

        <PlateContent>
          <VideoComponent
            localParticipant={get(room, 'localParticipant')}
            placeholder='Patient will join an appointment soon'
            setIsChatHidden={setIsChatHidden}
          />
          <VideoFooter />
        </PlateContent>
      </RowPlate>
      <SecureInfo />
    </StylesWrapper>
  )
})

const StylesWrapper = styled.div`
  width: 100%;
  max-width: 87rem;
`
