import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import { typesConfig, periodTabsConfig, dimensionConfig } from 'constants/withings-tabs'

import { useDaysData } from 'hooks/patient/widgets/useWidgets'

import { RegularText } from 'common/texts'
import LabelsList from './labels-list'
import Bars from './bars'
import DatesList from './dates-list'

export default function MonthView ({ currentTab, period }) {
  const type = typesConfig[currentTab]
  const { start } = periodTabsConfig.find(item => item.id === period)

  const daysData = useDaysData(type)

  const dates = React.useMemo(() => {
    const daysInMonth = moment().daysInMonth()
    return (new Array(daysInMonth))
      .fill()
      .map((item, i) => {
        const day = moment(start).add(i, 'days')
        const { value = 0 } = daysData.find(({ date }) => date === day.format('YYYY-MM-DD')) || {}
        return {
          label: day.format('D'),
          value
        }
      })
      .filter((item, i) => (i % 2 === 0))
  }, [start, daysData])

  return (
    <StyledWrapper>
      <RegularText>
        {moment(start).format('MMMM YYYY')} <b>(in {dimensionConfig[currentTab]})</b>
      </RegularText>
      <LabelsList dates={dates} />
      <Bars
        currentTab={currentTab}
        dates={dates}
      />
      <DatesList dates={dates} />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
`
