import React from 'react'
import styled from 'styled-components'
import { useParams, Link } from 'react-router-dom'

import { PATIENTS, DETAILS, WIDGETS, OURA, ACTIVITY_CONTRIBUTORS } from 'constants/routes'; 

import { useDayData, useDaysData } from 'hooks/patient/widgets/useWidgets'

import { RowPlate } from 'common/plate/plates'
import { PlateHeader, PlateContent } from 'common/plate/styles'
import BackButton from 'common/plate/BackButton'
import DurationData from 'components/patients/widgets/synchronized-widget/oura/common/DurationData'
import DaySelector from 'common/widgets/day-selector'
import { UnequalColumns } from 'components/patients/widgets/synchronized-widget/oura/common/styles/styledColumns'

import onlyPositive from 'utils/onlyPositive'
import toHM from 'utils/toHM'
import toRelative from 'utils/toRelative'

const minutesPerDay = 1440

export default function PatientDailyMovement () {
  const { patientId, widgetId } = useParams()
  const daysData = useDaysData('total')
  const dayData = useDayData()

  const label = React.useMemo(() => {
    const cal = dayData.cal_active
    if (!cal) return null
    return `${cal} Cal`
  }, [dayData])

  const normalizedHighActivity = onlyPositive(dayData.high)
  const normalizedMediumActivity = onlyPositive(dayData.medium)
  const normalizedLowActivity = onlyPositive(dayData.low)

  const highActivityDate = toHM(normalizedHighActivity, 'minutes')
  const mediumActivityDate = toHM(normalizedMediumActivity, 'minutes')
  const lowActivityDate = toHM(normalizedLowActivity, 'minutes')

  const highActivityPerDay = toRelative(normalizedHighActivity, minutesPerDay)
  const mediumActivityPerDay = toRelative(normalizedMediumActivity, minutesPerDay)
  const lowActivityPerDay = toRelative(normalizedLowActivity, minutesPerDay)

  return (
    <RowPlate>
      <PlateHeader>
        <BackButton href={`/${PATIENTS}/${DETAILS}/${patientId}/${WIDGETS}/${OURA}/${widgetId}/${ACTIVITY_CONTRIBUTORS}`} />
        Daily movement
      </PlateHeader>
      <DaySelector
        data={daysData}
        label={label}
        color='#fbb665'
      />
      <UnequalColumns className='g-d-flex g-justify-between g-mb-30'>
        <div className='col col-left'>
          <DurationData
            textTitle='High'
            lineBg='#c2e1ff'
            durationPercentage={highActivityPerDay}
            durationData={highActivityDate}
          />
          <DurationData
            textTitle='Medium'
            lineBg='#01dcf1'
            durationPercentage={mediumActivityPerDay}
            durationData={mediumActivityDate}
          />
          <DurationData
            textTitle='Low'
            lineBg='#276f81'
            durationPercentage={lowActivityPerDay}
            durationData={lowActivityDate}
          />
        </div>
        <div className='col col-right g-d-flex' />
      </UnequalColumns>
      <PlateContent>
        <StyledLink to={`/${PATIENTS}/${DETAILS}/${patientId}/${WIDGETS}/${OURA}/${widgetId}/${ACTIVITY_CONTRIBUTORS}`}>
          View activity
        </StyledLink>
      </PlateContent>
    </RowPlate>
  )
}

const StyledLink = styled(Link)`
  align-self: flex-start;
  display: inline-block;
  margin-top: 5.5rem;
  color: inherit;
  font-size: 1.2rem;
  line-height: 1.8rem;
  text-transform: uppercase;
  @media screen and (max-width: 768px) {
    margin-top: 0;
  }
`
