/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	MainQuery:{
		getAppointments:{
			filters:{
				type:"AppointmentsFilterType",
				array:false,
				arrayRequired:false,
				required:false
			},
			sort:{
				type:"AppointmentsSortType",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getAppointmentVideoToken:{
			appointmentId:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getAppointmentChatToken:{
			appointmentId:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getChatMembers:{
			members:{
				type:"MemberFilterType",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		states:{
			filters:{
				type:"StateFilter",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getPatientLabs:{
			page:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			perPage:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getLabUrl:{
			labId:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getProgramByCoupon:{
			activationCode:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getProviders:{
			filters:{
				type:"ProvidersFilterType",
				array:false,
				arrayRequired:false,
				required:false
			},
			sort:{
				type:"ProvidersSortType",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getProviderSlots:{
			filters:{
				type:"ProviderSlotFilterType",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getLibraryMaterials:{
			page:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			perPage:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			filters:{
				type:"LibraryMaterialFilterType",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getLibraryMaterial:{
			materialId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getPatientQuestionnaire:{
			questionnaireId:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		}
	},
	DateTime: "String",
	Date: "String",
	Decimal: "String",
	JSON: "String",
	AppointmentsFilterType:{
		status:{
			type:"AppointmentStatusEnum",
			array:true,
			arrayRequired:false,
			required:true
		},
		type:{
			type:"AppointmentTypeEnum",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentStatusEnum: "enum",
	AppointmentTypeEnum: "enum",
	AppointmentsSortType:{
		orderBy:{
			type:"AppointmentsOrderByEnum",
			array:false,
			arrayRequired:false,
			required:true
		},
		direction:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	AppointmentsOrderByEnum: "enum",
	OrderDirection: "enum",
	MemberFilterType:{
		type:{
			type:"ProfileTypes",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	ProfileTypes: "enum",
	UUID: "String",
	StateFilter:{
		country:{
			type:"Countries",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Countries: "enum",
	ProvidersFilterType:{
		available:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		qualifications:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	ProvidersSortType:{
		orderBy:{
			type:"ProviderOrderByEnum",
			array:false,
			arrayRequired:false,
			required:true
		},
		direction:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	ProviderOrderByEnum: "enum",
	ProviderSlotFilterType:{
		providerId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	LibraryMaterialFilterType:{
		categoryIds:{
			type:"UUID",
			array:true,
			arrayRequired:false,
			required:true
		},
		type:{
			type:"LibraryMaterialTypes",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"LibraryMaterialStatuses",
			array:false,
			arrayRequired:false,
			required:false
		},
		search:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LibraryMaterialTypes: "enum",
	LibraryMaterialStatuses: "enum",
	MainMutation:{
		storeAppointment:{
			data:{
				type:"StoreAppointmentInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		markAppointmentPresence:{
			appointmentId:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			patientId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		cancelAppointment:{
			appointmentId:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cancelReasonId:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cancelText:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			confirmCancellation:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateProfile:{
			data:{
				type:"UpdateProfileInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		subscribeToProgram:{
			activationCode:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		uploadAvatar:{
			file:{
				type:"Upload",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updatePatientInsurances:{
			data:{
				type:"UpdatePatientInsurancesInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updatePassword:{
			data:{
				type:"UpdatePasswordInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		confirmForgotPassword:{
			data:{
				type:"ConfirmForgotPasswordInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createLibraryMaterial:{
			data:{
				type:"LibraryMaterialInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateLibraryMaterial:{
			materialId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"LibraryMaterialUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteLibraryMaterial:{
			materialId:{
				type:"UUID",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createLibraryMaterialCategory:{
			title:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		saveQuestionnaireAnswer:{
			questionnaireId:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			answer:{
				type:"QuestionnaireAnswerInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		}
	},
	StoreAppointmentInput:{
		providerId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		datetime:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		agreementPolicy:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:true
		},
		agreementCancel:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:true
		},
		reason:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		qualification:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		timezone:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		patientId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	UpdateProfileInput:{
		noPrimaryProvider:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		primaryCareProvider:{
			type:"PrimaryCareProviderInfoInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		profile:{
			type:"PatientProfileInfoInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		contact:{
			type:"PatientContactInfoInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		emergency:{
			type:"PatientEmergencyInfoInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PrimaryCareProviderInfoInput:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		address:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		officeNumber:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PatientProfileInfoInput:{
		firstName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		birthDate:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatar:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gender:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PatientContactInfoInput:{
		phone:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		city:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		streetAddress1:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		streetAddress2:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		state:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PatientEmergencyInfoInput:{
		fullName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		phone:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		relationship:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Upload: "String",
	UpdatePatientInsurancesInput:{
		noInsurance:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:true
		},
		insurances:{
			type:"PatientInsurancesInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PatientInsurancesInput:{
		primary:{
			type:"PatientInsuranceInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		secondary:{
			type:"PatientInsuranceInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PatientInsuranceInput:{
		group:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		member:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		insuranceCompany:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	UpdatePasswordInput:{
		accessToken:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		oldPassword:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		newPassword:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	ConfirmForgotPasswordInput:{
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		newPassword:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	LibraryMaterialInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		type:{
			type:"LibraryMaterialTypes",
			array:false,
			arrayRequired:false,
			required:true
		},
		categoryId:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:true
		},
		content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		status:{
			type:"LibraryMaterialStatuses",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LibraryMaterialUpdateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"LibraryMaterialTypes",
			array:false,
			arrayRequired:false,
			required:false
		},
		categoryId:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"LibraryMaterialStatuses",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	QuestionnaireAnswerInput:{
		answer:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:true
		},
		step:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		},
		score:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	}
}

export const ReturnTypes: Record<string,any> = {
	MainQuery:{
		getAppointments:"AppointmentType",
		getAppointmentVideoToken:"VideoTokenType",
		getAppointmentChatToken:"ChatTokenType",
		getChatMembers:"MembersType",
		getCancelReasons:"CancelReasonType",
		states:"StatesInfo",
		genders:"GendersInfo",
		relationships:"RelationshipsInfo",
		me:"PatientInfo",
		getMyProgramInfo:"MembershipInfoType",
		getPatientExperienceProgress:"PatientExperienceProgressType",
		getMyInsurances:"PatientInsurancesInfoType",
		getAvailableQualifications:"QualificationType",
		getChatToken:"ConversationTokenType",
		getMyCareTeam:"CareTeamType",
		getPatientLabs:"LabTypeConnection",
		getLabUrl:"String",
		getProgramByCoupon:"CompanyPlanTypeCompanyDoesNotHaveMemberships",
		getProviders:"ProviderType",
		getProviderSlots:"String",
		getLibraryMaterials:"LibraryMaterialTypeConnection",
		getLibraryMaterial:"LibraryMaterialType",
		getLibraryMaterialCategories:"LibraryMaterialCategoryType",
		getLibraryMaterialTypes:"String",
		getPatientQuestionnaires:"GroupedQuestionnairesType",
		getPatientQuestionnaire:"QuestionnaireType",
		getPatientConsentForm:"QuestionnaireType"
	},
	AppointmentType:{
		provider:"ProviderType",
		patient:"PatientType",
		channel:"ChanelType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"Int",
		type:"String",
		reason:"String",
		startedAt:"DateTime",
		timeZone:"String",
		rate:"Int",
		status:"String",
		subStatus:"String",
		rescheduleReason:"String",
		canceledText:"String",
		canceledBy:"String",
		adminCanceled:"Boolean",
		integrationsMeta:"JSON",
		deletedAt:"DateTime",
		agreementPolicy:"Boolean",
		agreementCancel:"Boolean",
		visitId:"Int",
		visitType:"String",
		review:"String"
	},
	ProviderType:{
		profile:"ProfileType",
		providerInfo:"ProviderInformationType",
		qualifications:"ProviderQualificationType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"String",
		email:"String",
		isSuper:"Boolean",
		isSupport:"Boolean"
	},
	ProfileType:{
		avatarUrl:"String",
		gender:"GenderType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"Int",
		profileableId:"String",
		profileableType:"String",
		fName:"String",
		lName:"String",
		birthDate:"Date",
		phone:"String",
		city:"String",
		zip:"String",
		address1:"String",
		address2:"String",
		biography:"String",
		avatar:"String",
		deletedAt:"DateTime"
	},
	GenderType:{
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"Int",
		name:"String",
		slug:"String"
	},
	ProviderInformationType:{
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"Int",
		education:"String",
		practices:"String",
		experienceYears:"Int",
		shortSummary:"String",
		qualificationTitle:"String"
	},
	ProviderQualificationType:{
		qualification:"QualificationType",
		id:"Int"
	},
	QualificationType:{
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"Int",
		name:"String",
		title:"String",
		slug:"String",
		type:"String",
		icon:"String",
		cost:"Decimal"
	},
	PatientType:{
		profile:"ProfileType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"String",
		email:"String",
		emailVerifiedAt:"DateTime",
		medicalRecord:"String",
		experianceDone:"Boolean",
		trialEndsAt:"DateTime",
		termsAgree:"Boolean",
		noInsurance:"Boolean",
		firstVisitedAt:"DateTime",
		lastVisitedAt:"DateTime",
		deletedAt:"DateTime",
		noPrimaryProvider:"Boolean"
	},
	ChanelType:{
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"Int",
		channelSid:"String",
		channelUniqueName:"String",
		channelFrendlyName:"String"
	},
	VideoTokenType:{
		roomName:"String",
		roomTitle:"String",
		token:"String",
		participants:"ChatProviderTypeChatPatientType"
	},
	ChatProviderTypeChatPatientType:{
		"...on ChatProviderType":"ChatProviderType",
		"...on ChatPatientType":"ChatPatientType"
	},
	ChatProviderType:{
		profile:"ChatProfileType",
		providerInfo:"ProviderInformationType",
		qualifications:"ProviderQualificationType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"String",
		email:"String",
		isSuper:"Boolean",
		isSupport:"Boolean",
		chatIdentity:"String"
	},
	ChatProfileType:{
		avatarUrl:"String",
		fName:"String",
		lName:"String",
		avatar:"String"
	},
	ChatPatientType:{
		profile:"ChatProfileType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"String",
		email:"String",
		emailVerifiedAt:"DateTime",
		medicalRecord:"String",
		experianceDone:"Boolean",
		trialEndsAt:"DateTime",
		termsAgree:"Boolean",
		noInsurance:"Boolean",
		firstVisitedAt:"DateTime",
		lastVisitedAt:"DateTime",
		deletedAt:"DateTime",
		noPrimaryProvider:"Boolean",
		chatIdentity:"String"
	},
	ChatTokenType:{
		channelSid:"String",
		channelUniqueName:"String",
		token:"String",
		participants:"ChatProviderTypeChatPatientType"
	},
	MembersType:{
		members:"ChatProviderTypeChatPatientType"
	},
	CancelReasonType:{
		id:"Int",
		name:"String"
	},
	StatesInfo:{
		value:"String",
		label:"String"
	},
	GendersInfo:{
		value:"Int",
		label:"String"
	},
	RelationshipsInfo:{
		value:"String",
		label:"String"
	},
	PatientInfo:{
		id:"String",
		email:"String",
		companyId:"Int",
		noPrimaryProvider:"Boolean",
		termsAgree:"Boolean",
		primaryCareProvider:"PrimaryCareProviderInfoType",
		profile:"PatientProfileInfoType",
		contact:"PatientContactInfoType",
		emergency:"PatientEmergencyInfoType"
	},
	PrimaryCareProviderInfoType:{
		name:"String",
		address:"String",
		officeNumber:"String"
	},
	PrimaryCareProviderInterface:{
		"...on PrimaryCareProviderInfoType": "PrimaryCareProviderInfoType",
		name:"String",
		address:"String",
		officeNumber:"String"
	},
	PatientProfileInfoType:{
		firstName:"String",
		lastName:"String",
		birthDate:"Date",
		avatar:"String",
		gender:"GendersInfo",
		avatarUrl:"String"
	},
	PatientProfileInterface:{
		"...on PatientProfileInfoType": "PatientProfileInfoType",
		firstName:"String",
		lastName:"String",
		birthDate:"Date",
		avatar:"String"
	},
	PatientContactInfoType:{
		phone:"String",
		city:"String",
		zipCode:"String",
		streetAddress1:"String",
		streetAddress2:"String",
		state:"StatesInfo"
	},
	PatientContactInterface:{
		"...on PatientContactInfoType": "PatientContactInfoType",
		phone:"String",
		city:"String",
		zipCode:"String",
		streetAddress1:"String",
		streetAddress2:"String"
	},
	PatientEmergencyInfoType:{
		fullName:"String",
		phone:"String",
		relationship:"RelationshipsInfo"
	},
	PatientEmergencyInterface:{
		"...on PatientEmergencyInfoType": "PatientEmergencyInfoType",
		fullName:"String",
		phone:"String"
	},
	MembershipInfoType:{
		id:"Int",
		subscriptionId:"Int",
		patientId:"String",
		status:"String",
		startedAt:"Date",
		activeUntil:"Date",
		plan:"PlanInfoType",
		visits:"VisitInfoType"
	},
	PlanInfoType:{
		id:"Int",
		name:"String",
		baseName:"String",
		slug:"String",
		type:"String",
		packageType:"String",
		billed:"String",
		monthsOfCare:"String",
		doctorVisit:"Int",
		coachVisit:"Int",
		description:"String"
	},
	VisitInfoType:{
		id:"Int",
		name:"String",
		icon:"String",
		type:"String",
		visitType:"String",
		amount:"Int",
		used:"Int"
	},
	PatientExperienceProgressType:{
		done:"Boolean",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"Int",
		name:"String",
		slug:"String",
		sortOrder:"Int"
	},
	PatientInsurancesInfoType:{
		noInsurance:"Boolean",
		insurances:"PatientInsurancesType"
	},
	PatientInsurancesType:{
		primary:"PatientInsuranceInfoType",
		secondary:"PatientInsuranceInfoType"
	},
	PatientInsuranceInfoType:{
		id:"Int",
		group:"String",
		member:"String",
		type:"String",
		insuranceCompany:"InsuranceCompanyInfoType"
	},
	InsuranceCompanyInfoType:{
		id:"Int",
		name:"String",
		slug:"String"
	},
	ConversationTokenType:{
		token:"String",
		chatIdentity:"String"
	},
	CareTeamType:{
		provider:"ProviderType",
		channelSid:"String"
	},
	LabTypeConnection:{
		pageInfo:"PageInfo",
		items:"LabType"
	},
	PageInfo:{
		hasNextPage:"Boolean",
		hasPreviousPage:"Boolean",
		currentPage:"Int",
		perPage:"Int",
		lastPage:"Int",
		total:"Int"
	},
	LabType:{
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"Int",
		authorType:"String",
		authorId:"String",
		name:"String",
		path:"String",
		deletedAt:"DateTime"
	},
	CompanyPlanTypeCompanyDoesNotHaveMemberships:{
		"...on CompanyPlanType":"CompanyPlanType",
		"...on CompanyDoesNotHaveMemberships":"CompanyDoesNotHaveMemberships"
	},
	CompanyPlanType:{
		id:"Int",
		name:"String",
		baseName:"String",
		endsAt:"Date",
		status:"String",
		slug:"String",
		type:"String",
		packageType:"String",
		price:"Decimal",
		discount:"Int",
		billed:"String",
		monthsOfCare:"String",
		description:"String",
		isSinglePaymentOption:"Boolean",
		visits:"VisitType"
	},
	VisitType:{
		qualification:"String",
		available:"Int",
		title:"String"
	},
	CompanyDoesNotHaveMemberships:{
		message:"String"
	},
	LibraryMaterialTypeConnection:{
		pageInfo:"PageInfo",
		items:"LibraryMaterialType"
	},
	LibraryMaterialType:{
		category:"LibraryMaterialCategoryType",
		createdBy:"ProviderType",
		updatedBy:"ProviderType",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"UUID",
		title:"String",
		description:"String",
		type:"String",
		content:"String",
		currentVersion:"Boolean",
		materialId:"UUID",
		status:"String",
		deletedAt:"DateTime"
	},
	LibraryMaterialCategoryType:{
		createdAt:"DateTime",
		updatedAt:"DateTime",
		id:"UUID",
		slug:"String",
		title:"String"
	},
	GroupedQuestionnairesType:{
		mentalHealth:"QuestionnairesType",
		patientForms:"QuestionnairesType",
		patientMedicalHistory:"QuestionnairesType",
		preAssessment:"QuestionnairesType"
	},
	QuestionnairesType:{
		count:"Int",
		done:"Int",
		label:"String",
		items:"QuestionnaireType"
	},
	QuestionnaireType:{
		estimate:"Int",
		group:"String",
		id:"Int",
		name:"String",
		step:"Int",
		total:"Int",
		type:"String",
		content:"JSON"
	},
	MainMutation:{
		storeAppointment:"AppointmentTypeNoAvailableVisitsProviderNotAvailableProviderHasNotQualification",
		markAppointmentPresence:"Boolean",
		cancelAppointment:"IntTypeCannotCancelBefore24HoursAppointmentInThePast",
		updateProfile:"PatientInfo",
		acceptTermsAndPolicy:"Boolean",
		subscribeToProgram:"MembershipInfoTypeCompanyDoesNotHaveActiveMembershipsCompanyNoCapacityNoCompanyByCodePatientHasMembership",
		uploadAvatar:"UploadFileInfoTypeTooLargeFileFileTypeIsNotSupported",
		updatePatientInsurances:"PatientInsurancesInfoType",
		updatePassword:"UpdatePasswordSamePasswordErrorPasswordUpdateLimitExceededError",
		confirmForgotPassword:"UpdatePasswordConfirmCodeExpiredError",
		createLibraryMaterial:"LibraryMaterialTypeMaterialDuplicatedError",
		updateLibraryMaterial:"LibraryMaterialTypeMaterialDuplicatedError",
		deleteLibraryMaterial:"Boolean",
		createLibraryMaterialCategory:"LibraryMaterialCategoryType",
		saveQuestionnaireAnswer:"Boolean"
	},
	AppointmentTypeNoAvailableVisitsProviderNotAvailableProviderHasNotQualification:{
		"...on AppointmentType":"AppointmentType",
		"...on NoAvailableVisits":"NoAvailableVisits",
		"...on ProviderNotAvailable":"ProviderNotAvailable",
		"...on ProviderHasNotQualification":"ProviderHasNotQualification"
	},
	NoAvailableVisits:{
		message:"String"
	},
	ProviderNotAvailable:{
		message:"String"
	},
	ProviderHasNotQualification:{
		message:"String"
	},
	IntTypeCannotCancelBefore24HoursAppointmentInThePast:{
		"...on IntType":"IntType",
		"...on CannotCancelBefore24Hours":"CannotCancelBefore24Hours",
		"...on AppointmentInThePast":"AppointmentInThePast"
	},
	IntType:{
		id:"Int"
	},
	CannotCancelBefore24Hours:{
		message:"String"
	},
	AppointmentInThePast:{
		message:"String"
	},
	MembershipInfoTypeCompanyDoesNotHaveActiveMembershipsCompanyNoCapacityNoCompanyByCodePatientHasMembership:{
		"...on MembershipInfoType":"MembershipInfoType",
		"...on CompanyDoesNotHaveActiveMemberships":"CompanyDoesNotHaveActiveMemberships",
		"...on CompanyNoCapacity":"CompanyNoCapacity",
		"...on NoCompanyByCode":"NoCompanyByCode",
		"...on PatientHasMembership":"PatientHasMembership"
	},
	CompanyDoesNotHaveActiveMemberships:{
		message:"String"
	},
	CompanyNoCapacity:{
		message:"String"
	},
	NoCompanyByCode:{
		message:"String"
	},
	PatientHasMembership:{
		message:"String"
	},
	UploadFileInfoTypeTooLargeFileFileTypeIsNotSupported:{
		"...on UploadFileInfoType":"UploadFileInfoType",
		"...on TooLargeFile":"TooLargeFile",
		"...on FileTypeIsNotSupported":"FileTypeIsNotSupported"
	},
	UploadFileInfoType:{
		fileName:"String",
		originalFileName:"String",
		fileType:"String",
		id:"UUID"
	},
	TooLargeFile:{
		message:"String",
		originalFileName:"String"
	},
	FileTypeIsNotSupported:{
		message:"String",
		originalFileName:"String"
	},
	UpdatePasswordSamePasswordErrorPasswordUpdateLimitExceededError:{
		"...on UpdatePassword":"UpdatePassword",
		"...on SamePasswordError":"SamePasswordError",
		"...on PasswordUpdateLimitExceededError":"PasswordUpdateLimitExceededError"
	},
	UpdatePassword:{
		email:"String"
	},
	SamePasswordError:{
		message:"String"
	},
	PasswordUpdateLimitExceededError:{
		message:"String"
	},
	UpdatePasswordConfirmCodeExpiredError:{
		"...on UpdatePassword":"UpdatePassword",
		"...on ConfirmCodeExpiredError":"ConfirmCodeExpiredError"
	},
	ConfirmCodeExpiredError:{
		message:"String"
	},
	LibraryMaterialTypeMaterialDuplicatedError:{
		"...on LibraryMaterialType":"LibraryMaterialType",
		"...on MaterialDuplicatedError":"MaterialDuplicatedError"
	},
	MaterialDuplicatedError:{
		message:"String"
	}
}