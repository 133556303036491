import React from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { useFetchProviderInformation } from 'requests/providers'

import Modal from 'common/Modal'
import InputWithLabel from 'common/InputWithLabel'
import CustomButton from 'common/CustomButton'

import request from 'utils/request'
import createNotification from 'utils/createNotification'

export default function ChangeEmailModal ({ closeModal }) {
  const { providerId } = useParams()
  const fetchProviderInformation = useFetchProviderInformation()
  const [newEmail, setNewEmail] = React.useState('')

  const isValid = React.useMemo(() => {
    if (!newEmail) return true
    const emailRe = /\S+@\S+\.\S+/
    return emailRe.test(newEmail)
  }, [newEmail])

  const changeEmailAttempt = React.useCallback(() => {
    request({
      method: 'put',
      url: `/admins/members/email/reset`,
      data: {
        provider: providerId,
        email: newEmail
      }
    })
      .then(() => {
        closeModal()
        createNotification({ message: 'Email successfully updated', type: 'success' })
        fetchProviderInformation()
      })
      .catch((error) => console.log(error))
  }, [providerId, newEmail, closeModal, fetchProviderInformation])

  return (
    <Modal
      title='Change email'
      closeModal={closeModal}
      minWidth={46}
      maxWidth={46}
      withSeparator
    >
      <ModalContent>
        <InputWithLabel
          className='input g-mt-15'
          label='New email'
          placeholder='Enter new email'
          value={newEmail}
          changeHandler={setNewEmail}
          isRequred
          isValid={isValid}
        />
        {!isValid && (
          <div className='error'>
            Email should contain '@' and '.' simbols
          </div>
        )}
        <CustomButton
          className='g-mt-20'
          disabled={!newEmail || !isValid}
          clickHandler={changeEmailAttempt}
        >
          Confirm
        </CustomButton>
      </ModalContent>
    </Modal>
  )
}

const ModalContent = styled.div`
  .input {
    max-width: 100%;
  }
  .error {
    color: #f86060;
    font-size: 1.2rem;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
  }
`
