import { createUseGlobalState, createUseSetGlobalState } from 'hooks/useGlobalState';

export const useProvidersListFilters = createUseGlobalState('providersListFilters');
export const useSetProvidersListFilters = createUseSetGlobalState('providersListFilters');

export const useProviderResponsibilities = createUseGlobalState('providerResponsibilities');
export const useSetProviderResponsibilities = createUseSetGlobalState('providerResponsibilities');

export const useProvidersList = createUseGlobalState('providersList');
export const useSetProvidersList = createUseSetGlobalState('providersList');

export const useProviderProfile = createUseGlobalState('providerProfile');
export const useSetProviderProfile = createUseSetGlobalState('providerProfile');

export const useProviderAppointments = createUseGlobalState('providerAppointments');
export const useSetProviderAppointments = createUseSetGlobalState('providerAppointments');

export const useProviderAdminNotes = createUseGlobalState('providerAdminNotes');
export const useSetProviderAdminNotes = createUseSetGlobalState('providerAdminNotes');

export const useProviderAdminNote = createUseGlobalState('providerAdminNote');
export const useSetProviderAdminNote = createUseSetGlobalState('providerAdminNote');
