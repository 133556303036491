import React, { FC } from 'react';

import { ReactComponent as EditIcon } from 'assets/edit.svg';
import { Hint } from './Hint';
import UserAvatar from 'common/UserAvatar';
import moment from 'moment';

export type ProviderDetails = {
  avatarUrl?: string;
  avatar?: string;
  fName?: string;
  lName?: string;
};
export type PatientDetails = {
  avatarUrl?: string;
  avatar?: string;
  fName?: string;
  lName?: string;
  mrn?: string;
};

type SummarySectionViewProps = {
  title?: string;
  summary?: string;
  createdAt?: string;
  patient?: PatientDetails;
  provider?: ProviderDetails;
  isPreview?: boolean;
  onEdit: () => void;
};

export const SummarySectionView: FC<SummarySectionViewProps> = ({ summary, title, isPreview, onEdit, provider, createdAt, patient }) => {
  return (
    <div className="shadow-row bg-catskillWhite p-6 border rounded-main my-6">
      <div className="pb-6">
        <div className="flex justify-between pb-2">
          <div className="text-2020 font-bold font-ambit">{isPreview ? title : 'Summary'}</div>
          {!isPreview && (
            <div onClick={onEdit} className="w-6 h-6 group rounded-full hover:bg-athensGray flex justify-center items-center cursor-pointer">
              <EditIcon className="group-hover:stroke-main" />
            </div>
          )}
        </div>
      </div>
      {!isPreview && <div className="border-t w-full h-px mb-6"></div>}
      {!summary && !isPreview && <Hint />}
      {summary && !isPreview && (
        <div className="relative ">
          <div className="ck-editor-view max-w-[110rem] max-h-[15rem] overflow-hidden pb-8" dangerouslySetInnerHTML={{ __html: summary }}></div>
          <div className="absolute bottom-0 bg-transCatskillWhite w-full h-8"></div>
        </div>
      )}
      {summary && isPreview && (
        <div className="ck-editor-view max-w-[110rem] overflow-hidden pb-8" dangerouslySetInnerHTML={{ __html: summary }}></div>
      )}

      {isPreview && (
        <>
          <div className="border-t w-full mt-4 h-px"></div>
          <section className="flex items-center py-4">
            <div className="w-[10%] text-1220  text-manatee uppercase">Provider</div>
            <div className="w-[30%] flex items-center ">
              <UserAvatar size={24} avatar={provider?.avatarUrl} firstName={provider?.fName} lastName={provider?.lName} />
              <div className="ml-2 text-1622 font-ambit font-semibold">
                {provider?.fName} {provider?.lName}
              </div>
            </div>
            <div className="w-[10%] text-1220  text-manatee uppercase">Created</div>
            <div className="flex-1 text-1622 font-ambit font-semibold">{createdAt ? moment(createdAt).format('MM.DD.YYYY') : '-'}</div>
          </section>
          <div className="border-t w-full h-px"></div>
          <section className="flex items-center pt-4">
            <div className="w-[10%] text-1220  text-manatee uppercase">Patient</div>
            <div className="w-[30%] flex items-center ">
              <UserAvatar size={24} avatar={patient?.avatarUrl} firstName={patient?.fName} lastName={patient?.lName} />
              <div className="ml-2 text-1622 font-ambit font-semibold">
                {patient?.fName} {patient?.lName}
              </div>
            </div>
            <div className="w-[10%] text-1220  text-manatee uppercase">MRN</div>
            <div className="flex-1 text-1622 font-ambit font-semibold">{patient?.mrn}</div>
          </section>
        </>
      )}
    </div>
  );
};
