import React from 'react'

import { useProviderAdminNotes } from 'hooks/useProviders'

import ListPreviewPlaceholder from 'common/loaders/ListPreviewPlaceholder'
import EmptyAdminNotes from './EmptyAdminNotes'
import AdminNotesList from './AdminNotesList'

export default function AdminNotesLoader () {
  const providerAdminNotes = useProviderAdminNotes()

  if (!providerAdminNotes) return <ListPreviewPlaceholder />

  if (providerAdminNotes.length === 0) return <EmptyAdminNotes />

  return <AdminNotesList />
}
