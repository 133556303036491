import React from 'react'
import get from 'lodash/get'

import { DAYS_OPTIONS, MONTH_OPTIONS, YEAR_OPTIONS } from 'constants/dates-options'

import { useLocalPatientProfile, useSetLocalPatientProfile } from 'hooks/usePatients'

import CustomSelect from 'common/CustomSelect'

export default React.memo(function BirthDate ({ isLoading }) {
  const localPatientProfile = useLocalPatientProfile()

  const birthDate = get(localPatientProfile, 'birth_date') || '--'
  const [yyyy, mm, dd] = birthDate.replace('n/a', '').split('-')
  

  return (
    <div className='g-mt-20 birthday'>
      <CustomSelect
        className='year'
        label='Birth date'
        isRequred
        isValid={!!yyyy}
        placeholder='Year'
        value={yyyy}
        options={YEAR_OPTIONS}
        changeHandler={useSetLocalPatientProfile((prev, year) => ({ ...prev, birth_date: [year, mm, dd].join('-') }), [mm, dd])}
        isLoading={isLoading}
        />
      <CustomSelect
        className='day'
        placeholder='Day'
        value={dd}
        isValid={!!dd}
        isRequred
        options={DAYS_OPTIONS}
        changeHandler={useSetLocalPatientProfile((prev, day) => ({ ...prev, birth_date: [yyyy, mm, day].join('-') }), [yyyy, mm])}
        isLoading={isLoading}
        />
      <CustomSelect
        className='month'
        placeholder='Month'
        isRequred
        isValid={!!mm}
        value={mm}
        options={MONTH_OPTIONS}
        changeHandler={useSetLocalPatientProfile((prev, month) => ({ ...prev, birth_date: [yyyy, month, dd].join('-') }), [yyyy, dd])}
        isLoading={isLoading}
      />
    </div>
  )
})
