import React from 'react'
import moment from 'moment'
import get from 'lodash/get'

import { usePatinetSummary } from 'hooks/usePatients'

import { MediumText } from 'common/texts'
import PropertyValue from 'common/PropertyValue'

export default function PlanDetails ({ isLoading }) {
  const patinetSummary = usePatinetSummary()

  const packageName = get(patinetSummary, 'patient.package', '')
  const statusPackage = get(patinetSummary, 'patient.status_package', '').toLowerCase()
  const expiresIn = get(patinetSummary, 'patient.active_until_date', '')
  const parsedExpiresIn = expiresIn !== 'n/a' ? moment(expiresIn).fromNow() : expiresIn
  const nextBillDate = get(patinetSummary, 'patient.next_bill_date', '')
  const parsedNextBillDate = nextBillDate !== 'n/a' ? moment(nextBillDate).format('Do MMMM YYYY') : nextBillDate

  const visits = get(patinetSummary, 'visits', [])
  const parsedVisits = React.useMemo(() => {
    return visits.map(visit => {
      const property = get(visit, 'name', '')
      const used = get(visit, 'used', '')
      const amount = get(visit, 'amount', '')
      return (
        <PropertyValue
          key={property}
          className='g-mt-5'
          isLoading={isLoading}
          property={property}
          value={`${used} / ${amount}`}
        />
      )
    })
  }, [visits, isLoading])

  return (
    <>
      <MediumText className='g-mt-20'>
        Membership
      </MediumText>

      <PropertyValue
        className='g-mt-5'
        isLoading={isLoading}
        property='Package'
        value={packageName}
      />
      <PropertyValue
        className='g-mt-5'
        isLoading={isLoading}
        property='Status'
        value={statusPackage}
      />
      <PropertyValue
        className='g-mt-5'
        isLoading={isLoading}
        property='Membership expires in'
        value={<span className='g-red'>{parsedExpiresIn}</span>}
      />
      <PropertyValue
        className='g-mt-5'
        isLoading={isLoading}
        property='Next Bill Date'
        value={parsedNextBillDate}
      />
      {parsedVisits}
    </>
  )
}
