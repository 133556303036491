import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { PATIENTS, DETAILS, MEDICATIONS, EDIT } from 'constants/routes'; 

import { useModalHandler } from 'hooks/useUtils'
import { useDeletePatientMedications } from 'requests/patients/medication'

import { MediumText } from 'common/texts'
import LinkButton from 'common/LinkButton'
import ConfirmationModal from 'common/ConfirmationModal'

export default React.memo(function MedicationsPreviewHeader ({ category, medicationsListId }) {
  const { patientId } = useParams()
  const history = useHistory()
  const deletePatientMedications = useDeletePatientMedications(medicationsListId)
  const [isOpen, openModal, closeModal] = useModalHandler()

  const navigateToEdit = React.useCallback(() => {
    history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${MEDICATIONS}/${medicationsListId}/${EDIT}`)
  }, [history, patientId, medicationsListId])

  return (
    <StyledWrapper className='g-d-flex g-justify-between g-align-center g-mb-25'>
      <MediumText>
        {category}
      </MediumText>
      <div className='g-d-flex'>
        <LinkButton
          className='button g-ml-10'
          onClick={navigateToEdit}
        >
          Edit
        </LinkButton>
        <LinkButton
          className='button g-ml-10'
          onClick={openModal}
        >
          Delete
        </LinkButton>
        {isOpen && (
          <ConfirmationModal
            closeModal={closeModal}
            confirmHandler={deletePatientMedications}
          />
        )}
      </div>
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  .button {
    min-width: 10rem;
  }
`
