import React from 'react'

import { ColumnWidePlate } from 'common/plate/plates'
import ChatInput from 'common/chat/ChatInput'
import InviteHeader from './InviteHeader'

export default React.memo(function RenameGroupContent () {
  return (
    <ColumnWidePlate>
      <InviteHeader />

      <ChatInput />
    </ColumnWidePlate>
  )
})
