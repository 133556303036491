import React from 'react'
import get from 'lodash/get'

import { usePatientOverview } from 'hooks/usePatients'

import CardTemplate from '../CardTemplate'
import List from './List'

export default function AppointmentsOverview () {
  const patientOverview = usePatientOverview()
  const appointments = get(patientOverview, 'appointments', [])

  if (appointments.length === 0) return null

  return (
    <CardTemplate title='Appointments'>
      <List appointments={appointments} />
    </CardTemplate>
  )
}
