import React from 'react'
import { useLocation, matchPath } from 'react-router-dom'
import get from 'lodash/get'

import { MESSAGES } from 'constants/routes'; 

import { useChatChannels, useSetMessageCounter } from 'hooks/useMessages'

export default function CountHandler () {
  const { pathname } = useLocation()
  const location = matchPath(pathname, {
    path: `/${MESSAGES}/:channelSID`,
    exact: true,
    strict: false
  })
  const channelSID = get(location, 'params.channelSID')

  const chatChannels = useChatChannels()
  const setMessageCounter = useSetMessageCounter((prev, next) => next, [])

  React.useEffect(() => {
    let currentCount = 0
    chatChannels.forEach(channel => {
      const index = get(channel, 'lastMessage.index')
      const consumedIndex = get(channel, 'lastConsumedMessageIndex')
      let nextMessagesCount = 0
      if (index !== undefined && consumedIndex === null) {
        nextMessagesCount = index + 1
      } else {
        nextMessagesCount = (index || 0) - (consumedIndex || 0)
      }
      if (channel.sid === channelSID) return
      currentCount = currentCount + nextMessagesCount
    })
    if (currentCount < 0) {
      setMessageCounter(0)
    } else {
      setMessageCounter(currentCount)
    }
  }, [chatChannels, channelSID, setMessageCounter])

  return null
}
