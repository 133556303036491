import React from 'react'
import styled from 'styled-components'

import { useDayData } from 'hooks/patient/widgets/useWidgets'

import onlyPositive from 'utils/onlyPositive'
import toRelative from 'utils/toRelative'

export default function WeightProportions () {
  const weight = useDayData('weight')
  const boneMass = useDayData('boneMass')
  const muscleMass = useDayData('muscleMass')
  const fatRatio = useDayData('fatRatio')

  const muscleRatio = toRelative(onlyPositive(muscleMass), onlyPositive(weight))
  const boneRatio = toRelative(onlyPositive(boneMass), onlyPositive(weight))

  return (
    <div className='g-d-flex g-w-100 g-mt-20' aria-hidden='true'>
      <StyledLine
        className='body-fat'
        bodyFat={onlyPositive(fatRatio)}
      />
      <StyledLine
        className='body-muscle g-ml-5'
        bodyMuscle={muscleRatio}
      />
      <StyledLine
        className='bone-mass g-ml-5'
        boneMass={boneRatio}
      />
    </div>
  )
}

const StyledLine = styled.div`
  width: 0;
  height: 0.7rem;
  border-radius: 2rem;
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.15);
  transition: width 0.2s;
  &.body-fat {
    background: #38dee8;
    width: ${({ bodyFat }) => bodyFat}%;
  }
  &.body-muscle {
    background: #0dc3f5;
    width: ${({ bodyMuscle }) => bodyMuscle}%;
  }
  &.bone-mass {
    background: #0a7dc7;
    width: ${({ boneMass }) => boneMass}%;
  }
`
