import React from 'react'

import { ReactComponent as Arrow } from 'assets/arrow-down.svg'

export default React.memo(function PrevButton ({ page = 1, navigateHandler }) {
  const navigateBack = React.useCallback(() => {
    if (page <= 1) return
    navigateHandler(page - 1)
  }, [page, navigateHandler])
  return (
    <div
      className={`pagination-button prev ${page === 1 ? 'disabled' : ''}`}
      onClick={navigateBack}
    >
      <Arrow className='icon' />
      Prev
    </div>
  )
})
