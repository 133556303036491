import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { DASHBOARD, CREATE, PROGRAMS } from 'constants/routes'

import { PlateHeader } from 'common/plate/styles';
import BackButton from 'common/plate/BackButton';
import CustomButton from 'common/CustomButton';
import { useUserContext } from '../../../context/userContext';
import { permissions } from "../../../permissions";

export default function CompaniesHeader() {
  const history = useHistory();
  const { can } = useUserContext();
  const navigateToCreating = React.useCallback(() => {
    history.push(`/${PROGRAMS}/${CREATE}`);
  }, [history]);

  return (
    <StyledPlateHeader>
      <BackButton href={`/${DASHBOARD}`} />
      Programs
      {can(permissions.companies.canAddNewCompany) && (
        <CustomButton className="add-new" clickHandler={navigateToCreating}>
          Add new
        </CustomButton>
      )}
    </StyledPlateHeader>
  );
}

const StyledPlateHeader = styled(PlateHeader)`
  padding: 1.5rem 2.5rem;
  .add-new {
    margin-left: auto;
    padding: 1rem 2rem;
  }
`;
