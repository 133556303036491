import React from 'react'
import { useParams } from 'react-router-dom'

import { PATIENT_FORMS } from 'constants/quiz-types'

import { RegularText } from 'common/texts'
import ProgressBar from '../../../common/ProgressBar'

export default React.memo(function Progress ({ total, step }) {
  const { type } = useParams()
  if (type === PATIENT_FORMS) return null
  const progress = (step / total) * 100
  return (
    <>
      <RegularText className='g-o-50'>Progress</RegularText>
      <ProgressBar progress={progress} />
    </>
  )
})
