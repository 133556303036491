import React from 'react'

import { RegularMediumText } from 'common/texts'

export default React.memo(function EmptyProviders () {
  return (
    <RegularMediumText>
      Providers not found
    </RegularMediumText>
  )
})
