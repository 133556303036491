import React, { FC, useEffect, useState } from 'react';
import { ReactComponent as MagnifierIcon } from 'assets/magnifier.svg';

type SearchType = { value: string; onChange: (val: string) => void, debounce?: number };

export const SearchField: FC<SearchType> = ({ value, onChange, debounce = 500 }) => {
  const [localText, setLocalText] = useState(value);

  useEffect(() => {
    if(debounce) {
      const timeout = setTimeout(() => {
        onChange(localText);
      }, debounce);
      return () => clearTimeout(timeout);
    }

  }, [localText, debounce]);

  return (
    <div className="relative">
      <div className="absolute top-[.7rem] left-2.5">
        <MagnifierIcon />
      </div>
      <input
        value={localText}
        onChange={(e) => setLocalText(e.target.value)}
        className="w-full h-7.5 border border-lavenderGray rounded-[1.6rem] text-1324 font-poppins pl-7.5 pr-3 placeholder:text-manatee text-main"
        type="text"
        placeholder="Search"
      />
    </div>
  );
};
