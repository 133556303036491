import React from 'react'

export default React.memo(function DevicesListener ({ setDeviceList }) {
  React.useEffect(() => {
    function updateDeviceList (e) {
      e.target.enumerateDevices().then((devices) => {
        setDeviceList(devices.filter(({ kind }) => kind === 'videoinput'))
      })
    }
    // listen for changing in devices configuration
    navigator.mediaDevices.addEventListener('devicechange', updateDeviceList)
    return () => navigator.mediaDevices.removeEventListener('devicechange', updateDeviceList)
    // eslint-disable-next-line
  }, [])
  return null
})
