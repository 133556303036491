import { useQueryClient } from '@tanstack/react-query';
import { GraphQLTypes, $, ValueTypes } from 'zeus-graphql/patient/zeus';
import { GQLArgs, useMutation } from '../client';
import { CREATE_LIBRARY_MATERIAL, GET_LIBRARY_MATERIALS } from '../constants';

type MutationResponse = Record<'createLibraryMaterial', GraphQLTypes['LibraryMaterialType'] | GraphQLTypes['MaterialDuplicatedError']>;

type Vars = Required<ValueTypes['MainMutation']>['createLibraryMaterial'][0];

type Args = GQLArgs<MutationResponse>;

export const useCreateLibraryMaterial = (args?: Args) => {
  const client = useQueryClient();
  const instance = useMutation(
    [],
    {
      createLibraryMaterial: [
        {
          data: $`data`,
        },
        {
          '...on LibraryMaterialType': {
            id: true,
            __typename: true,
          },
          '...on MaterialDuplicatedError': {
            __typename: true,
            message: true,
          },
          __typename: true,
        },
      ],
    },
    {
      errorTypes: [
        {
          __typename: 'MaterialDuplicatedError',
          entityName: 'createLibraryMaterial',
        },
      ],
      operationName: CREATE_LIBRARY_MATERIAL,
    },
    {
      onSuccess: () => {
        client.invalidateQueries([
          GET_LIBRARY_MATERIALS,
          JSON.stringify({
            perPage: 10,
            page: 1,
            filters: {},
          }),
        ]);
      },
    }
  );

  const createLibraryMaterial = instance.mutateAsync as (vars: Vars) => Promise<MutationResponse>;

  return { ...instance, createLibraryMaterial };
};
