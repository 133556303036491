import React from 'react'

import { RegularMediumText } from 'common/texts'

export default function EmptyPatients () {
  return (
    <RegularMediumText>
      Patients not found
    </RegularMediumText>
  )
}
