import React from 'react'
import styled from 'styled-components'

const linesConfig = (new Array(6)).fill().map((item, i) => (i + 1))

export default React.memo(function Lines () {
  return linesConfig.map(item => {
    return (
      <StyledLine
        key={item}
        item={item}
      />
    )
  })
})

const StyledLine = styled.div`
  position: absolute;
  width: 100%;
  height: 0;
  border-bottom: dashed 1px #e9ebef;
  bottom: ${({ item }) => item * 3}rem;
  z-index: 0;
`
