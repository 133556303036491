import React from 'react';
import get from 'lodash/get';
import styled from 'styled-components';
import { useUser } from 'hooks/useGlobalState';
import LogoIcon from 'common/LogoIcon';
import UserInfo from './UserInfo';
import DesktopSidebar from './desktop/Sidebar';
import DesktopFooter from './desktop/Footer';
import MobileHeader from './mobile/header';
import MobileFooter from './mobile/footer';
import ExpirationModal from 'components/expiration-modal';
import PasswordChange from 'components/password-change';
import { TitleContainer } from 'components/HeaderTitle';
import { BreadcrumbsContainer } from 'components/Breadcrumbs';

const PatientTemplate = ({ children }) => {
  const userId = useUser((user) => get(user, 'id'), []);

  const content = React.useMemo(() => {
    return userId ? children : null;
  }, [userId, children]);

  return (
    <>
      <StyledWrapper>
        <div>
          <StyledTopBar>
            <div className="flex">
              <LogoIcon />
              <div className="ml-14">
                <TitleContainer />
                <BreadcrumbsContainer />
              </div>
            </div>
            <UserInfo />
          </StyledTopBar>

          <StyledContent>
            <DesktopSidebar />
            <MobileHeader />
            <StyledContentWrapper>{content}</StyledContentWrapper>
            <MobileFooter />
          </StyledContent>
        </div>
        <DesktopFooter />
      </StyledWrapper>

      <ExpirationModal />
      <PasswordChange />
    </>
  );
};

export default React.memo(PatientTemplate);

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  padding: 2.8rem 3.5rem 3rem;
  max-width: 1920px;
  min-height: 100vh;
  @media only screen and (max-width: 1024px) {
    padding: 0 3.5rem 7.8rem;
    min-height: 100vh;
  }
  @media only screen and (max-width: 480px) {
    padding: 0 0.5rem 7.8rem;
  }
`;

const StyledTopBar = styled.div`
  display: none;
  @media only screen and (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
  }
`;

const StyledContent = styled.div`
  @media only screen and (min-width: 1024px) {
    display: flex;
    margin-top: 2.2rem;
  }
`;

const StyledContentWrapper = styled.div`
  @media only screen and (min-width: 1024px) {
    flex-grow: 1;
  }
`;
