import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import { useModalHandler } from 'hooks/useUtils'
import { useDownloadPatinetDocument, useFetchPatinetDocuments } from 'requests/patients'

import { UppercaseText, RegularText } from 'common/texts'
import LinkButton from 'common/LinkButton'
import ConfirmationModal from 'common/ConfirmationModal'

import request from 'utils/request'
import createNotification from 'utils/createNotification'

export default React.memo(function DocumentPreview ({
  id, name, uploaded, uploadedBy, isOwner
}) {
  const downloadPatinetDocument = useDownloadPatinetDocument(id)
  const fetchPatinetDocuments = useFetchPatinetDocuments(id)

  const [isOpen, openModal, closeModal] = useModalHandler()

  const deleteDocument = React.useCallback(() => {
    request({
      method: 'delete',
      url: `/documents/${id}`
    })
      .then(() => {
        fetchPatinetDocuments()
        createNotification({ message: 'Document successfully deleted', type: 'success' })
      })
      .catch((error) => console.log(error))
      .finally(closeModal)
  }, [id, fetchPatinetDocuments, closeModal])

  return (
    <StyledWrapper>
      <div className='mrn g-mt-5 g-mb-5'>
        <UppercaseText>document name</UppercaseText>
        <RegularText className='name'>{name}</RegularText>
      </div>

      <div className='author g-mt-5 g-mb-5'>
        <UppercaseText>author</UppercaseText>
        <RegularText>{uploadedBy}</RegularText>
      </div>

      <div className='uploaded g-mt-5 g-mb-5'>
        <UppercaseText>uploaded</UppercaseText>
        <RegularText>{moment.utc(uploaded).local().fromNow()}</RegularText>
      </div>

      <LinkButton
        className='view'
        onClick={downloadPatinetDocument}
      >
        View document
      </LinkButton>
      {isOwner && (
        <LinkButton
          className='delete'
          onClick={openModal}
        >
          Delete
        </LinkButton>
      )}
      {isOpen && (
        <ConfirmationModal
          closeModal={closeModal}
          confirmHandler={deleteDocument}
        />
      )}
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  padding: 2.4rem;
  margin-bottom: 1.5rem;
  background: #fafbfd;
  border-radius: 0.8rem;
  display: flex;
  .name {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .mrn {
    width: 30%;
  }
  .author {
    width: 20%;
  }
  .uploaded {
    width: 20%;
  }
  .view {
    margin: auto 0 auto 1.5rem;
    white-space: nowrap;
  }
  .delete {
    margin: auto 0 auto 1.5rem;
  }
  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: space-between;
    .mrn {
      width: 30%;
      text-align: center;
    }
    .author {
      width: 30%;
      text-align: center;
    }
    .uploaded {
      width: 30%;
      text-align: center;
    }
    .view {
      width: 48%;
      margin: 1rem 0 0 0;
    }
    .delete {
      width: 48%;
      margin: 1rem 0 0 0;
    }
  }
`
