const functionsToCall = {}

const forms = ['createPatinetDoctorNoteDraft', 'updateDoctorNoteDraft']

export default function onbeforeunloadHandler ({ key, func }) {
  functionsToCall[key] = func
  window.onbeforeunload = function (event) {
    Object.values(functionsToCall).forEach((currentFunc) => currentFunc(event))
    if (forms.find(item => functionsToCall[item])) {
      const dialogText = 'Changes that you made may not be saved.'
      event.returnValue = dialogText
      return dialogText
    }
  }
}
