import React from 'react'

import { FlexLine } from 'common/plate/styles'
import ChatsList from 'components/messages/ChatsList'
import ChatContent from 'components/messages/ChatContent'

export default function Messages () {
  return (
    <FlexLine>
      <ChatsList />

      <ChatContent />
    </FlexLine>
  )
}
