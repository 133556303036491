import React from 'react'

export default React.memo(function PatientFeelingGood () {
  return (
    <div>
      Happy to hear that!<br />
      Just remember that you can always message us and we do everything to help you
      to achive your health goals!
    </div>
  )
})
